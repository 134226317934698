import axios from 'axios';
import { config } from './firebase';
import { Day, Programs, Set, SetKeys, Workout } from 'interfaces/db';
import { SelectOptions, ExerciseLibInitialValues, DATA, Entries, SetParam } from 'interfaces/utils';
import { COACH_WHITE_LIST, ANALYTICS_WHITE_LIST } from './constants';
import regexCreator from 'emoji-regex';
import { Buffer } from 'buffer';

const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const monthsLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const FREE_PLAN_MAX_CLIENTS = 5;
export const BASE_URL = `https://us-central1-${config.projectId}.cloudfunctions.net`;

export const formatQueryParams = (val: { [key: string]: string }): string => {
  return Object.keys(val)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(val[key])}`)
    .join('&');
};

export const getDateFromSeconds = (val: number): string => {
  const dateObj = new Date(val);

  const day = dateObj.getDay();
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();

  return `${daysLong[day]}, ${monthsLong[month]} ${date} ${year}`;
};

export const getNextBillingDate = (nextBillingDate: number): string => {
  if (!nextBillingDate) {
    return 'N/A';
  }

  return getDateFromSeconds(nextBillingDate);
};

export const getInitials = (fullName = ''): string => {
  let initials = '';
  const splitName = fullName.split(' ');

  if (splitName[0]) {
    initials = splitName[0].charAt(0);
  }

  if (splitName[1]) {
    initials = `${initials}${splitName[1].charAt(0)}`;
  }

  return initials.toUpperCase();
};

const reducerFn = (accumulated: any, current: any): any => {
  return accumulated && accumulated[current] ? accumulated[current] : undefined;
};

export const getNestedValue = (arrayOfKeys: any, object: any): any => {
  return arrayOfKeys.reduce(reducerFn, object);
};

export const currencyFormat = (val: number | string): string => {
  return Number(val)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const renderLabel = (val: string, suffix: string): string => {
  const valInt = parseInt(val, 10);

  if (valInt === 0) {
    return '';
  }

  // handle KG
  if (suffix === 'kg' && (!valInt || isNaN(valInt))) {
    return ` | ${val}`;
  }

  return ` | ${val} ${suffix}`;
};

export const getSetsLabel = (key: SetKeys, val: string): string | null => {
  switch (key) {
    case 'reps':
      return renderLabel(val, 'reps');

    case 'rpe':
      return renderLabel(val, 'rpe');

    case 'weight':
      return renderLabel(val, 'kg');

    case 'weightLBS':
      return renderLabel(val, 'lbs');

    case 'weightRM':
      return renderLabel(val, '%');

    case 'rir':
      return renderLabel(val, 'rir');

    case 'time':
      return renderLabel(val, 'secs');

    case 'timeMinutes':
      return renderLabel(val, 'mins');

    case 'distance':
      return renderLabel(val, 'distance');

    case 'calories':
      return renderLabel(val, 'calories');

    case 'percentage':
      return renderLabel(val, 'percentage');

    case 'rest':
      return renderLabel(val, 'rest');

    default:
      return renderLabel(val, 'resistance');
  }
};

export const getSetCountLabel = (count: number): string | null => {
  if (!count) {
    return null;
  }

  return count === 1 ? `${count} set` : `${count} sets`;
};

export const customStyles: any = {
  control: (provided: any) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    transitionProperty: 'all',
    transitionDuration: '150ms',
    backgroundColor: '#3c4148',
    transitionTimingFunction: 'ease-in',
    ':hover': { cursor: 'pointer', backgroundColor: '#545A62' },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    width: '100%',
    color: '#fff',
    textAlign: 'center',
    fontSize: '0.85rem',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: state.selectProps && state.selectProps.isSelected ? '#fff' : '#fff',
    ':hover': { color: '#fff' },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#545A62',
  }),
  menuList: (provided: any) => ({
    ...provided,
    borderRadius: 4,
    maxHeight: '10rem',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#fff',
    textAlign: 'center',
    fontSize: '0.85rem',
    transitionProperty: 'all',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-in',
    backgroundColor: state.isSelected ? '#878B94' : '#545A62',
    ':hover': { cursor: 'pointer', backgroundColor: '#878B94' },
  }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    color: '#fff',
  }),
};

export const setKeysMap: SelectOptions[] = [
  { label: 'Reps', value: 'reps' },
  { label: 'Weight (kg)', value: 'weight' },
  { label: 'RPE', value: 'rpe' },
  { label: 'Resistance', value: 'resistance' },
  { label: 'RIR', value: 'rir' },
  { label: 'Time (s)', value: 'time' },
  { label: 'Time (m)', value: 'timeMinutes' },
  { label: 'Weight (lbs)', value: 'weightLBS' },
  { label: '% 1RM', value: 'weightRM' },
  { label: 'Percentage (%)', value: 'percentage' },
  { label: 'Distance (m)', value: 'distance' },
  { label: 'Calories', value: 'calories' },
  { label: 'Rest (s)', value: 'rest' },
  // { label: 'Carbs', value: 'carbs' },
  // { label: 'Fat', value: 'fat' },
  // { label: 'Protein', value: 'protein' },
  // { label: 'Steps', value: 'steps' },
  // { label: 'Litres', value: 'litres' },
  // { label: 'Hours', value: 'hours' },
];

export const setKeysObject: { [key in SetParam]: SelectOptions } = {
  reps: { label: 'Reps', value: 'reps' },
  weight: { label: 'Weight (kg)', value: 'weight' },
  rpe: { label: 'RPE', value: 'rpe' },
  resistance: { label: 'Resistance', value: 'resistance' },
  rir: { label: 'RIR', value: 'rir' },
  time: { label: 'Time (s)', value: 'time' },
  timeMinutes: { label: 'Time (m)', value: 'timeMinutes' },
  weightLBS: { label: 'Weight (lbs)', value: 'weightLBS' },
  percentage: { label: 'Percentage (%)', value: 'percentage' },
  weightRM: { label: '% 1RM', value: 'weightRM' },
  distance: { label: 'Distance (m)', value: 'distance' },
  calories: { label: 'Calories', value: 'calories' },
  rest: { label: 'Rest (s)', value: 'rest' },
  // carbs: { label: 'Carbs', value: 'carbs' },
  // fat: { label: 'Fat', value: 'fat' },
  // protein: { label: 'Protein', value: 'protein' },
  // steps: { label: 'Steps', value: 'steps' },
  // litres: { label: 'Litres', value: 'litres' },
  // hours: { label: 'Hours', value: 'hours' },
};

export const MAX_SETS_LENGTH = 3;

export const emptyOption = { value: null, label: '-' };

export const emptySetObject = {
  achieved: '',
  programmed: '',
};

export const YOUTUBE_REGEX =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=|shorts\/))((\w|-){11})(?:\S+)?$/;

export const getThumbnailURL = async (url: string): Promise<string> => {
  const matches = url.match(YOUTUBE_REGEX);
  if (matches) {
    return `https://img.youtube.com/vi/${matches[1]}/0.jpg`;
  } else if (url.includes('vimeo')) {
    try {
      const result = await axios.get(`https://vimeo.com/api/oembed.json?url=${url}`);
      return result.data.thumbnail_url;
    } catch (error) {
      console.error(`Error retrieving thumbnail URL (${url}):`, error);
    }
  }
  return '';
};

export const formValues: ExerciseLibInitialValues = {
  type: '',
  videoURL: '',
  sets: ['reps', 'weight', 'rpe'],
  exerciseNotes: {
    coach: '',
    athlete: '',
  },
};

export const getToday = () => {
  const today = new Date();
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
  return date + ' ' + time;
};

export const handleScroll = (divId: string, offset: number) => {
  const element = document.querySelector(`#${divId}`);
  if (element) {
    const y = element?.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

export const encodeData = (data: any) => {
  return Buffer.from(data).toString('base64');
};

export const isEligibleCoach = (coachUserId: string | null, analytics?: boolean) => {
  if (coachUserId) {
    return analytics ? ANALYTICS_WHITE_LIST.indexOf(coachUserId) !== -1 : COACH_WHITE_LIST.indexOf(coachUserId) !== -1;
  }
  return false;
};

const emojiRegex = regexCreator();

export const emoji_strip = (string: string) => {
  return string.replace(emojiRegex, '');
};

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const convertDate = (date: string) => {
  const [day, month, year] = date.split('/');

  return `${day} ${months[+month - 1]} ${year}`;
};

export const convertMonth = (date: string) => {
  const [month, year] = date.split('_');
  return `${months[+month - 1]} ${year}`;
};

export const getWeightAverage = (val: Entries[]) => {
  return val.reduce((acc, cur, _, arr) => {
    return Math.round(acc + cur.value / arr.length);
  }, 0);
};

export const getSecsFromMnthString = (date: string): number => {
  if (!date) {
    return 0;
  }

  const dateParts = date.split('_');
  const year = dateParts[1]?.trim();
  const month = dateParts[0]?.trim();

  const dateObject = new Date(+year, +month, 0);

  return dateObject.getTime();
};

export const getSecsFromDayString = (date: string): number => {
  if (!date) {
    return 0;
  }

  const dateParts = date.split('/');
  const year = dateParts[2]?.trim();
  const month = dateParts[1]?.trim();
  const day = dateParts[0]?.trim();

  const dateObject = new Date(+year, +month - 1, +day);

  return dateObject.getTime();
};

export const getParsedMonths = (data: DATA[], period: number): DATA[] => {
  switch (period) {
    case 0.25: {
      const date = new Date();

      // filter for dates btw this period
      const currentTime = date.getTime();
      const lastDayOfWeekTime = new Date(date.setDate(date.getDate() - 7)).getTime();

      return data
        .filter((_, i) => i === 0)
        .map((el) => {
          const filteredData = el.data.filter((el) => el.dayToSecs >= lastDayOfWeekTime && el.dayToSecs <= currentTime);
          return { ...el, data: filteredData };
        });
    }

    case 1: {
      const date = new Date();

      // filter for dates btw this period
      const currentTime = date.getTime();
      const lastDayOfMonthTime = new Date(date.setDate(date.getDate() - 30)).getTime();

      return data
        .filter((_, i) => i === 0)
        .map((el) => {
          const filteredData = el.data.filter(
            (el) => el.dayToSecs >= lastDayOfMonthTime && el.dayToSecs <= currentTime,
          );
          return { ...el, data: filteredData };
        });
    }

    default: {
      const monthsInYear = 12;

      // get current date
      let years = 0;
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;

      if (period >= monthsInYear) {
        years = period / monthsInYear;
      }

      year = date.getFullYear() - years;
      month = date.getMonth() + 1 - period;

      // get last day time
      const lastDayOfMonth = new Date(year, month + 1, 0);
      const time = lastDayOfMonth.getTime();

      return data.filter((el) => el.monthToSecs >= time);
    }
  }
};

export const getDateStringWithNoSeparators = (date: Date | string): string => {
  const tempDate = new Date(date);
  const day = tempDate.getDate();
  const month = tempDate.getMonth() + 1;
  const year = tempDate.getFullYear();
  return `${year}${String(month).padStart(2, '0')}${String(day).padStart(2, '0')}`; // YYYYMMDD - this makes it easier to sort as the first number won't have a zero in front
};

// export const groupByMonth = (getKey: any, items: any) => {
//   return items.reduce((groups: any, item: any) => {
//     const k = getKey(item);
//     if (!groups[k]) {
//       groups[k] = { title: k, data: [item] };
//     } else {
//       groups[k].data.push(item);
//     }
//     return groups;
//   }, {});
// };

export const groupByMonth = (getKey: any, items: any) => {
  return items.reduce((groups: any, item: any) => {
    const k = getKey(item);
    if (!groups[k]) {
      groups[k] = [item];
    } else {
      groups[k].push(item);
    }
    return groups;
  }, {});
};

export const groupByYear = (getKey: any, items: any) => {
  return items.reduce((groups: any, item: any) => {
    const k = getKey(item);
    if (!groups[k]) groups[k] = [item];
    else groups[k].push(item);
    return groups;
  }, {});
};

export function flatDeep(arr: any, d = 1) {
  return d > 0
    ? arr.reduce((acc: any, val: number) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
    : arr.slice();
}

export const createDefaultSet = (defaultSetParams: SetParam[]): Set => {
  const newSet: Set = {
    completed: false,
  };

  let index = 1;
  for (const param of defaultSetParams) {
    newSet[param] = { ...emptySetObject, index };
    index++;
  }
  return newSet;
};

export const getCurrencySymbol = (currency) => {
  if (currency === 'USD') {
    return '$';
  } else if (currency === 'ZAR') {
    return 'R';
  } else if (currency === 'GBP') {
    return '£';
  } else {
    return '$';
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const checkIfUserIsInSouthAfrica = async (): Promise<boolean> => {
  try {
    const response = await axios.get('https://api.country.is');
    const country = response.data.country;
    return country === 'ZA';
  } catch (error) {
    console.error('Error retrieving country information:', error);
    return false;
  }
};

export const findWorkoutById = (program: Programs, workoutId: string): Workout | undefined | null => {
  if (!program.weeks) return null;

  const weekIndex = program.weeks?.findIndex((week) =>
    week?.Days?.some((day) => !day.rest && day.workout?.workoutId === workoutId),
  );

  if (weekIndex === -1) return null;

  const dayIndex = program.weeks?.[weekIndex]?.Days.findIndex(
    (day: Day) => !day.rest && day.workout?.workoutId === workoutId,
  );

  if (dayIndex === -1) return null;

  const workout: Workout = program.weeks[weekIndex].Days[dayIndex].workout;

  return {
    weekIndex,
    dayIndex,
    ...workout,
  };
};

/**
 * Check if a string is empty or consists only of whitespace characters.
 * @param value The string to check.
 * @returns True if the string is empty or whitespace, otherwise false.
 */
export function isEmptyOrWhitespace(value: string | null): boolean {
  if (!value) return true;
  return value.trim().length === 0;
}

export const getUniqueIndex = (group, index, isPrev: boolean) => {
  return `group${group}-index${index}-${isPrev ? 'prev' : 'next'}`;
};

export function insertElementAt<T>(array: T[], index: number, newElement: T): T[] {
  // Create a copy of the array to avoid mutating the original array
  const newArray = [...array];

  // Insert 'newElement' at 'index', 0 means no elements are removed
  newArray.splice(index, 0, newElement);

  return newArray;
}
