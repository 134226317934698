import mixpanel from 'mixpanel-browser';

export type AnyProperties = {
  [key: string]: any;
};

export type EventProperties = AnyProperties & {
  object?: 'button' | 'link';
  action?: 'click' | 'view' | 'submit';
  location?: string;
  source?: string;
  preposition?: string;
  button?: {
    text: string;
    action: string;
    description?: string;
  };
};

export class MixpanelService {
  protected trackEvent(eventName: string, properties?: EventProperties): void {
    if (!eventName || typeof eventName !== 'string') {
      console.error('Invalid event name');
      return;
    }

    const defaultProperties = {
      platform: 'web',
    };

    const eventProperties = { ...defaultProperties, ...properties };
    mixpanel.track(eventName, eventProperties);
  }

  protected identify(userId: string): void {
    mixpanel.identify(userId);
  }

  protected reset(): void {
    mixpanel.reset();
  }

  protected setGroup(groupKey: string, ids: string | string[]): void {
    mixpanel.set_group(groupKey, ids);
  }

  protected setProperties(properties: AnyProperties): void {
    mixpanel.people.set(properties);
  }

  protected setPropertiesOnce(properties: AnyProperties): void {
    mixpanel.people.set_once(properties);
  }
}
