import React, { FC, ReactNode } from 'react';
import classes from './CustomToast.module.css';
import { ReactComponent as TickIcon } from 'assets/svgs/tick-outline.svg';
import { ReactComponent as ErrorIcon } from 'assets/svgs/error-filled.svg';
import clsx from 'clsx';

type Props = {
  appearance: 'success' | 'error' | 'warning' | 'info';
  children: ReactNode;
};

const CustomToast: FC<Props> = ({ appearance, children }) => {
  return (
    <div className={classes.Container}>
      <div className={clsx(classes.Icon, appearance === 'success' ? classes.SuccessIcon : classes.ErrorIcon)}>
        {appearance === 'success' ? <TickIcon /> : <ErrorIcon />}
      </div>
      <span className={classes.message}>{children}</span>
    </div>
  );
};

export default CustomToast;
