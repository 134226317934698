import React, { FC, useEffect, useRef, useState } from 'react';
import { SetKeys, Day as DayType } from 'interfaces/db';
import QuickEdit from '../QuickEdit';
import classes from './QuickEditModal.module.css';
import Dialog from 'components/UI/Dialog';
import { Button } from 'components/UI';
import { programTrackingService } from 'utils/tracking/programService';

interface Props {
  showModal: boolean;
  day: DayType;
  dayNumber: number;
  weekNumber: number;
  saveProgram: (workoutId?: string) => void;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number) => void;
  addWorkoutNotes: (workoutNotes: string, dayIndex: number, weekIndex: number) => void;
  addSetHandler: (group: number, index: number) => void;
  removeSetHandler: (group: number, index: number) => void;
  setsChangeHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  autoFillSetsHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousDay?: DayType | undefined;
  clientId?: string;
  coachId?: string | null;
  weightColor: string;
  volumeColor: string;
  programmedTextColor: string;
  achievedTextColor: string;
  weightTextColor: string;
  volumeTextColor: string;
}

interface QuickEditProps {
  setIsQuickEdit: (val: boolean) => void;
}

const QuickEditModal: FC<Props & QuickEditProps> = ({
  showModal,
  day,
  dayNumber,
  weekNumber,
  saveProgram,
  setIsQuickEdit,
  addExerciseNotes,
  addWorkoutNotes,
  addSetHandler,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  previousDay,
  clientId,
  coachId,
  weightColor,
  volumeColor,
  programmedTextColor,
  achievedTextColor,
  weightTextColor,
  volumeTextColor,
}) => {
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  const [activeAnalyticsExercise, setActiveAnalyticsExercise] = useState<string>();

  const openAnalytics = (index: string) => {
    programTrackingService.trackProgramEvent('open_analytics', {
      location: 'quick_edit',
    });
    setActiveAnalyticsExercise(index);
  };

  const closeAnalytics = () => {
    setActiveAnalyticsExercise('');
  };

  useEffect(() => {
    if (!showModal) {
      closeAnalytics();
    }
  }, [showModal]);

  return (
    <Dialog title="" open={showModal} onOpenChange={setIsQuickEdit}>
      <div className={classes.ContentContainer}>
        <div className={classes.ModalHeader}>
          <h5>Quick Edit</h5>
          <p>
            Week {weekNumber + 1} Day {dayNumber + 1}
          </p>
        </div>
        <div className={classes.ModalInnerContainer} ref={scrollableDivRef}>
          <QuickEdit
            day={day}
            dayNumber={dayNumber}
            weekNumber={weekNumber}
            addSetHandler={addSetHandler}
            removeSetHandler={removeSetHandler}
            addExerciseNotes={addExerciseNotes}
            addWorkoutNotes={addWorkoutNotes}
            setsChangeHandler={setsChangeHandler}
            autoFillSetsHandler={autoFillSetsHandler}
            setShowAutoFill={setShowAutoFill}
            showAutoFill={showAutoFill}
            previousDay={previousDay}
            openAnalytics={openAnalytics}
            closeAnalytics={closeAnalytics}
            userId={clientId || coachId}
            activeAnalyticsExercise={activeAnalyticsExercise}
            weightColor={weightColor}
            volumeColor={volumeColor}
            programmedTextColor={programmedTextColor}
            achievedTextColor={achievedTextColor}
            scrollableDivRef={scrollableDivRef}
            weightTextColor={weightTextColor}
            volumeTextColor={volumeTextColor}
          />
        </div>
        <div className={classes.ButtonGroup}>
          <div className={classes.ButtonWrapper}>
            <Button onClick={() => setIsQuickEdit(false)}>Close</Button>
          </div>
          <div className={classes.ButtonWrapper}>
            <Button
              onClick={() => {
                saveProgram(day?.workout?.workoutId);
                setIsQuickEdit(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default QuickEditModal;
