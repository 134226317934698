import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useToasts, AddToast } from 'react-toast-notifications';

import { LoginForm } from 'components';
import { RootState } from 'store';
import { REGISTER } from 'utils/routes';
import { login, setAuthLoading } from 'store/actions/auth';
import Logo from 'components/Logo';
import withoutAuth from 'utils/withoutAuth';
import classes from './Auth.module.css';

const mapStateToProps = ({ auth, settings }: RootState) => {
  return {
    loading: auth.loading,
    theme: settings.theme,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogin: (email: string, password: string, addToast: AddToast) => dispatch(login(email, password, addToast)),
    onSetAuthLoading: (loading: boolean) => dispatch(setAuthLoading(loading)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type FormValues = {
  email: string;
  password: string;
};

const Auth: FC<PropsFromRedux> = ({ loading, theme, onLogin, onSetAuthLoading }) => {
  const { addToast } = useToasts();

  const handleLogin = ({ email, password }: FormValues) => {
    onLogin(email, password, addToast);
  };

  useEffect(() => {
    onSetAuthLoading(false);
    return () => {
      onSetAuthLoading(false);
    };
  }, []);

  return (
    <main>
      <div className={classes.Header}>
        <div className={classes.Logo}>
          <Logo link="https://www.coachd.ai/" auth={true} dashboard={false} />
        </div>

        <div className={classes.AuthButtons}>
          <p className={classes.NotAMemberText}>Not a member?</p>
          <NavLink to={{ pathname: REGISTER.URL }} className={classes.SignupButton}>
            Sign up now
          </NavLink>
        </div>
      </div>

      <div className={classes.Auth}>
        <div className={classes.AuthWrapper}>
          <div className={classes.AuthContainer}>
            <h1 className={classes.AuthHeading}>Welcome back</h1>
            <div className={classes.UserForm}>
              <LoginForm loading={loading} handleSubmit={handleLogin} theme={theme} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default connector(withoutAuth(Auth));
