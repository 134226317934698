import React, { FC } from 'react';
import classes from './Toolbar.module.css';
import { Button } from 'components';
import { ReactComponent as SaveIcon } from 'assets/svgs/save-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';
import { ReactComponent as CompleteIcon } from 'assets/svgs/complete-filled.svg';

interface Props {
  changed: boolean;
  setShowSavePlan: (show: boolean) => void;
  setShowRemovePlan?: (show: boolean) => void;
  setShowCompletePlan: (show: boolean) => void;
}

const Toolbar: FC<Props> = ({ changed, setShowSavePlan, setShowRemovePlan, setShowCompletePlan }) => {
  return (
    <div className={classes.Container}>
      <h4 className={classes.Heading}>Nutrition Program</h4>
      <div className={classes.Buttons}>
        {setShowRemovePlan && (
          <div>
            <Button type="button" intent="danger" onClick={() => setShowRemovePlan(true)} iconLeft={<DeleteIcon />}>
              REMOVE
            </Button>
          </div>
        )}
        {setShowCompletePlan && (
          <div>
            <Button
              type="button"
              intent="complete"
              onClick={() => setShowCompletePlan(true)}
              iconLeft={<CompleteIcon />}
            >
              COMPLETE
            </Button>
          </div>
        )}
        <div>
          <Button
            type="button"
            intent="success"
            onClick={() => setShowSavePlan(true)}
            disabled={!changed}
            iconLeft={<SaveIcon />}
          >
            SAVE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
