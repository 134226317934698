import React, { FC } from 'react';
import classes from './ToggleSwitch.module.css';
import * as Switch from '@radix-ui/react-switch';

type Props = {
  checked: boolean;
  onChange: () => void;
};

const ToggleSwitch: FC<Props> = ({ checked, onChange }) => (
  <Switch.Root className={classes.SwitchRoot} onCheckedChange={onChange} checked={checked} id="toggle">
    <Switch.Thumb className={classes.SwitchThumb} />
  </Switch.Root>
);

export default ToggleSwitch;
