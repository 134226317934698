import React, { FC } from 'react';

import classes from './ClientAssignCard.module.css';
import NameBadge from 'components/NameBadge';
import clsx from 'clsx';

interface Props {
  name: string;
  initials: string;
  assigned: boolean;
  setAssignedClient: () => void;
}

const ClientAssignCard: FC<Props> = ({ name, initials, assigned = false, setAssignedClient }) => {
  return (
    <div className={clsx(classes.Client, assigned && classes.Selected)} onClick={setAssignedClient}>
      <NameBadge initials={initials} />
      <p className={classes.ClientName}>{name}</p>
    </div>
  );
};

export default ClientAssignCard;
