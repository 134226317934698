import React, { FC, useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useToasts } from 'react-toast-notifications';
import queryString from 'query-string';

import classes from './Community.module.css';
import { CHAT } from 'utils/routes';
import Layout from '../Layout';
import withAuth from 'utils/withAuth';
import Message from 'components/Message';
import { CommunityForm, ChannelsSidebar, ChatSettings } from 'components';
import { isEligibleCoach } from 'utils/helpers';
import { Channel, ChatSettings as ChatSettingsType, Clients, NotificationData } from 'interfaces/db';
// REDUX
import { RootState } from 'store';
import { connect, ConnectedProps } from 'react-redux';
import Spinner from 'components/UI/Spinner';

// FIREBASE
import firebase from 'firebase/app';
import { firestore, storage } from 'utils/firebase';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { UploadTaskSnapshot, UploadTask, FullMetadata } from '@firebase/storage-types';

import DisabledChat from 'components/Chat/DisabledChat';
import Dialog from 'components/UI/Dialog';
import EmptyState from 'components/EmptyState';
import { chatTrackingService } from 'utils/tracking/chatService';

const mapStateToProps = ({ clients, auth, settings }: RootState) => {
  const { userId, coachProfile } = auth;
  return {
    clients: clients.clients,
    userId: userId,
    coachProfile: coachProfile,
    coachKey: Object.keys(coachProfile || {})[0],
    preference: settings.preference,
  };
};

export type ChannelData = {
  id: string;
  title?: string;
  type?: string;
};

type LocationState = {
  selectedChannelId?: string;
};

type FormValues = {
  text: string;
  file: any;
  channel: ChannelData;
};

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector> | any;

const Community: FC<PropsFromRedux> = ({ userId, coachProfile, coachKey, clients, preference }) => {
  const { addToast } = useToasts();

  const { search } = useLocation<LocationState>();
  const parsed = queryString.parse(search);
  const { channel: selectedChannelId = 'Chat-room' } = parsed || {};
  const [updatedChannels, setUpdatedChannels] = useState(false);
  const isMountedRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<ChannelData>({
    id: 'Chat-room',
    title: 'All Clients',
    type: 'community',
  });
  const [channels, setChannels] = useState<any[]>([]);
  const [showSettings, setShowSettings] = useState(false);
  const [chatSettings, setChatSettings] = useState<ChatSettingsType>({
    isCommunityChatEnabled: true,
    isDirectChatEnabled: false,
  });

  const dummy = useRef<null | HTMLDivElement>(null);
  const coachesRef = firestore?.collection('coaches');
  const coachDocument = coachesRef?.doc(userId || 'test');
  const channelsRef = coachDocument?.collection('channels');

  useEffect(() => {
    isMountedRef.current = true;
    channelsRef.get().then((snapshot) => {
      if (isMountedRef.current) {
        let docs = snapshot?.docs?.map((doc) => {
          return {
            id: doc.id,
            data: doc.data(),
          } as Channel;
        });

        // write a fucntion to look for the channel with the id euqal to 'Chat-room', and if it doesn't exist then add it into the array of channels
        const chatRoomDoc = docs?.find((channel) => channel.id === 'Chat-room');
        const chatRoom: Channel = {
          id: 'Chat-room',
          data: { channelTitle: 'All Clients', type: 'community' },
        };
        if (!chatRoomDoc) {
          docs = [chatRoom, ...docs];
        }
        setChannels(docs);
      }
    });

    return () => {
      isMountedRef.current = false;
    };
  }, [updatedChannels]);

  useEffect(() => {
    setLoading(true);
    const settingsRef = coachDocument.collection('settings').doc('chat');

    settingsRef
      .get()
      .then((docSnapshot) => {
        if (docSnapshot.exists) {
          const settings = docSnapshot.data() as ChatSettingsType;
          setChatSettings(settings);
        } else {
          console.log('No chat settings found');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedChannelId) {
      const newSelectedChannel = channels?.find((channel) => channel.id === selectedChannelId);

      if (newSelectedChannel) {
        let channelDetails;

        const createChannelDetail = (id, title, type) => ({ id, title, type });

        if (chatSettings.isDirectChatEnabled && chatSettings.isCommunityChatEnabled) {
          channelDetails = createChannelDetail(
            newSelectedChannel.id,
            newSelectedChannel.data?.channelTitle,
            newSelectedChannel.data?.type,
          );
        } else if (chatSettings.isDirectChatEnabled && newSelectedChannel.data?.type === 'direct') {
          channelDetails = createChannelDetail(newSelectedChannel.id, newSelectedChannel.data?.channelTitle, 'direct');
        } else if (chatSettings.isCommunityChatEnabled && newSelectedChannel.data?.type === 'community') {
          channelDetails = createChannelDetail(
            newSelectedChannel.id,
            newSelectedChannel.data?.channelTitle,
            'community',
          );
        } else if (!chatSettings.isCommunityChatEnabled && newSelectedChannel.data?.type === 'community' && clients) {
          const client = Object.values(clients)?.[0] as Clients;
          if (client) {
            channelDetails = createChannelDetail(client.clientUserId, client.profile.fullname, 'direct');
          }
        }
        channelDetails = channelDetails || createChannelDetail('Chat-room', 'All Clients', 'community');

        setSelectedChannel(channelDetails);
      }
    }
  }, [selectedChannelId, channels, chatSettings]);

  const handleNotifications = useCallback(() => {
    if (!selectedChannel || !userId) return;
    firestore
      .collection('users')
      .doc(userId)
      .collection('notifications')
      .where('isRead', '==', false)
      .onSnapshot((snapshot) => {
        const unreadNotifications = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as NotificationData),
        );
        if (unreadNotifications) {
          unreadNotifications.forEach((notification) => {
            if (notification.relatedId !== selectedChannel.id) return;
            firestore
              .collection('users')
              .doc(userId)
              .collection('notifications')
              .doc(notification.id)
              .update({
                isRead: true,
              })
              .catch((error) => console.error('Error updating notification: ', error));
          });
        }
      });
  }, [selectedChannelId, selectedChannel, userId]);

  useEffect(() => {
    handleNotifications();
  }, [handleNotifications]);

  const channelDocument = channelsRef?.doc(selectedChannel.id || 'Chat-room');
  const chatRoomRef = channelDocument?.collection('messages');

  const query = useMemo(() => {
    return chatRoomRef?.orderBy('createdAt');
  }, [selectedChannel.id]);

  const [messages, isFetching] = useCollectionData(query, { idField: 'id' });

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (dummy && dummy?.current) {
      // eslint-disable-next-line no-unused-expressions
      dummy.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [messages]);

  useEffect(() => {
    isMountedRef.current = true;
    if (isEligibleCoach(userId)) {
      const customersChannelDocument = channelsRef?.doc('customer-channel');
      customersChannelDocument.get().then((snapshot) => {
        if (!snapshot.exists && isMountedRef.current) {
          setUpdatedChannels(true);
          customersChannelDocument.set({
            channelTitle: 'All Subscribers',
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            type: 'community',
          });
        }
      });
    }
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const sendMessage = async (payload: FormValues, downloadURL?: string, metadata?: FullMetadata) => {
    channelDocument.get().then((docSnapshot) => {
      if (!docSnapshot.exists) {
        channelDocument.set(
          {
            channelTitle: payload.channel.title,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            type: payload.channel.type,
          },
          { merge: true },
        );
      }
    });
    const profile: any = coachProfile?.[coachKey];
    const trimmedMessage = payload.text.trim();
    // Add new message in Firestore
    await chatRoomRef.add({
      text: trimmedMessage,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      userId,
      displayName: profile.fullname,
      media: downloadURL
        ? {
            url: downloadURL || null,
            metadata: metadata
              ? {
                  contentType: metadata?.contentType,
                  timeCreated: metadata?.timeCreated,
                  updated: metadata?.updated,
                  size: metadata?.size,
                }
              : null,
          }
        : null,
    });
  };

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  const uploadError: (a: Error) => any = (error) => {
    console.log(error);
  };

  const uploadSuccess = async ({ uploadTask, payload }: { uploadTask: UploadTask; payload: FormValues }) => {
    await uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      uploadTask.snapshot.ref.getMetadata().then((metadata) => {
        sendMessage(payload, downloadURL, metadata);
      });
    });
  };

  const handleSubmit = (payload: FormValues): Promise<any> => {
    return new Promise((resolve) => {
      const channelType = payload.channel.type?.toLowerCase() === 'community' ? 'group' : 'direct';
      if (payload.file) {
        const storageRef = storage.ref(`coaches/${userId}/channels/${payload.channel}/${uuidv4()}`);
        const uploadTask: any = storageRef.put(payload.file[0]);

        uploadTask.on('state_changed', uploadProgress, uploadError, async () => {
          await uploadSuccess({
            uploadTask,
            payload,
          });
          chatTrackingService.trackChatEvent('send_message', {
            location: 'chat',
            channel_type: channelType,
            contains_media: true,
          });
          resolve(null);
        });
      } else {
        sendMessage(payload).then(() => {
          chatTrackingService.trackChatEvent('send_message', {
            location: 'chat',
            channel_type: channelType,
            contains_media: false,
          });
          resolve(null);
        });
      }
    });
  };

  const saveSettings = async (chatSettings: ChatSettingsType) => {
    setLoadingSettings(true);

    try {
      const settingsRef = coachDocument.collection('settings').doc('chat');
      await settingsRef.set(chatSettings);

      if (!chatSettings.isDirectChatEnabled && selectedChannel.type === 'direct' && clients) {
        setSelectedChannel({
          id: 'Chat-room',
          title: 'All Clients',
          type: 'community',
        });
      } else if (!chatSettings.isCommunityChatEnabled && selectedChannel.type === 'community' && clients) {
        const client = Object.values(clients)?.[0] as Clients;
        if (client) {
          setSelectedChannel({
            id: client.clientUserId,
            title: client.profile.fullname,
            type: 'direct',
          });
        }
      }

      chatTrackingService.trackChatEvent('update_chat_settings', {
        location: 'chat',
        is_community_chat_enabled: chatSettings.isCommunityChatEnabled,
        is_direct_chat_enabled: chatSettings.isDirectChatEnabled,
      });

      setChatSettings(chatSettings);
      setShowSettings(false);
    } catch (error) {
      addToast(`Error while updating settings.`, { appearance: 'error' });
    } finally {
      setLoadingSettings(false);
    }
  };

  if (loading) {
    return (
      <Layout loading={loading} heading={CHAT.TITLE}>
        <div className={classes.clients}>
          <Spinner />
        </div>
      </Layout>
    );
  }
  const messagesLength = messages?.length || 0;

  return (
    <Layout loading={false} heading={CHAT.TITLE}>
      <div className={classes.CommunityContainer}>
        {!chatSettings.isCommunityChatEnabled && !chatSettings.isDirectChatEnabled ? (
          <DisabledChat chatSettings={chatSettings} saveSettings={saveSettings} />
        ) : (
          <>
            <div className={classes.Sidebar}>
              <ChannelsSidebar
                channels={channels}
                clients={clients ? Object.values(clients) : []}
                setSelectedChannel={setSelectedChannel}
                selectedChannel={selectedChannel}
                showSettings={() => setShowSettings(true)}
                chatSettings={chatSettings}
              />
            </div>
            <div className={classes.ChatContainer}>
              {messages && messagesLength > 0 && !isFetching ? (
                <ul className={classes.MessageUl}>
                  {messages.reduce<JSX.Element[]>((acc, message) => {
                    if (message && (message.media || message.text)) {
                      acc.push(
                        <li key={message.id} className={classes.MessageLi}>
                          <Message message={message} currentUserId={userId} />
                        </li>,
                      );
                    }
                    return acc;
                  }, [])}
                  <span ref={dummy}></span>
                </ul>
              ) : messages ? (
                <div className={classes.NoMessageContainer}>
                  <EmptyState title="It's quiet in here..." description="Be the first to say hello!" />
                </div>
              ) : (
                isFetching && (
                  <div className={classes.NoMessageContainer}>
                    <Spinner />
                  </div>
                )
              )}
              <div className={classes.Form}>
                <CommunityForm
                  handleSubmit={handleSubmit}
                  progress={progress}
                  setProgress={setProgress}
                  selectedChannel={selectedChannel}
                  themePreference={preference}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog open={showSettings} onOpenChange={setShowSettings} title="Chat Settings">
        <ChatSettings
          chatSettings={chatSettings}
          saveSettings={saveSettings}
          cancel={() => setShowSettings(false)}
          loading={loadingSettings}
        />
      </Dialog>
    </Layout>
  );
};

export default connector(withAuth(Community));
