import {
  FETCH_CLIENTS_START,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAIL,
  REMOVE_CLIENTS_SUCCESS,
  REMOVE_CLIENTS_FAIL,
  REMOVE_CLIENTS_START,
  SAVE_CLIENT_PROFILE_START,
  SAVE_CLIENT_PROFILE_SUCCESS,
  SAVE_CLIENT_PROFILE_FAIL,
  UPDATE_CLIENT_STATUS,
} from '../actions/actionTypes';
import { AppThunk } from '../index';
import { database } from 'utils/firebase';
import { Clients, Documents, Profile } from 'interfaces/db';
import { FirebaseObject } from 'interfaces/utils';
import { ClientsActionTypes } from 'interfaces/actions/clients';

const fetchClientsStart = (): ClientsActionTypes => {
  return {
    type: FETCH_CLIENTS_START,
  };
};

const fetchClientsSuccess = (clients: FirebaseObject<Clients>): ClientsActionTypes => {
  return {
    type: FETCH_CLIENTS_SUCCESS,
    clients: clients,
  };
};

const fetchClientsFail = (): ClientsActionTypes => {
  return {
    type: FETCH_CLIENTS_FAIL,
  };
};

const removeClientsStart = (): ClientsActionTypes => {
  return {
    type: REMOVE_CLIENTS_START,
  };
};

const removeClientsSuccess = (clientKeys: string[]): ClientsActionTypes => {
  return {
    type: REMOVE_CLIENTS_SUCCESS,
    clientKeys,
  };
};

const removeClientsFail = (): ClientsActionTypes => {
  return {
    type: REMOVE_CLIENTS_FAIL,
  };
};

export const updateClientStatus = (clientKey: string, status: number) => {
  return {
    type: UPDATE_CLIENT_STATUS,
    clientKey: clientKey,
    status: status,
  };
};

const saveClientProfileStart = (): ClientsActionTypes => {
  return {
    type: SAVE_CLIENT_PROFILE_START,
  };
};

const saveClientProfileSuccess = (clientProfile: Profile, clientKey: string): ClientsActionTypes => {
  return {
    clientKey,
    clientProfile,
    type: SAVE_CLIENT_PROFILE_SUCCESS,
  };
};

const saveClientProfileFail = (): ClientsActionTypes => {
  return {
    type: SAVE_CLIENT_PROFILE_FAIL,
  };
};

export const fetchClients = (userId: string): AppThunk => {
  return (dispatch) => {
    dispatch(fetchClientsStart());

    database
      .ref('clients')
      .orderByChild('userId')
      .equalTo(userId)
      .once('value')
      .then((snapshot) => {
        const clientsData = snapshot.val();
        dispatch(fetchClientsSuccess(clientsData));
      })
      .catch(() => {
        dispatch(fetchClientsFail());
      });
  };
};

export const removeClients = (clientKeys: string[]): AppThunk => {
  return (dispatch) => {
    dispatch(removeClientsStart());

    const removalPromises = clientKeys.map(
      (key) =>
        new Promise((resolve, reject) => {
          database.ref(`clients/${key}`).remove((error) => {
            if (error) {
              reject(error);
            } else {
              resolve(key);
            }
          });
        }),
    );

    Promise.allSettled(removalPromises)
      .then((results) => {
        const failed = results.filter((result) => result.status === 'rejected');
        const successfulKeys = results
          .filter((result) => result.status === 'fulfilled')
          .map((result) => (result as PromiseFulfilledResult<string>).value);

        if (failed.length > 0) {
          dispatch(removeClientsFail());
        } else {
          dispatch(removeClientsSuccess(successfulKeys));
        }
      })
      .catch(() => {
        dispatch(removeClientsFail());
      });
  };
};

export const saveClientProfile = (clientProfile: Profile, clientKey: string): AppThunk => {
  return (dispatch) => {
    dispatch(saveClientProfileStart());
    database.ref(`clients/${clientKey}/profile`).update(clientProfile, (error) => {
      if (error) {
        dispatch(saveClientProfileFail());
      } else {
        dispatch(saveClientProfileSuccess(clientProfile, clientKey));
      }
    });
  };
};

export const deleteDocumentFromClient = (document: Documents, documentKey: string): AppThunk => {
  return (dispatch, state) => {
    const { clients } = state();
    if (!document) {
      return null;
    }
    return document?.clientList?.map((clientkey) => {
      const profile = clients?.clients?.[clientkey]?.profile;
      let newProfile = Object.assign({}, profile);
      if (profile) {
        const clientDocuments = profile?.documents?.filter((docKey) => docKey !== documentKey);

        newProfile = { ...profile, documents: clientDocuments };
      }
      dispatch(saveClientProfile(newProfile, clientkey));
      return null;
    });
  };
};
