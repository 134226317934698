import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import Button from '../../UI/Button';
import TextField from '../../inputs/TextField';
import classes from './Checkout.module.css';

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
};

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

type FormValuesExtended = FormValues;

const validationSchema = object().shape({
  firstName: string().max(50, 'First name is too long').required('First name is a required'),
  lastName: string().max(50, 'Last name is too long').required('Last name is a required'),
  email: string().email().required(),
});

const FullNameIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="18.333" viewBox="0 0 18.333 18.333">
      <path
        id="Icon_awesome-user-alt"
        data-name="Icon awesome-user-alt"
        d="M9.167,10.312A5.156,5.156,0,1,0,4.01,5.156,5.157,5.157,0,0,0,9.167,10.312Zm4.583,1.146H11.777a6.233,6.233,0,0,1-5.221,0H4.583A4.583,4.583,0,0,0,0,16.041v.573a1.719,1.719,0,0,0,1.719,1.719h14.9a1.719,1.719,0,0,0,1.719-1.719v-.573A4.583,4.583,0,0,0,13.75,11.458Z"
        fill="#83868f"
      />
    </svg>
  );
};

const EmailIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
      <path
        id="Icon_material-email"
        data-name="Icon material-email"
        d="M21,6H5A2,2,0,0,0,3.01,8L3,20a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,21,6Zm0,4-8,5L5,10V8l8,5,8-5Z"
        transform="translate(-3 -6)"
        fill="#cacbd5"
        opacity="0.5"
      />
    </svg>
  );
};

const PaymentForm: FC<{
  handleSubmit: (payload: FormValues) => Promise<void>;
  loading: boolean;
}> = ({ handleSubmit, loading }) => {
  const onSubmit = async (
    values: FormValuesExtended,
    // actions: FormikHelpers<FormValuesExtended>,
  ) => {
    await handleSubmit(values);
    // actions.setSubmitting(false);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ errors, touched, isSubmitting }) => (
        <Form className={classes.wrapper}>
          <input type="hidden" value="something" />
          <div className={classes.FullNameWrapper}>
            <div className={[classes.inputWrapper, classes.nameInput].join(' ')}>
              <TextField
                name="firstName"
                errors={errors}
                touched={touched}
                icon={<FullNameIcon />}
                placeholder="First Name"
                customClass={classes.Input}
              />
            </div>
            <div className={[classes.inputWrapper, classes.nameInput].join(' ')}>
              <TextField
                name="lastName"
                errors={errors}
                touched={touched}
                icon={<FullNameIcon />}
                placeholder="Last Name "
                customClass={classes.Input}
              />
            </div>
          </div>

          <div className={classes.inputWrapper}>
            <TextField
              name="email"
              errors={errors}
              touched={touched}
              placeholder="Email"
              icon={<EmailIcon />}
              customClass={classes.Input}
            />
          </div>

          <Button type="submit" loading={loading} disabled={isSubmitting}>
            CHECKOUT
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default PaymentForm;
