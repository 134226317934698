import React, { FC, ReactNode } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import classes from './Accordion.module.css';
import { ReactComponent as ChevronDown } from 'assets/svgs/chevron-down.svg';

const AccordionItem = ({ id, title, children }) => (
  <AccordionPrimitive.Item value={id} className={classes.AccordionItem}>
    <AccordionPrimitive.Header className={classes.AccordionHeader}>
      <AccordionPrimitive.Trigger className={classes.AccordionTrigger}>
        {title}
        <ChevronDown className={classes.AccordionChevron} aria-hidden stroke="#404040" />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
    <AccordionPrimitive.Content className={classes.AccordionContent}>{children}</AccordionPrimitive.Content>
  </AccordionPrimitive.Item>
);

interface Item {
  id: string | number;
  title: ReactNode | string;
  content: ReactNode;
}

interface Props {
  items: Item[];
  defaultValue?: string;
  collapsible?: boolean;
}

const Accordion: FC<Props> = ({ items, defaultValue, collapsible = false }) => (
  <AccordionPrimitive.Root
    type="single"
    className={classes.AccordionRoot}
    defaultValue={defaultValue}
    collapsible={collapsible}
  >
    {items.map((item) => (
      <AccordionItem key={item.id} title={item.title} id={item.id}>
        {item.content}
      </AccordionItem>
    ))}
  </AccordionPrimitive.Root>
);

export default Accordion;
