import React, { FC, ChangeEvent, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import QuickEditTable from 'components/WorkoutQuickEdit/QuickEditTable';
import { SetKeys, Day as DayType, Exercise as ExerciseType } from 'interfaces/db';
import classes from './QuickEdit.module.css';
import analyticsClasses from 'components/ExerciseBlock/ExerciseBlock.module.css'; // shared styles
import ExerciseHistoryBox from 'components/ExerciseHistoryBox';
import { Popover, TextArea } from 'components/UI';
import ExerciseGroup from 'components/ExerciseGroup';
import { ReactComponent as AnalyticsIcon } from 'assets/svgs/analytics-filled.svg';
import { getUniqueIndex } from 'utils/helpers';

interface Props {
  day: DayType;
  dayNumber: number;
  weekNumber: number;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number) => void;
  addWorkoutNotes: (workoutNotes: string, dayIndex: number, weekIndex: number) => void;
  addSetHandler: (group: number, index: number) => void;
  removeSetHandler: (group: number, index: number) => void;
  setsChangeHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  autoFillSetsHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousDay?: DayType | undefined; // ? Previous week day
  openAnalytics?: (index: string) => void;
  closeAnalytics?: () => void;
  userId?: string | null;
  activeAnalyticsExercise?: string | null;
  weightColor: string;
  volumeColor: string;
  weightTextColor: string;
  volumeTextColor: string;
  programmedTextColor: string;
  achievedTextColor: string;
  scrollableDivRef?: React.RefObject<HTMLDivElement>;
}

const QuickEdit: FC<Props> = ({
  day,
  dayNumber,
  weekNumber,
  addExerciseNotes,
  addWorkoutNotes,
  addSetHandler,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  previousDay,
  openAnalytics,
  closeAnalytics,
  userId,
  activeAnalyticsExercise,
  weightColor,
  volumeColor,
  weightTextColor,
  volumeTextColor,
  programmedTextColor,
  achievedTextColor,
  scrollableDivRef,
}) => {
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([]);
  const { register } = useForm();
  const { exercises = [] } = day?.workout;
  const previousDayFinished = previousDay?.workout?.finished || false;
  const isEquivalentExercise = useRef(false);

  const exerciseNotesOnChangeHandler = (value: string, group: number, index: number) => {
    addExerciseNotes(value, group, index);
  };

  const isExerciseHistoryVisible = (index: number, group: number, isPrev: boolean) => {
    if (!activeAnalyticsExercise) return false;
    return activeAnalyticsExercise === getUniqueIndex(index, group, isPrev);
  };

  const analyticsButton = (index: number, group: number, isPrev: boolean = false) => {
    if (userId) {
      const uniqueIndex = getUniqueIndex(index, group, isPrev);

      return (
        <button
          ref={(el) => (buttonsRef.current[uniqueIndex] = el)}
          className={analyticsClasses.AnalyticsButton}
          onClick={() => {
            openAnalytics?.(uniqueIndex);
          }}
        >
          <AnalyticsIcon />
        </button>
      );
    }
    return null;
  };

  const isButtonVisible = (button: HTMLButtonElement | null) => {
    if (!button || !scrollableDivRef?.current) return false;

    const rect = button.getBoundingClientRect();
    const parentRect = scrollableDivRef.current.getBoundingClientRect();

    return (
      rect.top >= parentRect.top &&
      rect.left >= parentRect.left &&
      rect.bottom <= parentRect.bottom &&
      rect.right <= parentRect.right
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (activeAnalyticsExercise && !isButtonVisible(buttonsRef.current[activeAnalyticsExercise]) && closeAnalytics) {
        closeAnalytics();
      }
    };

    const parent = scrollableDivRef?.current;
    if (parent) {
      parent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (parent) {
        parent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [activeAnalyticsExercise]);

  const getEquivalentExerciseBlock = (typeDay: string, char: string, exercise: ExerciseType, previousDay?: any) => {
    isEquivalentExercise.current = false;
    const exercisesOfPreviousDay = previousDay?.workout?.exercises || [];
    let returnValue: any = null;
    exercisesOfPreviousDay.every((groupPrev: any, i: number) => {
      groupPrev.every((exercisePrev: ExerciseType, j: number) => {
        const typePrev = exercisePrev?.type;
        const exerciseNotesPrev = exercisePrev?.exerciseNotes;
        if (typePrev !== typeDay) {
          return true; // continue to next iteration
        }

        if (typePrev === typeDay && previousDayFinished && weekNumber > 0) {
          isEquivalentExercise.current = true;
          returnValue = (
            <div
              key={`${i}${j}`}
              id={`${i}${j}prev`}
              className={clsx(
                classes.ExercisePrev,
                (!previousDay || !isEquivalentExercise.current) && classes.WithoutPreviousExercise,
              )}
            >
              {!previousDay && (
                <div className={classes.ExerciseInner}>
                  <div className={classes.ExerciseGroup}>{char + (j + 1)}</div>

                  {groupPrev.length > 1 && j !== groupPrev.length - 1 && (
                    <div className={classes.SuperSetLineWrapper}>
                      <div className={classes.SuperSetLine}></div>
                    </div>
                  )}
                </div>
              )}

              <div id={!previousDay ? typePrev : ''} className={classes.NotesTableContainer}>
                {!previousDay ? (
                  <>
                    <p className={classes.Type}>{typePrev}</p>
                    <div className={classes.notesWrapper}>
                      <textarea
                        placeholder="Exercise Notes"
                        className={classes.notesInnerWrapper}
                        value={exerciseNotesPrev?.coach || ''}
                        onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) =>
                          exerciseNotesOnChangeHandler(target.value, i, j)
                        }
                      />
                    </div>
                  </>
                ) : (
                  <div className={classes.AthleteFeedbackContainer}>
                    <div className={classes.AthleteFeedbackWrapper}>
                      <p className={classes.Type}>{typePrev}</p>

                      <TextArea
                        label="Exercise feedback"
                        name="clientWorkoutNotes"
                        placeholder="No feedback notes."
                        defaultValue={exerciseNotesPrev?.athlete || 'No feedback notes.'}
                        readOnly={true}
                      />
                    </div>

                    <Popover
                      trigger={analyticsButton(i, j, true)}
                      open={!!(isExerciseHistoryVisible(i, j, true) && userId && closeAnalytics)}
                      closePopover={() => closeAnalytics && closeAnalytics()}
                    >
                      {closeAnalytics && (
                        <div className={classes.ExerciseHistoryWrapper}>
                          <ExerciseHistoryBox
                            closeAnalytics={closeAnalytics}
                            userId={userId}
                            indexedExercise={{ exercise, index: i, group: j }}
                            weightColor={weightColor}
                            volumeColor={volumeColor}
                            weightTextColor={weightTextColor}
                            volumeTextColor={volumeTextColor}
                            uniqueIndex={getUniqueIndex(i, j, true)}
                            hideClose
                            quickEdit
                          />
                        </div>
                      )}
                    </Popover>
                  </div>
                )}

                <div className={classes.tableWrapper}>
                  <QuickEditTable
                    exercise={exercisePrev}
                    setsChangeHandler={(key: SetKeys, val: string, setIndex: number) =>
                      setsChangeHandler(key, val, setIndex, i, j)
                    }
                    autoFillSetsHandler={(key: SetKeys, val: string, setIndex: number) =>
                      autoFillSetsHandler(key, val, setIndex, i, j)
                    }
                    addSetHandler={() => addSetHandler(i, j)}
                    removeSetHandler={() => removeSetHandler(i, j)}
                    setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
                    showAutoFill={showAutoFill}
                    previousDay={previousDay}
                    day={day}
                    programmedTextColor={programmedTextColor}
                    achievedTextColor={achievedTextColor}
                  />
                </div>
              </div>
            </div>
          );
          return;
        }
        return true;
      });
      return true;
    });

    return returnValue;
  };

  const getExerciseBlock = (
    i: number,
    j: number,
    group: any,
    typeDay: string,
    exercise: ExerciseType,
    exerciseNotes:
      | {
          athlete: string;
          coach: string;
        }
      | undefined,
  ) => {
    return (
      <div
        key={`${i}${j}day`}
        className={clsx(
          classes.Exercise,
          (!previousDay || !isEquivalentExercise.current) && classes.WithoutPreviousExercise,
        )}
      >
        <div className={classes.GroupIndicatorContainer}>
          <ExerciseGroup group={i} index={j + 1} />
          {group.length > 1 && j !== group.length - 1 && (
            <div className={classes.SuperSetLineWrapper}>
              <div className={classes.SuperSetLine}></div>
            </div>
          )}
        </div>

        <div
          className={clsx(
            classes.NotesTableContainer,
            previousDayFinished &&
              (!previousDay || !isEquivalentExercise.current) &&
              classes.WithoutPreviousExerciseTable,
          )}
        >
          <div className={classes.SpaceBetween}>
            <p className={classes.Type}>{typeDay}</p>
            {(!previousDay || !isEquivalentExercise.current) && (
              <Popover
                trigger={analyticsButton(i, j, false)}
                open={!!isExerciseHistoryVisible(i, j, false)}
                closePopover={() => closeAnalytics && closeAnalytics()}
              >
                {closeAnalytics && (
                  <div className={classes.ExerciseHistoryWrapper}>
                    <ExerciseHistoryBox
                      closeAnalytics={closeAnalytics}
                      userId={userId}
                      indexedExercise={{ exercise, index: i, group: j }}
                      weightColor={weightColor}
                      volumeColor={volumeColor}
                      weightTextColor={weightTextColor}
                      volumeTextColor={volumeTextColor}
                      uniqueIndex={getUniqueIndex(i, j, false)}
                      hideClose
                      quickEdit
                    />
                  </div>
                )}
              </Popover>
            )}
          </div>
          <div>
            <TextArea
              register={register}
              name={`coachExerciseNotes-${i}-${j}`}
              placeholder="Add exercise notes..."
              defaultValue={exerciseNotes?.coach || ''}
              onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) =>
                exerciseNotesOnChangeHandler(target.value, i, j)
              }
            />
          </div>

          <div className={classes.TableWrapper}>
            <QuickEditTable
              exercise={exercise}
              setsChangeHandler={(key: SetKeys, val: string, setIndex: number) =>
                setsChangeHandler(key, val, setIndex, i, j)
              }
              autoFillSetsHandler={(key: SetKeys, val: string, setIndex: number) =>
                autoFillSetsHandler(key, val, setIndex, i, j)
              }
              addSetHandler={() => addSetHandler(i, j)}
              removeSetHandler={() => removeSetHandler(i, j)}
              setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
              showAutoFill={showAutoFill}
              day={day}
              programmedTextColor={programmedTextColor}
              achievedTextColor={achievedTextColor}
            />
          </div>
        </div>
      </div>
    );
  };

  const getGroup = (group: any, i: number, char: string) => {
    return group.map((exercise: ExerciseType, j: number) => {
      const { exerciseNotes, type: typeDay } = exercise;
      let equivalentExerciseBlock;
      if (previousDay) {
        // Get the equivalent exercise from the previous week's day - should be the same exercise that matches the one of the currently selected day
        equivalentExerciseBlock = getEquivalentExerciseBlock(typeDay, char, exercise, previousDay);
      }
      // Get the exercise block of the current day
      const showEquivalentExerciseBlock = previousDayFinished && weekNumber > 0 && equivalentExerciseBlock;
      const exerciseBlock = getExerciseBlock(i, j, group, typeDay, exercise, exerciseNotes);
      return (
        <div className={classes.GroupWrapperFlex} key={`${i}${j}`}>
          {exerciseBlock}
          {showEquivalentExerciseBlock && equivalentExerciseBlock}
        </div>
      );
    });
  };

  return (
    <div className={classes.Container}>
      <div id={'day.' + dayNumber + 'week.' + weekNumber}>
        <div className={classes.NotesContainer}>
          <div className={classes.FullWidth}>
            <p className={classes.NotesLabel}>Workout notes:</p>
            <div className={classes.WorkoutNotesWrapper}>
              <TextArea
                register={register}
                name="coachWorkoutNotes"
                placeholder="Add session notes..."
                defaultValue={day?.workout?.workoutNotes?.coach || ''}
                onChange={(e) => addWorkoutNotes(e.target.value, dayNumber, weekNumber)}
              />
            </div>
          </div>
          {previousDay && previousDayFinished && weekNumber > 0 && (
            <div className={classes.FullWidth}>
              <div className={classes.NotesLabel}>Workout feedback:</div>
              <div className={classes.WorkoutNotesWrapper}>
                <TextArea
                  register={register}
                  name="clientWorkoutNotes"
                  placeholder="Add session notes..."
                  defaultValue={previousDay?.workout?.workoutNotes?.athlete || 'No feedback notes.'}
                  onChange={(e) => addWorkoutNotes(e.target.value, dayNumber, weekNumber)}
                  readOnly={true}
                />
              </div>
            </div>
          )}
        </div>

        {exercises?.map((group, i) => {
          const char = String.fromCharCode(65 + i);
          return (
            <div key={i} className={classes.GroupWrapper}>
              {getGroup(group, i, char)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuickEdit;
