import React, { FC, useCallback, memo } from 'react';
import { useForm } from 'react-hook-form';
import isEqual from 'react-fast-compare';

import classes from './ProgramToolbar.module.css';
import { MAX_SETS_LENGTH, setKeysMap, setKeysObject } from 'utils/helpers';
import { SetParam } from 'interfaces/utils';
import { ReactComponent as AddSetSvg } from 'assets/svgs/add-set.svg';
import { Button } from 'components';
import { ReactComponent as SaveIcon } from 'assets/svgs/save-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';
import { ReactComponent as CompleteIcon } from 'assets/svgs/complete-filled.svg';
import { ReactComponent as BookmarkIcon } from 'assets/svgs/bookmark-filled.svg';

import Select from 'components/UI/Select';
import { useOpenSelect } from 'hooks';

interface Props {
  defaultSetParams: SetParam[];
  updateDefaultSetParam: (newParam: SetParam, originalParam?: SetParam) => void;
  removeDefaultSetParam: (originalParam?: SetParam) => void;
  addDefaultSetParam: (param: SetParam) => void;
  changed: boolean;
  setShowSaveProgram: (show: boolean) => void;
  setShowRemoveProgram?: (show: boolean) => void;
  setCompleteModal?: (show: boolean) => void;
  setShowSaveAsTemplate?: (show: boolean) => void;
}

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

const ProgramToolbar: FC<Props> = ({
  defaultSetParams,
  updateDefaultSetParam,
  removeDefaultSetParam,
  addDefaultSetParam,
  changed,
  setShowSaveProgram,
  setShowRemoveProgram,
  setCompleteModal,
  setShowSaveAsTemplate,
}) => {
  const { control } = useForm<any>();
  const { openSelect, handleSelectOpenChange } = useOpenSelect();

  const options = useCallback(() => {
    return setKeysMap.filter((el) => !defaultSetParams.includes(el.value));
  }, [defaultSetParams]);

  return (
    <div className={classes.ProgrammingSettings}>
      <div className={classes.ParametersContainer}>
        <span className={classes.ParametersHeading}>Default Parameters:</span>
        <div className={classes.SetParams}>
          {defaultSetParams.map((setKey) => {
            const selected = setKeysObject[setKey];
            return (
              <div key={selected.value} className={classes.SetParam}>
                <Select
                  name={selected.value}
                  placeholder="Type"
                  control={control}
                  onOpenChange={(isOpen) => handleSelectOpenChange(selected.value, isOpen)}
                  open={openSelect === selected.value}
                  optionArray={[selected, ...options(), { label: '-Remove-', value: 'remove_option' }]}
                  required
                  onChange={(chosen: any) => {
                    if (chosen && chosen === 'remove_option') {
                      removeDefaultSetParam(setKey);
                    } else {
                      chosen ? updateDefaultSetParam(chosen, selected?.value) : removeDefaultSetParam(selected?.value);
                    }
                  }}
                  defaultValue={selected.value}
                />
              </div>
            );
          })}

          {defaultSetParams.length !== MAX_SETS_LENGTH && (
            <button
              type="button"
              className={classes.AddSetBtn}
              onClick={() => {
                const [newSet] = options();
                addDefaultSetParam(newSet.value);
              }}
            >
              <AddSetSvg className={classes.AddSetIcon} />
            </button>
          )}
        </div>
      </div>
      <div className={classes.Buttons}>
        {setShowSaveAsTemplate && (
          <div>
            <Button
              type="button"
              intent="secondary"
              onClick={() => setShowSaveAsTemplate(true)}
              iconLeft={<BookmarkIcon />}
            >
              SAVE AS TEMPLATE
            </Button>
          </div>
        )}

        {setShowRemoveProgram && (
          <div>
            <Button type="button" intent="danger" onClick={() => setShowRemoveProgram(true)} iconLeft={<DeleteIcon />}>
              REMOVE
            </Button>
          </div>
        )}
        {setCompleteModal && (
          <div>
            <Button type="button" intent="complete" onClick={() => setCompleteModal(true)} iconLeft={<CompleteIcon />}>
              COMPLETE
            </Button>
          </div>
        )}
        <div>
          <Button
            type="button"
            intent="success"
            onClick={() => setShowSaveProgram(true)}
            disabled={!changed}
            iconLeft={<SaveIcon />}
          >
            SAVE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ProgramToolbar, areEqual);
