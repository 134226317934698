import { MixpanelService, EventProperties } from './mixpanelService';

export type ProgramEventProperties = EventProperties & {
  location: 'program_templates' | 'program_template' | 'client_training' | 'quick_edit' | 'workout_builder' | 'clients';
};

class ProgramTrackingService extends MixpanelService {
  public trackProgramEvent(eventName: string, properties?: ProgramEventProperties): void {
    super.trackEvent(eventName, properties);
  }
}

export const programTrackingService = new ProgramTrackingService();
