import {
  FETCH_NUTRITION_PLAN_START,
  FETCH_NUTRITION_PLAN_SUCCESS,
  FETCH_NUTRITION_PLAN_FAIL,
  REMOVE_NUTRITION_PLAN_START,
  REMOVE_NUTRITION_PLAN_SUCCESS,
  REMOVE_NUTRITION_PLAN_FAIL,
  COMPLETE_NUTRITION_PLAN_START,
  COMPLETE_NUTRITION_PLAN_SUCCESS,
  COMPLETE_NUTRITION_PLAN_FAIL,
  ADD_NUTRITION_PLAN_WEEK,
  REMOVE_NUTRITION_PLAN_WEEK,
  DUPLICATE_NUTRITION_PLAN_WEEK,
  COPY_NUTRITION_PLAN_DAY,
  PASTE_NUTRITION_PLAN_DAY,
  CREATE_NUTRITION_PLAN_START,
  CREATE_NUTRITION_PLAN_SUCCESS,
  CREATE_NUTRITION_PLAN_FAIL,
  UPDATE_NUTRITION_PLAN_START,
  UPDATE_NUTRITION_PLAN_SUCCESS,
  UPDATE_NUTRITION_PLAN_FAIL,
} from '../actions/actionTypes';
import { AppThunk } from '../index';
import { NutritionActionTypes } from 'interfaces/actions/nutrition';
import { CreateNutritionPlanDTO, NutritionPlan, NutritionWeek, UpdatePlanDTO } from 'interfaces/nutrition';
import {
  createNutritionPlan as createNutritionPlanAPI,
  fetchNutritionPlanForClient,
  removeNutritionPlanById,
  updateNutritionPlan as updateNutritionPlanAPI,
} from 'api/nutritionPlanApi';

export const fetchNutritionPlanStart = (): NutritionActionTypes => ({
  type: FETCH_NUTRITION_PLAN_START,
});

export const fetchNutritionPlanSuccess = (plan: NutritionPlan): NutritionActionTypes => ({
  type: FETCH_NUTRITION_PLAN_SUCCESS,
  plan,
});

export const fetchNutritionPlanFail = (err: Error | string | null): NutritionActionTypes => ({
  type: FETCH_NUTRITION_PLAN_FAIL,
  error: err,
});

export const createNutritionPlanStart = (): NutritionActionTypes => ({
  type: CREATE_NUTRITION_PLAN_START,
});

export const createNutritionPlanSuccess = (plan: NutritionPlan): NutritionActionTypes => ({
  type: CREATE_NUTRITION_PLAN_SUCCESS,
  plan,
});

export const createNutritionPlanFail = (): NutritionActionTypes => ({
  type: CREATE_NUTRITION_PLAN_FAIL,
});

export const updateNutritionPlanStart = (): NutritionActionTypes => ({
  type: UPDATE_NUTRITION_PLAN_START,
});

export const updateNutritionPlanSuccess = (plan: NutritionPlan): NutritionActionTypes => ({
  type: UPDATE_NUTRITION_PLAN_SUCCESS,
  plan,
});

export const updateNutritionPlanFail = (): NutritionActionTypes => ({
  type: UPDATE_NUTRITION_PLAN_FAIL,
});

export const removeNutritionPlanStart = (): NutritionActionTypes => ({
  type: REMOVE_NUTRITION_PLAN_START,
});

export const removeNutritionPlanSuccess = (planId: string): NutritionActionTypes => ({
  type: REMOVE_NUTRITION_PLAN_SUCCESS,
  planId,
});

export const removeNutritionPlanFail = (): NutritionActionTypes => ({
  type: REMOVE_NUTRITION_PLAN_FAIL,
});

export const completeNutritionPlanStart = (): NutritionActionTypes => ({
  type: COMPLETE_NUTRITION_PLAN_START,
});

export const completeNutritionPlanSuccess = (): NutritionActionTypes => ({
  type: COMPLETE_NUTRITION_PLAN_SUCCESS,
});

export const completeNutritionPlanFail = (): NutritionActionTypes => ({
  type: COMPLETE_NUTRITION_PLAN_FAIL,
});

export const addWeek = (week: NutritionWeek, key: string): NutritionActionTypes => ({
  type: ADD_NUTRITION_PLAN_WEEK,
  week,
  key,
});

export const removeWeek = (weekId: string): NutritionActionTypes => ({
  type: REMOVE_NUTRITION_PLAN_WEEK,
  weekId,
});

export const duplicateWeek = (weekId: string): NutritionActionTypes => ({
  type: DUPLICATE_NUTRITION_PLAN_WEEK,
  weekId,
});

export const copyDay = (dayId: string): NutritionActionTypes => ({
  type: COPY_NUTRITION_PLAN_DAY,
  dayId,
});

export const pasteDay = (dayId: string): NutritionActionTypes => ({
  type: PASTE_NUTRITION_PLAN_DAY,
  dayId,
});

export const fetchNutritionPlan =
  (clientUserId: string, token: string): AppThunk =>
  async (dispatch) => {
    dispatch(fetchNutritionPlanStart());

    try {
      const response = await fetchNutritionPlanForClient(clientUserId, token);
      dispatch(fetchNutritionPlanSuccess(response.data.data));
    } catch (err) {
      dispatch(fetchNutritionPlanFail(err as Error));
    }
  };

export const createNutritionPlan =
  (plan: CreateNutritionPlanDTO, token: string): AppThunk =>
  async (dispatch) => {
    dispatch(createNutritionPlanStart());

    try {
      const response = await createNutritionPlanAPI(plan, token);
      dispatch(createNutritionPlanSuccess(response.data.data));
    } catch (err) {
      dispatch(createNutritionPlanFail());
    }
  };

export const removeNutritionPlan =
  (planId: string, token: string): AppThunk =>
  async (dispatch) => {
    dispatch(removeNutritionPlanStart());

    try {
      await removeNutritionPlanById(planId, token);
      dispatch(removeNutritionPlanSuccess(planId));
    } catch (err) {
      dispatch(removeNutritionPlanFail());
    }
  };

export const completeNutritionPlan =
  (planId: string, token: string): AppThunk =>
  async (dispatch) => {
    dispatch(completeNutritionPlanStart());

    try {
      const completeData = {
        finished: true,
        dateFinished: new Date().toISOString(),
      };
      await updateNutritionPlanAPI({ planId, planData: completeData, weeks: [] }, token);
      dispatch(completeNutritionPlanSuccess());
    } catch (err) {
      dispatch(completeNutritionPlanFail());
    }
  };

export const updateNutritionPlan =
  (updatePlanDTO: UpdatePlanDTO, updatedPlan: NutritionPlan, token: string): AppThunk =>
  async (dispatch) => {
    dispatch(updateNutritionPlanStart());
    try {
      await updateNutritionPlanAPI(updatePlanDTO, token);
      dispatch(updateNutritionPlanSuccess(updatedPlan));
    } catch (err) {
      console.error(err);
      dispatch(updateNutritionPlanFail());
    }
  };
