import React, { FC, useEffect, useState, Fragment } from 'react';
import { nanoid } from 'nanoid';

import { SetObject } from 'interfaces/db';
import classes from './ExerciseItem.module.css';

interface Set {
  [key: string]: SetObject | string | boolean;
}
interface Props {
  sets: Set[];
  group?: string;
  type?: string;
}

const ExerciseItem: FC<Props> = ({ sets, type }) => {
  const [reducedSets, setReducedSets] = useState<{ count: number; original: number }[]>([]);
  const [calculatedSets, setCalculatedSets] = useState<boolean>(false);

  const extractRemainder = (set: Set) => {
    if (set && typeof set.completed === 'boolean') {
      const { completed, ...remainder } = set;
      return remainder;
    } else {
      return set;
    }
  };

  const compareSets = (sets: Set[], one: number, two: number, silent?: boolean): boolean => {
    let differ = false;
    if (!sets?.[one]) {
      differ = true;
    } else {
      const remainderOne: any = extractRemainder(sets?.[one]);
      const remainderTwo: any = extractRemainder(sets?.[two]);

      Object.keys(remainderTwo).forEach((title) => {
        const remainderOneValue =
          (remainderOne?.[title] as SetObject)?.achieved || (remainderOne?.[title] as SetObject)?.programmed;
        const remainderTwoValue =
          (remainderTwo?.[title] as SetObject)?.achieved || (remainderTwo?.[title] as SetObject)?.programmed;
        if (remainderOneValue !== remainderTwoValue) differ = true;
      });
    }

    if (!silent) {
      if (differ) {
        setReducedSets((prevState) => {
          const newState = prevState;
          newState.push({
            count: 1,
            original: two,
          });
          return newState;
        });
      } else {
        setReducedSets((prevState) => {
          const newState = prevState;
          newState[newState.length - 1].count++;
          return newState;
        });
      }
    }
    return !differ;
  };

  let reducedIndex = -1;
  useEffect(() => {
    for (let index = 0; index < sets.length; index++) {
      compareSets(sets, index - 1, index);
    }
    setCalculatedSets(true);
  }, [sets]);

  return calculatedSets ? (
    <div className={classes.Container}>
      {type && <span className={classes.ExerciseType} />}
      {sets ? (
        <div>
          {sets.map((_, index) => {
            // if the same as the previous, return null
            if (compareSets(sets, index - 1, index, true)) {
              return null;
            } else {
              reducedIndex++;
              return (
                <div className={classes.Set} key={nanoid()}>
                  {reducedSets?.[reducedIndex] && reducedSets?.[reducedIndex]?.count
                    ? reducedSets?.[reducedIndex]?.count === 1
                      ? `${reducedSets?.[reducedIndex]?.count} set`
                      : `${reducedSets?.[reducedIndex]?.count} sets`
                    : `no sets`}

                  {typeof extractRemainder(sets[reducedSets?.[reducedIndex]?.original]) !== 'undefined' &&
                    Object.keys(extractRemainder(sets[reducedSets?.[reducedIndex]?.original])).map((el: string) => {
                      const achieved = (sets[reducedSets?.[reducedIndex].original]?.[el] as SetObject).achieved;
                      const programmed = (sets[reducedSets?.[reducedIndex].original]?.[el] as SetObject).programmed;

                      const value = achieved || programmed;

                      return (
                        <Fragment key={nanoid()}>
                          <span className={classes.Separator} />
                          <p className={classes.Text}>
                            {`${value} ${
                              el.toLowerCase().includes(`weight`)
                                ? `KGs`
                                : el.toLowerCase().includes(`lbs`)
                                ? `lbs`
                                : el
                            }`}
                          </p>
                        </Fragment>
                      );
                    })}
                </div>
              );
            }
          })}
        </div>
      ) : (
        <p> No Sets </p>
      )}
    </div>
  ) : null;
};

export default ExerciseItem;
