import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object, string, ref } from 'yup';

import { FormProps } from 'interfaces/utils';
import classes from './Register.module.css';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { ReactComponent as EmailIcon } from 'assets/svgs/mail-filled.svg';
import { ReactComponent as PasswordIcon } from 'assets/svgs/lock-closed-filled.svg';
import { ReactComponent as PersonIcon } from 'assets/svgs/person-filled.svg';
import { useYupValidationResolver } from 'hooks';

type FormValues = {
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const validationSchema = object().shape({
  fullName: string()
    .test('fullname-test', 'Fill first and last name', (value = '') => {
      const [firstName, lastName] = value ? value.trim().split(' ') : [''];
      return !!(firstName && lastName);
    })
    .required('Full name is a required field'),
  email: string().email().required(),
  password: string()
    .matches(/.{6,}$/, {
      message: 'Min 6 characters required',
      excludeEmptyString: false,
    }) // match 6 or more chars except newline
    .required(),
  confirmPassword: string()
    .when('password', {
      is: (val: string | any[]) => !!(val && val.length > 0),
      then: string().oneOf([ref('password')], 'Password does not match'),
    })
    .required('confirm password'),
});

const Register: FC<FormProps<FormValues> & { loading: boolean }> = ({ loading, handleSubmit }) => {
  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: useYupValidationResolver(validationSchema),
  });

  return (
    <form className={classes.wrapper} onSubmit={hookFormSubmit(handleSubmit)}>
      <div className={classes.inputWrapper}>
        <Input
          type="text"
          name="fullName"
          register={register}
          label="Full name"
          placeholder="Enter your full name"
          error={errors.fullName}
          iconLeft={<PersonIcon />}
        />
      </div>

      <div className={classes.inputWrapper}>
        <Input
          type="text"
          name="email"
          register={register}
          label="Email address"
          placeholder="Enter your email address"
          error={errors.email}
          iconLeft={<EmailIcon />}
        />
      </div>

      <div className={classes.inputWrapper}>
        <Input
          type="password"
          name="password"
          register={register}
          label="Password"
          placeholder="Enter your password"
          error={errors.password}
          iconLeft={<PasswordIcon />}
        />
      </div>

      <Input
        type="password"
        name="confirmPassword"
        register={register}
        label="Confirm password"
        placeholder="Confirm your password"
        error={errors.confirmPassword}
        iconLeft={<PasswordIcon />}
      />

      <div className={classes.btnWrapper}>
        <Button type="submit" loading={loading || isSubmitting} disabled={loading || isSubmitting}>
          SIGN UP
        </Button>
      </div>
    </form>
  );
};

export default Register;
