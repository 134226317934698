import { Clients, Documents, Profile } from 'interfaces/db';
import { FirebaseObject } from 'interfaces/utils';

type DeleteDocumentsParams = {
  documentKeys: string[];
  token: string | null;
  userId: string | null;
  documents: FirebaseObject<Documents> | null;
  onDeleteDocuments: (
    urls: string[],
    token: string,
    userId: string,
    documents: FirebaseObject<Documents> | null,
    documentIds: string[],
  ) => Promise<any>;
};

export const deleteDocumentsAndFiles = async ({
  documentKeys,
  token,
  userId,
  documents,
  onDeleteDocuments,
}: DeleteDocumentsParams) => {
  // If deleting document templates (from the documents page), delete te documents from the database and storage

  if (!userId || !token) return;

  // Extract download URLs from the documents object using documentKeys
  const downloadURLs = documentKeys.map((key) => documents?.[key]?.downloadURL || '').filter((url) => url);

  if (documentKeys.length > 0 && downloadURLs.length > 0) {
    await onDeleteDocuments(downloadURLs, token, userId, documents, documentKeys);
    return Promise.resolve();
  }
};

type DeleteDocumentsForDocumentsParams = {
  documentKeys: string[];
  clientKey: string;
  clients: FirebaseObject<Clients>;
  token: string;
  userId: string | null;
  documents: FirebaseObject<Documents> | null;
  onSaveClientProfile: (clientProfile: Profile, clientKey: string) => any;
  onUpdateDocuments: (token: string, documents: { [docKey: string]: Documents }) => any;
};

export const deleteDocumentsForClient = async ({
  documentKeys,
  clientKey,
  clients,
  token,
  documents,
  onSaveClientProfile,
  onUpdateDocuments,
}: DeleteDocumentsForDocumentsParams) => {
  // Update client's profile if clientKey and clients are provided
  const profile = clients[clientKey].profile;
  const updatedDocuments = profile.documents?.filter((docKey) => !documentKeys.includes(docKey)) || [];

  const newClientProfile = {
    ...profile,
    documents: updatedDocuments,
  };

  await onSaveClientProfile(newClientProfile, clientKey);

  const documentsToUpdate: { [docKey: string]: Documents } = {};

  for (const docKey of documentKeys) {
    const document = documents?.[docKey];
    if (document) {
      documentsToUpdate[docKey] = {
        ...document,
        clientList: document.clientList?.filter((docClientKey) => docClientKey !== clientKey) || [],
      };
    }
  }

  if (Object.keys(documentsToUpdate).length > 0) {
    await onUpdateDocuments(token, documentsToUpdate);
  }
};
