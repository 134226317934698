import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { NavLink } from 'react-router-dom';

import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { FormProps } from 'interfaces/utils';
import { LOGIN } from 'utils/routes';
import classes from './ForgotPassword.module.css';
import { ReactComponent as EmailIcon } from 'assets/svgs/mail-filled.svg';
import { useYupValidationResolver } from 'hooks';

const validationSchema = object().shape({
  email: string().email().required('Email address is required'),
});

type FormValues = {
  email: string;
};

const ForgotPassword: FC<FormProps<FormValues>> = ({ handleSubmit }) => {
  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: useYupValidationResolver(validationSchema),
  });

  return (
    <form className={classes.wrapper} onSubmit={hookFormSubmit(handleSubmit)}>
      <div className={classes.inputWrapper}>
        <Input
          type="text"
          name="email"
          register={register}
          label="Email address"
          placeholder="Email"
          error={errors.email}
          iconLeft={<EmailIcon />}
        />

        <div className={classes.BackToLoginWrapper}>
          <NavLink to={{ pathname: LOGIN.URL }} className={classes.BackToLogin}>
            <span>GO BACK TO LOGIN</span>
          </NavLink>
        </div>

        <div className={classes.ButtonWrapper}>
          <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
            Send Password Reset Email
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
