import isEqual from 'react-fast-compare';
import { NutritionDay, NutritionPlan, NutritionWeek, UpdatePlanDTO, UpdatedWeek } from 'interfaces/nutrition';
import { WeekMethods } from '../components/Week';
import { updateNutritionPlan } from 'store/actions/nutrition';
import { Dispatch } from 'redux';

export const savePlan = async (
  weeksRef: React.MutableRefObject<(WeekMethods | null)[]>,
  weeks: NutritionWeek[],
  deletedWeekIds: string[],
  plan: NutritionPlan,
  clearDeletedWeeks: () => void,
  dispatch: Dispatch<any>,
  token: string | null,
) => {
  if (!weeksRef.current) return;
  if (weeksRef.current.length === 0) {
    console.warn('No weeks to save');
    return;
  }
  const deletedWeekIdsLength = deletedWeekIds?.length || 0;
  const potentialUpdates = weeksRef.current.map((weekRef, index) => {
    if (!weekRef) {
      return null;
    }

    const initialData = weeks[index];
    const finalData = weekRef.getValues();

    if (finalData.isNewWeek) {
      return { days: finalData.days, id: finalData.id };
    }

    if (deletedWeekIdsLength > 0 && deletedWeekIds.includes(initialData?.id)) {
      return null;
    }

    const updatedDays = (finalData.days as NutritionDay[]).reduce((acc, day, dayIndex) => {
      if (!isEqual(day, initialData.days?.[dayIndex])) {
        acc.push(day);
      }
      return acc;
    }, [] as NutritionDay[]);

    if (updatedDays.length > 0) {
      return { days: updatedDays, id: finalData.id };
    }

    return null;
  });

  const updatedWeeks = potentialUpdates.filter((update) => update !== null);
  const updatedPlanDTO: UpdatePlanDTO = {
    planData: {
      title: plan.title,
      image: plan.image,
    },
    planId: plan.planId,
    weekOrder: plan.weekOrder,
    deletedWeekIds,
    weeks: updatedWeeks as UpdatedWeek[],
  };

  await dispatch(updateNutritionPlan(updatedPlanDTO, plan, token || ''));
  clearDeletedWeeks();
};

export const saveGoalsAndPlan = async (
  weeks: NutritionWeek[], // initial weeks before changes
  deletedWeekIds: string[],
  newPlan: NutritionPlan, // newest plan with all updates
  clearDeletedWeeks: () => void,
  dispatch: Dispatch<any>,
  token: string | null,
) => {
  const deletedWeekIdsLength = deletedWeekIds?.length || 0;
  const potentialUpdates = newPlan?.weeks?.map((week, index) => {
    if (!week) {
      return null;
    }

    const initialData = weeks[index];
    const finalData = week;

    if (finalData?.isNewWeek) {
      return { days: finalData.days, id: finalData.id };
    }

    if (deletedWeekIdsLength > 0 && deletedWeekIds.includes(initialData?.id)) {
      return null;
    }

    const updatedDays = (finalData?.days as NutritionDay[])?.reduce((acc, day, dayIndex) => {
      if (!isEqual(day, initialData?.days?.[dayIndex])) {
        acc.push(day);
      }
      return acc;
    }, [] as NutritionDay[]);

    if (updatedDays.length > 0) {
      return { days: updatedDays, id: finalData?.id };
    }

    return null;
  });

  const updatedWeeks = potentialUpdates?.filter((update) => update !== null);
  const updatedPlanDTO: UpdatePlanDTO = {
    planData: {
      title: newPlan.title,
      image: newPlan.image,
    },
    planId: newPlan.planId,
    weekOrder: newPlan.weekOrder,
    deletedWeekIds,
    weeks: updatedWeeks as UpdatedWeek[],
  };

  await dispatch(updateNutritionPlan(updatedPlanDTO, newPlan, token || ''));
  clearDeletedWeeks();
};
