import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { ConnectedProps, connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import Layout from 'containers/Layout';
import { Tabs, Dialog, Button, BasicSmallSpinner, Table } from 'components/UI';
import { Plan, PlanPayload, Product, TableProduct } from 'interfaces/clientBilling';
import classes from './Payments.module.css';
import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import { CreateClientBillingPlan } from 'components/forms';
import {
  createExpressAccount,
  createPlan,
  getAccountLinkUrl,
  getConnectAccount,
  getActiveProducts,
} from 'api/clientBillingApi';
import { ReactComponent as Checkmark } from 'assets/svgs/checkmark-outline.svg';
import { ReactComponent as DuplicateIcon } from 'assets/svgs/copy-filled.svg';
import Checkbox from 'components/UI/Checkbox';
import { paymentTrackingService } from 'utils/tracking/paymentService';

const mapStateToProps = ({ auth }: RootState) => {
  const { coachProfile } = auth;

  return {
    coachProfile,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

const Payments: FC<PropsFromRedux> = ({ coachProfile, coachKey }) => {
  const { addToast } = useToasts();
  const [coachAccountId, setCoachAccountId] = useState<string | null>(null);
  const [chargesEnabled, setChargesEnabled] = useState<boolean>(false);
  const [products, setProducts] = useState<TableProduct[]>([]);
  const [clickedCellId, setClickedCellId] = useState<string>('');
  const [termsOfServiceChecked, setTermsOfSerivceChecked] = useState<boolean>(false);

  // Modal states
  const [showCreatePlanModal, setShowCreatePlanModal] = useState<boolean>(false);

  // Loading states
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetchingProducts, setIsFetchingProducts] = useState<boolean>(false);
  const [isCreatingPlan, setIsCreatingPlan] = useState<boolean>(false);
  const [isCreatingExpressAccount, setIsCreatingExpressAccount] = useState<boolean>(false);
  const [isFetchingNewAccountLink, setIsFetchingNewAccountLink] = useState<boolean>(false);

  const location = useLocation();

  const coachName = coachProfile && coachProfile[coachKey] ? coachProfile[coachKey].fullname : '';
  const isNoStripeAccount = !coachAccountId;
  const isOnboardingNotComplete = !chargesEnabled && coachAccountId;
  const isOnboardingComplete = chargesEnabled && coachAccountId;

  const setCreatePlanModalState = (state: boolean) => {
    setShowCreatePlanModal(state);
  };

  const handleActiveProducts = useCallback(
    async (accountId) => {
      if (!accountId || accountId.length === 0) return;
      try {
        setIsFetchingProducts(true);
        const result = await getActiveProducts(accountId);
        const { data } = result.data;
        const productsArr = Object.keys(data || []).map((productId: string) => {
          const product: Product = data[productId];
          const {
            productId: id,
            name,
            description,
            created,
            currency,
            price,
            priceId,
            coachAccountId,
            coachUserId,
            recurring: { interval, interval_count },
            funnelIdentifier,
            encodeData,
          } = product;
          const productObj: TableProduct = {
            id,
            plan: name,
            created,
            description,
            amount: currency.toUpperCase() + ' ' + price,
            paymentCycle: interval_count + ' ' + interval,
            actions: true,
            priceId,
            coachAccountId,
            coachUserId,
            funnelIdentifier,
            encodeData,
          };
          return productObj;
        });
        setProducts(productsArr ?? []);
        setIsFetchingProducts(false);
      } catch (error) {
        setIsFetchingProducts(false);
        addToast(`An error has occurred fetching your plans`, { appearance: 'error' });
      }
    },
    [coachAccountId, setIsFetchingProducts],
  );

  useEffect(() => {
    const handleFunctionsOnPageLoad = async () => {
      setLoading(true);
      try {
        const result = await getConnectAccount();
        const { account_id, charges_enabled } = result.data.data || {};

        setCoachAccountId(account_id);
        setChargesEnabled(charges_enabled);
        setLoading(false);

        if (account_id) {
          await handleActiveProducts(account_id);
        } else {
          setIsFetchingProducts(false);
          throw new Error('No account id found');
        }
      } catch (error) {
        setLoading(false);
        setIsFetchingProducts(false);
        console.log(error);
      }
    };
    handleFunctionsOnPageLoad();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const successfulAccountCreation = params.get('successfulAccountCreation');
    window.history.replaceState({}, '', '/payments');

    if (successfulAccountCreation === 'true') {
      // show success modal with some messaging about being able to create plans
      paymentTrackingService.trackPaymentEvent('create_payment_account', {
        location: 'payments',
        status: 'created',
        coach_stripe_account_id: coachAccountId || '',
      });

      paymentTrackingService.setProperties({ coach_stripe_account_id: coachAccountId || '' });
      paymentTrackingService.setPropertiesOnce({ integrated_payments: true });
    } else if (successfulAccountCreation === 'refresh') {
      // show error modal with some messaging about not being able to create plans
      paymentTrackingService.trackPaymentEvent('create_payment_account', {
        location: 'payments',
        status: 'onboarding_not_complete',
        coach_stripe_account_id: coachAccountId || '',
      });
    }
  }, [location.search]);

  const handleCreatePlanSubmitForm = async (planData: Plan) => {
    const data: PlanPayload = {
      ...planData,
      accountId: coachAccountId || '',
      identifier: nanoid(),
      coachName: coachName,
    };
    try {
      setIsCreatingPlan(true);
      const result = await createPlan(data);
      const { newProduct } = result.data.data;
      const {
        productId: id,
        name,
        description,
        created,
        currency,
        price,
        priceId,
        coachAccountId,
        coachUserId,
        recurring: { interval, interval_count },
        funnelIdentifier,
        encodeData,
      } = newProduct;
      setProducts((prevProducts) => {
        const productObj: TableProduct = {
          id,
          plan: name,
          created,
          description,
          amount: currency.toUpperCase() + ' ' + price,
          paymentCycle: interval_count + ' ' + interval,
          actions: true,
          priceId,
          coachAccountId,
          coachUserId,
          funnelIdentifier,
          encodeData,
        };
        return [...prevProducts, productObj];
      });

      paymentTrackingService.trackPaymentEvent('create_payment_plan', {
        location: 'payments',
        status: 'created',
        coach_stripe_account_id: coachAccountId || '',
        plan_id: newProduct.productId,
        currency: newProduct.currency,
        amount: newProduct.price,
        interval: newProduct.recurring.interval,
        interval_count: newProduct.recurring.interval_count,
        title: newProduct.name,
        description: newProduct.description,
      });

      setIsCreatingPlan(false);
      setCreatePlanModalState(false);
    } catch (error) {
      setIsCreatingPlan(false);
      addToast(`An error has occurred creating a plan`, { appearance: 'error' });
    }
  };

  const copyCheckoutLink = (data: any) => {
    setClickedCellId(data.id);
    const { encodeData, funnelIdentifier } = data;
    const url = `${window.location.origin}/client-billing/${funnelIdentifier}/checkout?data=${encodeData}`;
    // copy checkout link to clipboard
    navigator.clipboard.writeText(url);
    paymentTrackingService.trackPaymentEvent('copy_plan_checkout_link', {
      location: 'payments',
      coach_stripe_account_id: coachAccountId || '',
      plan_id: data.id,
      funnel_identifier: data.funnelIdentifier,
    });
  };

  useEffect(() => {
    if (clickedCellId) {
      setTimeout(() => {
        setClickedCellId('');
      }, 2800);
    }
  }, [clickedCellId]);

  const tableColumns = useMemo<ColumnDef<Record<string, unknown>>[]>(
    () => [
      {
        header: 'Plan',
        cell: (row) => row.renderValue(),
        accessorKey: 'plan',
      },
      {
        header: 'Description',
        cell: (row) => row.renderValue(),
        accessorKey: 'description',
      },
      {
        header: 'Amount',
        cell: (row) => row.renderValue(),
        accessorKey: 'amount',
      },
      {
        header: 'Payment Cycle',
        cell: (row) => row.renderValue(),
        accessorKey: 'paymentCycle',
      },
      {
        header: 'Actions',
        accessorKey: 'actions',
        cell: (table) => {
          const original = table.row.original;
          return (
            <div className={classes.CopyCheckoutLinkButton}>
              <Button onClick={() => copyCheckoutLink(original)} iconLeft={<DuplicateIcon />}>
                {clickedCellId !== original.id ? 'Copy Checkout Link' : <Checkmark className={classes.Checkbox} />}
              </Button>
            </div>
          );
        },
      },
    ],
    [clickedCellId],
  );

  const handleCreateExpressAccount = async () => {
    try {
      setIsCreatingExpressAccount(true);
      const result = await createExpressAccount();
      paymentTrackingService.trackPaymentEvent('create_payment_account', {
        location: 'payments',
        status: 'redirecting',
        description: 'Retrieved stripe account onboarding link to set up stripe account',
        coach_stripe_account_id: coachAccountId || '',
      });
      setIsCreatingExpressAccount(false);
      window.location.href = result.data.data.url;
    } catch (error) {
      setIsCreatingExpressAccount(false);
      paymentTrackingService.trackPaymentEvent('create_payment_account', {
        location: 'payments',
        status: 'faled',
        description: 'Failed to create stripe account',
        error: (error as Error).message,
      });
      addToast(`An error has occurred creating stripe account`, { appearance: 'error' });
    }
  };

  const handleCompleteAccountSetup = async () => {
    try {
      setIsFetchingNewAccountLink(true);
      const result = await getAccountLinkUrl(coachAccountId || '');
      paymentTrackingService.trackPaymentEvent('complete_payment_account_onboarding', {
        location: 'payments',
        status: 'redirecting',
        description: 'Retrieved stripe account onboarding link to complete onboarding',
        coach_stripe_account_id: coachAccountId || '',
      });
      setIsFetchingNewAccountLink(false);
      window.location.href = result.data.data.url;
    } catch (error) {
      setIsFetchingNewAccountLink(false);
      paymentTrackingService.trackPaymentEvent('complete_payment_account_onboarding', {
        location: 'payments',
        status: 'faled',
        description: 'Failed to complete stripe account onboarding while redirecting to stripe',
        coach_stripe_account_id: coachAccountId || '',
        error: (error as Error).message,
      });
      addToast(`An error has occurred redirecting you to stripe`, { appearance: 'error' });
    }
  };

  const onCheckedChanged = (val: boolean) => {
    setTermsOfSerivceChecked(val);
  };

  return (
    <Layout loading={false} heading={'Payments'}>
      <div>
        {loading ? (
          <div className={classes.CenterContainer}>
            <BasicSmallSpinner />
            <h3>Fetching and verifiying Stripe Account...</h3>
          </div>
        ) : (
          <div>
            {isNoStripeAccount && (
              <div className={classes.NoAccountContainer}>
                <h2 className={classes.NoAccountHeading}>No Stripe Connect Account</h2>
                <div className={classes.FullWidthContainer}>
                  <p className={classes.NoAccountText}>
                    Looks like you haven&#39;t connected your Stripe account, which is required for client billing.
                    Click the button below to get started.
                  </p>
                </div>

                <div className={classes.FullWidthContainer}>
                  <Button
                    type="button"
                    onClick={handleCreateExpressAccount}
                    loading={isCreatingExpressAccount}
                    disabled={!termsOfServiceChecked}
                  >
                    Create Connect Account
                  </Button>
                </div>
                <div className={classes.MarginTop}>
                  <Checkbox
                    label={
                      <span className={classes.CheckboxLabel}>
                        By checking this box you are agreeing to our&nbsp;
                        <a
                          href="https://docs.google.com/document/d/1SAw_DgFyfN_VvbHhHIVYbz3ccsu5l9Qo44aT0-74rcM/edit?usp=sharing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </a>
                      </span>
                    }
                    onCheckedChange={onCheckedChanged}
                    checked={termsOfServiceChecked}
                    name="termsOfService"
                  />
                </div>
              </div>
            )}
            {isOnboardingNotComplete && (
              <div className={classes.NoAccountContainer}>
                <h2 className={classes.NoAccountHeading}>Onboarding is not complete</h2>
                <p className={classes.NoAccountText}>
                  You first need to complete the onboarding process before you can create plans. Click the button below
                  to complete your account onboarding.
                </p>
                <div>
                  <Button type="button" onClick={handleCompleteAccountSetup} loading={isFetchingNewAccountLink}>
                    Complete Account Set Up
                  </Button>
                </div>
              </div>
            )}
            {isOnboardingComplete && (
              <Tabs defaultTab="Plans" ariaLabel="List of tab navigations">
                <div title="Plans">
                  <div className={classes.FlexEndContainer}>
                    <div className={classes.FlexContainer}>
                      <div className={classes.DashboardButton}>
                        <a
                          href="https://dashboard.stripe.com/login"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            paymentTrackingService.trackPaymentEvent('view_stripe_dashboard', {
                              location: 'payments',
                              status: 'redirecting',
                              description: 'Redirecting to stripe dashboard',
                              coach_stripe_account_id: coachAccountId || '',
                            });
                          }}
                        >
                          <Button type="button">View Dashboard</Button>
                        </a>
                      </div>

                      <Dialog
                        title="Create plan"
                        trigger={
                          <Button type="button" onClick={() => setCreatePlanModalState(true)}>
                            Create Plan
                          </Button>
                        }
                        open={showCreatePlanModal}
                        onOpenChange={setCreatePlanModalState}
                      >
                        <CreateClientBillingPlan onSubmit={handleCreatePlanSubmitForm} loading={isCreatingPlan} />
                      </Dialog>
                    </div>
                  </div>
                  <div className={classes.TableWrapper}>
                    <Table data={products} columns={tableColumns} loading={isFetchingProducts} />
                  </div>
                </div>
              </Tabs>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default connector(withAuth(Payments));
