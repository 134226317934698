import { Theme } from 'interfaces/settings';
import { firestore } from 'utils/firebase';
import { FirestoreCollections } from 'enums/firestoreEnum';
import { AppThunk } from 'store';
import { DefaultThemes } from 'enums/themeEnum';

export const SET_THEME = 'SET_THEME';
export const FETCH_THEME_PREFERENCE = 'FETCH_THEME_PREFERENCE';

export const setTheme = (themePreference: Theme) => ({
  type: SET_THEME,
  themePreference,
});

export const fetchThemePreference = (userId: string | null): AppThunk => {
  return async (dispatch) => {
    if (!userId) {
      dispatch(setTheme(DefaultThemes.Light));
      return;
    }

    const themeRef = firestore
      .collection(FirestoreCollections.Coaches)
      .doc(userId)
      .collection(FirestoreCollections.Settings)
      .doc('theme');

    const themeDoc = await themeRef.get();

    if (!themeDoc.exists) {
      dispatch(setTheme(DefaultThemes.Light));
      return;
    }

    const themeData = themeDoc.data();
    const preference = themeData?.preference as Theme;

    dispatch(setTheme(preference || DefaultThemes.Light));
  };
};
