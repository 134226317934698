import React, { FC, useEffect, useState } from 'react';
import classes from './PieChart.module.css';

import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import isEqual from 'react-fast-compare';
ChartJS.register(ArcElement, Tooltip);

interface Props {
  watchFields: [
    string,
    number | string | null,
    number | string | null,
    number | string | null,
    number | string | null,
    number | string | null,
    number | string | null,
  ];
  disabled: boolean;
}

const PieChart: FC<Props> = ({ watchFields, disabled = false }) => {
  const [macroPieData, setMacroPieData] = useState({
    labels: ['Protein', 'Carbs', 'Fat'],
    datasets: [
      {
        labels: [Number(watchFields[1]) + '%', Number(watchFields[2]) + '%', Number(watchFields[3]) + '%'],
        data: [Number(watchFields[1]), Number(watchFields[2]), Number(watchFields[3])],
        backgroundColor: ['#5964DA', '#7782F8', '#C1C5F4'],
        hoverBackgroundColor: ['#5964DA', '#7782F8', '#C1C5F4'],
        borderColor: ['#5964DA', '#7782F8', '#C1C5F4'],
        borderWidth: 1,
        hoverBorderWidth: 0,
        hoverOffset: 0,
      },
    ],
  });
  const [previousValues, setPreviousValues] = useState({});

  useEffect(() => {
    const valuesChanged = watchFields.some((field, index) => {
      return !isEqual(watchFields[index], previousValues?.[field || 0]);
    });

    if (valuesChanged) {
      setMacroPieData((prevMacroData) => {
        return {
          ...prevMacroData,
          datasets: [
            {
              ...prevMacroData.datasets[0],
              data: [Number(watchFields[1]), Number(watchFields[2]), Number(watchFields[3])],
            },
          ],
        };
      });

      setPreviousValues({
        macrosType: watchFields[0],
        'macros.protein.percentage': watchFields[1],
        'macros.carbs.percentage': watchFields[2],
        'macros.fat.percentage': watchFields[3],
        'macros.protein.grams': watchFields[4],
        'macros.carbs.grams': watchFields[5],
        'macros.fat.grams': watchFields[6],
      });
    }
  }, [watchFields[0], watchFields[1], watchFields[2], watchFields[3]]);

  return (
    <div className={classes.PieChart}>
      {Number(watchFields[1]) + Number(watchFields[2]) + Number(watchFields[3]) !== 0 && !disabled ? (
        <Pie data={macroPieData} />
      ) : (
        <div className={classes.Disabled}></div>
      )}
    </div>
  );
};

export default PieChart;
