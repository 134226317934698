import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import firebase from 'firebase/app';
import { Line } from 'react-chartjs-2';
import { database, firestore } from 'utils/firebase';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { QuerySnapshot, DocumentData } from '@firebase/firestore-types';
import clsx from 'clsx';
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
} from 'chart.js';

import { ClientProfileParams } from 'interfaces/routes';
import withAuth from 'utils/withAuth';
import { RootState } from 'store';
import Layout from '../Layout';
import { CLIENTS_PROGRESS } from 'utils/routes';
import { saveClientProfile } from 'store/actions/clients';
import { Profile, Progress, ProgressPhotosTemplate } from 'interfaces/db';
import classes from './Progress.module.css';
import { Spinner, Button, Dialog } from 'components/UI';
import { getDateStringWithNoSeparators, groupByMonth, groupByYear, flatDeep } from 'utils/helpers';
import { PHOTOS_TEMPLATE } from 'utils/constants';
import CompareProgressPhotos from 'components/CompareProgressPhotos';
import { ReactComponent as ChevronForwardIcon } from 'assets/svgs/chevron-forward-outline.svg';
import { progressTrackingService } from 'utils/tracking/progressService';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);

dayjs.extend(customParseFormat); // allows us to set our own format for dates

const mapStateToProps = ({ auth, clients, settings }: RootState) => {
  return {
    token: auth.token,
    clients: clients.clients,
    loading: clients.loading,
    removed: clients.removed,
    theme: settings.theme,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveClientProfile: (clientProfile: Profile, clientKey: string) =>
      dispatch(saveClientProfile(clientProfile, clientKey)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & RouteComponentProps<ClientProfileParams>;
type EntriesProps = {
  entriesData: any;
  setSelectedEntry: (value: Progress) => void;
  selectedEntry: Progress | undefined;
};

type TimeFilterTabsProps = {
  timeFilterOptions: {
    value: string;
    label: string;
  }[];
  timeFilter: string;
  setSelectedTimeFilter: (value: string) => void;
};
interface IProgress {
  [key: string]: Progress;
}
interface ProgressDateKey {
  [date: string]: Progress;
}

const EntriesList: FC<EntriesProps> = ({ entriesData, setSelectedEntry, selectedEntry }) => {
  const selectedEntryHandler = (currentEntry) => {
    progressTrackingService.trackProgressEvent('select_body_weight_entry', {
      location: 'client_progress',
      object: 'button',
      action: 'click',
      list_item: {
        text: `${currentEntry?.bodyWeight}kg`,
        action: 'select_progress_entry',
        description: 'Body weight entry list item on progress page',
      },
    });
    setSelectedEntry(currentEntry);
  };
  return (
    <div className={classes.EntriesWrapper}>
      <ul className={classes.WeightsList}>
        <div className={classes.EntriesTitle}>
          <span>Entries:</span>
          <hr className={classes.Divider} />
        </div>
        {entriesData?.labels.map((label: any, i: number) => {
          const currentEntry = entriesData.datasets[0]?.data[i];
          return (
            <React.Fragment key={label}>
              <li
                onClick={() => selectedEntryHandler(currentEntry)}
                className={clsx(classes.EntryListItem, selectedEntry === currentEntry && classes.EntrySelected)}
              >
                <div>
                  <span>{currentEntry.bodyWeight}kg</span>
                  <p>{dayjs(label, 'DD MMM YYYY').format('ddd, D MMM YYYY')}</p>
                </div>
                <ChevronForwardIcon />
              </li>
              <hr className={classes.Divider} />
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

const TimeFilterTabs: FC<TimeFilterTabsProps> = ({ timeFilterOptions, timeFilter, setSelectedTimeFilter }) => {
  return (
    <div className={classes.GraphHeader}>
      <ul className={classes.TabList}>
        {timeFilterOptions.map((option) => (
          <li
            key={option.value}
            className={clsx(classes.Tab, timeFilter === option.value && classes.TabSelected)}
            onClick={() => {
              if (option.value !== timeFilter) {
                setSelectedTimeFilter(option.value);
              }
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ProgressPage: FC<Props> = ({ match, theme }) => {
  const [timeFilter, setTimeFilter] = useState('all');
  const [isFullData, setIsFullData] = useState(true);
  const [data, setData] = useState<any>();
  const [fullGraphData, setFullGraphData] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>();
  const [entriesData, setEntriesData] = useState<any>();
  const [isGraphMounted, setIsGraphMounted] = useState(false);
  const [, setLoading] = useState<boolean>(false);
  const { clients } = useSelector((state: RootState) => state.clients);
  const { clientKey } = match.params;
  const clientUserId: string = clients?.[clientKey || '']?.clientUserId || '';
  const [clientProgress, setClientProgress] = useState<IProgress[]>();
  const [, setClientProgressPhotosTemplate] = useState<ProgressPhotosTemplate>();
  const [selectedEntry, setSelectedEntry] = useState<Progress>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [leftSelectedEntry, setLeftSelectedEntry] = useState<Progress>();
  const [rightSelectedEntry, setRightSelectedEntry] = useState<Progress>();
  const [leftDate, setLeftDate] = useState<string>();
  const [rightDate, setRightDate] = useState<string>();
  const [selectedLeftImageCategory, setSelectedLeftImageCategory] = useState<string>('');
  const [selectedRightImageCategory, setSelectedRightImageCategory] = useState<string>('');

  const today = dayjs();

  const timeFilterOptions = [
    { value: 'all', label: 'All' },
    { value: 'oneYearAgo', label: 'YTD' },
    { value: 'nineMonthsAgo', label: '9M' },
    { value: 'sixMonthsAgo', label: '6M' },
    { value: 'threeMonthsAgo', label: '3M' },
    { value: 'twoMonthsAgo', label: '2M' },
    { value: 'oneMonthAgo', label: '1M' },
  ];

  const [filters] = useState<any>({
    oneYearAgo: today.subtract(1, 'year'),
    nineMonthsAgo: today.subtract(9, 'month'),
    sixMonthsAgo: today.subtract(6, 'month'),
    threeMonthsAgo: today.subtract(3, 'month'),
    twoMonthsAgo: today.subtract(2, 'month'),
    oneMonthAgo: today.subtract(1, 'month'),
  });

  const fetchPhotoTemplates = async (isFocused = true) => {
    const photoCategories: ProgressPhotosTemplate = {};
    await firestore
      .collection(`clients`)
      .doc(clientUserId)
      .collection('progressPhotosTemplate')
      .get()
      .then((snapshot: QuerySnapshot) => {
        snapshot.forEach((doc: DocumentData) => {
          photoCategories[doc.data().categoryName.toLowerCase().replace(' ', '_')] = doc.data();
        });
        if (isFocused || isFocused === undefined) {
          setClientProgressPhotosTemplate(photoCategories);
        }
      })
      .catch((err: any) => {
        console.log(err, 'Failed to fetch progress photo categories.');
      });
  };

  const fetchClientProgress = async () => {
    const dataPoints: IProgress[] = [];
    const keyDataPoints: ProgressDateKey = {};
    const fetchProgressDocsPromise = firestore
      .collection(`clients`)
      .doc(clientUserId)
      .collection('progress')
      .orderBy(firebase.firestore.FieldPath.documentId())
      .get()
      .then((snapshot: QuerySnapshot) => {
        snapshot.forEach((doc: DocumentData) => {
          const data = doc.data();
          dataPoints.push(data);
          keyDataPoints[doc.id] = data;
        });
      })
      .catch((err: any) => {
        console.log(err, 'Failed to fetch progress documents.');
      });

    Promise.all([fetchPhotoTemplates, fetchProgressDocsPromise])
      .then(() => {
        setClientProgress(dataPoints);
        setData(keyDataPoints);
        return dataPoints;
      })
      .catch((err) => {
        console.log(err, 'Catch all error for fetchClientProgress');
      });
  };

  useEffect(() => {
    const transferBodyWeightDataToFirestore = async () =>
      new Promise((resolve, reject) => {
        const progressData: Progress[] = [];

        database
          .ref(`bodyWeights/${clientKey}`)
          .once('value')
          .then((snapshot) => {
            snapshot.forEach((el: any) => {
              const key = el.key;

              if (!key) {
                return;
              }

              Object.entries(el.val()).map((el: any) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: Unused variable
                const [_, elVal] = el;
                const dateParts = elVal.date.split('/');
                const dateObject = dayjs(new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]));

                progressData.push({
                  bodyWeight: +elVal.weightValue,
                  date: dateObject.toString(),
                  overallClientNotes: '',
                  overallCoachNotes: '',
                  photos: PHOTOS_TEMPLATE,
                });
              });
            });

            const batch = firestore.batch();
            const clientRef = firestore.collection(`clients`).doc(clientUserId);

            progressData.map((progressDoc: any) => {
              const clientsProgressNewDoc = clientRef
                .collection('progress')
                .doc(getDateStringWithNoSeparators(progressDoc?.date));
              batch.set(clientsProgressNewDoc, progressDoc);
            });

            Object.entries(PHOTOS_TEMPLATE).map(([_, value]) => {
              console.log(_);
              const photosTemplateRef = clientRef.collection('progressPhotosTemplate').doc();
              batch.set(photosTemplateRef, { ...value, docId: photosTemplateRef.id });
            });

            batch
              .commit()
              .then(() => {
                clientRef
                  .set({
                    bodyWeightsTransferred: true,
                  })
                  .then(() => {
                    resolve('Successful transfer of bodyweight node to firestore progress document.');
                  });
              })
              .catch((err: any) => {
                reject(err);
                console.log(
                  err,
                  'Failed to batch commit in body weight transfer [progress data and/or photo categories].',
                );
              });
          })
          .catch((err: any) => {
            reject(err);
            console.log(err, 'Failed to fetch body weight data from real time database node.');
          });
      });

    const runProgressDataProcessing = async () => {
      const clientRef = firestore.collection(`clients`).doc(clientUserId);
      setLoading(true);
      await clientRef.get().then(async (clientDoc: any) => {
        if (clientDoc.exists) {
          const clientData = clientDoc.data();
          if (clientData?.bodyWeightsTransferred) {
            // fetch progress data as body weights have been transferred to firstore
            fetchClientProgress();

            setLoading(false);
          } else {
            transferBodyWeightDataToFirestore().then(async () => {
              await fetchClientProgress();
              setLoading(false);
            });
          }
        } else {
          transferBodyWeightDataToFirestore().then(async () => {
            await fetchClientProgress();
            setLoading(false);
          });
        }
      });
    };

    runProgressDataProcessing();
  }, []);

  const setSelectedTimeFilter = (timeValue: string) => {
    setTimeFilter(timeValue);
    if (!isFullData && timeValue === 'all') {
      setIsFullData(true);
    } else if (isFullData && timeValue !== 'all') {
      setIsFullData(false);
    }
  };

  useEffect(() => {
    if (clientProgress) {
      const values = clientProgress?.map((value: any) => {
        if (value) {
          const date = dayjs(value?.date).format('DD MMM YYYY');
          return {
            ...value,
            date: date,
            bodyWeight: +value?.bodyWeight,
          };
        }
      });
      const entriesByYear = groupByYear(({ date }: any) => date, values);

      const entriesByMonth = Object.keys(entriesByYear).map((year) => {
        const value = groupByMonth(({ date }: any) => date, entriesByYear[year]);
        return Object.values(value);
      });
      const flattenedData = flatDeep(entriesByMonth, 3);
      const labelsTemp: any[] = flattenedData.map((item: any) => item.date);

      const graphData: any = {
        labels: labelsTemp,
        datasets: [
          {
            label: 'Body Weight (Kg)',
            data: flattenedData || [],
            borderColor: theme['--color-accent-primary'] || '#7782F8',
            backgroundColor: theme['--color-accent-primary'] || '#7782F8',
          },
        ],
      };

      const graphDataCopy = JSON.parse(JSON.stringify(graphData));
      setEntriesData({
        ...graphDataCopy,
        labels: graphDataCopy.labels.reverse(),
        datasets: [
          {
            ...graphDataCopy?.datasets,
            data: graphDataCopy?.datasets[0]?.data?.reverse(),
          },
        ],
      });

      fetchPhotoTemplates(true);
      setSelectedTimeFilter('all');
      setFullGraphData(graphData);
      setFilteredData(graphData);
      setIsGraphMounted(true);
    }
  }, [clientProgress]);

  useEffect(() => {
    if (fullGraphData && timeFilter) {
      if (timeFilter !== 'all') {
        const filterLabels = fullGraphData.labels?.filter((label: string) => {
          return !dayjs(label, 'DD MMM YYYY').isBefore(filters[timeFilter]);
        });

        const filteredData = fullGraphData.datasets[0].data?.filter((entry: any) => {
          return !dayjs(entry?.date, 'DD MMM YYYY').isBefore(filters[timeFilter]);
        });

        if (fullGraphData.labels !== filterLabels) {
          setFilteredData({
            ...fullGraphData,
            datasets: [
              {
                ...fullGraphData?.datasets,
                data: filteredData,
                label: 'Body Weight (Kg)',
                borderColor: theme['--color-accent-primary'] || '#7782F8',
                backgroundColor: theme['--color-accent-primary'] || '#7782F8',
              },
            ],
            labels: filterLabels,
          });
        }
      } else {
        setFilteredData(fullGraphData);
      }
    }
  }, [timeFilter]);

  useEffect(() => {
    if (data) {
      const keysArray: string[] = Object.keys(data);
      const progressLength: number = keysArray.length;
      const leftRightDate = data[keysArray[progressLength - 1]];
      setLeftDate(dayjs(leftRightDate?.date).format('DD MMMM YYYY') || new Date().toString());
      setRightDate(dayjs(leftRightDate?.date).format('DD MMMM YYYY') || new Date().toString());
    }
  }, [data]);

  const selectEntryByDate = async (
    date: Date | string,
    setProgress: (progress: Progress) => void,
    setSelectedImageCategory: (categoryKey: string) => void,
  ) => {
    const dateKey = getDateStringWithNoSeparators(new Date(date));
    if (data && dateKey) {
      const progressDataObject: Progress = data?.[dateKey];

      setProgress(progressDataObject);
      setSelectedImageCategory(progressDataObject ? Object.keys(progressDataObject.photos)[0] : '');
    }
  };

  useEffect(() => {
    if (leftDate) {
      selectEntryByDate(leftDate, setLeftSelectedEntry, setSelectedLeftImageCategory);
    }
  }, [leftDate]);

  useEffect(() => {
    if (rightDate) {
      selectEntryByDate(rightDate, setRightSelectedEntry, setSelectedRightImageCategory);
    }
  }, [rightDate]);

  const getProgressImages = () => {
    const imageCards = Object.values(selectedEntry?.photos || {})
      .filter((photo) => photo?.image)
      ?.map((photo) => {
        return (
          <div className={classes.ProgressPhotoCard} key={photo.image}>
            <div className={classes.ImageContainer}>
              <img src={photo?.image} />
            </div>
            <div className={classes.CategoryInfo}>
              <h5>{photo.categoryName}</h5>
              <p>{photo.clientNotes}</p>
            </div>
          </div>
        );
      });
    return imageCards.length > 0 ? imageCards : <h6 className={classes.Heading}>No Images</h6>;
  };

  const compareButtonHandler = () => {
    progressTrackingService.trackProgressEvent('compare_progress_photos', {
      location: 'client_progress',
      object: 'button',
      action: 'click',
      interaction_type: 'open_compare_progress_photos_modal',
      button: {
        text: 'COMPARE',
        action: 'open_compare_progress_photos_modal',
        description: 'Compare progress photos button on progress page',
      },
    });
    setIsModalVisible(true);
  };

  return (
    <>
      <Layout loading={false} heading={CLIENTS_PROGRESS.TITLE}>
        <div className={classes.Container}>
          <table className={classes.BodyWeightGraphContainer}>
            <tbody>
              <tr>
                <td className={classes.Graph}>
                  {isGraphMounted && data && (
                    <TimeFilterTabs
                      timeFilterOptions={timeFilterOptions}
                      timeFilter={timeFilter}
                      setSelectedTimeFilter={setSelectedTimeFilter}
                    />
                  )}
                  {filteredData?.labels?.length === 0 && <h6 className={classes.Heading}>No Data</h6>}
                  {isGraphMounted && !data && <h6 className={classes.Heading}>No Entries</h6>}
                  {isGraphMounted ? (
                    <div className={classes.LineGraphWrapper}>
                      <Line
                        data={filteredData || {}}
                        options={{
                          parsing: {
                            yAxisKey: 'bodyWeight',
                            xAxisKey: 'date',
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className={classes.BottomContainer}>
            {isGraphMounted && data && (
              <>
                <EntriesList
                  entriesData={entriesData}
                  setSelectedEntry={setSelectedEntry}
                  selectedEntry={selectedEntry}
                />
                <div className={classes.ProgressPhotosContainer}>
                  <div className={classes.ProgressPhotosHeader}>
                    <div>
                      {selectedEntry ? (
                        selectedEntry?.overallClientNotes ? (
                          <>
                            <h6 className={classes.Heading}>Client Overall Notes</h6>
                            <p className={classes.ClientNotesOverall}>{selectedEntry?.overallClientNotes}</p>
                          </>
                        ) : null
                      ) : (
                        <h6 className={classes.Heading}>Please select an entry</h6>
                      )}
                    </div>
                    <div>
                      <Button type="button" onClick={compareButtonHandler}>
                        Compare
                      </Button>
                    </div>
                  </div>
                  <div className={classes.ProgressPhotoGrid}>{selectedEntry && getProgressImages()}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
      <Dialog title="Compare progress photos" onOpenChange={setIsModalVisible} open={isModalVisible}>
        <CompareProgressPhotos
          leftSelectedEntry={leftSelectedEntry}
          selectedLeftImageCategory={selectedLeftImageCategory}
          leftDate={leftDate || ''}
          setLeftDate={setLeftDate}
          setSelectedLeftImageCategory={setSelectedLeftImageCategory}
          rightSelectedEntry={rightSelectedEntry}
          selectedRightImageCategory={selectedRightImageCategory}
          rightDate={rightDate || ''}
          setRightDate={setRightDate}
          setSelectedRightImageCategory={setSelectedRightImageCategory}
          data={data}
          setIsModalVisible={setIsModalVisible}
        />
      </Dialog>
    </>
  );
};

export default connector(withAuth(ProgressPage));
