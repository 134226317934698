import rfdc from 'rfdc';
import {
  Macronutrients,
  Micronutrients,
  NutritionDay,
  NutritionGoals,
  NutritionPlan,
  NutritionWeek,
} from 'interfaces/nutrition';
import { generateWeekId } from './generateWeekId';

export const convertToNutritionPlan = (
  formData: NutritionGoals,
  existingPlan: NutritionPlan,
  weeksRef,
): NutritionPlan => {
  const clone = rfdc();
  const weeksRefWeeks: NutritionWeek[] = weeksRef.current
    .map((week) => week?.getValues() || ({} as NutritionWeek))
    .filter((week) => Object.keys(week).length !== 0);

  const newPlan = clone({ ...existingPlan, weeks: weeksRefWeeks });

  const processedMacros: Macronutrients = {};
  for (const [key, value] of Object.entries(formData.macros)) {
    processedMacros[key] = {
      achieved: null,
      goal: value.grams,
      goalPercentage: value.percentage,
      unit: value.unit,
      selected: value.selected,
    };
  }

  const processMicros = (day: NutritionDay | undefined) => {
    const processedMicros: Micronutrients = {};
    for (const [key, value] of Object.entries(formData.micros)) {
      if (value) {
        processedMicros[key] = {
          achieved: null,
          goal: Number(day?.micros?.[key]?.goal || 0),
          goalPercentage: Number(day?.micros?.[key]?.goalPercentage || 0),
          unit: key === 'sodium' ? 'mg' : 'g',
          selected: day?.micros?.[key]?.selected || false,
        };
      }
    }

    return processedMicros;
  };

  let startWeekIndex: number = 0;
  const weeksLength = newPlan.weeks?.length || 0;

  for (let i = 0; i < weeksLength; i++) {
    if (newPlan.weeks?.[i].id === formData.schedule.startWeek) {
      startWeekIndex = i;
      break;
    }
  }

  const numberOfWeeks = parseInt(formData.schedule.numberOfWeeks);
  const newWeekIds: string[] = [];

  for (let weekOffset = 0; weekOffset < numberOfWeeks; weekOffset++) {
    const existingWeek = newPlan.weeks?.[startWeekIndex + weekOffset];
    const isNewWeek = !existingWeek?.id;
    const weekId = existingWeek?.id || generateWeekId(newPlan.planId);

    const week: NutritionWeek = existingWeek || { id: weekId, days: [], isNewWeek: true };

    if (!week.days) {
      week.days = [];
    }

    const daysLength = week.days!.length;

    formData.schedule.days.forEach((isActive, dayIndex) => {
      if (isActive && !week.days?.[dayIndex]?.finished) {
        const day: NutritionDay = {
          macrosType: formData.macrosType,
          finished: false,
          dayIndex,
          calories: {
            index: 0,
            achieved: null,
            goal: Number(formData.calories || 0),
            unit: 'kcal',
          },
          macros: processedMacros,
          micros: processMicros(week.days?.[dayIndex]),
          variance: Number(formData.variance || 0),
          notes:
            {
              client: week.days && week.days[dayIndex] ? week.days[dayIndex].notes?.client || null : null,
              coach: formData.notes,
            } || null,
          title: formData.title,
        };

        if (daysLength === 0) {
          week.days!.push(day);
        } else {
          week.days![dayIndex] = day;
        }
      } else if (!isActive) {
        if (isNewWeek) {
          week.days!.push({
            finished: false,
            dayIndex,
          } as NutritionDay);
        }
      }
    });

    if (isNewWeek) {
      newWeekIds.push(weekId);
      newPlan.weeks?.push(week);
    }
  }

  return {
    ...newPlan,
    weekOrder: [...newPlan.weekOrder, ...newWeekIds],
  };
};
