import { Dispatch } from 'redux';
import { removeNutritionPlan } from 'store/actions/nutrition';

export const removePlan = async (planId: string, token: string | null, dispatch: Dispatch<any>) => {
  if (!token) return console.error('No token');
  try {
    await dispatch(removeNutritionPlan(planId, token));
  } catch (err) {
    console.error(err);
  }
};
