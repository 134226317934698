import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classes from './Slider.module.css';

// Import Swiper styles
import './styles.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

import ProgramCard from 'components/FunnelCards/ProgramCard';
import TestimonialCard from 'components/FunnelCards/TestimonialCard';
import SwiperCore, { Navigation, Pagination, FreeMode } from 'swiper';
import { FunnelProgram, Testimonial } from 'interfaces/db';

import { useMediaQuery } from 'utils/hooks';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, FreeMode]);

interface Props {
  programs?: FunnelProgram[];
  testimonials?: Testimonial[];
}

const Slider: FC<Props> = ({ programs, testimonials }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  return (
    <div className={classes.sliderWrapper}>
      <div className={classes.swiperContainer}>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={20}
          freeMode={true}
          grabCursor={true}
          navigation={!!isDesktop}
          pagination={{
            clickable: true,
            type: 'progressbar',
          }}
        >
          {programs?.map((program) => (
            <SwiperSlide key={program.programKey}>
              <ProgramCard program={program} />
            </SwiperSlide>
          ))}

          {testimonials?.map((testimonial, key) => (
            <SwiperSlide key={key}>
              <TestimonialCard testimonial={testimonial} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
