import { useState } from 'react';

const useDropdown = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    toggleDropdown,
    closeDropdown,
  };
};

const useDropdownUnique = () => {
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((currentOpenId) => (currentOpenId === id ? null : id));
  };

  const closeDropdown = () => {
    setOpenDropdownId(null);
  };

  const isOpen = (id: string) => {
    return openDropdownId === id;
  };

  return {
    toggleDropdown,
    closeDropdown,
    isOpen,
  };
};

export { useDropdown, useDropdownUnique };
