import { Set } from 'interfaces/db';

export const getFormattedExerciseParameters = (sets: Set[]) => {
  const { completed, ...setsData } = sets[0] || {};
  const setsDataArray = Object.keys(setsData || {}) as Array<keyof typeof setsData>;

  const formattedParameters = setsDataArray
    .sort((elA, elB) => (setsData[elA] as any)?.index - (setsData[elB] as any)?.index)
    .map((key, index) => {
      if (index === setsDataArray.length - 1) {
        return key;
      }

      return `${key} | `;
    });

  return formattedParameters;
};
