import React, { FC, ReactNode } from 'react';
import { Field, ErrorMessage } from 'formik';
import clsx from 'clsx';

import classes from './TextField.module.css';

interface TextFieldProps {
  icon?: ReactNode;
  name: string;
  errors?: any;
  touched?: any;
  type?: 'text' | 'password';
  placeholder: string;
  customClass?: string;
  auto?: boolean;
}

const TextField: FC<TextFieldProps> = ({
  icon,
  name = '',
  errors = {},
  touched = {},
  type = 'text',
  placeholder = '',
  customClass = '',
  auto = false,
}) => {
  const isInvalid = !!(touched[name] && errors[name]);

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.innerWrapper, customClass, isInvalid && classes.errorInnerWrapper)}>
        {!!icon && <div className={classes.iconWrapper}>{icon}</div>}

        <Field
          id={name}
          name={name}
          type={type}
          className={classes.input}
          placeholder={placeholder}
          autoComplete={!auto ? (type === 'password' ? 'new-password' : 'text') : true}
        />
      </div>
      <ErrorMessage name={name}>{(errMsg) => <small className={classes.error}>{errMsg}</small>}</ErrorMessage>
    </div>
  );
};

export default TextField;
