import React, { ChangeEvent, FC, useState } from 'react';
import classes from './ChannelsSidebar.module.css';
import { Channel, ChatSettings, Clients } from 'interfaces/db';
import { ChannelData } from 'containers/Community';
import { ReactComponent as SettingsSvg } from 'assets/svgs/settings-outline.svg';
import { ReactComponent as SearchSvg } from 'assets/svgs/search-filled.svg';
import { Button, Input } from 'components/UI';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

interface Props {
  channels: Channel[];
  clients: Clients[];
  setSelectedChannel: (channel: ChannelData) => void;
  selectedChannel: ChannelData;
  showSettings: () => void;
  chatSettings: ChatSettings;
}

type SearchClients = {
  search: string;
};

const ChannelsSidebar: FC<Props> = ({
  channels,
  clients,
  setSelectedChannel,
  selectedChannel,
  showSettings,
  chatSettings,
}) => {
  const { register } = useForm<SearchClients>();
  const [clientsFiltered, setClientsFiltered] = useState<Clients[]>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const { isCommunityChatEnabled, isDirectChatEnabled } = chatSettings;

  const channelIds = new Set(channels.map((channel) => channel.id));

  const clientsList = searchValue ? clientsFiltered : clients;
  // Sort clients
  const sortedClients = [...clientsList].sort((a, b) => {
    const aExistsInChannels = channelIds.has(a.clientUserId);
    const bExistsInChannels = channelIds.has(b.clientUserId);

    if (aExistsInChannels && !bExistsInChannels) {
      return -1;
    }

    if (!aExistsInChannels && bExistsInChannels) {
      return 1;
    }

    return 0;
  });

  const onSearchClients = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();
    setSearchValue(value);

    const clientsFiltered = clients.filter((client) => client.profile.fullname.toLowerCase().includes(value));

    setClientsFiltered(clientsFiltered);
  };

  return (
    <div className={classes.Container}>
      {isCommunityChatEnabled && <p className={classes.SectionHeading}>Communities</p>}
      <ul className={clsx(classes.ChannelList, isDirectChatEnabled && isCommunityChatEnabled && classes.MarginBottom)}>
        {isCommunityChatEnabled &&
          channels?.map((channel, key) => {
            if (channel.data?.type === 'direct') return null;
            return (
              <li key={key} className={classes.ListItem}>
                <button
                  className={clsx(classes.ChannelButton, selectedChannel.id === channel.id && classes.Selected)}
                  onClick={() =>
                    setSelectedChannel({
                      id: channel.id,
                      title: channel.data?.channelTitle,
                      type: 'community',
                    })
                  }
                >
                  {channel?.data?.channelTitle || channel.id}
                </button>
              </li>
            );
          })}
      </ul>
      {isDirectChatEnabled && (
        <>
          <p className={classes.SectionHeading}>Clients</p>
          <div className={classes.SearchClients}>
            <Input
              name="search"
              register={register}
              type="text"
              placeholder="Search clients..."
              onChange={onSearchClients}
              iconLeft={<SearchSvg />}
            />
          </div>
          <ul className={classes.ClientList}>
            {sortedClients?.map((client, key) => (
              <li key={key} className={classes.ListItem}>
                <button
                  className={clsx(
                    classes.ChannelButton,
                    selectedChannel.id === client.clientUserId && classes.Selected,
                  )}
                  onClick={() =>
                    setSelectedChannel({
                      id: client.clientUserId,
                      title: client.profile.fullname,
                      type: 'direct',
                    })
                  }
                >
                  {client.profile.fullname}
                </button>
              </li>
            ))}
          </ul>
        </>
      )}

      <div className={classes.SettingsContainer}>
        <Button onClick={showSettings} intent="secondary" iconLeft={<SettingsSvg />}>
          Chat Settings
        </Button>
      </div>
    </div>
  );
};

export default ChannelsSidebar;
