import { nanoid } from 'nanoid';
import React, { FC } from 'react';
import classes from './TabNav.module.css';
import clsx from 'clsx';
interface Props {
  tabHeadingsObject?: Array<{ title: string; items?: any }>;
  setSelectedTab: (tab: string) => void;
  selectedTab: string;
  setCategoriesModalOpen?: (val: boolean) => void;
  setEditTabsModalOpen?: (val: boolean) => void;
}

const TabNavTotals: FC<Props> = ({ tabHeadingsObject = [], setSelectedTab, selectedTab }) => {
  const tabs: any = tabHeadingsObject?.map((tab) => {
    const { title, items } = typeof tab === 'string' ? { title: tab, items: undefined } : tab;
    return (
      <div
        key={nanoid()}
        onClick={() => setSelectedTab(title)}
        className={clsx(classes.Tab, selectedTab === title && classes.Selected)}
      >
        {title}
        {items !== undefined ? ` (${items})` : null}
      </div>
    );
  });

  return (
    <div className={classes.TabsContainer}>
      <div className={classes.LeftTabContainer}>{tabs}</div>
    </div>
  );
};

export default TabNavTotals;
