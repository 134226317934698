import React, { FC, ReactElement, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Root, Trigger, Portal, Content, Item, Separator } from '@radix-ui/react-dropdown-menu';

import classes from './DropdownMenu.module.css';
import { SVGIconProps } from 'interfaces/ui';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  menuItems: {
    title: string;
    url?: string;
    onClick?: any;
    iconLeft?: ReactElement<SVGIconProps> | null;
  }[];
  trigger?: ReactElement<any>;
  width?: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
};

const DropdownMenu: FC<Props> = ({
  open,
  onOpenChange,
  menuItems,
  trigger,
  width = '8.3125rem',
  side = 'left',
  align = 'start',
}) => {
  const menuItemsLength = menuItems.length;
  const menuItemsJSX = menuItems?.map((item, index) => {
    const iconLeft = item?.iconLeft ? item.iconLeft : null;
    const listItem = (
      <Item className={clsx(classes.DropDownButton, iconLeft && classes.WithIcon)} onClick={item.onClick}>
        {iconLeft && <span className={clsx(classes.Icon, classes.MarginRight)}>{iconLeft}</span>}
        {item.title}
      </Item>
    );
    return (
      <Fragment key={index}>
        {item?.url ? (
          <NavLink to={item.url} activeClassName={classes.active} exact>
            {listItem}
          </NavLink>
        ) : (
          listItem
        )}
        {menuItemsLength > 1 && index !== menuItemsLength - 1 && <Separator className={classes.LineRule}></Separator>}
      </Fragment>
    );
  });

  return (
    <Root open={open} onOpenChange={onOpenChange} modal={false}>
      <Trigger asChild>{trigger}</Trigger>

      <Portal>
        <Content sideOffset={5} side={side} align={align} className={classes.DropDownContainer} style={{ width }}>
          {menuItemsJSX}
        </Content>
      </Portal>
    </Root>
  );
};

export default DropdownMenu;
