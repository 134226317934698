import React, { FC, ChangeEvent } from 'react';
import { UseFormRegister } from 'react-hook-form';

import Input from 'components/UI/Input';
import { ReactComponent as SearchMagnifyingGlass } from 'assets/svgs/search-filled.svg';
import classes from './AssignToClients.module.css';
import ModalButtonGroup from 'components/ModalButtonGroup';

interface Props {
  setShowAssignModal: (show: boolean) => void;
  handleAssign: () => void;
  onSearchClients: (event: ChangeEvent<HTMLInputElement>) => void;
  clientList: React.ReactNode;
  register?: UseFormRegister<any>;
  loading?: boolean;
}

const AssignToClients: FC<Props> = ({
  setShowAssignModal,
  handleAssign,
  onSearchClients,
  clientList,
  register,
  loading = false,
}) => {
  return (
    <div className={classes.Container}>
      <div className={classes.TopSection}>
        <div className={classes.SearchClients}>
          <Input
            name="clientsSearch"
            type="text"
            register={register}
            onChange={onSearchClients}
            placeholder={'Search clients...'}
            iconLeft={<SearchMagnifyingGlass />}
          />
        </div>
      </div>

      <div className={classes.ClientList}>{clientList}</div>
      <ModalButtonGroup
        secondaryButtonText="Cancel"
        secondaryButtonIntent="secondary"
        onSecondaryClick={() => setShowAssignModal(false)}
        showCancelButton={true}
        onMainButtonClick={handleAssign}
        mainButtonText="Assign"
        mainButtonIntent="primary"
        mainButtonType="button"
        loading={loading}
      />
    </div>
  );
};

export default AssignToClients;
