import React, { FC, useState, useEffect } from 'react';
import classes from './DisabledChat.module.css';
import { ChatSettings as ChatSettingsType } from 'interfaces/db';
import EmptyState from 'components/EmptyState';
import ToggleSettings from '../ToggleSettings';

interface DisabledChatProps {
  chatSettings: ChatSettingsType;
  saveSettings: (chatSettings: ChatSettingsType) => void;
}

const DisabledChat: FC<DisabledChatProps> = ({ chatSettings, saveSettings }) => {
  const { isCommunityChatEnabled, isDirectChatEnabled } = chatSettings;

  const [enabledCommunity, setEnabledCommunity] = useState(isCommunityChatEnabled);
  const [enabledDirectMessages, setEnabledDirectMessages] = useState(isDirectChatEnabled);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    // Check if chat settings have been changed
    setHasChanges(enabledCommunity !== isCommunityChatEnabled || enabledDirectMessages !== isDirectChatEnabled);
  }, [enabledCommunity, enabledDirectMessages, isCommunityChatEnabled, isDirectChatEnabled]);

  const handleUndoChanges = (): void => {
    setEnabledCommunity(isCommunityChatEnabled);
    setEnabledDirectMessages(isDirectChatEnabled);
    setHasChanges(false);
  };

  const handleSaveSettings = (): void => {
    if (hasChanges) {
      saveSettings({
        isCommunityChatEnabled: enabledCommunity,
        isDirectChatEnabled: enabledDirectMessages,
      });
      setHasChanges(false);
    }
  };

  const toggleCommunity = () => {
    setEnabledCommunity((enabledCommunity) => !enabledCommunity);
  };

  const toggleDirectMessages = () => {
    setEnabledDirectMessages((enabledDirectMessages) => !enabledDirectMessages);
  };

  return (
    <div className={classes.Container}>
      <EmptyState
        title="You do not have any chat features enabled."
        description="Toggle which chat features you would like your clients to have access to below."
        primaryButton={{
          text: 'Save',
          onClick: handleSaveSettings,
          disabled: !hasChanges,
        }}
        secondaryButton={{
          text: 'Undo Changes',
          onClick: handleUndoChanges,
          disabled: !hasChanges,
        }}
      >
        <ToggleSettings
          enabledCommunity={enabledCommunity}
          toggleCommunity={toggleCommunity}
          enabledDirectMessages={enabledDirectMessages}
          toggleDirectMessages={toggleDirectMessages}
        />
      </EmptyState>
    </div>
  );
};

export default DisabledChat;
