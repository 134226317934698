import React, { FC, useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect, ConnectedProps } from 'react-redux';
import { UseFormRegister, useForm } from 'react-hook-form';
import { UploadTaskSnapshot, UploadTask } from '@firebase/storage-types';
import { storage } from 'utils/firebase';
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router';

import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import { UploadDocument, AddCategoryForm, Button } from 'components';
import TabNav from 'components/UI/TabNav/TabNav';
import { DOCUMENTS } from 'utils/routes';
import { CoachProfile, Profile, Documents as DocumentsType } from 'interfaces/db';
import { getInitials, getToday, isEligibleCoach } from 'utils/helpers';
import { saveClientProfile } from 'store/actions/clients';
import { saveCategoriesInCoachProfile, saveCoachProfile } from 'store/actions/auth';
import Layout from '../Layout';
import classes from './Documents.module.css';
import { fetchDocuments, addDocument, updateDocuments, deleteDocuments } from 'store/actions/documents';
import Input from 'components/UI/Input';
import Dialog from 'components/UI/Dialog';
import CategoriesList from 'components/CategoriesList';
import EmptyState from 'components/EmptyState';
import { ReactComponent as SearchMagnifyingGlass } from 'assets/svgs/search-filled.svg';
import { ReactComponent as UploadIcon } from 'assets/svgs/upload-filled.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-filled.svg';
import { documentTrackingService } from 'utils/tracking/documentService';
import { AlertDialog, DropdownMenu, Spinner, Table } from 'components/UI';
import KebabMenu from 'components/KebabMenu';
import { useDropdownUnique } from 'hooks';
import { ReactComponent as AssignIcon } from 'assets/svgs/assign-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';
import AssignToClients from 'components/AssignToClients';
import ClientAssignCard from 'components/ClientAssignCard';
import { assignDocuments, deleteDocumentsAndFiles } from './helpers';
import { FirebaseObject } from 'interfaces/utils';
import SelectCheckbox from 'components/UI/Table/SelectCheckbox';

type SearchValue = {
  documentsSearch: string;
  clientsSearch: string;
};

const mapStateToProps = ({ auth, clients, documents }: RootState) => {
  const { userId, token, coachProfile, loading } = auth;
  return {
    token,
    userId,
    loadingCoach: loading,
    loading: documents.loading,
    coachProfile,
    clients,
    documents: documents.documents,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchDocuments: (token: string, userId: string) => dispatch(fetchDocuments(token, userId)),
    onSaveCoachProfile: (coachProfile: CoachProfile, coachProfileKey: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey)),
    onSaveCategoriesInCoachProfile: (coachProfile: CoachProfile['categories'], coachKey: string) =>
      dispatch(saveCategoriesInCoachProfile(coachProfile, coachKey)),
    onSaveClientProfile: async (clientProfile: Profile, clientKey: string) =>
      await dispatch(saveClientProfile(clientProfile, clientKey)),
    onDeleteDocuments: async (
      urls: string[],
      token: string,
      userId: string,
      documents: FirebaseObject<DocumentsType> | null,
      documentIds: string[],
    ) => await dispatch(deleteDocuments(urls, token, userId, documents, documentIds)),
    onAddDocument: (token: string, document: DocumentsType, userId?: string) =>
      dispatch(addDocument(token, document, userId)),
    onUpdateDocuments: async (token: string, documents: { [key: string]: DocumentsType }) =>
      await dispatch(updateDocuments(token, documents)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Documents: FC<PropsFromRedux> = ({
  token,
  userId,
  clients,
  documents,
  loading,
  coachKey,
  coachProfile,
  onFetchDocuments,
  onDeleteDocuments,
  onSaveCoachProfile,
  onSaveCategoriesInCoachProfile,
  onSaveClientProfile,
  onAddDocument,
  onUpdateDocuments,
  loadingCoach,
}) => {
  const tableRef = useRef<any>();
  const [progress, setProgress] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditTabsModalOpen, setEditTabsModalOpen] = useState(false);
  const [isCategoriesModalOpen, setCategoriesModalOpen] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState<any>([]);
  const [searchDocumentsValue, setSearchDocumentsValue] = useState<string | null>(null);
  const [uploadDocumentLoading, setUploadDocumentLoading] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState({});
  const { pathname } = useLocation();
  const { toggleDropdown, isOpen } = useDropdownUnique();
  const [clientsToAssign, setClientsToAssign] = useState<string[]>([]);
  const [assignmentModal, showAssignmentModal] = useState(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [clientsFiltered, setClientsFiltered] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [assignLoading, setAssignLoading] = useState(false);
  const [selectedDocumentKey, setSelectedDocumentKey] = useState<string | null>(null);
  const [deletingDocuments, setDeletingDocuments] = useState(false);

  const defaultCategories = Object.values(
    coachProfile?.[coachKey]?.categories ||
      ['Meal Plans', 'Ebooks'].concat(isEligibleCoach(userId) ? ['Subscription App'] : []),
  );

  const [categories, setCategories] = useState(defaultCategories);
  const [selectedTab, setSelectedTab] = useState(categories[0]);
  const [categoriesEdit, setCategoriesEdit] = useState<any>(categories.reduce((o, key) => ({ ...o, [key]: key }), {}));

  const { register } = useForm<SearchValue>();

  const createDocumentsFromMealPlans = (
    mealPlans: {
      downloadURL: string;
      fileName: string;
      title: string;
    }[],
  ) => {
    if (userId && token) {
      mealPlans.forEach((mealPlan) => {
        const newDocument: DocumentsType = {
          documentId: 'string',
          downloadURL: mealPlan.downloadURL,
          fileName: mealPlan.fileName,
          title: mealPlan.title,
          userId: userId,
          fileSize: 0,
          uploadedAt: getToday(),
          category: 'Meal plans',
        };
        onAddDocument(token, newDocument);
      });
    }
  };

  useEffect(() => {
    // ? This can be removed at some stage, it was used for migrating away from meal plans to documents
    if (coachKey && coachProfile && coachProfile[coachKey].mealPlans && token && userId) {
      const mealPlans = coachProfile[coachKey].mealPlans;
      if (mealPlans && mealPlans?.length > 0) {
        let newCoachProfile = Object.assign({}, coachProfile?.[coachKey]);
        newCoachProfile = {
          ...newCoachProfile,
          categories: defaultCategories,
          mealPlans: [],
        };

        onSaveCoachProfile(newCoachProfile, coachKey);

        createDocumentsFromMealPlans(mealPlans || []);
      }
    }
  }, [coachProfile, coachKey]);

  useEffect(() => {
    const categoriesNew = JSON.parse(JSON.stringify(defaultCategories));
    if (categoriesNew.indexOf('Subscription App') === -1 && isEligibleCoach(userId)) {
      categoriesNew.push('Subscription App');
    }
    setCategories(categoriesNew);
    setCategoriesEdit(categoriesNew.reduce((o: any, key: any) => ({ ...o, [key]: key }), {}));
    setSelectedTab(categoriesNew[0]);
  }, [coachProfile?.[coachKey]?.categories]);

  useEffect(() => {
    if (token && userId) {
      onFetchDocuments(token, userId);
    }
  }, [token, userId, onFetchDocuments]);

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  const uploadError: (a: Error) => any = (error) => {
    setUploadDocumentLoading(false);
    console.log(error);
  };

  const uploadSuccess = ({
    uploadTask,
    title,
    fileName,
    fileSize,
    category,
  }: {
    uploadTask: UploadTask;
    title: string;
    fileName: string;
    fileSize: number;
    category: string;
  }) => {
    uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
      if (coachProfile && coachProfile[coachKey] && token && documents && userId) {
        const { totalDocumentsSize = 0, documentCount = 0, recentDocuments = [] } = coachProfile[coachKey];

        const newCoachProfile = {
          ...coachProfile[coachKey],
          totalDocumentsSize: totalDocumentsSize + fileSize,
          documentCount: documentCount + 1,
          recentDocuments: recentDocuments,
        };

        const newDocument = {
          documentId: uuidv4(),
          clientList: [],
          downloadURL: downloadURL,
          fileName: fileName,
          title: title,
          userId: userId,
          fileSize: fileSize,
          uploadedAt: getToday(),
          category: category,
        };

        await onAddDocument(token, newDocument, userId);

        documentTrackingService.trackDocumentEvent('upload_document', {
          location: 'documents',
          document_title: title,
          document_category: category,
          document_id: newDocument.documentId,
          object: 'button',
          action: 'click',
          button: {
            text: 'UPLOAD DOCUMENT',
            action: 'upload_document',
            description: 'Uploading new document to the documents section',
          },
        });

        onSaveCoachProfile(newCoachProfile, coachKey);
        setUploadDocumentLoading(false);
        setModalOpen(false);
        setProgress(0);
      }
    });
  };

  const handleSubmit = async ({ title, file, category }: { title: string; file: File; category: string }) => {
    setUploadDocumentLoading(true);
    const { name, size } = file;
    const storageRefString =
      category !== 'Subscription App'
        ? `${userId}/documents/${uuidv4()}`
        : `${userId}/documents/subscription-app/${uuidv4()}`;
    const storageRef = storage.ref(storageRefString);
    const uploadTask: any = storageRef.put(file);

    uploadTask.on('state_changed', uploadProgress, uploadError, () =>
      uploadSuccess({
        uploadTask,
        title,
        fileName: name,
        fileSize: size,
        category,
      }),
    );
  };

  const getSelectedDocumentKeys = () => {
    const documentRowsSelected = tableRef.current?.getSelectedRowModel()?.flatRows;
    const programKeys = documentRowsSelected?.map((row) => row.original.documentKey);
    return programKeys;
  };

  const documentsForCategory =
    documents &&
    Object.fromEntries(
      Object.entries(documents || {})?.filter(
        ([_, value]) => value?.category?.toLowerCase() === selectedTab?.toLowerCase(),
      ),
    );

  const addCategory = async (category: string) => {
    if (coachProfile && coachKey && token) {
      const coachCategories: string[] = coachProfile[coachKey].categories
        ? Array.from(new Set([...coachProfile[coachKey].categories, ...categories]))
        : categories;

      const updatedCoachProfile = {
        ...coachProfile[coachKey],
        categories: [...coachCategories, category],
      };
      await onSaveCoachProfile(updatedCoachProfile, coachKey);

      documentTrackingService.trackDocumentEvent('add_category', {
        location: 'documents',
        category: category,
        object: 'button',
        action: 'click',
        button: {
          text: 'ADD CATEGORY',
          action: 'add_docuemnt_category',
          description: 'Adding a new category to the documents section',
        },
      });
      setCategoriesModalOpen(false);
    }
  };

  const removeCategory = async (key: string) => {
    if (coachProfile && coachKey && token) {
      const coachProfileCopy = { ...coachProfile?.[coachKey] };

      const categories = coachProfileCopy?.categories?.filter((cat) => cat.toLowerCase() !== key.toLowerCase()) || [];

      if (categories && coachKey) {
        await onSaveCategoriesInCoachProfile(categories, coachKey);
      }
    }
  };

  const onSaveCategoriesHandler = (updateCategories: { [key: string]: string }) => {
    const saveCoachProfile = new Promise((resolve, reject) => {
      if (coachProfile && coachKey && token) {
        const categories = Object.values(updateCategories);
        onSaveCategoriesInCoachProfile(categories, coachKey).then(() => {
          resolve(null);
        });
      } else {
        reject(new Error("One of the following don't exist: coach-profile && coach-key && token"));
      }
    });

    const saveDocuments = new Promise((resolve, reject) => {
      if (token && documents && updateCategories) {
        const documentsToUpdate: { [docKey: string]: DocumentsType } = {};

        for (const docKey in documents) {
          if (Object.prototype.hasOwnProperty.call(documents, docKey)) {
            const doc = documents[docKey];
            const docCategory = doc.category?.toLowerCase();
            for (const catKey in updateCategories) {
              if (docCategory === catKey.toLowerCase() && docCategory !== updateCategories[catKey].toLowerCase()) {
                documentsToUpdate[docKey] = {
                  ...doc,
                  category: updateCategories[catKey],
                };
                break;
              }
            }
          }
        }

        const documentUpdateKeys = Object.keys(documentsToUpdate);
        if (documentUpdateKeys.length > 0) {
          onUpdateDocuments(token, documentsToUpdate)
            .then(() => {
              resolve(null);
            })
            .catch(reject);
        } else {
          resolve(null);
        }
      } else {
        reject(new Error("One of the following don't exist: token && documents && categories"));
      }
    });

    Promise.all([saveCoachProfile, saveDocuments])
      .then(() => {
        setEditTabsModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearchDocuments = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setSearchDocumentsValue(value);

    const documentsFilteredBySearch = Object.entries(documentsForCategory || {})?.filter(([_, document]) => {
      return document?.title?.toLowerCase()?.includes(value);
    });

    setFilteredDocuments(documentsFilteredBySearch);
  };

  const handleOpenDropdown = (id: string) => {
    toggleDropdown(id);
  };

  const handleOpenDeleteModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, documentKey: string) => {
    e.stopPropagation();
    setDeleteDocumentModal(true);
    setSelectedDocumentKey(documentKey);
  };

  const handleOpenAssignmentModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, documentKey: string) => {
    e.stopPropagation();
    showAssignmentModal(true);
    setSelectedDocumentKey(documentKey);
  };

  const menuItems = (documentKey: string) => [
    {
      title: 'Assign',
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleOpenAssignmentModal(e, documentKey),
      iconLeft: <AssignIcon />,
    },
    {
      title: 'Delete',
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleOpenDeleteModal(e, documentKey),
      iconLeft: <DeleteIcon />,
    },
  ];

  const tableColumns = useMemo<ColumnDef<Record<string, unknown>>[]>(
    () => [
      SelectCheckbox(),
      {
        header: 'Title',
        cell: ({ getValue }) => getValue(),
        accessorKey: 'title',
      },
      {
        header: 'Document Name',
        cell: (row) => row.renderValue(),
        accessorKey: 'fileName',
      },
      {
        header: 'Date Uploaded',
        cell: (row) => row.renderValue(),
        accessorKey: 'uploadedAt',
      },
      {
        header: '',
        accessorKey: 'actions',
        cell: ({ row }) => {
          return (
            <DropdownMenu
              menuItems={menuItems(row.original.documentKey as string)}
              open={isOpen(row.id)}
              onOpenChange={() => handleOpenDropdown(row.id)}
              trigger={
                <KebabMenu
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(row.id);
                  }}
                />
              }
              width="12.5rem"
            />
          );
        },
      },
    ],
    [isOpen],
  );

  const documentsList = () => {
    if (!documents) {
      return null;
    }

    let documentsNew = Object.entries(documentsForCategory || {});
    if (searchDocumentsValue) documentsNew = filteredDocuments;

    const documentData = documentsNew.map(([documentKey, documentData]) => ({
      title: documentData.title,
      fileName: documentData.fileName,
      uploadedAt: documentData.uploadedAt,
      documentKey: documentKey,
      downloadURL: documentData.downloadURL,
    }));

    return (
      <Table
        ref={tableRef}
        data={documentData}
        columns={tableColumns}
        loading={false}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        bulkActions={
          <div className={classes.BulkActions}>
            <div>
              <Button
                type="button"
                intent="secondary"
                onClick={() => {
                  setSelectedDocumentKey(null);
                  showAssignmentModal(true);
                }}
                iconLeft={<AssignIcon />}
              >
                Assign
              </Button>
            </div>
            <div>
              <Button
                type="button"
                intent="secondary"
                onClick={() => {
                  setSelectedDocumentKey(null);
                  setDeleteDocumentModal(true);
                }}
                iconLeft={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          </div>
        }
        onRowClick={(row) => {
          const downloadURL = row.original.downloadURL;
          if (downloadURL) {
            window.open(downloadURL, '_blank');
          }
        }}
      />
    );
  };

  const onSetSelectedTab = (tab: string) => {
    setSelectedTab(tab);
    setSearchValue(null);
  };

  const uploadDocument = (
    <Dialog
      title="Upload document"
      trigger={
        <div>
          <Button type="button" intent="primary" onClick={() => setModalOpen(true)} iconLeft={<UploadIcon />}>
            Upload Document
          </Button>
        </div>
      }
      open={isModalOpen}
      onOpenChange={setModalOpen}
    >
      <UploadDocument
        progress={progress}
        selectedTab={selectedTab}
        handleSubmit={handleSubmit}
        categories={categories}
        setModalOpen={setModalOpen}
        loading={uploadDocumentLoading}
      />
    </Dialog>
  );

  let clientList;

  if (clients && clients.clients) {
    let clientsNew = Object.entries(clients.clients);
    if (searchValue) {
      clientsNew = clientsFiltered;
    }

    clientList =
      clients.loading && !assignLoading ? (
        <Spinner />
      ) : (
        clientsNew.map(([clientKey, client]) => {
          const clientName = client.profile.fullname || '';

          return (
            <ClientAssignCard
              key={clientKey}
              name={clientName}
              initials={getInitials(clientName)}
              assigned={clientsToAssign.includes(clientKey)}
              setAssignedClient={() => setAssignedClient(clientKey)}
            />
          );
        })
      );
  }

  const handleAssignment = async () => {
    if (!token || !userId) return;
    const selectedDocumentKeys = !selectedDocumentKey ? getSelectedDocumentKeys() : [selectedDocumentKey];

    await assignDocuments(
      token,
      clients?.clients,
      clientsToAssign,
      setAssignLoading,
      onSaveClientProfile,
      onUpdateDocuments,
      documentTrackingService,
      pathname,
      selectedDocumentKeys,
      documents,
    );
    setClientsToAssign([]);
    setRowSelection({});
    showAssignmentModal(false);
  };

  const setAssignedClient = (clientKey: string) => {
    if (clientsToAssign.includes(clientKey)) {
      const newClientsToAssign = clientsToAssign.filter((el) => el !== clientKey);
      setClientsToAssign(newClientsToAssign);
      return;
    }

    setClientsToAssign([...clientsToAssign, clientKey]);
  };

  const handleDeleteDocuments = async () => {
    if (!token || !userId) return;

    setDeletingDocuments(true);

    const selectedDocumentKeys = !selectedDocumentKey ? getSelectedDocumentKeys() : [selectedDocumentKey];

    await deleteDocumentsAndFiles({
      documentKeys: selectedDocumentKeys,
      token,
      userId,
      documents,
      onDeleteDocuments,
    });

    setDeletingDocuments(false);
    setRowSelection({});
    setDeleteDocumentModal(false);
  };

  const onSearchClients = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setSearchValue(value);

    const clientsFiltered = Object.entries(clients.clients || {})?.filter(([_, client]) => {
      return client?.profile?.fullname?.toLowerCase()?.includes(value);
    });

    setClientsFiltered(clientsFiltered);
  };

  if (loading) {
    return (
      <Layout loading={loading} heading={DOCUMENTS.TITLE}>
        <div className={classes.Clients}>
          <Spinner />
        </div>
      </Layout>
    );
  }

  return (
    <Layout loading={loading} heading={DOCUMENTS.TITLE}>
      <div className={classes.Container}>
        <TabNav
          setSelectedTab={onSetSelectedTab}
          selectedTab={selectedTab}
          tabHeadings={categories}
          setCategoriesModalOpen={setCategoriesModalOpen}
        />

        <Dialog
          title="Edit categories"
          trigger={
            <div className={classes.ButtonWrapper}>
              <Button type="button" intent="primary" onClick={() => setEditTabsModalOpen(true)} iconLeft={<EditIcon />}>
                EDIT CATEGORIES
              </Button>
            </div>
          }
          open={isEditTabsModalOpen}
          onOpenChange={setEditTabsModalOpen}
        >
          <CategoriesList
            categoriesEdit={categoriesEdit}
            documents={documents}
            userId={userId || ''}
            removeCategory={removeCategory}
            isEligibleCoach={isEligibleCoach}
            onSaveCategoriesHandler={onSaveCategoriesHandler}
            loading={loading}
            loadingCoach={loadingCoach}
            setModalOpen={setEditTabsModalOpen}
          />
        </Dialog>
        {Object.keys(documentsForCategory || {}).length === 0 ? (
          <div className={classes.NoDocuments}>
            <EmptyState title="No documents" description="You don’t have any documents in this category.">
              {uploadDocument}
            </EmptyState>
          </div>
        ) : (
          <>
            <div className={classes.TopSection}>
              <div className={classes.SearchDocuments}>
                <form>
                  <Input
                    name="documentsSearch"
                    type="text"
                    placeholder="Search documents..."
                    register={register}
                    onChange={onSearchDocuments}
                    iconLeft={<SearchMagnifyingGlass />}
                  />
                </form>
              </div>

              {uploadDocument}
            </div>

            <div className={classes.FilesWrapper}>{documentsList()}</div>
          </>
        )}
      </div>

      <Dialog title="Add category" open={isCategoriesModalOpen} onOpenChange={setCategoriesModalOpen}>
        <AddCategoryForm
          handleSubmit={addCategory}
          existingCategories={categories?.map((category) => category?.toLowerCase())}
          loading={loading || loadingCoach}
          closeModal={setCategoriesModalOpen}
        />
      </Dialog>

      <AlertDialog
        title="Delete document"
        description="Are you sure you want to remove this document? This action cannot be undone."
        open={deleteDocumentModal}
        onOpenChange={setDeleteDocumentModal}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setDeleteDocumentModal(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={handleDeleteDocuments} loading={deletingDocuments}>
            Delete
          </Button>
        }
      />

      <Dialog title="Assign a document" open={assignmentModal} onOpenChange={showAssignmentModal}>
        <AssignToClients
          setShowAssignModal={showAssignmentModal}
          handleAssign={handleAssignment}
          onSearchClients={onSearchClients}
          clientList={clientList}
          register={register as UseFormRegister<SearchValue>}
          loading={assignLoading}
        />
      </Dialog>
    </Layout>
  );
};

export default connector(withAuth(Documents));
