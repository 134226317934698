import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object, string, ref } from 'yup';

import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import classes from './PostPayment.module.css';
import { useYupValidationResolver } from 'hooks';

interface FormValues {
  password: string;
  confirmPassword: string;
}

const validationSchema = object().shape({
  password: string()
    .matches(/.{6,}$/, {
      message: 'Min 6 characters required',
      excludeEmptyString: false,
    })
    .required(),
  confirmPassword: string()
    .oneOf([ref('password')], 'Password does not match')
    .required('confirm password'),
});

const PostPaymentForm: FC<{
  handleSubmit: (payload: FormValues) => Promise<void>;
  loading: boolean;
}> = ({ handleSubmit, loading }) => {
  const {
    register,
    handleSubmit: handleRHFSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: useYupValidationResolver(validationSchema),
  });

  const onSubmit = async (data: FormValues) => {
    await handleSubmit(data);
  };

  return (
    <form onSubmit={handleRHFSubmit(onSubmit)} className={classes.wrapper}>
      <div className={classes.inputWrapper}>
        <Input
          label="Password"
          name="password"
          type="password"
          placeholder="Enter password"
          register={register}
          error={errors.password}
        />
      </div>
      <Input
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        placeholder="Please confirm password"
        register={register}
        error={errors.confirmPassword}
      />
      <div className={classes.btnWrapper}>
        <Button type="submit" loading={loading} disabled={isSubmitting}>
          CREATE PASSWORD
        </Button>
      </div>
    </form>
  );
};

export default PostPaymentForm;
