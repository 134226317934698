import React, { FC } from 'react';
import Sidebar from 'components/Navigation/Sidebar';
import Header from 'components/UI/Header';
import classes from './Layout.module.css';
import { useLocation } from 'react-router-dom';
import ClientProfileLayout from './ClientProfile';

type Props = {
  heading: string;
  loading: boolean;
};

const Layout: FC<Props> = ({ heading, loading, children }) => {
  const location = useLocation();
  const isClientProfile = location.pathname.includes('/clients/');

  return isClientProfile ? (
    <ClientProfileLayout heading={heading} loading={loading}>
      {children}
    </ClientProfileLayout>
  ) : (
    <div className={classes.Container}>
      <div className={classes.LHSWrapper}>
        <Sidebar viewType={isClientProfile ? 'client' : 'standard'} />
      </div>

      <div className={classes.RHSWrapper}>
        <div className={classes.InnerRhsWrapper}>
          <Header heading={heading} loading={loading} viewType={isClientProfile ? 'client' : 'standard'} />

          <main>
            <div>{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
