import React, { FC, useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';

import { RootState } from 'store';
import Layout from '../Layout';
import withAuth from 'utils/withAuth';
import { Exercise, Set } from 'interfaces/db';
import { formValues } from 'utils/helpers';
import { Button, ExerciseLibForm } from 'components';
import { ExerciseLibInitialValues } from 'interfaces/utils';
import { EXERCISE_LIBRARY } from 'utils/routes';
import { saveExercise, deleteExercises, fetchExercises, updateExercise } from 'store/actions/exerciseLibrary';
import { Spinner, Input, Dialog, AlertDialog, DropdownMenu, Table } from 'components/UI';
import classes from './ExerciseLibrary.module.css';
import { ReactComponent as SearchMagnifyingGlass } from 'assets/svgs/search-filled.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
// import ExerciseCard from 'components/ExerciseCard';
import EmptyState from 'components/EmptyState';
import SelectCheckbox from 'components/UI/Table/SelectCheckbox';
import { ColumnDef } from '@tanstack/react-table';
import { getFormattedExerciseParameters } from './helpers';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';
import { useDropdownUnique } from 'hooks';

import KebabMenu from 'components/KebabMenu';

type SearchValue = {
  exercisesSearch: string;
};

const mapStateToProps = ({ auth, exerciseLibrary }: RootState) => {
  const { token, coachProfile } = auth;
  const { exercises, loading } = exerciseLibrary;

  return {
    token,
    loading,
    exercises,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchExercise: (token: string, coachKey: string) => dispatch(fetchExercises(token, coachKey)),

    onSaveExercise: (
      token: string,
      coachKey: string,
      exercise: Exercise,
      location: 'exercise_library' | 'workout_builder',
    ) => dispatch(saveExercise(token, coachKey, exercise, location)),

    onUpdateExercise: async (token: string, coachKey: string, exerciseKey: string, exercise: Exercise) =>
      await dispatch(updateExercise(token, coachKey, exerciseKey, exercise)),

    onDeleteExercises: (token: string, coachKey: string, exerciseKeys: string[]) =>
      dispatch(deleteExercises(token, coachKey, exerciseKeys)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ExerciseLibrary: FC<PropsFromRedux> = ({
  token,
  loading,
  coachKey,
  exercises,
  onSaveExercise,
  onFetchExercise,
  onUpdateExercise,
  onDeleteExercises,
}) => {
  const tableRef = useRef<any>();
  const { toggleDropdown, isOpen } = useDropdownUnique();
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState<ExerciseLibInitialValues>(formValues);
  const [selectedExercise, setSelectedExercise] = useState('');
  const [deleteModal, showDeleteModal] = useState(false);
  const [exerciseModal, showExerciseModal] = useState(false);
  const [fetched, setFetched] = useState<boolean>(false);
  const [exercisesFiltered, setExercisesFiltered] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [rowSelection, setRowSelection] = useState({});
  const [isEditExercise, setIsEditExercise] = useState<boolean>(false);

  const { register } = useForm<SearchValue>();
  const [deletingExercises, setDeletingExercises] = useState(false);
  const [editingExercise, setEditingExercise] = useState(false);

  useEffect(() => {
    (async () => {
      if (token && coachKey) {
        setFetched(false);
        await onFetchExercise(token, coachKey);
        setFetched(true);
      }
    })();

    return () => {
      setFetched(false);
    };
  }, [token, coachKey, onFetchExercise]);

  const isEdit = !!initialValues.type;

  const handleSubmit = async (val: Exercise) => {
    if (token && coachKey) {
      if (isEdit && selectedKey) {
        setEditingExercise(true);
        await onUpdateExercise(token, coachKey, selectedKey, val);
        showExerciseModal(false);
        setEditingExercise(false);
        return;
      }

      onSaveExercise(token, coachKey, val, 'exercise_library');
      showExerciseModal(false);
    }
  };

  const getSelectedExerciseKeys = () => {
    const exerciseRowsSelected = tableRef.current?.getSelectedRowModel()?.flatRows;
    return exerciseRowsSelected?.map((row) => row.original.exerciseKey);
  };

  const handleRemoveExercises = async () => {
    setDeletingExercises(true);

    const selectedExercisedKeys = !selectedKey ? getSelectedExerciseKeys() : [selectedKey];

    if (token && coachKey) {
      await onDeleteExercises(token, coachKey, selectedExercisedKeys);
    }

    showDeleteModal(false);
    setDeletingExercises(false);
    setRowSelection({});
  };

  const handleAddExercise = (val: ExerciseLibInitialValues, isEdit: boolean = false) => {
    setInitialValues(val);
    showExerciseModal(true);
    setIsEditExercise(isEdit);
  };

  const onSearchExercises = ({ target }: ChangeEvent<HTMLInputElement>) => {
    let { value } = target;
    value = value.toLowerCase();
    setSearchValue(value);

    const exercisesFiltered = Object.entries(exercises || {})?.filter((exercise) => {
      return exercise[1].type.toLowerCase()?.includes(value);
    });
    setExercisesFiltered(exercisesFiltered);
  };

  const handleOpenDropdown = (id: string) => {
    toggleDropdown(id);
  };

  const handleOpenEditModal = (exerciseKey: string) => {
    const exercise = exercises ? exercises[exerciseKey] : null;
    const sets = exercise?.sets;
    if (sets) {
      const { completed, ...rest } = sets[0];
      const setsArr = (Object.keys(rest) as Array<keyof typeof rest>).sort((elA, elB) => {
        const valA: any = rest[elA];
        const valB: any = rest[elB];

        return valA.index - valB.index;
      });

      setSelectedKey(exerciseKey);
      setSelectedExercise(exercise.type);
      handleAddExercise({
        type: exercise.type,
        videoURL: exercise.videoURL,
        sets: setsArr,
        exerciseNotes: exercise.exerciseNotes,
      });
    }
  };

  const handleOpenDeleteModalFromMenuItem = (exerciseKey: string) => {
    const exercise = exercises ? exercises[exerciseKey] : null;
    setSelectedKey(exerciseKey);
    setSelectedExercise(exercise?.type || '');
    showDeleteModal(true);
  };

  const menuItems = (exerciseKey: string) => [
    {
      title: 'Edit',
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        handleOpenEditModal(exerciseKey);
      },
      iconLeft: <EditIcon />,
    },
    {
      title: 'Delete',
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        handleOpenDeleteModalFromMenuItem(exerciseKey);
      },
      iconLeft: <DeleteIcon />,
    },
  ];

  const tableColumns = useMemo<ColumnDef<Record<string, unknown>>[]>(
    () => [
      SelectCheckbox(),
      {
        header: 'Exercise Name',
        cell: ({ getValue }) => getValue(),
        accessorKey: 'type',
      },
      {
        header: 'Video',
        accessorKey: 'video',
        cell: ({ row }) => {
          return row.original.videoUrl ? (
            <p className={classes.ClientActive}>Instructional video linked</p>
          ) : (
            <button
              type="button"
              className={classes.LinkVideoButton}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenEditModal(row.original.exerciseKey as string);
              }}
            >
              Link video
            </button>
          );
        },
      },
      {
        header: 'Parameters',
        cell: ({ getValue }) => getValue(),
        accessorKey: 'parameters',
      },
      {
        header: '',
        accessorKey: 'actions',
        cell: ({ row }) => {
          return (
            <DropdownMenu
              menuItems={menuItems(row.original.exerciseKey as string)}
              open={isOpen(row.id)}
              onOpenChange={() => handleOpenDropdown(row.id)}
              trigger={
                <KebabMenu
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(row.id);
                  }}
                />
              }
              width="12.5rem"
            />
          );
        },
      },
    ],
    [isOpen],
  );

  const exerciseList = () => {
    if (!exercises) {
      return null;
    }
    let exercisesNew = Object.entries(exercises);
    if (searchValue) {
      exercisesNew = exercisesFiltered;
    }

    const exerciseData = exercisesNew.map(([exerciseKey, exerciseData]) => ({
      type: exerciseData.type,
      videoUrl: exerciseData.videoURL,
      parameters: getFormattedExerciseParameters(exerciseData.sets as Set[]),
      exerciseKey: exerciseKey,
    }));

    return (
      <Table
        ref={tableRef}
        data={exerciseData}
        columns={tableColumns}
        loading={false}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        bulkActions={
          <div className={classes.BulkActions}>
            <div>
              <Button
                type="button"
                intent="secondary"
                onClick={() => {
                  showDeleteModal(true);
                  setSelectedKey(null);
                }}
                iconLeft={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          </div>
        }
        onRowClick={(row) => {
          const exerciseKey = row.original.exerciseKey;
          handleOpenEditModal(exerciseKey as string);
        }}
      />
    );
  };

  const addExercise = (
    <Dialog
      title={!isEditExercise ? 'Create exercise' : 'Edit exercise'}
      trigger={
        <div>
          <Button type="button" onClick={() => handleAddExercise(formValues)} iconLeft={<PlusIcon />}>
            Create Exercise
          </Button>
        </div>
      }
      open={exerciseModal}
      onOpenChange={showExerciseModal}
    >
      <ExerciseLibForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        setModalOpen={showExerciseModal}
        loading={editingExercise}
      />
    </Dialog>
  );

  if (loading && !deletingExercises && !editingExercise) {
    return (
      <Layout loading={loading} heading={EXERCISE_LIBRARY.TITLE}>
        <div className={classes.SpinnerWrapper}>
          <Spinner />
        </div>
      </Layout>
    );
  }

  const deleteDescription = selectedKey
    ? `Do you want to delete ${selectedExercise}? This cannot be undone.`
    : 'Do you want to delete these exercises? This cannot be undone.';

  return (
    <Layout loading={loading} heading={EXERCISE_LIBRARY.TITLE}>
      {Object.entries(exercises || {}).length === 0 && !loading && fetched ? (
        <div className={classes.NoExercises}>
          <EmptyState title="No exercises found" description="There are currently no exercises logged.">
            {addExercise}
          </EmptyState>
        </div>
      ) : (
        fetched && (
          <>
            <div className={classes.ToolBar}>
              <div className={classes.SearchExerciseLibrary}>
                <form>
                  <Input
                    name="exercisesSearch"
                    type="text"
                    placeholder="Search exercises..."
                    register={register}
                    onChange={onSearchExercises}
                    iconLeft={<SearchMagnifyingGlass />}
                  />
                </form>
              </div>

              {addExercise}
            </div>
            <div className={classes.Container}>{exerciseList()}</div>
          </>
        )
      )}
      <AlertDialog
        title="Delete exercise"
        description={deleteDescription}
        open={deleteModal}
        onOpenChange={showDeleteModal}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => showDeleteModal(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={handleRemoveExercises} loading={deletingExercises}>
            Delete
          </Button>
        }
      />
    </Layout>
  );
};

export default connector(withAuth(ExerciseLibrary));
