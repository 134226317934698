import React, { FC, useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { removeClients, saveClientProfile } from 'store/actions/clients';
import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import Layout from '../../Layout';
import { CLIENTS } from 'utils/routes';
import { Profile } from 'interfaces/db';
import { ClientProfileParams } from 'interfaces/routes';
import classes from './ClientProfile.module.css';
import AlertDialog from 'components/UI/AlertDialog';
import Button from 'components/UI/Button';
import NameBadge from 'components/NameBadge';
import ModalButtonGroup from 'components/ModalButtonGroup';
import { useForm } from 'react-hook-form';
import { Input } from 'components';
import { getInitials } from 'utils/helpers';

type formValues = {
  fullname: string;
  email: string;
};

const mapStateToProps = ({ auth, clients }: RootState) => {
  const { clients: clientsList, loading, removed } = clients;
  return {
    token: auth.token,
    clients: clientsList,
    loading: loading,
    removed: removed,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveClientProfile: (clientProfile: Profile, clientKey: string) =>
      dispatch(saveClientProfile(clientProfile, clientKey)),
    onRemoveClient: (clientKeys: string[]) => {
      dispatch(removeClients(clientKeys));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps<ClientProfileParams>;

const ClientProfile: FC<Props> = ({ token, match, clients, loading, history, onRemoveClient, onSaveClientProfile }) => {
  const { clientKey } = match.params;

  const [showSave, setShowSave] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<formValues>();

  const clientProfile = watch();

  useEffect(() => {
    if (clients && clientKey) {
      const { email, fullname } = clients[clientKey].profile;
      setValue('email', email);
      setValue('fullname', fullname);
    }
  }, [clients, clientKey, setValue]);

  const saveProfile = () => {
    if (clientKey && token) {
      onSaveClientProfile(clientProfile, clientKey);
      setShowSave(false);
    }
  };

  const onSubmit = (data) => {
    if (clientKey && token) {
      onSaveClientProfile(data, clientKey);
      setShowSave(false);
    }
  };

  const removeProfile = () => {
    if (clientKey && token) {
      onRemoveClient([clientKey]);
      setShowRemove(false);
      history.push(CLIENTS.URL);
    }
  };

  return (
    <Layout loading={loading} heading={clientProfile.fullname || ''}>
      <div>
        <div className={classes.ClientProfile}>
          <div className={classes.ImageDiv}>
            <NameBadge initials={getInitials(clientProfile.fullname || '')} />
          </div>

          <div className={classes.ClientName}>
            <p>{clientProfile.fullname}</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.TopInformation}>
              <div className={classes.InputBlock}>
                <Input
                  name="fullname"
                  type="text"
                  required
                  register={register}
                  placeholder="Enter full name"
                  label="Full name"
                  defaultValue={clientProfile.fullname}
                  error={errors.fullname}
                />
              </div>

              <div className={classes.InputBlock}>
                <Input
                  name="email"
                  type="text"
                  required={false}
                  register={register}
                  placeholder="Enter email address"
                  label="Email address"
                  defaultValue={clientProfile.email}
                  error={errors.email}
                  readOnly
                />
              </div>
            </div>
            <ModalButtonGroup
              mainButtonText="Save"
              mainButtonType="button"
              onMainButtonClick={() => setShowSave(true)}
              secondaryButtonText="Remove"
              onSecondaryClick={() => setShowRemove(true)}
            />
          </form>
        </div>
      </div>

      <AlertDialog
        title="Save client profile"
        description={`Do you want to save ${clientProfile.fullname}'s profile? This will save any changes that were made.`}
        open={showSave}
        onOpenChange={setShowSave}
        cancelButton={
          <Button intent="secondary" onClick={() => setShowSave(false)}>
            Cancel
          </Button>
        }
        actionButton={<Button onClick={saveProfile}>Save</Button>}
      />

      <AlertDialog
        title="Save client profile"
        description={`Do you want to remove ${clientProfile.fullname}'s profile? This cannot be undone.`}
        open={showRemove}
        onOpenChange={setShowRemove}
        cancelButton={
          <Button intent="secondary" onClick={() => setShowRemove(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button intent="danger" onClick={removeProfile}>
            Remove
          </Button>
        }
      />
    </Layout>
  );
};

export default connector(withAuth(ClientProfile));
