import React, { FC, ReactNode } from 'react';
import classes from './EmptyState.module.css';
import ModalButtonGroup from 'components/ModalButtonGroup';

type Props = {
  title: string;
  description: string | ReactNode;
  primaryButton?: {
    text: string;
    onClick: (params?: unknown) => void;
    disabled?: boolean;
  };
  secondaryButton?: {
    text: string;
    onClick: (params?: unknown) => void;
    disabled?: boolean;
  };
  loading?: boolean;
  children?: ReactNode;
};

const EmptyState: FC<Props> = ({ title, description, primaryButton, secondaryButton, loading, children }) => {
  const primaryText = primaryButton?.text;
  const primaryOnClick = primaryButton?.onClick;
  const primaryDisabled = primaryButton?.disabled;

  const secondaryText = secondaryButton?.text;
  const secondaryOnClick = secondaryButton?.onClick;
  const secondaryDisabled = secondaryButton?.disabled;

  return (
    <div className={classes.Container}>
      <div className={classes.InnerContainer}>
        <span className={classes.Title}>{title}</span>
        {typeof description === 'string' ? (
          <p className={classes.Description}>{description}</p>
        ) : (
          <div className={classes.Description}>{description}</div>
        )}

        {children && <div className={classes.Content}>{children}</div>}

        {(primaryButton || secondaryButton) && (
          <ModalButtonGroup
            mainButtonText={primaryText}
            mainButtonDisabled={primaryDisabled}
            onMainButtonClick={primaryOnClick}
            secondaryButtonText={secondaryText}
            onSecondaryClick={secondaryOnClick}
            secondaryButtonDisabled={secondaryDisabled}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default EmptyState;
