import { DefaultThemes } from 'enums/themeEnum';
import { ThemesActionTypes } from 'interfaces/actions/settings';
import { SettingsState } from 'interfaces/state';
import { Theme } from 'interfaces/settings';
import { SET_THEME } from 'store/actions/actionTypes';
import { updateObject } from 'store/utility';
import themes from 'themes';

const initialState: SettingsState = {
  // TODO set a type for the theme object so that it's strict on the keys and values
  theme: {},
  preference: DefaultThemes.Light,
};

const setTheme = (state: SettingsState, action: { themePreference: Theme }): SettingsState => {
  return updateObject(state, {
    preference: action.themePreference,
    theme: themes?.[action.themePreference] || themes[DefaultThemes.Light],
  });
};

const reducer = (state = initialState, action: ThemesActionTypes) => {
  switch (action.type) {
    case SET_THEME:
      return setTheme(state, action);
    default:
      return state;
  }
};

export default reducer;
