import { useState, useEffect } from 'react';
import { DocumentData, QueryDocumentSnapshot } from '@firebase/firestore-types';
import { firestore } from 'utils/firebase';

const useExerciseData = (exerciseType, userId) => {
  const [pastInstances, setPastInstances] = useState<QueryDocumentSnapshot<DocumentData>[]>();
  const [PRs, setPRs] = useState<QueryDocumentSnapshot<DocumentData>[]>();
  const [calculated, setCalculated] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    if (!exerciseType || !userId) {
      setCalculated(true);
      return;
    }

    const clientDoc = firestore.collection('clients').doc(userId);
    const exerciseTypeDoc = clientDoc.collection('pastExercises').doc(exerciseType);

    // Fetch past instances of the exercise and PRs
    Promise.all([
      exerciseTypeDoc.collection('instances').limit(30).orderBy('date', 'desc').get(),
      exerciseTypeDoc.collection('prs').get(),
    ])
      .then(([instancesQuery, prsQuery]) => {
        if (isMounted) {
          setPastInstances(instancesQuery.docs);
          setPRs(prsQuery.docs);
          setCalculated(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });

    return () => {
      isMounted = false;
    };
  }, [exerciseType, userId]);

  return { pastInstances, PRs, calculated };
};

export default useExerciseData;
