import React, { FC, useRef } from 'react';
import Textarea from 'react-textarea-autosize';
import classes from './TextArea.module.css';
import { TextAreaProps } from 'interfaces/ui';

import clsx from 'clsx';

const TextArea: FC<TextAreaProps> = ({
  register,
  onChange,
  name,
  label,
  error,
  isLabelInside = false,
  required = false,
  placeholder,
  defaultValue,
  readOnly = false,
  leftLabelIcon,
  rightLabelIcon,
}) => {
  const textareaFieldSetRef = useRef<HTMLFieldSetElement>(null);

  const handleFocusEvents = (
    event: React.MouseEvent<HTMLTextAreaElement> | React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (readOnly) {
      event.preventDefault();
    }
  };

  let inputAttributes = {
    className: classes.TextArea,
    placeholder,
    readOnly,
    onMouseDown: handleFocusEvents,
    onKeyDown: handleFocusEvents,
  };

  if (register) {
    inputAttributes = {
      ...inputAttributes,
      ...register(name, { required, onChange, value: defaultValue }),
    };
  }

  const errorMessage = typeof error === 'string' ? error : error?.message || 'This field is required';

  if (textareaFieldSetRef.current) {
    textareaFieldSetRef.current.addEventListener('click', (event: Event) => {
      const target = event.currentTarget as HTMLElement;
      const textarea = target.querySelector('textarea');

      if (textarea && !textarea.readOnly) {
        textarea.focus();
      }
    });
  }

  return (
    <fieldset className={clsx(classes.TextAreaContainer, isLabelInside && classes.WrapLabel)} ref={textareaFieldSetRef}>
      {isLabelInside && (
        <div className={classes.LabelContainer}>
          <div className={classes.IconWrapper}>{leftLabelIcon}</div>
          <label htmlFor={name} className={classes.InternalLabel}>
            {label}
          </label>
          <div className={clsx(classes.IconWrapper, classes.SVGFill)}>{rightLabelIcon}</div>
        </div>
      )}
      {label && !isLabelInside && (
        <label htmlFor={name} className={classes.Label}>
          {label}
        </label>
      )}
      <Textarea {...inputAttributes} />
      {error && <span className={classes.Error}>{errorMessage}</span>}
    </fieldset>
  );
};

export default TextArea;
