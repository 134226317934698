import { AnalyticsProgramData } from 'interfaces/api';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import classes from './ProgramPerformanceList.module.css';
import Spinner from 'components/UI/Spinner';
import Select from 'components/UI/Select';

// import positiveGrowthSymbol from 'assets/icons/positiveGrowthSymbol.svg';
// import negativeGrowthSymbol from 'assets/icons/negativeGrowthSymbol.svg';
// import flatlineGrowthSymbol from 'assets/icons/flatlineGrowthSymbol.svg';

interface Props {
  loading: boolean;
  programAnalyticsData: AnalyticsProgramData[];
}

type DropdownOption = 'allTimeSubscribers' | 'activeSubscribers';

const ProgramPerformanceList: FC<Props> = ({ programAnalyticsData, loading }) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption>('allTimeSubscribers');
  const [loadingMessage, setLoadingMessage] = useState<string>('Loading Program Analytics...');
  const [sortedProgramAnalyticsData, setSortedProgramAnalyticsData] = useState<AnalyticsProgramData[]>([]);
  const [openSelect, setOpenSelect] = useState<string | null>(null);
  const { control } = useForm<any>();

  const handleSelectChange = (value: DropdownOption) => {
    setSelectedOption(value);
  };

  const loader = (
    <div className={classes.Loading}>
      <Spinner />
      {loadingMessage}
    </div>
  );

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      count === 0 && setLoadingMessage('Still Loading...');
      count === 2 && setLoadingMessage('Calculating analytics for all programs...');
      count++;
    }, 7000);

    if (loading === false || count === 1) {
      clearInterval(interval);
    }
  }, [loading]);

  const handleSelectOpenChange = (name: string, isOpen: boolean) => {
    if (isOpen) {
      setOpenSelect(name);
    } else if (openSelect === name) {
      setOpenSelect(null);
    }
  };

  useEffect(() => {
    const sortedData = programAnalyticsData.sort((a, b) => {
      return b[selectedOption] - a[selectedOption];
    });
    setSortedProgramAnalyticsData(sortedData);
  }, [selectedOption, programAnalyticsData]);

  return (
    <div className={classes.Wrapper}>
      <div className={classes.TopLayer}>
        <p className={classes.Title}>Top Performers</p>
        <div>
          <Select
            name={'filter'}
            placeholder="Performance Filter"
            control={control}
            onOpenChange={(isOpen) => handleSelectOpenChange('filter', isOpen)}
            open={openSelect === 'filter'}
            optionArray={[
              { label: 'All Time Subscribers', value: 'allTimeSubscribers' },
              { label: 'Active Subscribers', value: 'activeSubscribers' },
            ]}
            required
            onChange={(value) => handleSelectChange(value as DropdownOption)}
            defaultValue={'allTimeSubscribers'}
          />
        </div>
      </div>

      {loading ? (
        loader
      ) : (
        <div className={classes.ScrollableContainer}>
          <table className={classes.TableWrapper}>
            <thead className={classes.TableHeader}>
              <tr>
                <th className={classes.ColumnHeader}>Pos</th>
                <th className={classes.ColumnHeader}>Program</th>
                <th className={classes.ColumnHeader}>Subscribers</th>
              </tr>
            </thead>
            <tbody>
              {sortedProgramAnalyticsData.map((program, index) => (
                <tr key={program.programName} className={classes.TabeRow}>
                  <td className={classes.Item}>{index + 1}</td>
                  {/* <td className={classes.Item}>
                </td> */}
                  <td className={classes.Item}>
                    <div className={classes.ProgramWrapper}>
                      <img
                        className={classes.Thumbnail}
                        src={program.image || program.programImage}
                        alt={program.programName}
                      />
                      {program.programName}
                    </div>
                  </td>
                  <td className={classes.Item}>{program[selectedOption]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProgramPerformanceList;

// <div className={classes.ListWrapper}>
// {programAnalyticsData?.map((program, index) => (
//   <div className={classes.ListItem} key={index}>
//     <div className={classes.OverviewWrapper}>
//       <div className={classes.Position}>{index + 1 + '.'}</div>
//       <div className={classes.Thumbnail}>{program.image}</div>
//       <div className={classes.ProgramTitle}>{program.programName}</div>
//     </div>
//     <div className={classes.GrowthWrapper}>
//       <div className={classes.GrowthCount}>
//         {/* {program.growth > 0 ? <img
//         src={positiveGrowthSymbol}
//         alt="positiveGrowthSymbol"
//         className={classes.GrowthIcon}
//       /> : program.growth == 0 ? <img
//         src={flatlineGrowthSymbol}
//         alt="flatlineGrowthSymbol"
//         className={classes.GrowthIcon}
//       /> : <img
//             src={negativeGrowthSymbol}
//             alt="negativeGrowthSymbol"
//             className={classes.GrowthIcon}
//           />} */}
//         {/* {(program.growth + "").replace(/[0-]/g, '')} */}
//       </div>
//       <div className={classes.SubCount}>{program.totalSubscribers}</div>
//     </div>
//   </div>
// ))}
// </div>
