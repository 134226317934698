import { NutritionGoals } from 'interfaces/nutrition';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

export const onChangeVarianceHandler = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<NutritionGoals>,
) => {
  const value = +e.target.value;
  if (value > 50) {
    setValue('variance', 50);
  } else if (value < 0) {
    setValue('variance', value * -1);
  } else {
    setValue('variance', value);
  }
};

export const onChangeMicrosHandler = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<FieldValues>,
  goalFieldName: string,
) => {
  const micro = +e.target.value;

  if (micro > 1000) {
    setValue(goalFieldName, 1000);
  } else if (micro < 0) {
    setValue(goalFieldName, micro * -1);
  } else {
    setValue(goalFieldName, micro);
  }
};
