import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { useYupValidationResolver } from 'hooks';
import { FormProps } from 'interfaces/utils';
import Input from 'components/UI/Input';
import ModalButtonGroup from 'components/ModalButtonGroup';

type FormData = {
  category: string;
};

const AddCategory: FC<
  FormProps<string> & {
    existingCategories: string[];
    loading: boolean;
    closeModal: (value: boolean) => void;
  }
> = ({ existingCategories, handleSubmit, loading, closeModal }) => {
  const schema = object({
    category: string()
      .min(4, 'Min number of characters is 4.')
      .test('unique-category-validation', 'Sorry this category is already available.', (value) => {
        return !existingCategories.includes(value?.toLowerCase() || '');
      })
      .required('Category is required.'),
  });

  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: useYupValidationResolver(schema),
  });

  return (
    <form onSubmit={handleFormSubmit((data) => handleSubmit(data.category?.trim()))}>
      <Input name="category" register={register} placeholder="Category" error={errors.category} />

      <ModalButtonGroup
        loading={loading}
        mainButtonType="submit"
        mainButtonText="Save Category"
        secondaryButtonText="Cancel"
        onSecondaryClick={() => closeModal(false)}
      />
    </form>
  );
};

export default AddCategory;
