import React, { FC } from 'react';
import SectionHeader from 'components/SectionHeader';
import classes from './Features.module.css';
import SFClasses from '../SubscriptionFunnel.module.css';
import AccessImage from 'assets/images/features_access.png';
import AccessImageSm from 'assets/images/features_access_sm.png';
import GuidanceImage from 'assets/images/features_guidance.png';
import GuidanceImageSm from 'assets/images/features_guidance_sm.png';
import InstructionsImage from 'assets/images/features_instructions.png';
import InstructionsImageSm from 'assets/images/features_instructions_sm.png';

import { useMediaQuery } from 'utils/hooks';
interface Props {
  organisation: boolean;
  isTrialEnabled: boolean;
}

const Features: FC<Props> = ({ organisation, isTrialEnabled }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <section className={SFClasses.Section} id="features">
      <div className={classes.sectionGrid}>
        <div className={classes.gridCol2}>
          <SectionHeader
            featureText
            paragraph="Comprehensive analytics that help you stay on top of your progress across the board – including the ability to monitor your strength progression and track your bodyweight!"
          >
            access to {organisation ? 'our' : 'my'} <span>athletes app</span>
          </SectionHeader>
        </div>
        <img className={classes.gridCol1} src={isMobile ? AccessImageSm : AccessImage} alt="app access" />
      </div>

      {!isTrialEnabled && (
        <div className={classes.sectionGrid}>
          <div className={classes.gridCol1}>
            <SectionHeader
              featureText
              paragraph={`Get videos from ${
                organisation ? 'us' : 'me'
              } showing you exactly how to perform each exercise, making sure you always use the correct technique.`}
            >
              <span>video guidance</span> on exercises
            </SectionHeader>
          </div>
          <img className={classes.gridCol2} src={isMobile ? GuidanceImageSm : GuidanceImage} alt="guidance" />
        </div>
      )}
      <div>
        <div className={classes.sectionGrid}>
          <div className={classes.gridCol2}>
            <SectionHeader
              featureText
              paragraph={`${
                organisation ? 'We' : 'I'
              } will provide detailed instructions on each exercise telling you exactly how to perform each exercise to maximise your gains.`}
            >
              <span>detailed instructions</span> from {organisation ? 'us' : 'me'}
            </SectionHeader>
          </div>

          <img
            className={classes.gridCol1}
            src={isMobile ? InstructionsImageSm : InstructionsImage}
            alt="instructions"
          />
        </div>
      </div>
    </section>
  );
};

export default Features;
