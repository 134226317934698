export enum SubscriptionIntervals {
  MONTHLY = 'month',
  YEARLY = 'year',
  WEEKLY = 'week',
  DAILY = 'day',
}

export enum ProductDuration {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  YEAR = 12,
}

export enum Currencies {
  USD = 'USD',
  ZAR = 'ZAR',
  GBP = 'GBP',
}
export interface Plan {
  planName: string;
  planDescription: string;
  price: number;
  currency: Currencies;
  paymentCycle: SubscriptionIntervals;
  productDuration?: ProductDuration;
  intervalCount: number;
  identifier: string;
}

export interface PlanPayload extends Plan {
  accountId: string;
  identifier: string;
  coachName: string;
}

export interface Product {
  name: string;
  description: string;
  active?: boolean;
  currency: Currencies;
  price: string;
  coachAccountId: string;
  created: number;
  coachUserId: string;
  recurring: {
    interval: SubscriptionIntervals;
    interval_count: number;
  };
  priceUnitAmount: number;
  productId: string;
  priceId: string;
  funnelIdentifier: string;
  encodeData: string; // base64 encoded string used for checkout link query param
}

export interface Branding {
  icon: File | null;
  logo: File | null;
  primaryColor: string | null;
  secondaryColor: string | null;
}

export interface AccountBranding {
  icon: string;
  iconFileURL: string;
  iconStripeLink: string;
  logo: string;
  logoFileURL: string;
  logoStripeLink: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface TableProduct {
  id: string;
  plan: string;
  created: number;
  description: string;
  amount: string;
  paymentCycle: string;
  actions?: boolean;
  priceId: string;
  coachAccountId: string;
  coachUserId: string;
  funnelIdentifier: string;
  encodeData: string;
}
