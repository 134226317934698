import React, { FC, useCallback } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import SetComp from '../Set';
import classes from './ExerciseTable.module.css';
import { setKeysMap, MAX_SETS_LENGTH, emptySetObject } from 'utils/helpers';
import { Set, SetKeys, Exercise } from 'interfaces/db';
import { DEFAULT_SET } from 'utils/constants';
import { Button, Select } from 'components/UI';
import { useOpenSelect } from 'hooks';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { ReactComponent as MinusIcon } from 'assets/svgs/remove-outline.svg';

interface Props {
  finished: boolean;
  exercise: Exercise;
  addSetHandler: () => void;
  removeSetHandler: () => void;
  setsChangeHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  setTypeChangeHandler: (setType: SetKeys, setIndex: number) => void;
  removeTypeChangeHandler: (setKey: SetKeys) => void;
  addTypeChangehandler: (setType: SetKeys) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  achievedTextColor: string;
  programmedTextColor: string;
}

const newSet: Set = DEFAULT_SET;

const Table: FC<Props> = ({
  finished,
  exercise,
  addSetHandler,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  setTypeChangeHandler,
  addTypeChangehandler,
  removeTypeChangeHandler,
  setShowAutoFill,
  showAutoFill,
  achievedTextColor,
  programmedTextColor,
}) => {
  const { control } = useForm<any>();
  const { openSelect, handleSelectOpenChange } = useOpenSelect();

  const { sets = [] } = exercise;
  const numSets = sets.length;

  const { completed, ...otherSets } = sets[0] || newSet;

  const sortedSetKeys = (Object.keys(otherSets) as Array<keyof typeof otherSets>).map((el) => {
    const setValue = otherSets?.[el];

    if (typeof setValue === 'string') {
      return { [el]: { ...emptySetObject } };
    }

    return { [el]: setValue };
  });

  const otherSetsKeys = sortedSetKeys.map((el) => {
    return Object.keys(el)[0];
  });

  const keysLength = otherSetsKeys.length;
  const titleClass = finished ? classes.ColumnHeaderEqual : classes.ColumnHeader;

  const options = useCallback(() => {
    return setKeysMap.filter((el) => !otherSetsKeys.includes(el.value));
  }, [otherSetsKeys]);

  return (
    <>
      <div className={classes.Table}>
        <ul>
          {!!numSets && (
            <li className={classes.TableHeadingRow}>
              <span className={classes.ColumnHeader}>Sets</span>

              {otherSetsKeys.map((el, index) => {
                const selected = setKeysMap.find((key) => key.value === el);

                if (finished) {
                  return (
                    <div key={selected?.label} className={classes.ColumnHeader}>
                      {selected?.label}
                    </div>
                  );
                }

                if (selected) {
                  return (
                    <div key={selected.value} className={titleClass}>
                      <Select
                        name={selected.value}
                        placeholder="Type"
                        control={control}
                        onOpenChange={(isOpen) => handleSelectOpenChange(selected.value, isOpen)}
                        open={openSelect === selected.value}
                        optionArray={[selected, ...options(), { label: '-Remove-', value: 'remove_option' }]}
                        required
                        onChange={(value: any) => {
                          if (value && value === 'remove_option') {
                            removeTypeChangeHandler(selected.value);
                          } else {
                            value ? setTypeChangeHandler(value, index) : removeTypeChangeHandler(selected.value);
                          }
                        }}
                        defaultValue={selected.value}
                      />
                    </div>
                  );
                }

                return undefined;
              })}

              {!finished && keysLength !== MAX_SETS_LENGTH && (
                <div className={clsx(titleClass, classes.btnCentralize)}>
                  <button
                    type="button"
                    className={classes.addSetBtn}
                    onClick={() => {
                      const [newSet] = options();
                      addTypeChangehandler(newSet.value);
                    }}
                  >
                    <svg
                      className={classes.addSetIcon}
                      viewBox="0 -2.384 29.25 29.25"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g data-name="Icon ionic-md-add-circle-outline">
                        <path
                          data-name="Path 10586"
                          d="M22.148 16.102h-6.046v6.047h-2.954v-6.047H7.102v-2.954h6.047V7.102h2.953v6.047h6.047z"
                        />
                        <path
                          data-name="Path 10587"
                          d="M14.625 2.953A11.667 11.667 0 116.37 6.37a11.623 11.623 0 018.255-3.417m0-2.953A14.625 14.625 0 1029.25 14.625 14.623 14.623 0 0014.625 0z"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              )}
            </li>
          )}

          {sets.map((set, index) => (
            <SetComp
              set={set}
              key={index}
              setNum={index}
              finished={finished}
              ticked={set.completed}
              setsChangeHandler={(setType, value) => setsChangeHandler(setType, value, index)}
              autoFillSetsHandler={(setType, value) => autoFillSetsHandler(setType, value, index)}
              setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
              showAutoFill={showAutoFill}
              achievedTextColor={achievedTextColor}
              programmedTextColor={programmedTextColor}
            />
          ))}
        </ul>
      </div>
      <div className={classes.FlexContainer}>
        {!finished && (
          <div className={classes.AddButtonWrapper}>
            <Button
              type="button"
              intent="secondary"
              onClick={addSetHandler}
              iconLeft={
                <div className={classes.SetActionButton}>
                  <PlusIcon />
                </div>
              }
            >
              ADD SET
            </Button>
          </div>
        )}
        {numSets > 0 && !finished && (
          <div>
            <Button
              type="button"
              intent="secondary"
              onClick={removeSetHandler}
              iconLeft={
                <div className={classes.SetActionButton}>
                  <MinusIcon />
                </div>
              }
            >
              DELETE SET
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
