import { NutritionDay, NutritionGoals } from 'interfaces/nutrition';

export const mapDayToForm = (dayData: NutritionDay, weekId: string): NutritionGoals => {
  const formObject: NutritionGoals = {
    calories: null,
    variance: null,
    macrosType: 'percentage',
    macros: {
      protein: {
        percentage: null,
        grams: null,
        selected: true,
        unit: 'g',
        calories: 0,
      },
      carbs: {
        percentage: null,
        grams: null,
        selected: true,
        unit: 'g',
        calories: 0,
      },
      fat: {
        percentage: null,
        grams: null,
        selected: true,
        unit: 'g',
        calories: 0,
      },
    },
    micros: {
      fibre: false,
      sugar: false,
      saturatedFats: false,
      polyunsaturatedFats: false,
      transFat: false,
      sodium: false,
    },
    schedule: {
      days: new Array(7).fill(false),
      startWeek: weekId,
      numberOfWeeks: '1',
    },
    notes: '',
    title: dayData?.title || 'Nutrition Day Title',
  };

  if (dayData.macrosType) {
    formObject.macrosType = dayData.macrosType || 'percentage';
  }

  if (dayData.variance) {
    formObject.variance = dayData.variance || 0;
  }

  if (dayData.notes) {
    formObject.notes = dayData.notes.coach || '';
  }

  if (dayData.calories) {
    formObject.calories = dayData.calories?.goal || 0;
  }

  if (dayData.macros) {
    for (const macro in dayData.macros) {
      if (formObject.macros[macro]) {
        formObject.macros[macro].percentage = dayData.macros[macro].goalPercentage;
        formObject.macros[macro].grams = dayData.macros[macro].goal;
        formObject.macros[macro].calories = dayData.macros[macro].calories;
        formObject.macros[macro].selected = dayData.macros[macro].selected;
      }
    }
  }

  if (dayData.micros) {
    for (const micro in dayData.micros) {
      if (Object.prototype.hasOwnProperty.call(formObject.micros, micro)) {
        formObject.micros[micro] = !!dayData.micros[micro];
      }
    }
  }

  return formObject;
};
