import { SelectOptionProps } from 'interfaces/ui';

export const numberOfWeeks: SelectOptionProps[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
];

export const microsArr = [
  { title: 'Fibre (g)', name: 'micros.fibre' },
  { title: 'Sugar (g)', name: 'micros.sugar' },
  { title: 'Saturated Fats (g)', name: 'micros.saturatedFats' },
  { title: 'Polyunsaturated Fats (g)', name: 'micros.polyunsaturatedFats' },
  { title: 'Trans Fat (g)', name: 'micros.transFat' },
  { title: 'Sodium (mg)', name: 'micros.sodium' },
];

export const macrosArr = [
  { name: 'protein', label: 'Protein', placeholder: '0%', color: '#5964DA' },
  { name: 'carbs', label: 'Carbs', placeholder: '0%', color: '#7782F8' },
  { name: 'fat', label: 'Fat', placeholder: '0%', color: '#C1C5F4' },
];

export const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

export const calculateGramsFromPercentage = (
  percentage: number,
  macroType: 'protein' | 'carbs' | 'fat',
  totalCalories: number,
): number => {
  const macroCalories = (percentage / 100) * totalCalories;
  let caloriesPerGram: number;

  switch (macroType) {
    case 'protein':
    case 'carbs':
      caloriesPerGram = 4;
      break;
    case 'fat':
      caloriesPerGram = 9;
      break;
    default:
      return 0;
  }

  return Math.round(macroCalories / caloriesPerGram);
};

export const calculateCaloriesFromPercentage = (totalCalories: number, percentage: number): number => {
  return Math.round((totalCalories * percentage) / 100);
};

export const calculateCaloriesFromGrams = (grams: number, macroType: 'protein' | 'carbs' | 'fat'): number => {
  let caloriesPerGram: number;

  switch (macroType) {
    case 'protein':
    case 'carbs':
      caloriesPerGram = 4;
      break;
    case 'fat':
      caloriesPerGram = 9;
      break;
    default:
      return 0;
  }

  return Math.round(grams * caloriesPerGram);
};

export const calculatePercentageFromGrams = (
  grams: number | null,
  macroType: 'protein' | 'carbs' | 'fat',
  totalCalories: number,
): number => {
  let caloriesPerGram: number;

  switch (macroType) {
    case 'protein':
    case 'carbs':
      caloriesPerGram = 4;
      break;
    case 'fat':
      caloriesPerGram = 9;
      break;
    default:
      return 0;
  }
  return Math.round((((grams || 0) * caloriesPerGram) / totalCalories) * 100);
};

export const calculateGramsFromCalories = (calories: number, macroType: 'protein' | 'carbs' | 'fat'): number => {
  let caloriesPerGram: number;

  switch (macroType) {
    case 'protein':
    case 'carbs':
      caloriesPerGram = 4;
      break;
    case 'fat':
      caloriesPerGram = 9;
      break;
    default:
      return 0;
  }

  return Math.round(calories / caloriesPerGram);
};

export const calculateTotalCalories = (
  proteinCalories: number | string | null,
  carbsCalories: number | string | null,
  fatCalories: number | string | null,
): number => {
  return Number(proteinCalories) + Number(carbsCalories) + Number(fatCalories);
};

export const calculatePercentageFromCalories = (calories: number | null, totalCalories: number | null): number => {
  return Math.round(((calories || 0) / (totalCalories || 1)) * 100);
};

export const capitalizeAndSplit = (sentence: string) => {
  let inBrackets = 0;

  return sentence
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Split connected words
    .replace(/(\w)(\w*)/g, (match, firstChar, rest, offset) => {
      // Update bracket count
      if (match === '(') inBrackets++;
      if (match === ')') inBrackets--;

      // Capitalize first letter if not in brackets
      return inBrackets > 0 || (offset > 0 && sentence[offset - 1] === '(')
        ? match
        : firstChar.toUpperCase() + rest.toLowerCase();
    });
};

export const calculateMacroPercentages = (calories: number[], totalCalories: number) => {
  const percentages = calories.map((c) => (c / totalCalories) * 100);
  const roundedPercentages = percentages.map((p) => Math.floor(p));
  const remainders = percentages.map((p, i) => p - roundedPercentages[i]);

  let remainderSum = 100 - roundedPercentages.reduce((a, b) => a + b, 0);

  while (remainderSum > 0) {
    const index = remainders.indexOf(Math.max(...remainders));
    roundedPercentages[index]++;
    remainders[index] = 0; // reset the remainder since we've accounted for it
    remainderSum--;
  }

  return roundedPercentages;
};
