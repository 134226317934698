import React, { FC, useEffect, useState } from 'react';
import { firestore } from 'utils/firebase';
import firebase from 'firebase/app';

import { NotificationData } from 'interfaces/db';
import classes from './NotificationCentre.module.css';
import Notification from './Notification';
import { BasicSmallSpinner } from 'components/UI';
import { NavLink } from 'react-router-dom';
import { CHAT } from 'utils/routes';

interface Props {
  coachId: string | null;
}

const NotificationCentre: FC<Props> = ({ coachId }) => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  // Can update these into hooks

  useEffect(() => {
    if (!coachId) return;

    const oneWeekAgoTimestamp = firebase.firestore.Timestamp.fromDate(oneWeekAgo);

    setLoading(true);
    const unsubscribe = firestore
      .collection('users')
      .doc(coachId)
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .where('createdAt', '>=', oneWeekAgoTimestamp)
      .onSnapshot((snapshot) => {
        const fetchedNotifications: NotificationData[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data() as NotificationData;
          fetchedNotifications.push({
            ...data,
            id: doc.id,
          });
        });
        setNotifications(fetchedNotifications);
        setLoading(false);
      });

    return () => {
      unsubscribe();
    };
  }, []);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const notificationsToday = notifications.filter((notification) => notification.createdAt.toDate() >= today);
  const notificationsThisWeek = notifications.filter(
    (notification) => notification.createdAt.toDate() < today && notification.createdAt.toDate() >= oneWeekAgo,
  );

  const markAsRead = (id: string) => {
    if (!coachId) return;
    firestore
      .collection('users')
      .doc(coachId)
      .collection('notifications')
      .doc(id)
      .update({
        isRead: true,
      })
      .catch((error) => console.error('Error updating notification: ', error));
  };

  return (
    <div className={classes.NotificationCentre}>
      <div className={classes.NotificationHeader}>
        <h5>Notifications</h5>
      </div>
      {loading ? (
        <div className={classes.Loading}>
          <BasicSmallSpinner />
        </div>
      ) : !notifications || (notifications.length === 0 && !loading) ? (
        <div className={classes.NotificationSection}>
          <p className={classes.NoNotifications}>There are no notifications to display</p>
          <span className={classes.Separator} />
        </div>
      ) : (
        notifications &&
        !loading &&
        notifications.length > 0 && (
          <div className={classes.NotificationList}>
            {notificationsToday && notificationsToday.length > 0 && (
              <div className={classes.NotificationSection}>
                <span className={classes.SectionHeading}>Today</span>
                <div className={classes.NotificationSubList}>
                  {notificationsToday.map((notification) => {
                    const { id, relatedId: relatedChannelId, type, description, users, isRead } = notification;
                    return (
                      <NavLink to={`${CHAT.URL}?channel=${relatedChannelId}`} onClick={() => markAsRead(id)} key={id}>
                        <Notification type={type} description={description} users={users} isRead={isRead} />
                      </NavLink>
                    );
                  })}
                </div>

                {notificationsThisWeek && notificationsThisWeek.length > 0 && <span className={classes.Separator} />}
              </div>
            )}

            {notificationsThisWeek && notificationsThisWeek.length > 0 && (
              <div className={classes.NotificationSection}>
                <span className={classes.SectionHeading}>This Week</span>
                <div className={classes.NotificationSubList}>
                  {notificationsThisWeek.map((notification) => {
                    const { id, relatedId: relatedChannelId, type, description, users, isRead } = notification;
                    return (
                      <NavLink to={`${CHAT.URL}?channel=${relatedChannelId}`} onClick={() => markAsRead(id)} key={id}>
                        <Notification type={type} description={description} users={users} isRead={isRead} />
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default NotificationCentre;
