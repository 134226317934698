import React, { FC, useRef, useState, ReactNode, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import Fuse from 'fuse.js';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

import { Exercise } from 'interfaces/db';
import { getInitials } from 'utils/helpers';
import classes from './Autocomplete.module.css';
import NameBadge from 'components/NameBadge';
import SmallLogo from 'assets/images/small-logo.png';

type Suggestions = Exercise & {
  author: 'coach' | 'admin';
};

const Autocomplete: FC<{
  fullname: string;
  footer: ReactNode;
  exerciseType: string;
  suggestions: Suggestions[];
  setExerciseFromLib: (exercise: Suggestions) => void;
  exerciseTypeOnChangeHandler: (value: string) => void;
}> = ({ footer, fullname, suggestions, exerciseType, setExerciseFromLib, exerciseTypeOnChangeHandler }) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const activeRef = useRef<HTMLLIElement>(null);

  const [showList, setShowList] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState<Suggestions[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    setUserInput(exerciseType);
  }, [exerciseType]);

  useEffect(() => {
    if (showSuggestions && userInput && filteredSuggestions.length) {
      setShowList(true);
    } else {
      setShowList(false);
    }
  }, [showSuggestions, userInput, filteredSuggestions]);

  // Event fired when the input value is changed
  const onChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    const { value } = currentTarget;
    exerciseTypeOnChangeHandler(value);

    const fuse = new Fuse(suggestions, {
      keys: ['type'],
      minMatchCharLength: 3,
    });

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = fuse.search(value).map((el) => el.item);

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    setActiveSuggestion(0);
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(value);
  };

  // Event fired when the user clicks on a suggestion
  const onClick = (index: number) => {
    const { type, sets, videoURL, author, exerciseNotes } = filteredSuggestions[index];

    // Update the user input and reset the rest of the state
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(type);

    // set sets
    if (sets?.length) {
      setExerciseFromLib({
        type,
        sets,
        videoURL,
        author,
        exerciseNotes: { coach: exerciseNotes?.coach || '', athlete: exerciseNotes?.athlete || '' },
      });
    }
  };

  // Event fired when the user presses a key down
  const onKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    const li = activeRef.current;
    const container = containerRef.current;
    const liHeight = li?.offsetHeight || 0;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (key === 'Enter') {
      const { type, sets, videoURL, author, exerciseNotes } = filteredSuggestions[activeSuggestion];
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(type);

      // set sets
      if (sets?.length) {
        setExerciseFromLib({
          type,
          sets,
          videoURL,
          author,
          exerciseNotes: { coach: exerciseNotes?.coach || '', athlete: exerciseNotes?.athlete || '' },
        });
      }
    }

    // User pressed the up arrow, decrement the index
    else if (key === 'ArrowUp') {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
      const { type, sets, videoURL, author, exerciseNotes } = filteredSuggestions[activeSuggestion - 1] || {};

      // set sets
      if (type && sets?.length) {
        setUserInput(type);
        setExerciseFromLib({
          type,
          sets,
          videoURL,
          author,
          exerciseNotes: { coach: exerciseNotes?.coach || '', athlete: exerciseNotes?.athlete || '' },
        });
      }

      if (li && container) {
        container.scrollTo({
          behavior: 'smooth',
          top: li.offsetTop - liHeight,
        });
      }
    }

    // User pressed the down arrow, increment the index
    else if (key === 'ArrowDown') {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
      const { type, sets, videoURL, author, exerciseNotes } = filteredSuggestions[activeSuggestion + 1] || {};

      // set sets
      if (type && sets?.length) {
        setUserInput(type);
        setExerciseFromLib({
          type,
          sets,
          videoURL,
          author,
          exerciseNotes: { coach: exerciseNotes?.coach || '', athlete: exerciseNotes?.athlete || '' },
        });
      }

      if (li && container) {
        container.scrollTo({
          behavior: 'smooth',
          top: li.offsetTop,
        });
      }
    }
  };

  return (
    <div>
      <input
        type="text"
        value={userInput}
        onChange={onChange}
        onKeyDown={onKeyDown}
        className={classes.ExerciseTypeInput}
      />
      {showList && (
        <OutsideClickHandler disabled={!showList} onOutsideClick={() => setShowList(false)}>
          <div className={classes.SuggestionsOuterContainer}>
            <ul ref={containerRef} className={classes.SuggestionsInnerContainer}>
              {filteredSuggestions.map((suggestion, index) => {
                const activeLI = index === activeSuggestion;
                return (
                  <li
                    key={index}
                    className={clsx(classes.Suggestion, activeLI && classes.SuggestionHighlighted)}
                    onClick={() => onClick(index)}
                    ref={activeLI ? activeRef : null}
                  >
                    {suggestion.author === 'admin' ? (
                      <div className={classes.AuthorImg}>
                        <img alt="admin created exercise" src={SmallLogo} />
                      </div>
                    ) : (
                      <div className={classes.AuthorImg}>
                        <NameBadge initials={getInitials(fullname)} size="xsmall" />
                      </div>
                    )}
                    {suggestion.type}
                  </li>
                );
              })}
            </ul>

            <div>{footer}</div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default Autocomplete;
