import { MixpanelService, EventProperties } from './mixpanelService';

export type ProgressEventProperties = EventProperties & {
  location: 'client_progress';
};

class ProgressTrackingService extends MixpanelService {
  public trackProgressEvent(eventName: string, properties?: ProgressEventProperties): void {
    super.trackEvent(eventName, properties);
  }
}

export const progressTrackingService = new ProgressTrackingService();
