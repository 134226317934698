import React, { FC, useEffect, useState, useRef } from 'react';
import {
  Formik,
  Form,
  FieldArray,
  Field,
  // ErrorMessage,
  // FormikHelpers,
} from 'formik';
// import { object, string } from 'yup';
import TextField from '../../inputs/TextField';
import classes from './FunnelSettings.module.css';
import { v4 as uuidv4 } from 'uuid';
import { Accordion, Button } from 'components';
import Textarea from 'react-textarea-autosize';
import Spinner from 'components/UI/Spinner';

type InitialValues = {
  coachOverview: {
    coachName: string;
    title: string;
    descriptionTitle: string;
    description: string;
  };
  planOverview: {
    identifier: string;
    price: number;
    // displayPrice: number;
    description: string;
    currency: string;
    organisation: boolean;
    isTrialEnabled: boolean;
  };
  testimonials: any[];
  faqs: any[];
  otherImages: {
    heroImageMobile: any;
    heroImageDesktop: any;
    getToKnowImage: any;
  };
};

const ImageUploadComponent: FC<{
  testimonial: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  imageName: string;
  label: string;
  index: number;
}> = ({ testimonial, setFieldValue, imageName, label, index }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    const x = hiddenFileInput.current?.click();
    console.log(x);
  };

  return (
    <div>
      <label className={classes.FileInputLabels}>{label}:</label>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {testimonial[imageName].downloadURL && (
          <div className={classes.ImageContainer}>
            <img src={testimonial[imageName].downloadURL} alt={imageName + 'alt'} />
          </div>
        )}
        <div>
          <label htmlFor="file" className={classes.fileInput}>
            <input
              type="file"
              ref={hiddenFileInput}
              id={`testimonials.${index}.${imageName}`}
              name={`testimonials.${index}.${imageName}`}
              onChange={(event) => {
                setFieldValue(`testimonials.${index}.${imageName}`, event.currentTarget.files?.[0]);
              }}
              className={classes.file}
            />
            <button onClick={handleClick} type="button">
              Select {label}
            </button>
          </label>
        </div>
      </div>
    </div>
  );
};

const UpdateProfile: FC<{
  handleSubmit: (payload: InitialValues) => Promise<void>;
  funnelValues: InitialValues;
  loading: boolean;
  coachValues: any;
}> = ({ handleSubmit, funnelValues, loading, coachValues }) => {
  // const validationSchema = object().shape({
  //   fullname: string()
  //     .test(
  //       'fullname-test',
  //       'Fill first and last name',
  //       (value = '') => {
  //         const [firstName, lastName] = value
  //           ? value.trim().split(' ')
  //           : [];
  //         return !!(firstName && lastName);
  //       },
  //     )
  //     .required('Full name is a required field'),
  //   birthday: string()
  //     .matches(
  //       /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/,
  //       {
  //         message: 'Must be in DD/MM/YYYY format',
  //         excludeEmptyString: false,
  //       },
  //     )
  //     .required(),
  //   email: string().email().required(),
  //   code: string().required(),
  // });

  const [faqs, setFaqs] = useState<any[]>([]);
  const [testimonials, setTestimonials] = useState<any[]>([]);

  const handleAdd = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    values: any,
    section: string,
  ) => {
    if (section === 'testimonials') {
      testimonialsFunc(setFieldValue);
    } else {
      faqsFunc(setFieldValue, values);
    }
  };

  const testimonialsFunc = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    // if (values.content.length > 0 && values.name.length > 0 && values.location.length > 0) {
    const newList = testimonials.concat({
      content: '',
      name: '',
      location: '',
      id: uuidv4(),
      beforeImage: '',
      afterImage: 'values.afterImage',
    });
    setTestimonials(newList);
    setFieldValue('testimonials', newList);
    // setFieldValue('content', '');
    // setFieldValue('name', '');
    // setFieldValue('location', '');
    // setFieldValue('beforeImage', '');
    // setFieldValue('afterImage', '');
    // }
  };

  const faqsFunc = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    values: any,
  ) => {
    if (values.question.length > 0 && values.answer.length > 0) {
      const newList = faqs.concat({
        question: values.question,
        answer: values.answer,
        id: uuidv4(),
      });
      setFaqs(newList);
      setFieldValue('faqs', newList);
      setFieldValue('question', '');
      setFieldValue('answer', '');
    }
  };

  const hiddenFileInputOtherImagesHeroDesktop = useRef<HTMLInputElement>(null);
  const hiddenFileInputOtherImagesHeroMobile = useRef<HTMLInputElement>(null);
  const hiddenFileInputOtherImagesGetToKnow = useRef<HTMLInputElement>(null);

  const handleClickOtherImagesHeroDesktop = () => {
    const x = hiddenFileInputOtherImagesHeroDesktop.current?.click();
    console.log(x);
  };

  const handleClickOtherImagesHeroMobile = () => {
    const x = hiddenFileInputOtherImagesHeroMobile.current?.click();
    console.log(x);
  };

  const handleClickOtherImagesGetToKnow = () => {
    const x = hiddenFileInputOtherImagesGetToKnow.current?.click();
    console.log(x);
  };

  useEffect(() => {
    if (funnelValues?.testimonials) {
      setTestimonials(funnelValues?.testimonials);
    }
  }, [funnelValues?.testimonials]);

  const initialValues = {
    coachOverview: {
      coachName: coachValues?.fullname,
      title: funnelValues.coachOverview?.title || '',
      descriptionTitle: funnelValues.coachOverview?.descriptionTitle || '',
      description: funnelValues.coachOverview?.description || '',
    },
    planOverview: {
      identifier: funnelValues.planOverview?.identifier || '',
      price: funnelValues.planOverview?.price || 0,
      // displayPrice: funnelValues.planOverview?.displayPrice || 0,
      description: funnelValues.planOverview?.description || '',
      currency: funnelValues.planOverview?.currency || 'ZAR',
      organisation: funnelValues.planOverview?.organisation || false,
      isTrialEnabled: funnelValues.planOverview?.isTrialEnabled || false,
    },
    testimonials: funnelValues?.testimonials || [],
    faqs: funnelValues?.faqs || [],
    otherImages: {
      heroImageDesktop: funnelValues.otherImages?.heroImageDesktop || '',
      heroImageMobile: funnelValues.otherImages?.heroImageMobile || '',
      getToKnowImage: funnelValues.otherImages?.getToKnowImage || '',
    },
    question: '',
    answer: '',
    content: '',
    name: '',
    location: '',
    beforeImage: '',
    afterImage: '',
  };

  const onSubmit = async ({ coachOverview, planOverview, testimonials, faqs, otherImages }: InitialValues) =>
    //  actions: <InitialValues>FormikHelpers,
    {
      await handleSubmit({
        coachOverview,
        planOverview,
        testimonials,
        faqs,
        otherImages,
      });
    };

  return loading ? (
    <Spinner />
  ) : (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      // validationSchema={validationSchema}
    >
      {({ errors, touched, isSubmitting, setFieldValue, values, handleChange }) => (
        <Form>
          <div>
            <div>
              <div className={classes.Title}>
                <h4>Coach Overview</h4>
              </div>
              <div className={classes.topInformation}>
                <div className={classes.row}>
                  <div className={classes.inputBlock}>
                    <label className={classes.InputLabels}>Professional title:</label>
                    <TextField
                      name="coachOverview.title"
                      errors={errors}
                      touched={touched}
                      placeholder="Professional title"
                    />
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.inputBlock}>
                    <label className={classes.InputLabels}>Description title:</label>
                    <TextField
                      name="coachOverview.descriptionTitle"
                      errors={errors}
                      touched={touched}
                      placeholder="Description title"
                    />
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.inputBlock}>
                    <label className={classes.InputLabels}>Description:</label>
                    <Textarea
                      name="coachOverview.description"
                      placeholder="Summary about you"
                      value={values.coachOverview.description}
                      onChange={(event) => {
                        setFieldValue('coachOverview.description', event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.Title}>
                <h4>Plan Overview</h4>
              </div>
              <div className={classes.topInformation}>
                <div className={classes.row}>
                  <div className={classes.inputBlockPlan}>
                    <label className={classes.InputLabels}>Identifier:</label>
                    <TextField
                      name="planOverview.identifier"
                      errors={errors}
                      touched={touched}
                      placeholder="Identifier"
                    />
                  </div>

                  <div className={classes.inputBlockPlan}>
                    <label className={classes.InputLabels}>Subscription price:</label>
                    <TextField
                      name="planOverview.price"
                      errors={errors}
                      touched={touched}
                      placeholder="Subscription price"
                    />
                  </div>
                </div>

                <div className={classes.row}>
                  <div className={classes.inputBlockPlan}>
                    <label className={classes.InputLabels}>Currency:</label>
                    <select
                      name="planOverview.currency"
                      // value={values.color}
                      onChange={handleChange}
                    >
                      <option value="ZAR" label="ZAR (R)" />
                      <option value="USD" label="USD ($)" />
                    </select>
                  </div>
                  {/* <div className={classes.inputBlockPlan}>
                    <label className={classes.InputLabels}>
                      Subscription display price &#40;{values?.planOverview?.currency}&#41;:
                    </label>
                    <TextField
                      name="planOverview.displayPrice"
                      errors={errors}
                      touched={touched}
                      placeholder="Display price"
                    />
                  </div> */}
                  <label className={classes.inputBlockPlan}>
                    Organisation?
                    <Field type="checkbox" name="planOverview.organisation" />
                  </label>
                  <label className={classes.inputBlockPlan}>
                    1 Month Trial?
                    <Field type="checkbox" name="planOverview.isTrialEnabled" />
                  </label>
                </div>

                <div className={classes.row}>
                  <div className={classes.inputBlock}>
                    <label className={classes.InputLabels}>Description:</label>
                    <Textarea
                      name="coachOverview.description"
                      placeholder="Description"
                      value={values.planOverview.description}
                      onChange={(event) => {
                        setFieldValue('planOverview.description', event.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.Title}>
                <h4>Testimonials</h4>
              </div>
              <div className={classes.topInformation}>
                <div className={classes.row}>
                  <ul className={classes.ListOfItems}>
                    <FieldArray
                      name="testimonials"
                      render={() =>
                        values.testimonials?.map((testimonial: any, index: number) => (
                          <Accordion key={testimonial.id} title={testimonial.name} content={testimonial.content}>
                            <div className={classes.inputBlock}>
                              <label className={classes.FileInputLabels}>Client full name:</label>
                              <TextField
                                name={`testimonials.${index}.name`}
                                errors={errors}
                                touched={touched}
                                placeholder="Client full name"
                              />
                            </div>
                            <div className={classes.inputBlock}>
                              <label className={classes.FileInputLabels}>Client location:</label>
                              <TextField
                                name={`testimonials.${index}.location`}
                                errors={errors}
                                touched={touched}
                                placeholder="Client location"
                              />
                            </div>
                            <div className={classes.inputBlock}>
                              <label className={classes.FileInputLabels}>Client testimonial:</label>
                              <TextField
                                name={`testimonials.${index}.content`}
                                errors={errors}
                                touched={touched}
                                placeholder="Client testimonial"
                              />
                            </div>

                            <div>
                              <ImageUploadComponent
                                testimonial={testimonial}
                                setFieldValue={setFieldValue}
                                imageName={'beforeImage'}
                                label={'Before image'}
                                index={index}
                              />
                              <br />
                              <ImageUploadComponent
                                testimonial={testimonial}
                                setFieldValue={setFieldValue}
                                imageName={'afterImage'}
                                label={'After image'}
                                index={index}
                              />
                            </div>
                          </Accordion>
                        ))
                      }
                    />

                    <div>
                      <button type="button" onClick={() => handleAdd(setFieldValue, values, 'testimonials')}>
                        + Add testimonial
                      </button>
                    </div>
                  </ul>
                </div>
              </div>
              {/* <div className={classes.Title}>
                <h4>FAQs</h4>
              </div>
              <div className={classes.topInformation}>
                <div className={classes.row}>
                  <div>
                    <div className={classes.inputBlock}>
                      <label className={classes.InputLabels}>
                        Question:
                      </label>
                      <TextField
                        name="question"
                        errors={errors}
                        touched={touched}
                        placeholder="Question"
                      />
                    </div>
                    <div className={classes.inputBlock}>
                      <label className={classes.InputLabels}>
                        Answer:
                      </label>
                      <TextField
                        name="answer"
                        errors={errors}
                        touched={touched}
                        placeholder="Answer"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() =>
                        handleAdd(setFieldValue, values, 'faqs')
                      }
                    >
                      Add
                    </button>
                  </div>

                  <ul>
                    {faqs?.map((faq: any) => (
                      <Accordion
                        key={faq.id}
                        title={faq.question}
                        content={faq.answer}
                      />
                    ))}
                  </ul>
                </div>
              </div> */}
              <div className={classes.Title}>
                <h4>Other Images</h4>
              </div>
              <div className={classes.topInformation}>
                <div className={classes.row}>
                  <div>
                    <div>
                      <label className={classes.InputLabels}>Hero image:</label>
                      <br />
                      <label className={classes.InputLabels}>Desktop:</label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {funnelValues?.otherImages?.heroImageDesktop?.downloadURL && (
                          <div className={classes.ImageContainer}>
                            <img
                              src={funnelValues?.otherImages?.heroImageDesktop?.downloadURL}
                              alt="heroImageDesktop-alt"
                            />
                          </div>
                        )}
                        <div>
                          <label htmlFor="file" className={classes.fileInput}>
                            <input
                              type="file"
                              ref={hiddenFileInputOtherImagesHeroDesktop}
                              id="otherImages.heroImageDesktop"
                              name="otherImages.heroImageDesktop"
                              onChange={(event) => {
                                setFieldValue(`otherImages.heroImageDesktop`, event.currentTarget.files?.[0]);
                              }}
                              className={classes.file}
                            />
                            <button
                              onClick={handleClickOtherImagesHeroDesktop}
                              type="button"
                              className={classes.lightUploadButton}
                            >
                              Select desktop hero image
                            </button>
                          </label>
                        </div>
                      </div>

                      <label className={classes.InputLabels}>Mobile:</label>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {funnelValues?.otherImages?.heroImageMobile?.downloadURL && (
                          <div className={classes.ImageContainer}>
                            <img
                              src={funnelValues?.otherImages?.heroImageMobile?.downloadURL}
                              alt="heroImageMobile-alt"
                            />
                          </div>
                        )}
                        <div>
                          <label htmlFor="file" className={classes.fileInput}>
                            <input
                              type="file"
                              ref={hiddenFileInputOtherImagesHeroMobile}
                              id="otherImages.heroImageMobile"
                              name="otherImages.heroImageMobile"
                              onChange={(event) => {
                                setFieldValue(`otherImages.heroImageMobile`, event.currentTarget.files?.[0]);
                              }}
                              className={classes.file}
                            />
                            <button
                              onClick={handleClickOtherImagesHeroMobile}
                              type="button"
                              className={classes.lightUploadButton}
                            >
                              Select mobile hero image
                            </button>
                          </label>
                        </div>
                      </div>
                    </div>

                    <br />

                    <div>
                      <label className={classes.InputLabels}>
                        Your <i>Get To Know</i> image:
                      </label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {funnelValues?.otherImages?.getToKnowImage?.downloadURL && (
                          <div className={classes.ImageContainer}>
                            <img src={funnelValues?.otherImages?.getToKnowImage?.downloadURL} alt="getknowimage-alt" />
                          </div>
                        )}
                        <div>
                          <label htmlFor="file" className={classes.fileInput}>
                            <input
                              type="file"
                              ref={hiddenFileInputOtherImagesGetToKnow}
                              id="otherImages.getToKnowImage"
                              name="otherImages.getToKnowImage"
                              onChange={(event) => {
                                setFieldValue(`otherImages.getToKnowImage`, event.currentTarget.files?.[0]);
                              }}
                              className={classes.file}
                            />
                            <button
                              onClick={handleClickOtherImagesGetToKnow}
                              type="button"
                              className={classes.lightUploadButton}
                            >
                              Select get to know image
                            </button>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.coachProfileButtons}>
            <Button type="submit" disabled={isSubmitting}>
              SAVE
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateProfile;
