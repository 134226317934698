import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { useYupValidationResolver } from 'hooks';
import classes from './AddProgram.module.css';
import Input from 'components/UI/Input';
import ModalButtonGroup from 'components/ModalButtonGroup';

interface FormValues {
  title: string;
}

const initialValues: FormValues = { title: '' };
const validationSchema = object().shape({
  title: string().max(30, 'Max number of charaters is 30.').required('Program name is required.'),
});

interface AddProgramProps {
  closeModal: () => void;
  handleSubmit: (value: string) => void;
  loading?: boolean;
}

const AddProgram: FC<AddProgramProps> = ({ closeModal, handleSubmit, loading }) => {
  const {
    register,
    handleSubmit: handleFormSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: useYupValidationResolver(validationSchema),
  });

  const onSubmit = async (data: FormValues) => {
    const { title } = data;
    await handleSubmit(title);
  };

  return (
    <form onSubmit={handleFormSubmit(onSubmit)} className={classes.Form}>
      <div className={classes.InputWrapper}>
        <Input
          type="text"
          label="Program title"
          name="title"
          register={register}
          placeholder="Enter a program title..."
          error={errors.title}
        />
      </div>

      <ModalButtonGroup
        loading={loading}
        mainButtonType="submit"
        mainButtonText="Create Program"
        secondaryButtonText="Cancel"
        onSecondaryClick={closeModal}
      />
    </form>
  );
};

export default AddProgram;
