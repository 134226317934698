import { NutritionWeek } from 'interfaces/nutrition';

export const findCurrentWeek = (weeks: NutritionWeek[]): number => {
  if (!weeks || weeks.length === 0) {
    return 0;
  }

  let lastWeekWithCompletedDay = 0;
  for (let weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
    const days = weeks[weekIndex].days;
    const weekHasFinishedDay = days?.some((day) => day.finished);

    if (weekHasFinishedDay) {
      lastWeekWithCompletedDay = weekIndex;
    }
  }

  // Return the last week where any day has been completed
  return lastWeekWithCompletedDay;
};
