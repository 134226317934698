import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { auth, database } from './firebase';
import { LOGIN } from './routes';
import { setIsInSouthAfrica, setToken as setTokenAction } from 'store/actions/auth';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import { checkIfUserIsInSouthAfrica } from './helpers';
import { userProfileTrackingService } from './tracking/userProfileService';

const WithAuth = (Component: any) => {
  return function HOC(props: any) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
      // Attach an auth state observer and get user data when user is logged in
      const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
        if (user) {
          const fetchedToken = await user.getIdToken();
          if (!fetchedToken) {
            console.log('No token found');
            return;
          }

          setToken(fetchedToken);
          dispatch(setTokenAction(fetchedToken));

          checkIfUserIsInSouthAfrica().then((isInSouthAfrica) => dispatch(setIsInSouthAfrica(isInSouthAfrica)));

          // Set user's online status in the database
          const userStatusDatabaseRef = database.ref(`/status/${user.uid}`);
          const isOfflineForDatabase = {
            state: 'offline',
            lastChanged: firebase.database.ServerValue.TIMESTAMP,
          };
          const isOnlineForDatabase = {
            state: 'online',
            lastChanged: firebase.database.ServerValue.TIMESTAMP,
          };

          // Set user's status to online when logging in and to offline when logging out
          database.ref('.info/connected').on('value', (snapshot) => {
            if (snapshot.val() === false) {
              return;
            }

            userStatusDatabaseRef
              .onDisconnect()
              .set(isOfflineForDatabase)
              .then(() => {
                userStatusDatabaseRef.set(isOnlineForDatabase);
              })
              .catch((error) => {
                console.error('Error updating user status: ', error);
              });
          });
        } else {
          // User is not logged in
          history.push(LOGIN.URL);
        }
      });

      // Listen for token changes
      const unsubscribeToken = auth.onIdTokenChanged(async (user) => {
        if (user) {
          userProfileTrackingService.identifyUser(user.uid);
          const refreshedToken = await user.getIdToken();
          if (refreshedToken) {
            dispatch(setTokenAction(refreshedToken));
            setToken(refreshedToken);
          }
        }
      });

      return () => {
        // Clean up observers
        unsubscribeAuth();
        unsubscribeToken();
      };
    }, [dispatch]);

    return <Component {...props} token={token} />;
  };
};

export default WithAuth;
