// Would be cool if we could build using radix-UI: https://github.com/radix-ui/design-system/blob/master/components/Sheet.tsx

import React, { FC, ReactNode } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import Portal from '../Modal/Portal';
import classes from './SideDrawer.module.css';
import clsx from 'clsx';

interface Props {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  exemptedRef: React.RefObject<HTMLDivElement>;
  side?: 'left' | 'right' | 'top' | 'bottom';
  children: ReactNode;
}

const SideDrawer: FC<Props> = ({ children, closeDrawer, isDrawerOpen, exemptedRef, side = 'right' }) => {
  const handleOutsideClick = (event: any) => {
    if (exemptedRef.current && exemptedRef.current.contains(event.target)) {
      return;
    }
    closeDrawer();
  };

  return (
    <Portal selector="#drawer">
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className={clsx(classes.Container, classes[side], isDrawerOpen && classes.active)}>
          <div className={classes.SideDrawerInner}>{children}</div>
        </div>
      </OutsideClickHandler>
    </Portal>
  );
};

export default SideDrawer;
