import React, { FC, forwardRef } from 'react';
import clsx from 'clsx';

import classes from './Button.module.css';
import { ButtonProps } from 'interfaces/ui';
import { NavLink } from 'react-router-dom';

const INTENT_CLASSES: Record<string, string> = {
  primary: classes.Primary,
  secondary: classes.Secondary,
  tertiary: classes.Tertiary,
  danger: classes.Danger,
  success: classes.Success,
  warning: classes.Warning,
  complete: classes.Complete,
};

const SIZE_CLASSES: Record<string, string> = {
  xsmall: classes.XSmall,
  small: classes.Small,
  large: classes.Large,
};

const Button: FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      onClick,
      children,
      loading,
      disabled,
      iconLeft,
      iconCenter,
      iconRight,
      to,
      intent = 'primary',
      type = 'button',
      ...styleProps
    },
    ref,
  ) => {
    const buttonClasses = clsx(
      classes.Button,
      disabled && classes.Disabled,
      INTENT_CLASSES[intent],
      size && SIZE_CLASSES[size],
      loading && classes.Loading,
    );

    const { color, border, radius, height, width, ...props } = styleProps;

    const buttonElement = (
      <button
        ref={ref}
        onClick={onClick}
        style={{
          backgroundColor: color,
          border,
          borderRadius: radius,
          height,
          width,
        }}
        disabled={disabled}
        type={type}
        className={buttonClasses}
        {...props}
      >
        {iconLeft && !loading && <span className={clsx(classes.Icon, classes.MarginRight)}>{iconLeft}</span>}
        {iconCenter && !loading && <span className={classes.Icon}>{iconCenter}</span>}
        {loading ? <i className="fa fa-circle-o-notch fa-spin" /> : children}
        {iconRight && !loading && <span className={clsx(classes.Icon, classes.MarginLeft)}>{iconRight}</span>}
      </button>
    );

    return to ? <NavLink to={to}>{buttonElement}</NavLink> : buttonElement;
  },
);

export default Button;
