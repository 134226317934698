import React, { FC } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-outline.svg';
import classes from './Popover.module.css';

type Props = {
  trigger?: React.ReactNode;
  children?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  closePopover?: (param?: any) => void;
};

const PopoverDemo: FC<Props> = ({ trigger, children, open, onOpenChange, closePopover }) => (
  <Popover.Root open={open} onOpenChange={onOpenChange} modal={false}>
    {trigger && <Popover.Trigger asChild>{trigger}</Popover.Trigger>}
    {/* <Popover.Portal> */}
    <Popover.Content
      className={classes.Content}
      sideOffset={5}
      onOpenAutoFocus={(event) => event?.preventDefault}
      avoidCollisions
      collisionPadding={5}
      onCloseAutoFocus={(event) => {
        event.preventDefault();
      }}
    >
      {children}
      <Popover.Close className={classes.Close} aria-label="Close" onClick={closePopover}>
        <CloseIcon />
      </Popover.Close>
      <Popover.Arrow className={classes.Arrow} />
    </Popover.Content>
    {/* </Popover.Portal> */}
  </Popover.Root>
);

export default PopoverDemo;
