import React, { FC } from 'react';
import SectionHeader from 'components/SectionHeader';
import classes from './CoachDetails.module.css';
import SFClasses from '../SubscriptionFunnel.module.css';
import { PlanImage } from 'interfaces/db';
import clsx from 'clsx';

interface Props {
  coachDetails: {
    title: string;
    coachName: string;
    descriptionTitle: string;
    description: string;
  };
  coachImg: PlanImage;
}

const CoachDetails: FC<Props> = ({ coachImg, coachDetails: { title, coachName, descriptionTitle, description } }) => {
  return (
    <section className={SFClasses.Section} id="coach-overview">
      <SectionHeader>
        <span>Get to know {coachName}</span>
      </SectionHeader>
      <p className={classes.Subtitle}>{title}</p>

      <div className={classes.CoachPicContainer}>
        <img src={coachImg.downloadURL} alt={coachImg.fileName} />
      </div>
      <div className={classes.DetailsContainer}>
        <div>
          <p className={classes.Heading}>{descriptionTitle}</p>
          <p className={clsx(classes.Text, classes.Description)}>{description}</p>
        </div>
      </div>
    </section>
  );
};

export default CoachDetails;
