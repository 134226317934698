import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';

import classes from './Day.module.css';
import { Macro, NutritionDay } from 'interfaces/nutrition';
import { Button, DropdownMenu, Input, TextArea } from 'components/UI';
import { useDropdown } from 'hooks';
import KebabMenu from 'components/KebabMenu';
import {
  calculateCaloriesFromGrams,
  calculateCaloriesFromPercentage,
  calculateGramsFromPercentage,
  calculateMacroPercentages,
  capitalizeAndSplit,
  onChangeMicrosHandler,
} from 'containers/Nutrition/helpers';
import { ReactComponent as DayFinishedIcon } from 'assets/svgs/day-complete.svg';
import { ReactComponent as CopyIcon } from 'assets/svgs/copy-filled.svg';
import { ReactComponent as PasteIcon } from 'assets/svgs/paste-filled.svg';
import { ReactComponent as ChatBubbleIcon } from 'assets/svgs/chat-bubble.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-filled.svg';
import { ReactComponent as TickIcon } from 'assets/svgs/tick-outline.svg';
import { ReactComponent as CancelIcon } from 'assets/svgs/close-outline.svg';

interface Props {
  day: NutritionDay;
  weekIndex: number;
  weekId: string;
  copyDay: (day: NutritionDay) => void;
  pasteDay: (weekIndex: number, dayIndex: number) => void;
  handleOpenGoalsModal: (weekId: string, dayIndex: number, day: NutritionDay) => void;
}

const Day: FC<Props> = ({ day, weekIndex, weekId, copyDay, pasteDay, handleOpenGoalsModal }) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();

  const { finished, macros = {}, calories, micros = {}, dayIndex = 0, notes, macrosType } = day;

  const { isOpen, toggleDropdown } = useDropdown();
  const dayData = watch(`days.${dayIndex}`);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [inputWidth, setInputWidth] = useState(132);
  const [originalDayTitle, setOriginalDayTitle] = useState('');

  const menuItems = [
    {
      title: 'Copy',
      onClick: () => copyDay(dayData),
      iconLeft: <CopyIcon />,
    },
    {
      title: 'Paste',
      onClick: () => pasteDay(weekIndex, dayIndex),
      iconLeft: <PasteIcon />,
    },
  ];

  const triggerHandlerOpenGoalsModal = () => {
    handleOpenGoalsModal(weekId, dayIndex, dayData);
  };

  const macroOrder = ['protein', 'carbs', 'fat'];
  const dayTitle = watch(`days.${dayIndex}.title`);
  const totalCalories = watch(`days.${dayIndex}.calories.goal`);
  const totalCaloriesPercentage = watch(`days.${dayIndex}.totalCaloriesPercentage`);
  const proteinPercentage = watch(`days.${dayIndex}.macros.protein.goalPercentage`);
  const carbsPercentage = watch(`days.${dayIndex}.macros.carbs.goalPercentage`);
  const fatPercentage = watch(`days.${dayIndex}.macros.fat.goalPercentage`);

  const prevValuesRef = useRef({ proteinPercentage: 0, carbsPercentage: 0, fatPercentage: 0 });
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!macros || Object.keys(macros).length === 0) return;
    clearErrors();

    const totalCaloriesPercentageFieldName = `days.${dayIndex}.totalCaloriesPercentage`;

    const {
      proteinPercentage: prevProtein,
      carbsPercentage: prevCarbs,
      fatPercentage: prevFat,
    } = prevValuesRef.current;

    // Check if each macro is selected
    const isProteinSelected = getValues(`days.${dayIndex}.macros.protein.selected`);
    const isCarbsSelected = getValues(`days.${dayIndex}.macros.carbs.selected`);
    const isFatSelected = getValues(`days.${dayIndex}.macros.fat.selected`);

    if (!isProteinSelected && !isCarbsSelected && !isFatSelected) {
      setValue(totalCaloriesPercentageFieldName, 'N/A');
      return;
    }

    if (proteinPercentage === prevProtein && carbsPercentage === prevCarbs && fatPercentage === prevFat) {
      return;
    }

    prevValuesRef.current = { proteinPercentage, carbsPercentage, fatPercentage };

    // Include only the percentages of selected macros
    const totalCaloriesPercentage = [
      isProteinSelected ? proteinPercentage : 0,
      isCarbsSelected ? carbsPercentage : 0,
      isFatSelected ? fatPercentage : 0,
    ].reduce((acc, curr) => acc + Number(curr ?? 0), 0);

    setValue(totalCaloriesPercentageFieldName, totalCaloriesPercentage);
  }, [proteinPercentage, carbsPercentage, fatPercentage, setValue, macros, dayIndex, getValues]);

  useEffect(() => {
    if (macrosType === 'percentage' && totalCalories) {
      const isProteinSelected = getValues(`days.${dayIndex}.macros.protein.selected`);
      const isCarbsSelected = getValues(`days.${dayIndex}.macros.carbs.selected`);
      const isFatSelected = getValues(`days.${dayIndex}.macros.fat.selected`);

      // Only perform calculations and setValue for selected macros
      if (isProteinSelected) {
        const proteinGrams = calculateGramsFromPercentage(proteinPercentage, 'protein', totalCalories);
        const proteinCalories = calculateCaloriesFromPercentage(totalCalories, proteinPercentage);
        setValue(`days.${dayIndex}.macros.protein.goal`, Number(proteinGrams));
        setValue(`days.${dayIndex}.macros.protein.calories`, proteinCalories);
      }

      if (isCarbsSelected) {
        const carbsGrams = calculateGramsFromPercentage(carbsPercentage, 'carbs', totalCalories);
        const carbsCalories = calculateCaloriesFromPercentage(totalCalories, carbsPercentage);
        setValue(`days.${dayIndex}.macros.carbs.goal`, Number(carbsGrams));
        setValue(`days.${dayIndex}.macros.carbs.calories`, carbsCalories);
      }

      if (isFatSelected) {
        const fatGrams = calculateGramsFromPercentage(fatPercentage, 'fat', totalCalories);
        const fatCalories = calculateCaloriesFromPercentage(totalCalories, fatPercentage);
        setValue(`days.${dayIndex}.macros.fat.goal`, Number(fatGrams));
        setValue(`days.${dayIndex}.macros.fat.calories`, fatCalories);
      }
    }
  }, [totalCalories]);

  // TODO make SAVE button disabled if there is an error in the form (complicated and not required for now 13/12/2023)
  // useEffect(() => {
  //   setDisabled(!!Object.keys(errors).length);
  // }, [errors]);

  useEffect(() => {
    const numTotalCaloriesPercentage = Number(totalCaloriesPercentage);
    if (numTotalCaloriesPercentage === 100) {
      clearErrors();
    } else {
      // chcek if one of the macros is not selected and if so, don't show the error otherwise set it
      const isProteinSelected = getValues(`days.${dayIndex}.macros.protein.selected`);
      const isCarbsSelected = getValues(`days.${dayIndex}.macros.carbs.selected`);
      const isFatSelected = getValues(`days.${dayIndex}.macros.fat.selected`);

      if (!isProteinSelected || !isCarbsSelected || !isFatSelected) {
        clearErrors();
      } else if (isProteinSelected && isCarbsSelected && isFatSelected) {
        const errorMessage =
          numTotalCaloriesPercentage > 100
            ? `The value is ${numTotalCaloriesPercentage - 100}% above 100%`
            : `The value is ${100 - numTotalCaloriesPercentage}% below 100%`;
        setError(`days.${dayIndex}.general`, {
          type: 'manual',
          message: errorMessage,
        });
      }
    }
  }, [totalCaloriesPercentage]);

  const handlePercentageChange = (percentageInput: number | string, macroType: Macro) => {
    const regex = /^[0-9]*$/;
    let percentage = percentageInput;
    if (typeof percentageInput === 'string' && !regex.test(percentageInput)) {
      // If non-numeric characters are entered, reset the value
      percentage = Number(percentageInput.replace(/[^0-9]/g, ''));
      setValue(`days.${dayIndex}.macros.${macroType}.goalPercentage`, percentage);
      return;
    }

    if (Number.isNaN(percentage)) return;
    const numPercentage = Number(percentage);
    setValue(`days.${dayIndex}.macros.${macroType}.goalPercentage`, numPercentage);

    const grams = calculateGramsFromPercentage(numPercentage, macroType, totalCalories);
    setValue(`days.${dayIndex}.macros.${macroType}.goal`, grams);
  };

  const handleGramsChange = (gramsInput: number | string, macroType: Macro) => {
    const regex = /^[0-9]*$/;
    let grams = gramsInput;

    if (typeof gramsInput === 'string' && !regex.test(gramsInput)) {
      grams = Number(gramsInput.replace(/[^0-9]/g, ''));
      setValue(`days.${dayIndex}.macros.${macroType}.goal`, grams);
      return;
    }

    if (Number.isNaN(grams)) return;
    const numGrams = Number(grams);
    const newValues: { [key: string]: number } = {};

    const isProteinSelected = getValues(`days.${dayIndex}.macros.protein.selected`);
    const isCarbsSelected = getValues(`days.${dayIndex}.macros.carbs.selected`);
    const isFatSelected = getValues(`days.${dayIndex}.macros.fat.selected`);

    const macroKeys: Macro[] = ['protein', 'carbs', 'fat'];
    macroKeys.forEach((type) => {
      if (getValues(`days.${dayIndex}.macros.${type}.selected`)) {
        const gramsValue = type === macroType ? numGrams : getValues(`days.${dayIndex}.macros.${type}.goal`);
        const cals = calculateCaloriesFromGrams(gramsValue, type);
        newValues[`days.${dayIndex}.macros.${type}.goal`] = gramsValue;
        newValues[`days.${dayIndex}.macros.${type}.calories`] = cals;
      }
    });

    const newTotalCalories = macroKeys.reduce((total, key) => {
      const cals = newValues[`days.${dayIndex}.macros.${key}.calories`] || 0;
      return total + cals;
    }, 0);

    newValues[`days.${dayIndex}.calories.goal`] = newTotalCalories;

    const newMacroPercentages = calculateMacroPercentages(
      [
        isProteinSelected ? newValues[`days.${dayIndex}.macros.protein.calories`] : 0,
        isCarbsSelected ? newValues[`days.${dayIndex}.macros.carbs.calories`] : 0,
        isFatSelected ? newValues[`days.${dayIndex}.macros.fat.calories`] : 0,
      ],
      newTotalCalories,
    );

    macroKeys.forEach((type, index) => {
      if (getValues(`days.${dayIndex}.macros.${type}.selected`)) {
        newValues[`days.${dayIndex}.macros.${type}.goalPercentage`] = newMacroPercentages[index];
      }
    });

    newValues[`days.${dayIndex}.totalCaloriesPercentage`] = 100;

    Object.entries(newValues).forEach(([field, value]) => {
      setValue(field, value, { shouldDirty: true, shouldValidate: true });
    });
  };

  const handleEdit = () => {
    if (finished) {
      return;
    }
    setIsEditingTitle(true);
    setOriginalDayTitle(dayTitle);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleCancel = () => {
    setValue(`days.${dayIndex}.title`, originalDayTitle);
    const textWidth = calculateTextWidth(originalDayTitle || 'Nutrition Day Title');
    if (textWidth) setInputWidth(textWidth);
    setIsEditingTitle(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 16) return;
    setValue(`days.${dayIndex}.title`, e.target.value);
  };

  const handleAcceptTitle = () => {
    setOriginalDayTitle(dayTitle);
    setIsEditingTitle(false);
  };

  const calculateTextWidth = (text: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context || !inputRef.current) return;

    context.font = getComputedStyle(inputRef.current).font;
    return context.measureText(text).width + 7;
  };

  useEffect(() => {
    const textWidth = calculateTextWidth(dayTitle || 'Nutrition Day Title');
    if (textWidth) setInputWidth(textWidth);
  }, [dayTitle]);

  useEffect(() => {
    if (isEditingTitle && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditingTitle, inputRef]);

  const { onChange, name, ref } = register(`days.${dayIndex}.title`, {
    onChange: (e) => handleChange(e),
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.Day}>
        <div>
          <div className={classes.Header}>
            <div>
              <h6 className={classes.DayHeading}>Day {dayIndex + 1}</h6>
              <OutsideClickHandler onOutsideClick={handleAcceptTitle} disabled={!isEditingTitle}>
                <div className={classes.DayTitleWrapper}>
                  {isEditingTitle ? (
                    <input
                      type="text"
                      className={classes.DayTitleInput}
                      name={name}
                      onChange={onChange}
                      ref={(e) => {
                        ref(e);
                        inputRef.current = e;
                      }}
                      style={{ width: `${inputWidth}px` }}
                    />
                  ) : (
                    <div
                      className={clsx(classes.UneditableTitleWrapper, !finished && classes.CursorPointer)}
                      onClick={handleEdit}
                    >
                      <span className={classes.DayTitle}>{dayTitle}</span>
                      {!finished ? <EditIcon width={'1rem'} /> : <div className={classes.MinHeight}></div>}
                    </div>
                  )}
                  {isEditingTitle && (
                    <div className={classes.TitleActionButtons}>
                      <Button type="button" onClick={handleAcceptTitle} size="xsmall" iconCenter={<TickIcon />} />
                      <Button
                        type="button"
                        onClick={handleCancel}
                        size="xsmall"
                        iconCenter={<CancelIcon />}
                        intent="tertiary"
                      />
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
            </div>
            <DropdownMenu
              menuItems={menuItems}
              open={isOpen}
              onOpenChange={toggleDropdown}
              width={'12.5rem'}
              trigger={<KebabMenu onClick={toggleDropdown} />}
              side="right"
              align="start"
            />
          </div>

          <div className={classes.DayContent}>
            <div className={classes.Content}>
              {finished && (
                <div className={classes.MarginRight}>
                  <div className={classes.DayFinished}>
                    <DayFinishedIcon height={24} width={24} />
                    <span className={classes.DayCompleteText}>Day Complete</span>
                  </div>
                </div>
              )}

              {calories && (
                <div className={classes.RowInputs}>
                  <span className={classes.NutrientHeading}></span>
                  <div className={classes.InputWrapper}>
                    <p className={classes.ColumnHeading}>Goal</p>
                  </div>
                  <div className={classes.InputWrapper}>
                    <p className={classes.ColumnHeading}>{finished ? 'Achieved' : 'Goal (%)'}</p>
                  </div>
                </div>
              )}

              <div>
                {calories && (
                  <div key={`calories-${dayIndex}-${weekIndex}`} className={classes.RowInputs}>
                    <span className={classes.NutrientHeading}>Calories (kCal)</span>
                    <div className={classes.InputWrapper}>
                      <Input
                        register={register}
                        name={`days.${dayIndex}.calories.goal`}
                        placeholder={'kCal'}
                        readOnly={finished || macrosType === 'grams'}
                        type="number"
                        valueAsNumber
                        className={classes.TextCentered}
                      />
                    </div>
                    {finished ? (
                      <div className={classes.InputWrapper}>
                        <span className={classes.AchievedValue}>{watch(`days.${dayIndex}.calories.achieved`)}</span>
                      </div>
                    ) : (
                      <div className={classes.InputWrapper}>
                        <Input
                          register={register}
                          name={`days.${dayIndex}.totalCaloriesPercentage`}
                          placeholder={'0'}
                          readOnly
                          className={classes.TextCentered}
                          error={errors.days?.[dayIndex]?.general}
                          showError={false}
                        />
                      </div>
                    )}
                  </div>
                )}

                {macroOrder.map((macroKey) => {
                  const macro = macros[macroKey];
                  if (!macro) return null;

                  const goalFieldName = `days.${dayIndex}.macros.${macroKey}.goal`;
                  const goalPercentageFieldName = `days.${dayIndex}.macros.${macroKey}.goalPercentage`;
                  const achievedFieldName = `days.${dayIndex}.macros.${macroKey}.achieved`;
                  const isSelected = watch(`days.${dayIndex}.macros.${macroKey}.selected`);

                  return (
                    <div key={`${macroKey}-${dayIndex}-${weekIndex}`} className={classes.RowInputs}>
                      <span className={classes.NutrientHeading}>
                        {capitalizeAndSplit(macroKey)} ({macro.unit})
                      </span>
                      <div className={classes.InputWrapper}>
                        <Input
                          register={register}
                          name={goalFieldName}
                          placeholder={macroKey}
                          readOnly={finished || macrosType === 'percentage' || !isSelected}
                          className={classes.TextCentered}
                          onChange={(e) => handleGramsChange(e.target.value, macroKey as Macro)}
                        />
                      </div>
                      {finished ? (
                        <div className={classes.InputWrapper}>
                          <span className={classes.AchievedValue}>{watch(achievedFieldName) ?? '-'}</span>
                        </div>
                      ) : (
                        <div className={classes.InputWrapper}>
                          <Input
                            register={register}
                            name={goalPercentageFieldName}
                            placeholder={macroKey}
                            readOnly={macrosType === 'grams' || !isSelected}
                            className={classes.TextCentered}
                            onChange={(e) => handlePercentageChange(e.target.value, macroKey as Macro)}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}

                {errors.days?.[dayIndex]?.general && (
                  <p className={classes.PercentageTotalError}>
                    {errors.days?.[dayIndex]?.general?.message || 'Total calories percentage must be 100%'}
                  </p>
                )}

                {Object.keys(micros).length > 0 && <div className={classes.Divider} />}

                {Object.entries(micros).map((micro: any) => {
                  if (micro[0] === 'completed') return;
                  const goalFieldName = `days.${dayIndex}.micros.${micro[0]}.goal`;
                  const achievedFieldName = `days.${dayIndex}.micros.${micro[0]}.achieved`;
                  const processText = capitalizeAndSplit(micro[0]);
                  return (
                    <div key={`${micro[0]}-${dayIndex}-${weekIndex}`} className={classes.RowInputs}>
                      <span className={classes.NutrientHeading}>
                        {processText} ({micro[1].unit})
                      </span>
                      <div className={clsx(classes.MicroGoalInput, finished && classes.MicroGoalInputWhenAchieved)}>
                        <Input
                          register={register}
                          name={goalFieldName}
                          placeholder={processText}
                          readOnly={finished}
                          valueAsNumber
                          type="number"
                          className={classes.TextCentered}
                          onChange={(e) => onChangeMicrosHandler(e, setValue, goalFieldName)}
                        />
                      </div>
                      {finished && (
                        <div className={classes.InputWrapper}>
                          <span className={classes.AchievedValue}>{watch(achievedFieldName) ?? '-'}</span>
                        </div>
                      )}
                    </div>
                  );
                })}

                <div className={classes.Notes}>
                  {Object.entries(notes || {}).map((note: any) => {
                    const fieldName = `days.${dayIndex}.notes.${note[0]}`;
                    const isClientNotes = note[0] === 'client';

                    return !finished && isClientNotes ? null : (
                      <div key={`${note[0]}-${dayIndex}-${weekIndex}`}>
                        <TextArea
                          register={register}
                          name={fieldName}
                          placeholder={
                            note[1] ||
                            (isClientNotes ? 'No client notes' : finished ? 'No coach notes' : 'Enter notes here...')
                          }
                          label={isClientNotes ? 'Client Notes' : 'My Notes'}
                          isLabelInside
                          leftLabelIcon={<ChatBubbleIcon width={18} height={18} />}
                          rightLabelIcon={!finished ? <EditIcon width={18} height={18} /> : null}
                          readOnly={finished || isClientNotes}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              {!finished && (!calories || Object.keys(calories).length === 0) ? (
                <Button type="button" onClick={triggerHandlerOpenGoalsModal}>
                  SET NUTRITION GOALS
                </Button>
              ) : (
                !finished && (
                  <div className={classes.EditButtonsContainer}>
                    <Button type="button" onClick={triggerHandlerOpenGoalsModal}>
                      EDIT NUTRITION GOALS
                    </Button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Day;
