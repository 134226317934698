import React, { FC } from 'react';
import classes from './Notification.module.css';
import { getInitials } from 'utils/helpers';
import NameBadge from 'components/NameBadge';

interface Props {
  type: string;
  users: { userId: string; name: string }[];
  description: string;
  isRead: boolean;
}

const Notification: FC<Props> = ({ users, description, isRead }) => {
  const getUserString = (): string => {
    const userNames = users.map((user) => user.name);
    switch (userNames.length) {
      case 0:
        return '';
      case 1:
        return userNames[0];
      case 2:
        return `${userNames[0]} and ${userNames[1]}`;
      default:
        return `${userNames[0]}, ${userNames[1]} and others`;
    }
  };

  const renderInitials = () => {
    if (users.length === 1) {
      return <NameBadge initials={getInitials(users[0].name)} size="small" />;
    } else if (users.length >= 2) {
      return (
        <div className={classes.CirclesWrapper}>
          <div className={classes.SmallSquare}>
            <NameBadge initials={getInitials(users[0].name)} size="xsmall" />
          </div>
          <div className={classes.SmallSquare}>
            <NameBadge initials={getInitials(users[1].name)} size="xsmall" />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.Container}>
      <div>{renderInitials()}</div>
      <div className={classes.DetailsAndDotWrapper}>
        <p className={classes.NotificationDetails}>
          {getUserString()} {description}
        </p>
        {!isRead && <div className={classes.UnreadDot}></div>}
      </div>
    </div>
  );
};

export default Notification;
