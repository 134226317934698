import React, { useState, useRef, FC } from 'react';
import Chevron from './Chevron';
import classes from './Accordion.module.css';
interface propsType {
  title: string;
  content: string;
}
const Accordion: FC<propsType> = (props) => {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState(classes.accordion__icon);

  const content = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setActiveState(setActive === '' ? 'active' : '');
    setHeightState(setActive === 'active' ? '0px' : `${content.current?.scrollHeight}px`);
    setRotateState(
      setActive === 'active' ? classes.accordion__icon : [classes.accordion__icon, classes.rotate].join(' '),
    );
  };

  return (
    <div className={classes.accordion__section}>
      <button
        type="button"
        className={[classes.accordion, classes[`${setActive}`]].join(' ')}
        onClick={toggleAccordion}
      >
        <p className={classes.accordion__title}>{props.title}</p>
        <Chevron className={setRotate} width={10} fill={'#777'} />
      </button>
      <div ref={content} style={{ maxHeight: `${setHeight}` }} className={classes.accordion__content}>
        <div className={classes.accordion__text}>{props.children}</div>
      </div>
    </div>
  );
};

export default Accordion;
