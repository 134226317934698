import React, { Dispatch, ForwardedRef, ReactElement, forwardRef, useImperativeHandle } from 'react';
import { getCoreRowModel, useReactTable, flexRender } from '@tanstack/react-table';
import type { ColumnDef, Row } from '@tanstack/react-table';

import classes from './Table.module.css';
import BasicSmallSpinner from '../Spinners';
import clsx from 'clsx';

interface ReactTableProps<T extends Record<any, any>> {
  data: T[];
  columns: ColumnDef<T>[];
  loading: boolean;
  setRowSelection?: Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  rowSelection?: { [key: string]: boolean };
  bulkActions?: ReactElement<any, any>;
  onRowClick?: (row: Row<T>) => void;
}

const Table = forwardRef(
  <T extends Record<any, any>>(
    { data, columns, loading, setRowSelection, rowSelection, bulkActions, onRowClick }: ReactTableProps<T>,
    ref: ForwardedRef<any>,
  ) => {
    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      state: {
        rowSelection,
      },
      enableRowSelection: true,
      onRowSelectionChange: setRowSelection,
    });

    useImperativeHandle(ref, () => ({
      getSelectedRowModel: () => table.getSelectedRowModel(),
    }));

    const isSelectable = Object.keys(rowSelection || {}).length > 0;

    return (
      <div className={classes.Column}>
        <table className={classes.Table}>
          <thead className={classes.TableHeader}>
            {!isSelectable
              ? table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className={classes.TableHeading}
                        style={{ width: header.column.columnDef.size }}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))
              : table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className={classes.BulkActionsContainer}>
                    <th key={headerGroup.headers[0].id} className={clsx(classes.TableHeading, classes.SmallerPadding)}>
                      {headerGroup.headers[0].isPlaceholder
                        ? null
                        : flexRender(
                            headerGroup.headers[0].column.columnDef.header,
                            headerGroup.headers[0].getContext(),
                          )}
                    </th>
                    <th
                      className={clsx(classes.TableHeading, classes.SmallerPadding)}
                      colSpan={headerGroup.headers.length - 1}
                    >
                      {bulkActions}
                    </th>
                  </tr>
                ))}
          </thead>

          <tbody className={classes.TableBody}>
            {!loading &&
              data &&
              data.length > 0 &&
              table.getRowModel().rows.map((row) => (
                <tr key={row.id} className={classes.TableBodyRow} onClick={() => onRowClick && onRowClick(row)}>
                  {row.getVisibleCells().map((cell) => (
                    <td className={classes.TableData} key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
        {loading ? (
          <div className={classes.Loading}>
            <BasicSmallSpinner />
          </div>
        ) : (
          !loading &&
          data &&
          data.length === 0 && (
            <div className={classes.Loading}>
              <h5 className={classes.NoData}>No plans</h5>
            </div>
          )
        )}
      </div>
    );
  },
);

export default Table;
