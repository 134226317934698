import React, { FC, useState, useEffect } from 'react';
import GooglePlayBadge from 'assets/images/Google-play.svg';
import AppleStoreBadge from 'assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import AppGalleryBadge from 'assets/images/app-gallery.svg';
// import { ReactComponent as InstagramIcon } from 'assets/svgs/logo-instagram.svg';
import classes from './Footer.module.css';
import Logo from 'components/Logo';

const Footer: FC = () => {
  const [currentYear, setCurrentYear] = useState<number>();

  useEffect(() => {
    const date = new Date().getFullYear();
    setCurrentYear(date);
  }, []);

  return (
    <footer className={classes.Footer}>
      <div className={classes.FooterContainer}>
        <div>
          <div className={classes.Logo}>
            <Logo checkout link="https://www.coachd.ai/" />
          </div>
          <p className={classes.Paragraph}>Download the athlete app today</p>
          <div className={classes.PlayStoreBadges}>
            <a
              href="https://play.google.com/store/apps/details?id=ai.coachd.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePlayBadge} alt="Google play store badge" />
            </a>
            <a href="https://apps.apple.com/us/app/coachd/id6462992243" target="_blank" rel="noopener noreferrer">
              <img src={AppleStoreBadge} alt="Apple-store download badge" />
            </a>
            <a
              href="https://appgallery.huawei.com/app/C109038497?sharePrepath=ag"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppGalleryBadge} alt="App Gallery download badge" />
            </a>
          </div>
        </div>
        <div className={classes.FooterMiddle}>
          <p className={classes.Paragraph}>HAVE QUESTIONS? SAY HELLO!</p>
          <a href="mailto:support@coachd.ai" className={classes.Email}>
            support<span className={classes.AtInEmail}>@</span>coachd.ai
          </a>
        </div>
      </div>
      <div className={classes.FooterBottom}>
        <p>© {currentYear} Coachd (Pty) Ltd.</p>
        <div>
          <a
            href="https://docs.google.com/document/d/13WjD6seDfgcXDNzoZj_zmSRNZ0JCj0ZpdoR-vNh3ZaY/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
          <a
            href="https://docs.google.com/document/d/1ONQ_Vg7YTxa1jJ3oh3rD2Gr-Ny4imuOWrpoZ-3XemfY/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms &amp; conditions
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
