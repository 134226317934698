import React, { FC, ReactNode } from 'react';
import { Root, Indicator } from '@radix-ui/react-checkbox';
import classes from './Checkbox.module.css';
import { ReactComponent as CheckIcon } from 'assets/svgs/tick-outline.svg';

type Props = {
  label: ReactNode;
  name: string;
  onCheckedChange?: (val: boolean) => void;
  checked?: boolean;
};

const Checkbox: FC<Props> = ({ label, name, onCheckedChange, checked }) => (
  <div className={classes.FlexCenter}>
    <Root className={classes.CheckboxRoot} onCheckedChange={onCheckedChange} checked={checked} id={name}>
      <Indicator className={classes.CheckboxIndicator}>
        <CheckIcon />
      </Indicator>
    </Root>
    <label className={classes.Label} htmlFor={name}>
      {label}
    </label>
  </div>
);

export default Checkbox;
