import { UPDATE_CLIENT_STATUS } from './../actions/actionTypes';
import { ClientsActionTypes } from 'interfaces/actions/clients';
import { FirebaseObject } from 'interfaces/utils';
import { Clients, Profile } from 'interfaces/db';
import { ClientsState } from 'interfaces/state';
import { updateObject } from '../utility';
import {
  REMOVE_CLIENTS_START,
  REMOVE_CLIENTS_SUCCESS,
  REMOVE_CLIENTS_FAIL,
  FETCH_CLIENTS_START,
  FETCH_CLIENTS_SUCCESS,
  FETCH_CLIENTS_FAIL,
  SAVE_CLIENT_PROFILE_START,
  SAVE_CLIENT_PROFILE_SUCCESS,
  SAVE_CLIENT_PROFILE_FAIL,
} from '../actions/actionTypes';

const initialState: ClientsState = {
  clients: null,
  loading: false,
  removed: false,
};

const fetchClientsStart = (state: ClientsState): ClientsState => {
  return updateObject(state, { loading: true });
};

const fetchClientsSuccess = (state: ClientsState, action: { clients: FirebaseObject<Clients> }): ClientsState => {
  return updateObject(state, {
    clients: action.clients,
    loading: false,
  });
};

const fetchClientsFail = (state: ClientsState): ClientsState => {
  return updateObject(state, { loading: false });
};

const removeClientsStart = (state: ClientsState): ClientsState => {
  return updateObject(state, { loading: true, removed: true });
};

const removeClientsSuccess = (state: ClientsState, action: { clientKeys: string[] }): ClientsState => {
  const clientsCopy = { ...state.clients };

  action.clientKeys.forEach((clientKey) => {
    delete clientsCopy[clientKey];
  });

  return updateObject(state, {
    removed: true,
    loading: false,
    clients: clientsCopy,
  });
};

const removeClientsFail = (state: ClientsState): ClientsState => {
  return updateObject(state, { loading: false, removed: true });
};

const updateClientStatus = (
  state: ClientsState,
  action: {
    clientKey: string;
    status: number;
  },
): ClientsState => {
  if (!state.clients) {
    return state;
  }
  return updateObject(state, {
    clients: {
      ...state.clients,
      [action.clientKey]: {
        ...state.clients[action.clientKey],
        status: action.status,
      },
    },
  });
};

const saveClientProfileStart = (state: ClientsState): ClientsState => {
  return updateObject(state, { loading: true });
};

const saveClientProfileSuccess = (
  state: ClientsState,
  action: {
    clientKey: string;
    clientProfile: Profile;
  },
): ClientsState => {
  if (!state.clients) {
    return state;
  }

  return updateObject(state, {
    clients: {
      ...state.clients,
      [action.clientKey]: {
        ...state.clients[action.clientKey],
        profile: action.clientProfile,
      },
    },
    loading: false,
  });
};

const saveClientProfileFail = (state: ClientsState) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action: ClientsActionTypes): ClientsState => {
  switch (action.type) {
    case REMOVE_CLIENTS_START:
      return removeClientsStart(state);
    case REMOVE_CLIENTS_SUCCESS:
      return removeClientsSuccess(state, action);
    case REMOVE_CLIENTS_FAIL:
      return removeClientsFail(state);

    case FETCH_CLIENTS_START:
      return fetchClientsStart(state);
    case FETCH_CLIENTS_SUCCESS:
      return fetchClientsSuccess(state, action);
    case FETCH_CLIENTS_FAIL:
      return fetchClientsFail(state);

    case SAVE_CLIENT_PROFILE_START:
      return saveClientProfileStart(state);
    case SAVE_CLIENT_PROFILE_SUCCESS:
      return saveClientProfileSuccess(state, action);
    case SAVE_CLIENT_PROFILE_FAIL:
      return saveClientProfileFail(state);

    case UPDATE_CLIENT_STATUS:
      return updateClientStatus(state, action);

    default:
      return state;
  }
};
export default reducer;
