import React, { FC } from 'react';
import classes from './AnalyticCard.module.css';

interface Props {
  label: string;
  value?: number | string | JSX.Element;
  payout?: string;
}

const AnalyticCard: FC<Props> = ({ label, value, payout }) => (
  <div className={classes.Wrapper}>
    <span className={classes.Value}>{value || 0}</span>
    <div className={classes.LabelWrapper}>
      <h4 className={classes.Label}>{label || 'NO TITLE'}</h4>
      {payout && <div className={classes.Payout}>{payout}</div>}
    </div>
  </div>
);

export default AnalyticCard;
