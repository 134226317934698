import { NutritionWeek } from 'interfaces/nutrition';

export const getSortedWeeks = (weeks: NutritionWeek[], weekOrder: string[]): NutritionWeek[] => {
  if (!weeks || !weekOrder) return [];

  const orderMap = new Map<string, number>();
  weekOrder.forEach((id, index) => {
    orderMap.set(id, index);
  });

  const sortedWeeks = weeks.sort((a, b) => {
    const indexA = orderMap.get(a.id) ?? Number.MAX_SAFE_INTEGER;
    const indexB = orderMap.get(b.id) ?? Number.MAX_SAFE_INTEGER;
    return indexA - indexB;
  });

  return sortedWeeks;
};
