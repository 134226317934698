import React, { FC } from 'react';
import clsx from 'clsx';

import SetsColumn from './SetsColumn';
import classes from './Set.module.css';
import { emptySetObject } from 'utils/helpers';
import { SetObject, Set as SetType, SetKeys } from 'interfaces/db';
import { ReactComponent as TickIcon } from 'assets/svgs/tick-outline.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-outline.svg';

const MAX_SETS_LENGTH = 3;

interface Props {
  set: SetType;
  setNum: number;
  finished: boolean;
  ticked: boolean;
  setsChangeHandler: (setType: SetKeys, value: string) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  achievedTextColor: string;
  programmedTextColor: string;
}

const Set: FC<Props> = ({
  set,
  setNum,
  ticked,
  finished,
  setsChangeHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  achievedTextColor,
  programmedTextColor,
}) => {
  const { completed, ...otherSets } = set;

  const setsArray = (Object.keys(otherSets) as Array<keyof typeof otherSets>)
    .map((el) => {
      const setValue = otherSets[el];

      if (typeof setValue === 'string') {
        return { [el]: { ...emptySetObject } };
      }

      return { [el]: setValue };
    })
    .map((el, index) => {
      const [setKey] = Object.keys(el) as SetKeys[];
      return (
        <SetsColumn
          key={index}
          setNum={setNum}
          setKey={setKey}
          finished={finished}
          setsChangeHandler={setsChangeHandler}
          autoFillSetsHandler={autoFillSetsHandler}
          set={otherSets[setKey] as string | SetObject}
          setShowAutoFill={setShowAutoFill}
          showAutoFill={showAutoFill}
          achievedTextColor={achievedTextColor}
          programmedTextColor={programmedTextColor}
        />
      );
    });

  return (
    <li key={setNum} className={classes.TableRow}>
      <span className={!finished ? classes.TableSetNumber : classes.TableSetNumberFinished}>{setNum + 1}</span>

      {setsArray}

      {setsArray.length !== MAX_SETS_LENGTH && (
        <div className={clsx(classes.InputContainer, classes.TableInputEmpty)} />
      )}

      {finished &&
        (ticked ? (
          <div className={clsx(classes.FinishedSetIcons, classes.Tick)}>
            <TickIcon />
          </div>
        ) : (
          <div className={clsx(classes.FinishedSetIcons, classes.Cross)}>
            <CloseIcon />
          </div>
        ))}
    </li>
  );
};

export default Set;
