import { AxiosResponse } from 'axios';
import { CreateNutritionPlanDTO, UpdatePlanDTO } from 'interfaces/nutrition';
import { appAxios } from 'utils/api';

const headers = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const fetchNutritionPlanForClient = (clientUserId: string, token: string): Promise<AxiosResponse> => {
  return appAxios.get(`/v1-https-nutritionPlans-getPlanForClient?clientUserId=${clientUserId}`, headers(token));
};

export const fetchNutritionPlanById = (nutritionPlanId: string, token: string): Promise<AxiosResponse> => {
  return appAxios.get(`/v1-https-nutritionPlans-getPlanById/${nutritionPlanId}`, headers(token));
};

export const createNutritionPlan = (nutritionPlan: CreateNutritionPlanDTO, token: string): Promise<AxiosResponse> => {
  return appAxios.post(`/v1-https-nutritionPlans-createPlan`, nutritionPlan, headers(token));
};

export const updateNutritionPlan = (nutritionPlan: UpdatePlanDTO, token: string) => {
  return appAxios.put('/v1-https-nutritionPlans-updatePlan', nutritionPlan, headers(token));
};

export const removeNutritionPlanById = (nutritionPlanId: string, token: string) => {
  return appAxios.delete(`/v1-https-nutritionPlans-removePlanById?planId=${nutritionPlanId}`, headers(token));
};
