import React, { FC, useCallback, useState, useEffect, useRef, forwardRef } from 'react';
import { Formik, Form, Field, FormikHelpers, ErrorMessage, FieldInputProps, FormikProps, FieldMetaProps } from 'formik';
import { object, string, mixed } from 'yup';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import OutsideClickHandler from 'react-outside-click-handler';
import Textarea from 'react-textarea-autosize';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import { FormPropsPromise } from 'interfaces/utils';
import classes from './Community.module.css';
import { ChannelData } from 'containers/Community';
import { ReactComponent as SmileyIcon } from 'assets/svgs/happy-outline.svg';
import { ReactComponent as AttachmentIcon } from 'assets/svgs/attachment-outline.svg';
import { ReactComponent as SendIcon } from 'assets/svgs/send-filled.svg';

// const MAX_FILE_SIZE = 100000000; // 100 MB
// const ACCEPTED_FORMATS = ['application/pdf'];

type FormValues = {
  text: string;
  file: any;
  channel: ChannelData;
};

export interface FieldProps<V = any> {
  field: FieldInputProps<V>;
  form: FormikProps<V>; // if ppl want to restrict this for a given form, let them.
  meta: FieldMetaProps<V>;
}

const initialValues = { text: '', file: undefined, channel: { id: 'Chat-room' } };
const validationSchema = object().shape({
  text: string(),
  file: mixed(),
  // .test(
  //   'fileSize',
  //   'File too large',
  //   (value) => value && value[0] && value[0].size <= MAX_FILE_SIZE,
  // ),
  // .test(
  //   'fileFormat',
  //   'Unsupported Format',
  //   (value) =>
  //     value && value[0] && ACCEPTED_FORMATS.includes(value[0].type),
  // ),
});

const DragAndDrop: FC<{
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFileObj: (val: any) => void;
}> = ({ setFieldValue, setFileObj }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFieldValue('file', acceptedFiles);
      setFileObj(acceptedFiles[0]);
    },
    [setFieldValue, setFileObj],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div className={clsx(classes.dndWrapper, isDragActive && classes.activeWrapper)} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className={classes.activeDrag}>Drop the files here ...</p>
      ) : (
        <div className={classes.AttachButton}>
          <AttachmentIcon />
        </div>
      )}
    </div>
  );
};

type EmojiButtonProps = {
  onClick: () => void;
};

const EmojiButton = forwardRef<HTMLDivElement, EmojiButtonProps>(({ onClick }, ref) => {
  return (
    <div className={classes.EmojiButton} onClick={onClick} ref={ref}>
      <SmileyIcon />
    </div>
  );
});

const SendButton: FC<{
  submitForm: () => void;
}> = ({ submitForm }) => {
  return (
    <div onClick={submitForm} className={classes.SendButton}>
      <SendIcon />
    </div>
  );
};

const Community: FC<
  FormPropsPromise<FormValues> & {
    progress: number;
    handleSubmit: (payload: FormValues) => Promise<any>;
    setProgress: (value: number) => void;
    selectedChannel: ChannelData;
    themePreference: string;
  }
> = ({ handleSubmit, progress, setProgress, selectedChannel, themePreference = 'light' }) => {
  const [fileObj, setFileObj] = useState<File | null>(null);
  const [emojiPickerState, setEmojiPicker] = useState<boolean>(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef<HTMLDivElement>(null);

  let submitting = false;

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    if (!submitting) {
      submitting = true;

      handleSubmit({ ...values, channel: selectedChannel }).then(() => {
        actions.setSubmitting(false);
        actions.resetForm({});
        setFileObj(null);
        setProgress(0);
        submitting = false;
      });
    }
  };

  const MessageInputClass = fileObj !== null ? classes.MessageInputUpload : classes.MessageInput;

  const previewURL = fileObj !== null ? window.URL.createObjectURL(fileObj) : '';

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(previewURL);
    },
    [fileObj],
  );

  const handleKeyPress = (e: any, submitForm: () => void) => {
    if (e.key === 'Enter' && e.shiftKey) {
      submitForm();
    }
  };

  const handleEmojiButtonClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
      });
      setEmojiPicker(!emojiPickerState);
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ submitForm, setFieldValue, values, isSubmitting }) => (
        <Form className={classes.formWrapper}>
          <Field name="text">
            {({ meta, field }: FieldProps) => (
              <div
                className={MessageInputClass}
                onKeyDown={(e) => (!isSubmitting ? handleKeyPress(e, submitForm) : {})}
              >
                <Textarea id="text" placeholder="Send a message..." readOnly={isSubmitting} {...field} />
                {meta.touched && meta.error && <div className={classes.error}>{meta.error}</div>}
                {previewURL && (
                  <div className={classes.PreviewUpload}>
                    <div className={classes.ProgressWrapper}>
                      <div className={classes.ProgressBar} style={{ width: `${progress}%` }} />
                    </div>
                    {fileObj?.type?.includes('video') ? (
                      <video height="80" controls>
                        <source src={previewURL} />
                      </video>
                    ) : (
                      <img src={previewURL} alt="preview" />
                    )}
                  </div>
                )}
              </div>
            )}
          </Field>

          <ErrorMessage name="file">{(errMsg) => <p className={classes.error}>{errMsg}</p>}</ErrorMessage>

          {emojiPickerState && (
            <OutsideClickHandler disabled={!emojiPickerState} onOutsideClick={() => setEmojiPicker(false)}>
              <Picker
                style={{
                  position: 'absolute',
                  top: `${position.y - 426}px`,
                  left: `${position.x - 389}px`,
                  zIndex: 2,
                  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.1)',
                }}
                theme={themePreference === 'dark' ? 'dark' : 'light'}
                title="Pick your emoji..."
                emoji="point_up"
                onSelect={(emoji: any) => setFieldValue('text', values.text + emoji.native)}
              />
            </OutsideClickHandler>
          )}

          <div className={classes.ButtonsContainer}>
            <EmojiButton onClick={handleEmojiButtonClick} ref={buttonRef} />
            <DragAndDrop setFieldValue={setFieldValue} setFileObj={setFileObj} />
            <div className={classes.Divider}></div>
            <SendButton submitForm={submitForm} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Community;
