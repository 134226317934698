export const colors = {
  '--color-primary': '#1D3557',
  '--color-secondary': '#457B9D',

  '--color-accent-primary': '#7782F8',

  '--color-background': '#181818',
  '--color-menu-background': '#0B0B0B',

  '--color-text': '#1D3557',
  '--color-text-inverted': '#F1FAEE',
  '--color-border': '#333333',
  '--color-shadow': 'rgba(0, 0, 0, 0.2)',
};

export const typography = {
  '--font-family-regular': 'PlusJakartaSans-Regular, sans-serif',
  '--font-size-base': '16px',
};

export const spacing = {
  '--spacing-base': '1rem',
};

export const boxModel = {
  '--border-radius-small': '0.25rem',
  '--border-radius-base': '0.5rem',
  '--border-radius-large': '1rem',
  '--border-radius-xlarge': '2rem',
};

const dark = {
  ...colors,
  ...typography,
  ...spacing,
  ...boxModel,
};

export default dark;
