import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import classes from './SetGoals.module.css';
import { Button, Input, Select, TextArea } from 'components';
import Checkbox from 'components/UI/Checkbox/CheckboxForm';
import Accordion from 'components/UI/Accordion';
import ModalButtonGroup from 'components/ModalButtonGroup';
import {
  macrosArr,
  microsArr,
  numberOfWeeks,
  days,
  calculateGramsFromPercentage,
  calculateCaloriesFromGrams,
  calculateCaloriesFromPercentage,
  calculateTotalCalories,
  calculateGramsFromCalories,
  calculateMacroPercentages,
  onChangeVarianceHandler,
} from 'containers/Nutrition/helpers';
import PieChart from '../PieChart';
import ScheduleDays from '../ScheduleDays';
import { Macro, NutritionGoals } from 'interfaces/nutrition';
import { SelectOptionProps } from 'interfaces/ui';
import { ReactComponent as TickIcon } from 'assets/svgs/tick-outline.svg';
import { ReactComponent as CancelIcon } from 'assets/svgs/close-outline.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-filled.svg';

interface Props {
  defaultValues?: NutritionGoals;
  selectedDayIndex: number;
  listOfWeeks: SelectOptionProps[];
  handleSaveGoals: (nutritionGoals: NutritionGoals) => void;
  loading: boolean;
}

const SetGoals: FC<Props> = ({ defaultValues, selectedDayIndex, listOfWeeks, handleSaveGoals, loading }) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit: handleFormSubmit,
    getValues,
  } = useForm<NutritionGoals>({
    defaultValues,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);

  const dayTitle = watch(`title`);
  // TODO Split this up into different variables to improve readability
  const watchFields = watch([
    'macrosType',
    'macros.protein.percentage',
    'macros.carbs.percentage',
    'macros.fat.percentage',
    'macros.protein.grams',
    'macros.carbs.grams',
    'macros.fat.grams',
  ]);
  const numWeeks = watch('schedule.numberOfWeeks');
  const calories = watch('calories');
  const watchedCheckboxes = watch(['macros.protein.selected', 'macros.carbs.selected', 'macros.fat.selected']);
  const proteinCalories = watch('macros.protein.calories');
  const carbsCalories = watch('macros.carbs.calories');
  const fatCalories = watch('macros.fat.calories');

  const [openSelect, setOpenSelect] = useState<string | null>(null);
  const [activeDays, setActiveDays] = useState(new Array(days.length).fill(false));
  const [focusedField, setFocusedField] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [inputWidth, setInputWidth] = useState(215);
  const [hasDisabledMacro, setHasDisabledMacro] = useState(false);
  const [originalDayTitle, setOriginalDayTitle] = useState(dayTitle);
  const totalPercentage = Number(watchFields[1]) + Number(watchFields[2]) + Number(watchFields[3]);

  const macroKeys: Macro[] = ['protein', 'carbs', 'fat'];

  useEffect(() => {
    const arrayOfFalses = new Array(7).fill(false);
    arrayOfFalses[selectedDayIndex] = true;
    setActiveDays(arrayOfFalses);
  }, [selectedDayIndex]);

  const handleSelectOpenChange = (name: string, isOpen: boolean) => {
    if (isOpen) {
      setOpenSelect(name);
    } else if (openSelect === name) {
      setOpenSelect(null);
    }
  };

  const handleCheckboxChange = (macroName: string, checked: boolean) => {
    const name = macroName as Macro;
    setValue(`macros.${name}.selected`, checked);
    if (!checked) {
      clearErrors();
      setHasDisabledMacro(true);
      setValue(`macros.${name}.percentage`, 'N/A');
      setValue(`macros.${name}.grams`, 'N/A');
      setValue(`macros.${name}.calories`, 'N/A');
    } else {
      setValue(`macros.${name}.percentage`, 0);
      setValue(`macros.${name}.grams`, 0);
      setValue(`macros.${name}.calories`, 0);
    }
  };

  useEffect(() => {
    const hasDisabled = watchedCheckboxes.some((checkbox) => !checkbox);
    setHasDisabledMacro(hasDisabled);
  }, [watchedCheckboxes]);

  useEffect(() => {
    if (!hasDisabledMacro && totalPercentage !== 100) {
      setError('server' as any, {
        type: 'manual',
        message: 'The total percentage exceeds 100%',
      });
    } else {
      clearErrors();
    }
  }, [hasDisabledMacro]);

  const handleFocus = (name: string) => {
    setFocusedField(name);
  };

  const handlePercentageChange = (percentageInput: number | string, macroType: Macro) => {
    const regex = /^[0-9]*$/;
    let percentage = percentageInput;

    if (typeof percentageInput === 'string' && !regex.test(percentageInput)) {
      // If non-numeric characters are entered, reset the value
      percentage = Number(percentageInput.replace(/[^0-9]/g, ''));
      setValue(`macros.${macroType}.percentage`, percentage);
      return;
    }
    if (Number.isNaN(percentage)) return;
    const numPercentage = Number(percentage);
    const totalCalories = calories ?? 0;
    const grams = calculateGramsFromPercentage(numPercentage, macroType, totalCalories);
    setValue(`macros.${macroType}.grams`, grams);
    setValue(`macros.${macroType}.percentage`, numPercentage);
  };

  const handleGramsChange = (gramsInput: number | string, macroType: Macro) => {
    const regex = /^[0-9]*$/;
    let grams = gramsInput;

    if (typeof gramsInput === 'string' && !regex.test(gramsInput)) {
      // If non-numeric characters are entered, reset the value
      grams = Number(gramsInput.replace(/[^0-9]/g, ''));
      setValue(`macros.${macroType}.grams`, grams);
      return;
    }

    if (Number.isNaN(grams)) return;

    clearErrors();
    const numGrams = Number(grams);
    const macroCalories = calculateCaloriesFromGrams(numGrams, macroType);
    setValue(`macros.${macroType}.grams`, Number(grams));
    setValue(`macros.${macroType}.calories`, macroCalories);

    const proteinSelected = getValues('macros.protein.selected');
    const carbsSelected = getValues('macros.carbs.selected');
    const fatSelected = getValues('macros.fat.selected');

    let currentProteinCalories = proteinSelected ? proteinCalories : 0;
    let currentCarbsCalories = carbsSelected ? carbsCalories : 0;
    let currentFatCalories = fatSelected ? fatCalories : 0;

    // update the value for the changed macro type if it's selected
    if (macroType === 'protein' && proteinSelected) {
      currentProteinCalories = macroCalories;
    } else if (macroType === 'carbs' && carbsSelected) {
      currentCarbsCalories = macroCalories;
    } else if (macroType === 'fat' && fatSelected) {
      currentFatCalories = macroCalories;
    }

    // calculate total calories and macro percentages with the updated values
    const newTotalCalories = calculateTotalCalories(currentProteinCalories, currentCarbsCalories, currentFatCalories);
    const newMacroPercentages = calculateMacroPercentages(
      [currentProteinCalories as number, currentCarbsCalories as number, currentFatCalories as number],
      newTotalCalories,
    );

    // update the percentages for each selected macro
    macroKeys.forEach((type, index) => {
      if (getValues(`macros.${type}.selected`)) {
        setValue(`macros.${type}.percentage`, newMacroPercentages[index]);
      }
    });

    setValue('calories', newTotalCalories);
  };

  const handleMacroCaloriesChange = (caloriesInput: number | string, macroType: Macro) => {
    const regex = /^[0-9]*$/;
    let cals = caloriesInput;

    if (typeof caloriesInput === 'string' && !regex.test(caloriesInput)) {
      // If non-numeric characters are entered, reset the value
      cals = Number(caloriesInput.replace(/[^0-9]/g, ''));
      setValue(`macros.${macroType}.calories`, cals);
      return;
    }

    if (Number.isNaN(cals)) return;
    clearErrors();
    const numCalories = Number(cals);
    const grams = calculateGramsFromCalories(numCalories, macroType);

    setValue(`macros.${macroType}.grams`, grams);
    setValue(`macros.${macroType}.calories`, numCalories);

    const proteinSelected = getValues('macros.protein.selected');
    const carbsSelected = getValues('macros.carbs.selected');
    const fatSelected = getValues('macros.fat.selected');

    let currentProteinCalories = proteinSelected ? proteinCalories : 0;
    let currentCarbsCalories = carbsSelected ? carbsCalories : 0;
    let currentFatCalories = fatSelected ? fatCalories : 0;

    // update the value for the changed macro type if it's selected
    if (macroType === 'protein' && proteinSelected) {
      currentProteinCalories = numCalories;
    } else if (macroType === 'carbs' && carbsSelected) {
      currentCarbsCalories = numCalories;
    } else if (macroType === 'fat' && fatSelected) {
      currentFatCalories = numCalories;
    }

    // calculate total calories and macro percentages with the updated values
    const newTotalCalories = calculateTotalCalories(currentProteinCalories, currentCarbsCalories, currentFatCalories);
    const newMacroPercentages = calculateMacroPercentages(
      [currentProteinCalories as number, currentCarbsCalories as number, currentFatCalories as number],
      newTotalCalories,
    );

    // update the percentages for each selected macro
    macroKeys.forEach((type, index) => {
      if (getValues(`macros.${type}.selected`)) {
        setValue(`macros.${type}.percentage`, newMacroPercentages[index]);
      }
    });

    setValue('calories', newTotalCalories);
  };

  useEffect(() => {
    if (watchFields[0] === 'grams') return;
    const totalCalories = calories || 0;

    // Assuming you can get the selected status of each macro
    const isProteinSelected = getValues(`macros.protein.selected`);
    const isCarbsSelected = getValues(`macros.carbs.selected`);
    const isFatSelected = getValues(`macros.fat.selected`);

    // Only perform calculations and setValue for selected macros
    if (isProteinSelected) {
      const proteinPercentage = Number(watchFields[1] || 0);
      const proteinGrams = calculateGramsFromPercentage(proteinPercentage, 'protein', totalCalories);
      const proteinCalories = calculateCaloriesFromPercentage(totalCalories, proteinPercentage);
      setValue('macros.protein.grams', Number(proteinGrams));
      setValue('macros.protein.calories', proteinCalories);
    }

    if (isCarbsSelected) {
      const carbsPercentage = Number(watchFields[2] || 0);
      const carbsGrams = calculateGramsFromPercentage(carbsPercentage, 'carbs', totalCalories);
      const carbsCalories = calculateCaloriesFromPercentage(totalCalories, carbsPercentage);
      setValue('macros.carbs.grams', Number(carbsGrams));
      setValue('macros.carbs.calories', carbsCalories);
    }

    if (isFatSelected) {
      const fatPercentage = Number(watchFields[3] || 0);
      const fatGrams = calculateGramsFromPercentage(fatPercentage, 'fat', totalCalories);
      const fatCalories = calculateCaloriesFromPercentage(totalCalories, fatPercentage);
      setValue('macros.fat.grams', Number(fatGrams));
      setValue('macros.fat.calories', fatCalories);
    }
  }, [calories, getValues]);

  useEffect(() => {
    clearErrors();

    const proteinSelected = getValues('macros.protein.selected');
    const carbsSelected = getValues('macros.carbs.selected');
    const fatSelected = getValues('macros.fat.selected');

    const totalCalories = calories || 0;
    let totalSelectedPercentage = 0;

    // Calculate percentages for selected macros only
    const proteinPercentage = proteinSelected ? Number(watchFields[1] || 0) : 0;
    const carbsPercentage = carbsSelected ? Number(watchFields[2] || 0) : 0;
    const fatPercentage = fatSelected ? Number(watchFields[3] || 0) : 0;

    totalSelectedPercentage += proteinSelected ? proteinPercentage : 0;
    totalSelectedPercentage += carbsSelected ? carbsPercentage : 0;
    totalSelectedPercentage += fatSelected ? fatPercentage : 0;

    if (!hasDisabledMacro) {
      if (totalSelectedPercentage > 100) {
        setError(focusedField as any, {
          type: 'manual',
          message: 'The total percentage exceeds 100%',
        });
      } else if (totalSelectedPercentage < 100) {
        setError(focusedField as any, {
          type: 'manual',
          message: `${100 - totalSelectedPercentage}% remaining`,
        });
      } else {
        clearErrors();
      }
    }

    // Set the calories for each macro or "N/A" if not selected
    setValue(
      'macros.protein.calories',
      proteinSelected ? Math.round((totalCalories * proteinPercentage) / 100) : 'N/A',
    );
    setValue('macros.carbs.calories', carbsSelected ? Math.round((totalCalories * carbsPercentage) / 100) : 'N/A');
    setValue('macros.fat.calories', fatSelected ? Math.round((totalCalories * fatPercentage) / 100) : 'N/A');
  }, [watchFields[0], watchFields[1], watchFields[2], watchFields[3], setError, clearErrors]);

  useEffect(() => {
    setDisabled(!!Object.keys(errors).length);
  }, [errors]);

  const macros = macrosArr.map((macro) => (
    <Checkbox
      key={macro.name + 'checkbox'}
      name={`macros.${macro.name}.selected`}
      control={control}
      label={macro.label}
      onCheckedChange={(checked) => handleCheckboxChange(macro.name, checked)}
    />
  ));

  const micros = microsArr.map((micro) => (
    <Checkbox key={micro.name} name={micro.name} control={control} label={micro.title} />
  ));

  const macroBreakdown = macrosArr.map((macro, index) => {
    const isSelected = watchedCheckboxes[index];
    return (
      <div key={macro.label} className={clsx(classes.FlexContainer, classes.SpaceBetween)}>
        <div className={classes.KeyPieChart} style={{ backgroundColor: macro.color }} />
        <span className={clsx(classes.MacroLabel, !isSelected && classes.Disabled)}>{macro.label}</span>
        <div className={classes.MacroBreakdownInput}>
          <Input
            register={register}
            name={`macros.${macro.name}.percentage`}
            placeholder={macro.placeholder}
            readOnly={watchFields[0] === 'grams' || !isSelected}
            onChange={(e) => handlePercentageChange(e.target.value, macro.name as Macro)}
            showError={false}
            required={false}
            onFocus={handleFocus}
            className={classes.CenterInputText}
          />
        </div>
        <div className={classes.MacroBreakdownInput}>
          <Input
            register={register}
            name={`macros.${macro.name}.grams`}
            placeholder={'0g'}
            readOnly={watchFields[0] === 'percentage' || !isSelected}
            onChange={(e) => handleGramsChange(e.target.value, macro.name as Macro)}
            required={false}
            onFocus={handleFocus}
            showError={false}
            className={classes.CenterInputText}
          />
        </div>
        <div className={classes.MacroBreakdownInput}>
          <Input
            register={register}
            name={`macros.${macro.name}.calories`}
            placeholder={'0g'}
            readOnly={watchFields[0] === 'percentage' || !isSelected}
            onChange={(e) => handleMacroCaloriesChange(e.target.value, macro.name as Macro)}
            required={false}
            onFocus={handleFocus}
            showError={false}
            className={classes.CenterInputText}
          />
        </div>
      </div>
    );
  });

  const accordionItems = [
    {
      id: 'calories-macros',
      title: 'Calories & Macros',
      content: (
        <div className={classes.CaloriesMacrosContainer}>
          <p className={classes.MacrosHeading}>How would you like to set your macros?</p>
          <div className={clsx(classes.FlexContainer, classes.MacrosContainer)}>
            <div>
              <div>
                <div className={classes.MacrosTypeWidth}>
                  <Select
                    name="macrosType"
                    optionArray={[
                      { value: 'percentage', label: 'By Percentage' },
                      { value: 'grams', label: 'By Grams' },
                    ]}
                    required
                    control={control}
                    defaultValue="percentage"
                    value={watchFields[0]}
                    onOpenChange={(isOpen) => handleSelectOpenChange('macrosType', isOpen)}
                    open={openSelect === 'macrosType'}
                    placeholder="Select your macros type"
                  />
                </div>
              </div>
              <div className={classes.MacroInputsContainer}>
                <div className={clsx(classes.FlexContainer, classes.SpaceBetween)}>
                  <span className={clsx(classes.MacroLabel, classes.InputLabel)}>Calories Goal</span>
                  <div className={classes.TopLevelInputs}>
                    <Input
                      register={register}
                      name="calories"
                      placeholder="kCal"
                      type="number"
                      valueAsNumber
                      required={false}
                      readOnly={watchFields[0] === 'grams'}
                    />
                  </div>
                </div>
                <div className={clsx(classes.FlexContainer, classes.SpaceBetween)}>
                  <span className={clsx(classes.MacroLabel, classes.InputLabel)}>Variance</span>
                  <div className={classes.TopLevelInputs}>
                    <Input
                      register={register}
                      name="variance"
                      placeholder="%"
                      required={false}
                      type="number"
                      valueAsNumber
                      onChange={(e) => onChangeVarianceHandler(e, setValue)}
                    />
                  </div>
                </div>
              </div>
              <p className={classes.SmallMediumText}>
                Select which macros you&#39;d like <br />
                your client to track.
              </p>
            </div>
            <div className={clsx(classes.FlexContainer, classes.PieContainer)}>
              <PieChart watchFields={watchFields} disabled={hasDisabledMacro} />
              <div className={classes.GridContainer}>
                <div>
                  <div className={classes.GridHeading}>
                    <div></div>
                    <span>%</span>
                    <span>Grams</span>
                    <span>kCal</span>
                  </div>
                </div>
                <div className={classes.Grid}>{macroBreakdown}</div>
                {!hasDisabledMacro ? (
                  <p className={classes.TotalPercentage}>
                    Total:{' '}
                    <span className={clsx(totalPercentage !== 100 && classes.ErrorPercentage)}>{totalPercentage}%</span>
                  </p>
                ) : (
                  <p className={classes.TotalPercentage}></p>
                )}
              </div>
            </div>
          </div>
          <div className={classes.MacrosCheckboxes}>{macros}</div>
        </div>
      ),
    },
    {
      id: 'other-nutrients',
      title: 'Other Nutrients',
      content: <div className={classes.MicrosContainer}>{micros}</div>,
    },
    {
      id: 'schedule',
      title: 'Schedule',
      content: (
        <div>
          <div className={clsx(classes.FlexContainer, classes.OneRemGap)}>
            <span>Start on</span>
            <div>
              <Select
                name="schedule.startWeek"
                optionArray={listOfWeeks}
                required
                control={control}
                defaultValue={listOfWeeks[0].value}
                value={watch('schedule.startWeek')}
                onOpenChange={(isOpen) => handleSelectOpenChange('schedule.startWeek', isOpen)}
                open={openSelect === 'schedule.startWeek'}
                placeholder="Select your schedule type"
              />
            </div>
            <span>For</span>
            <div>
              <Select
                name="schedule.numberOfWeeks"
                optionArray={numberOfWeeks}
                required
                control={control}
                defaultValue="1"
                onOpenChange={(isOpen) => handleSelectOpenChange('schedule.numberOfWeeks', isOpen)}
                open={openSelect === 'schedule.numberOfWeeks'}
                placeholder="1"
              />
            </div>
            <span>{`Week${+numWeeks > 1 ? 's' : ''}`}</span>
          </div>
          <div className={classes.DaysToPractice}>
            <span>Days to practice</span>
            <ScheduleDays days={days} activeDays={activeDays} setActiveDays={setActiveDays} />
          </div>
        </div>
      ),
    },
    {
      id: 'notes',
      title: 'Add Notes',
      content: (
        <div>
          <TextArea name="notes" register={register} placeholder="My notes" defaultValue="" />
        </div>
      ),
    },
  ];

  const onSubmit = (data: NutritionGoals) => {
    const updatedData: NutritionGoals = {
      ...data,
      schedule: {
        ...data.schedule,
        days: activeDays,
      },
    };

    handleSaveGoals(updatedData);
  };

  const handleEdit = () => {
    setIsEditingTitle(true);
    inputRef.current?.focus();
  };

  const handleCancel = () => {
    setValue(`title`, originalDayTitle);
    const textWidth = calculateTextWidth(originalDayTitle || 'Nutrition Day Title');
    if (textWidth) setInputWidth(textWidth);
    setIsEditingTitle(false);
  };

  const handleAcceptTitle = () => {
    setOriginalDayTitle(dayTitle);
    setIsEditingTitle(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(`title`, e.target.value);
  };

  const calculateTextWidth = (text: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context || !inputRef.current) return;

    context.font = getComputedStyle(inputRef.current).font;
    return context.measureText(text).width + 7;
  };

  useEffect(() => {
    const textWidth = calculateTextWidth(dayTitle || 'Nutrition Day Title');
    if (textWidth) setInputWidth(textWidth);
  }, [dayTitle]);

  useEffect(() => {
    if (isEditingTitle && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditingTitle, inputRef]);

  const { onChange, name, ref } = register(`title`, {
    onChange: (e) => handleChange(e),
  });

  const handleSubmitForm = () => {
    handleFormSubmit(onSubmit)();
  };

  return (
    <>
      <div className={classes.FormWrapper}>
        <form id="setGoalsForm" onSubmit={handleFormSubmit(onSubmit)} className={classes.Form}>
          <div className={classes.DayTitleWrapper}>
            {isEditingTitle ? (
              <input
                type="text"
                name={name}
                onChange={onChange}
                style={{ width: `${inputWidth}px` }}
                className={classes.DayTitleInput}
                ref={(e) => {
                  ref(e);
                  inputRef.current = e;
                }}
              />
            ) : (
              <div className={classes.UneditableTitleWrapper} onClick={handleEdit}>
                <h3 className={clsx(classes.DayTitleInput, classes.Title)}>{dayTitle}</h3>
                <EditIcon />
              </div>
            )}
            {isEditingTitle && (
              <div className={classes.TitleActionButtons}>
                <Button type="button" onClick={handleAcceptTitle} size="xsmall" iconCenter={<TickIcon />} />
                <Button
                  type="button"
                  onClick={handleCancel}
                  size="xsmall"
                  iconCenter={<CancelIcon />}
                  intent="tertiary"
                />
              </div>
            )}
          </div>
          <Accordion items={accordionItems} defaultValue="calories-macros" collapsible={false} />
        </form>
      </div>
      <div className={classes.ButtonGroup}>
        <ModalButtonGroup
          loading={loading}
          onMainButtonClick={handleSubmitForm}
          mainButtonText="SAVE DAILY NUTRITION"
          showCancelButton={false}
          mainButtonDisabled={disabled}
        />
      </div>
    </>
  );
};

export default SetGoals;
