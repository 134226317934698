import React, { FC, useState, useEffect } from 'react';
import classes from './ChatSettings.module.css';
import { ChatSettings as ChatSettingsType } from 'interfaces/db';
import ModalButtonGroup from 'components/ModalButtonGroup';
import ToggleSettings from '../ToggleSettings';

interface Props {
  chatSettings: ChatSettingsType;
  saveSettings: (chatSettings: ChatSettingsType) => void;
  cancel: () => void;
  loading: boolean;
}

const ChatSettings: FC<Props> = ({ chatSettings, saveSettings, cancel, loading }) => {
  const [enabledCommunity, setEnabledCommunity] = useState(chatSettings.isCommunityChatEnabled);
  const [enabledDirectMessages, setEnabledDirectMessages] = useState(chatSettings.isDirectChatEnabled);
  const [settingsChanged, setSettingsChanged] = useState(false);

  useEffect(() => {
    // Check if chat settings have been modified
    if (
      enabledCommunity !== chatSettings.isCommunityChatEnabled ||
      enabledDirectMessages !== chatSettings.isDirectChatEnabled
    ) {
      setSettingsChanged(true);
    } else {
      setSettingsChanged(false);
    }
  }, [enabledCommunity, enabledDirectMessages, chatSettings]);

  const toggleCommunity = () => {
    setEnabledCommunity((enabledCommunity) => !enabledCommunity);
  };

  const toggleDirectMessages = () => {
    setEnabledDirectMessages((enabledDirectMessages) => !enabledDirectMessages);
  };

  return (
    <div className={classes.Container}>
      <ToggleSettings
        enabledCommunity={enabledCommunity}
        toggleCommunity={toggleCommunity}
        enabledDirectMessages={enabledDirectMessages}
        toggleDirectMessages={toggleDirectMessages}
      />

      <ModalButtonGroup
        mainButtonText="Save"
        mainButtonDisabled={!settingsChanged}
        onMainButtonClick={() =>
          saveSettings({
            isCommunityChatEnabled: enabledCommunity,
            isDirectChatEnabled: enabledDirectMessages,
          })
        }
        secondaryButtonText="Cancel"
        onSecondaryClick={cancel}
        loading={loading}
      />
    </div>
  );
};

export default ChatSettings;
