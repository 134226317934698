import React, { FC } from 'react';
import AlertDialog from 'components/UI/AlertDialog';
import Button from 'components/UI/Button';

interface Props {
  openModal: boolean;
  setModalShow: (value: boolean) => void;
  discardChanges: () => void;
}

const ForgotToSaveDialog: FC<Props> = ({ openModal, setModalShow, discardChanges }) => {
  return (
    <AlertDialog
      title="Did you forget to save?"
      description="You have unsaved changes. Do you want to stay?"
      open={openModal}
      onOpenChange={setModalShow}
      cancelButton={
        <Button intent="secondary" onClick={discardChanges}>
          Discard changes
        </Button>
      }
      actionButton={<Button onClick={() => setModalShow(false)}>Stay</Button>}
    />
  );
};

export default ForgotToSaveDialog;
