import axios from 'axios';

import { FETCH_EXERCISES_START, FETCH_EXERCISES_SUCCESS, FETCH_EXERCISES_FAIL } from './actionTypes';
import { AppThunk } from '../index';
import { Exercise } from 'interfaces/db';
import { config } from 'utils/firebase';
import { FirebaseObject } from 'interfaces/utils';
import { ExerciseLibraryActionTypes } from 'interfaces/actions/exerciseLibrary';
import { exerciseLibraryTrackingService } from 'utils/tracking/exerciseLibraryService';

const fetchExercisesStart = (): ExerciseLibraryActionTypes => {
  return {
    type: FETCH_EXERCISES_START,
  };
};

const fetchExercisesSuccess = (exercises: FirebaseObject<Exercise>): ExerciseLibraryActionTypes => {
  return {
    type: FETCH_EXERCISES_SUCCESS,
    exercises,
  };
};

const fetchExercisesFail = (): ExerciseLibraryActionTypes => {
  return {
    type: FETCH_EXERCISES_FAIL,
  };
};

export const fetchExercises = (token: string, coachKey: string): AppThunk => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchExercisesStart());
      const queryParams = '?auth=' + token;

      axios
        .get(`${config.databaseURL}/exerciseLibrary/${coachKey}.json${queryParams}`)
        .then((response) => {
          dispatch(fetchExercisesSuccess(response.data));
          resolve(response.data);
        })
        .catch(() => {
          dispatch(fetchExercisesFail());
          reject(new Error('Failed to fetch exercises'));
        });
    });
  };
};

export const saveExercise = (
  token: string,
  coachKey: string,
  exercise: Exercise,
  location: 'exercise_library' | 'workout_builder',
): AppThunk => {
  return (dispatch) => {
    const queryParams = '?auth=' + token;

    axios
      .post(`${config.databaseURL}/exerciseLibrary/${coachKey}.json${queryParams}`, exercise)
      .then((response) => {
        dispatch(fetchExercises(token, coachKey));
        const exerciseKey = response.data.name;
        exerciseLibraryTrackingService.trackExerciseLibraryEvent('exercise_library_save', {
          location: location,
          exercise_title: exercise.type,
          exercise_id: exerciseKey,
          exercise_video_url: exercise?.videoURL || '',
          author: 'coach',
        });
      })
      .catch((e) => console.log(e.toString()));
  };
};

export const updateExercise = (token: string, coachKey: string, exerciseKey: string, exercise: Exercise): AppThunk => {
  return async (dispatch) => {
    const queryParams = '?auth=' + token;

    await axios
      .patch(`${config.databaseURL}/exerciseLibrary/${coachKey}/${exerciseKey}.json${queryParams}`, exercise)
      .then(async () => {
        await dispatch(fetchExercises(token, coachKey));
      })
      .catch((e) => console.log(e.toString()));
  };
};

export const deleteExercises = (token: string, coachKey: string, exerciseKeys: string[]): AppThunk => {
  return async (dispatch) => {
    const queryParams = '?auth=' + token;

    const deletePromises = exerciseKeys.map((exerciseKey) =>
      axios.delete(`${config.databaseURL}/exerciseLibrary/${coachKey}/${exerciseKey}.json${queryParams}`),
    );

    await Promise.all(deletePromises)
      .then(() => {
        dispatch(fetchExercises(token, coachKey));
      })
      .catch((e) => console.log(e.toString()));
  };
};
