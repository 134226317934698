import React, { forwardRef, RefObject, Ref } from 'react';

import classes from './KebabMenu.module.css';
import { ReactComponent as KebabIcon } from 'assets/svgs/kebab-filled.svg';

interface Props {
  onClick: (e) => void;
  ref?: Ref<HTMLButtonElement>;
}

const KebabMenu = forwardRef<HTMLDivElement, Props>(({ onClick }, ref) => {
  return (
    <div className={classes.DotsContainer} onClick={onClick} ref={ref as RefObject<HTMLDivElement>}>
      <KebabIcon />
    </div>
  );
});

export default KebabMenu;
