import { MixpanelService, EventProperties } from './mixpanelService';

export type NutritionEventProperties = EventProperties & {
  location: 'nutrition';
};

class NutritionTrackingService extends MixpanelService {
  public trackNutritionEvent(eventName: string, properties?: NutritionEventProperties): void {
    super.trackEvent(eventName, properties);
  }
}

export const nutritionTrackingService = new NutritionTrackingService();
