import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import clsx from 'clsx';
import { Button, Input } from 'components/UI';

import classes from './Checkout.module.css';
import { useYupValidationResolver } from 'hooks';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

const validationSchema = object().shape({
  firstName: string().max(50, 'First name is too long').required('First name is required'),
  lastName: string().max(50, 'Last name is too long').required('Last name is required'),
  email: string().email('Must be a valid email').required('Email is required'),
});

const PaymentForm: FC<{
  handleSubmitForm: (payload: FormValues) => Promise<void>;
  loading: boolean;
}> = ({ handleSubmitForm, loading }) => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  const onSubmit = async (values: FormValues) => {
    await handleSubmitForm(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.Wrapper}>
      <div className={classes.FullNameWrapper}>
        <div className={clsx(classes.InputWrapper, classes.NameInput)}>
          <Input
            type="text"
            name="firstName"
            register={register}
            error={errors.firstName}
            placeholder="Enter first name"
            label="First Name"
          />
        </div>
        <div className={clsx(classes.InputWrapper, classes.NameInput)}>
          <Input
            type="text"
            name="lastName"
            register={register}
            error={errors.lastName}
            placeholder="Enter last name"
            label="Last Name"
          />
        </div>
      </div>

      <div className={classes.InputWrapper}>
        <Input
          type="text"
          name="email"
          label="Email Address"
          register={register}
          error={errors.email}
          placeholder="Enter email address"
        />
      </div>

      <Button type="submit" loading={loading} disabled={isSubmitting}>
        CHECKOUT
      </Button>
    </form>
  );
};

export default PaymentForm;
