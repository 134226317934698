import React, { FC } from 'react';
import Header from 'components/UI/Header';
import Sidebar from 'components/Navigation/Sidebar';
import classes from './ClientProfileLayout.module.css';

type Props = {
  heading: string;
  loading: boolean;
  children: React.ReactNode;
};

const ClientProfileLayout: FC<Props> = ({ heading, loading, children }) => {
  return (
    <div className={classes.Container}>
      <div className={classes.Header}>
        <Header heading={heading} loading={loading} viewType="client" />
      </div>

      <div className={classes.ContentArea}>
        <div className={classes.Sidebar}>
          <Sidebar viewType="client" />
        </div>
        <main className={classes.MainContent}>{children}</main>
      </div>
    </div>
  );
};

export default ClientProfileLayout;
