import React from 'react';
import IndeterminateCheckbox from '../IndeterminateCheckbox';

const SelectCheckbox = () => ({
  accessorKey: 'select',
  header: ({ table }) => (
    <IndeterminateCheckbox
      checked={table.getIsAllRowsSelected()}
      indeterminate={table.getIsSomeRowsSelected()}
      onChange={table.getToggleAllRowsSelectedHandler()}
    />
  ),
  cell: ({ row }) => (
    <div onClick={(e) => e.stopPropagation()}>
      <IndeterminateCheckbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        indeterminate={row.getIsSomeSelected()}
        onChange={(e) => {
          e.stopPropagation();
          row.getToggleSelectedHandler()(e);
        }}
      />
    </div>
  ),
});

export default SelectCheckbox;
