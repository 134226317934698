import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { object, string, array } from 'yup';

import { useYupValidationResolver } from 'hooks';
import Select from 'components/UI/Select';
import { Exercise, SetKeys } from 'interfaces/db';
import { setKeysMap, emptySetObject, MAX_SETS_LENGTH, setKeysObject } from 'utils/helpers';
import { ExerciseLibInitialValues, FormProps } from 'interfaces/utils';
import classes from './ExerciseLibrary.module.css';
import Button from 'components/UI/Button';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import ModalButtonGroup from 'components/ModalButtonGroup';
import Input from 'components/UI/Input';
import TextArea from 'components/UI/TextArea';

const validationSchema = object().shape({
  type: string().required('Title is a required field'),
  videoURL: string().trim(),
  sets: array(string()).min(1).max(3).required(),
  exerciseNotes: object().shape({
    coach: string().trim(),
  }),
});

const ExerciseLibForm: FC<
  FormProps<Exercise> & { initialValues: ExerciseLibInitialValues; setModalOpen: (value) => void } & {
    loading?: boolean;
  }
> = ({ handleSubmit, initialValues, setModalOpen, loading }) => {
  const {
    register,
    control,
    handleSubmit: handleFormSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<ExerciseLibInitialValues>({
    defaultValues: initialValues,
    resolver: useYupValidationResolver(validationSchema),
  });

  const [openSelect, setOpenSelect] = useState<string | null>(null);

  const onSubmit = async (values: ExerciseLibInitialValues) => {
    const { type, videoURL, sets: preSets } = values;
    const sets = preSets.reduce(
      (acc, cur, index) => ({
        ...acc,
        [cur]: { ...emptySetObject, index: index + 1 },
      }),
      { completed: false },
    );

    const value = {
      type,
      videoURL,
      sets: [sets],
      exerciseNotes: {
        coach: values.exerciseNotes?.coach || '',
        athlete: '',
      },
    };

    handleSubmit(value);
  };

  const { sets } = watch();

  const handleSelectOpenChange = (name: string, isOpen: boolean) => {
    if (isOpen) {
      setOpenSelect(name);
    } else if (openSelect === name) {
      setOpenSelect(null);
    }
  };

  const options = useCallback(() => {
    return setKeysMap.filter((el) => !(sets || []).includes(el.value));
  }, [sets]);

  const removeTypeChangeHandler = (index: number) => {
    const updatedSets = (sets || []).filter((_, i) => i !== index);
    setValue('sets', updatedSets);
  };

  const setTypeChangeHandler = (value: SetKeys, index: number) => {
    const updatedSets = (sets || []).map((el, i) => {
      if (i === index) {
        return value;
      }
      return el;
    });
    setValue('sets', updatedSets);
  };

  return (
    <form onSubmit={handleFormSubmit(onSubmit)} className={classes.formWrapper}>
      <div className={classes.InputWrapper}>
        <Input
          type="text"
          name="type"
          placeholder="Enter an exercise title"
          register={register}
          label="Exercise title"
          error={errors.type}
        />
      </div>

      <div className={classes.InputWrapper}>
        <Input
          type="text"
          name="videoURL"
          placeholder="Enter video URL"
          register={register}
          label="Video URL &#40;Optional&#41;"
          error={errors.videoURL}
          required={false}
        />
      </div>

      <div className={classes.InputWrapper}>
        <p className={classes.Label}>Parameters</p>

        <div className={classes.SelectsRowWrapper}>
          {(sets || []).map((paramValue, index) => {
            const selected = setKeysObject[paramValue];
            return (
              <div key={paramValue} className={classes.SelectWrapper}>
                <Select
                  name={paramValue}
                  placeholder="Type"
                  control={control}
                  onOpenChange={(isOpen) => handleSelectOpenChange(paramValue, isOpen)}
                  open={openSelect === paramValue}
                  optionArray={[selected, ...options(), { label: '-Remove-', value: 'remove_option' }]}
                  required
                  onChange={(val: any) => {
                    if (val && val === 'remove_option') {
                      removeTypeChangeHandler(index);
                    } else {
                      val ? setTypeChangeHandler(val, index) : removeTypeChangeHandler(index);
                    }
                  }}
                  defaultValue={paramValue}
                  error={errors.sets}
                />
              </div>
            );
          })}

          {(sets || []).length !== MAX_SETS_LENGTH && (
            <div className={clsx(classes.sets, classes.btnCentralize)}>
              <Button
                type="button"
                size="small"
                onClick={() => {
                  const [newSet] = options();
                  setValue('sets', [...(sets || []), newSet.value]);
                }}
                iconCenter={<PlusIcon />}
              />
            </div>
          )}
        </div>
      </div>

      <div className={classes.InputWrapper}>
        <TextArea
          label="Exercise notes"
          register={register}
          name="exerciseNotes.coach"
          placeholder="Add exercise notes..."
          defaultValue={initialValues?.exerciseNotes?.coach || ''}
        />
      </div>

      <ModalButtonGroup
        mainButtonText="Save Exercise"
        mainButtonType="submit"
        secondaryButtonText="Cancel"
        onSecondaryClick={() => setModalOpen(false)}
        loading={loading}
      />
    </form>
  );
};

export default ExerciseLibForm;
