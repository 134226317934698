import React, { FC } from 'react';
import clsx from 'clsx';

import classes from './ScheduleDays.module.css';
import { Button } from 'components';

interface Props {
  days: string[];
  activeDays: boolean[];
  setActiveDays: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const ScheduleDays: FC<Props> = ({ days, activeDays, setActiveDays }) => {
  const toggleDay = (index: number) => {
    const updatedActiveDays = [...activeDays];
    updatedActiveDays[index] = !updatedActiveDays[index];
    setActiveDays(updatedActiveDays);
  };

  return (
    <div className={classes.DayContainer}>
      {days.map((day, index) => (
        <button
          key={index}
          type="button"
          className={clsx(classes.DayButton, activeDays[index] && classes.Active)}
          onClick={() => toggleDay(index)}
        >
          {day}
        </button>
      ))}
      <div className={classes.EveryDayButton}>
        <Button type="button" intent="secondary" onClick={() => setActiveDays(new Array(days.length).fill(true))}>
          EVERYDAY
        </Button>
      </div>
    </div>
  );
};

export default ScheduleDays;
