import { MixpanelService, EventProperties } from './mixpanelService';

export type ExerciseLibraryEventProperties = EventProperties & {
  location: 'exercise_library' | 'workout_builder';
  exercise_title?: string;
  exercise_id?: string;
  author?: 'coach' | 'admin';
  exercise_video_url?: string;
};

class ExerciseLibraryTrackingService extends MixpanelService {
  public trackExerciseLibraryEvent(eventName: string, properties?: ExerciseLibraryEventProperties): void {
    super.trackEvent(eventName, properties);
  }
}

export const exerciseLibraryTrackingService = new ExerciseLibraryTrackingService();
