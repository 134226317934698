import { NutritionPlan } from 'interfaces/nutrition';
import { useState } from 'react';

type UseDeletedWeeksReturn = {
  deletedWeeks: string[];
  addDeletedWeek: (weekId: string) => void;
  clearDeletedWeeks: () => void;
};

export const deleteWeek = (
  weekId: string,
  plan: NutritionPlan,
  setPlan: React.Dispatch<React.SetStateAction<NutritionPlan>>,
  addDeletedWeek: (weekId: string) => void,
) => {
  if (!plan) {
    console.warn('No plan provided to deleteWeek helper function');
    return;
  }

  const weeks = plan.weeks?.filter((week) => week.id !== weekId) || [];
  const weekOrder = plan.weekOrder.filter((id) => id !== weekId);
  const updatedPlan = {
    ...plan,
    weekOrder,
    weeks,
  };

  setPlan(updatedPlan);
  addDeletedWeek(weekId);
};

export const useDeletedWeeks = (): UseDeletedWeeksReturn => {
  const [deletedWeeks, setDeletedWeeks] = useState<string[]>([]);

  const addDeletedWeek = (weekId: string) => {
    setDeletedWeeks((prev) => [...prev, weekId]);
  };

  const clearDeletedWeeks = () => {
    setDeletedWeeks([]);
  };

  return { deletedWeeks, addDeletedWeek, clearDeletedWeeks };
};
