type Theme = {
  [key: string]: string;
};
export const colors: Theme = {
  '--color-text-on-accent': '#FFFFFF',

  '--color-accent-primary': '#7782F8',
  '--color-accent-primary-hover': '#5964DA',
  '--color-accent-primary-light': '#C1C5F4',

  '--color-exercise-group-badge': '#FFFFFF',
  '--color-exercise-group-badge-text': '#404040',

  '--color-no-image-background': '#404040',

  '--color-icon-stroke': '#404040',
  '--color-icon-stroke-disabled': '#DBDBDB',
  '--color-icon-wrapper-hover': '#DBDBDB',
  '--color-icon-wrapper-active': 'var(--color-accent-primary)',
  '--color-icon-stroke-active': 'var(--color-text-on-accent)',

  '--color-text-navitem': '#404040',
  '--color-text-navitem-active': '#FFFFFF',
  '--color-navitem-background-hover': '#DBDBDB',

  '--color-background': '#F3F4F5',
  '--color-background-secondary': '#DBDBDB',
  '--color-menu-background': '#EDEFF1',
  '--color-card-background': '#FFFFFF',

  '--color-divider': '#DBDBDB',

  '--color-text-primary': '#181818',
  '--color-text-secondary': '#959595',

  '--color-text-primary-hover': '#7782F8',
  '--color-text-inverted': '#F1FAEE',
  '--color-border': '#E1E1E1',

  '--color-success': '#40C463',
  '--color-danger': '#FF5050',
  '--color-complete': '#49a3e4',

  '--color-success-light': 'rgba(64, 196, 99, 0.10);',

  '--color-box-shadow': '0px 4px 20px 0px rgba(0, 0, 0, 0.1)',
};

export const inputs = {
  '--color-text-input-label': '#181818',

  '--color-text-input-inactive': '#959595',

  '--color-text-input-error': 'var(--color-danger)',
  '--color-input-background': '#DBDBDB',

  '--color-input-border-active': 'var(--color-text-secondary)',

  '--color-input-border-error': 'var(--color-danger)',

  '--color-community-message-input-background': '#959595',
};

const select = {
  '--select-background': 'var(--color-input-background)',
  '--select-border': 'var(--color-input-border)',
  '--select-border-radius': 'var(--border-radius-base)',
  '--select-padding': '0.5rem 1rem',
  '--select-color': 'var(--color-text-input-active)',
  '--select-color-placeholder': 'var(--color-text-input-inactive)',
  '--select-color-disabled': 'var(--color-text-input-inactive)',
  '--select-color-hover': '#959595',
  '--select-border-color-focus': 'var(--color-input-border-active)',
  '--select-color-error': 'var(--color-text-input-error)',
  '--select-color-success': 'var(--color-text-input-active)',
  '--select-color-warning': 'var(--color-text-input-active)',
  '--select-color-icon': 'var(--color-text-input-active)',
};

export const button = {
  '--button-padding': '0.5rem 1rem',
  '--button-border-radius': 'var(--border-radius-base)',

  '--button-border-radius-xsmall': 'var(--border-radius-small)',

  '--button-background-primary': 'var(--color-accent-primary)',
  '--button-background-secondary': 'transparent',

  '--button-background-primary-hover': 'var(--color-accent-primary-hover)',
  '--button-background-secondary-hover': '#DBDBDB',

  '--button-background-active': 'var(--color-accent-primary-hover)',
  '--button-background-focus': 'var(--color-accent-primary-hover)',

  '--button-background-disabled': '#DBDBDB',

  '--button-background-danger': 'var(--color-danger)',
  '--button-background-success': 'var(--color-success)',
  '--button-background-complete': 'var(--color-complete)',

  '--button-background-danger-hover': '#CD1E1E',
  '--button-background-success-hover': '#2CB04F',
  '--button-background-complete-hover': '#358AC7',

  '--button-text-color-primary': 'var(--color-text-on-accent)',
  '--button-text-color-secondary': 'var(--color-text-primary)',
  '--button-text-color-danger': '#FFFFFF',
  '--button-text-color-success': '#FFFFFF',
  '--button-text-color-disabled': '#959595',
  '--button-text-color-complete': '#FFFFFF',

  '--button-color-hover': 'var(--color-text-on-accent)',
  '--button-color-active': 'var(--color-text-on-accent)',
  '--button-color-focus': 'var(--color-text-on-accent)',
};

export const typography = {
  '--font-family-light': 'PlusJakartaSans-Light, sans-serif',
  '--font-family-regular': 'PlusJakartaSans-Regular, sans-serif',
  '--font-family-medium': 'PlusJakartaSans-Medium, sans-serif',
  '--font-family-bold': 'PlusJakartaSans-Bold, sans-serif',
  '--font-family-demi-bold': 'PlusJakartaSans-DemiBold, sans-serif',
  '--font-size-base': '16px',
};

export const boxModel = {
  '--border-radius-xsmall': '0.25rem',
  '--border-radius-small': '0.3125rem',
  '--border-radius-base': '0.5rem',
  '--border-radius-medium': '0.75rem',
  '--border-radius-large': '1rem',
  '--border-radius-xlarge': '2rem',
};

export const modals = {
  '--modal-background': 'var(--color-card-background)',
  '--modal-overlay': 'rgba(0, 0, 0, 0.8)',
};

export const tables = {
  '--table-background': 'var(--color-card-background)',
  '--table-border': 'var(--color-border)',
  '--table-border-radius': 'var(--border-radius-base)',
  '--table-header-background': 'var(--color-menu-background)',
  '--table-row-hover-background': '#FAFAFA',
};

const light = {
  ...colors,
  ...inputs,
  ...select,
  ...button,
  ...typography,
  ...boxModel,
  ...modals,
  ...tables,
};

export default light;
