import React, { FC, Children, useState } from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';
import classes from './Tabs.module.css';
import { TabProps, TabsProps } from 'interfaces/ui';
import clsx from 'clsx';

const Tabs: FC<TabsProps> = ({ children, defaultTab, ariaLabel = 'List of tab navigations' }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const triggers = Children.map(children, (child) => {
    const childElement = child as React.ReactElement<TabProps>;
    const { title } = childElement.props;
    return (
      <RadixTabs.Trigger
        className={clsx(classes.TabsTrigger, title === activeTab && classes.Selected)}
        value={title}
        onClick={() => setActiveTab(title)}
      >
        {title}
      </RadixTabs.Trigger>
    );
  });

  const contents = Children.map(children, (child) => {
    const childElement = child as React.ReactElement<TabProps>;
    const { title, children: content } = childElement.props;
    return (
      activeTab === title && (
        <RadixTabs.Content className={classes.TabsContent} value={title}>
          {content}
        </RadixTabs.Content>
      )
    );
  });

  return (
    <RadixTabs.Root className={classes.TabsRoot} defaultValue={defaultTab}>
      <RadixTabs.List className={classes.TabsList} aria-label={ariaLabel} defaultValue={defaultTab}>
        {triggers}
      </RadixTabs.List>
      {contents}
    </RadixTabs.Root>
  );
};

export default Tabs;
