import React, { FC } from 'react';

import NavItem from './NavItem';
import classes from './NavItems.module.css';

import { PAYEMENTS, ANALYTICS } from 'utils/routes';

import { isEligibleCoach } from 'utils/helpers';
import clsx from 'clsx';
import { getClientProfileNavItems, getStandardNavItems } from 'utils/navigation';

type ViewType = 'standard' | 'client';
interface Props {
  viewType: ViewType;
  isInSouthAfrica?: boolean;
  userId?: string | null;
  clientKey?: string;
}

const NavItems: FC<Props> = ({ isInSouthAfrica = true, userId = '', viewType = 'standard', clientKey = '' }) => {
  const navItemsArr = viewType === 'standard' ? getStandardNavItems : getClientProfileNavItems(clientKey);

  const navitemsClass =
    viewType === 'standard' ? classes.NavItems : clsx(classes.ClientProfileNavItems, classes.NavItems);

  const navItems = navItemsArr.map((item) => {
    if (item.link === ANALYTICS.URL && !isEligibleCoach(userId, true)) return null;
    if (!isInSouthAfrica || item.link !== PAYEMENTS.URL) {
      return (
        <NavItem viewType={viewType} key={item.link} link={item.link} SVGIcon={item.SVGIcon} styleType={item.styleType}>
          {item.title}
        </NavItem>
      );
    }
    return null;
  });
  return <ul className={navitemsClass}>{navItems}</ul>;
};

export default NavItems;
