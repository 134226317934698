import React, { FC, useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import clsx from 'clsx';
import rfdc from 'rfdc';
import { Chart as ChartJS, Filler } from 'chart.js';

import classes from './ExerciseHistoryBox.module.css';
import ExerciseItem from 'components/ExerciseItem';
import { SetObject } from 'interfaces/db';
import { DataSet, IndexedExercise } from 'interfaces/utils';
import { useExerciseData } from 'hooks';
import { BasicSmallSpinner } from 'components/UI';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-outline.svg';
import { ReactComponent as TickIcon } from 'assets/svgs/checkmark-circle-outline.svg';
import { ReactComponent as CircleIcon } from 'assets/svgs/ellipse-outline.svg';

ChartJS.register(Filler);
interface Props {
  closeAnalytics: (item: any) => void;
  userId?: string | null;
  indexedExercise: IndexedExercise;
  weightColor: string;
  volumeColor: string;
  weightTextColor: string;
  volumeTextColor: string;
  uniqueIndex?: string;
  hideClose?: boolean;
  quickEdit?: boolean;
}
interface Set {
  [key: string]: SetObject | string | boolean;
}

const OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

const ExerciseHistoryBox: FC<Props> = ({
  closeAnalytics,
  userId,
  indexedExercise,
  weightColor,
  volumeColor,
  weightTextColor,
  volumeTextColor,
  uniqueIndex,
  hideClose = false,
  quickEdit = false,
}) => {
  const { pastInstances, PRs, calculated } = useExerciseData(indexedExercise.exercise.type, userId);

  const [graphData, setGraphData] = useState<any>({
    datasets: [],
  });

  const extractRemainder = (set: Set) => {
    if (set && typeof set.completed === 'boolean') {
      const { ...remainder } = set;
      return remainder;
    } else {
      return set;
    }
  };

  const selectGraph = (key: string) => {
    const clone = rfdc();
    const tempGraphData = clone(graphData.datasets);

    if (key === 'weight') {
      tempGraphData[0].hidden = !tempGraphData[0].hidden;
    }

    if (key === 'volume') {
      tempGraphData[1].hidden = !tempGraphData[1].hidden;
    }

    setGraphData((prevState: any) => {
      return {
        ...prevState,
        datasets: tempGraphData,
      };
    });
  };

  useEffect(() => {
    if (!pastInstances) return;

    const bestWeight = (sets: Set[]) => {
      // calculate relevant numbers
      const max = { weight: 0, numSets: 0 };
      sets.forEach((set) => {
        Object.keys(extractRemainder(set)).forEach((title) => {
          const weightValue = Number((set?.[title] as SetObject).achieved || (set?.[title] as SetObject).programmed);
          if (title.includes(`weight`)) {
            if (weightValue > max.weight) {
              max.weight = weightValue;
              max.numSets = 0;
            } else if (weightValue === max.weight) {
              max.numSets = max.numSets + 1;
            }
          }
        });
      });
      return max;
    };

    const totalVolume = (sets: Set[]) => {
      // calculate relevant numbers
      const total = { weight: 0, numSets: 0 };
      sets.forEach((set) => {
        Object.keys(extractRemainder(set)).forEach((title) => {
          const value = Number((set?.[title] as SetObject).achieved || (set?.[title] as SetObject).programmed);
          if (title.includes(`weight`)) {
            total.weight += value;
            total.numSets = total.numSets + 1;
          }
        });
      });
      return total;
    };

    const weightDataSet: DataSet = {
      label: 'Weight',
      fill: true,
      backgroundColor: weightColor,
      hidden: false,
    };

    const volumeDataSet: DataSet = {
      label: 'Volume',
      fill: true,
      backgroundColor: volumeColor,
      hidden: true,
    };

    const weightData: number[] = [];
    const volumeData: number[] = [];
    const labels: string[] = [];

    pastInstances.forEach((instance) => {
      const data = instance.data();
      labels.push(`${new Date(data?.date).getDate()}/${new Date(data?.date).getMonth() + 1}`);
      weightData.push(bestWeight(data?.sets).weight);
      volumeData.push(totalVolume(data?.sets).weight);
    });

    const datasets: DataSet[] = [
      {
        ...weightDataSet,
        data: weightData,
      },
      {
        ...volumeDataSet,
        data: volumeData,
      },
    ];

    setGraphData({
      labels,
      datasets,
    });
  }, [pastInstances]);

  return (
    <div className={clsx(classes.Container, quickEdit && classes.QuickEditView)}>
      {!hideClose && (
        <button
          className={classes.CloseBoxButton}
          onClick={() => closeAnalytics(quickEdit ? indexedExercise : uniqueIndex)}
        >
          <CloseIcon />
        </button>
      )}
      {!calculated ? (
        <div className={classes.Loading}>
          <BasicSmallSpinner />
          <span>Calculating...</span>
        </div>
      ) : !pastInstances ? (
        <div className={classes.NoData}>
          <p>No past instances recorded</p>
        </div>
      ) : (
        <>
          <div className={classes.Graph}>
            <div className={classes.GraphSelectorContainer}>
              {graphData?.datasets?.map((item: any) => {
                const backgroundColor = item?.label === 'Weight' ? weightColor : volumeColor;
                const textColor = item?.label === 'Weight' ? weightTextColor : volumeTextColor;
                return (
                  <div
                    key={item.label}
                    className={classes.GraphSelector}
                    style={{ backgroundColor: backgroundColor, color: textColor }}
                    onClick={() => selectGraph(item.label.toLowerCase())}
                  >
                    {item.label}
                    {item.hidden ? <CircleIcon className={classes.Icon} /> : <TickIcon className={classes.Icon} />}
                  </div>
                );
              })}
            </div>
            <Line data={graphData} options={OPTIONS} redraw />
          </div>
          <hr className={classes.Divider} />
          <div className={classes.InnerContainer}>
            {PRs && PRs?.length > 0 && (
              <div className={classes.PRContainer}>
                <span className={classes.SectionHeading}> PR&#39;s </span>
                {PRs?.map((item) => {
                  const data = item.data();
                  return (
                    <div key={item.id} className={classes.PRList}>
                      {`${item.id} PR: `}
                      {data.value}
                      {` ${data.unit}`}
                    </div>
                  );
                })}
              </div>
            )}

            <div className={classes.HistoryContainer}>
              <span className={classes.SectionHeading}>History</span>
              {pastInstances?.map((item, i) => {
                const data = item.data();
                const date = new Date(data.date).toDateString();
                return (
                  <div className={classes.HistoryList} key={i + date}>
                    <p className={classes.DateEntry}>{date}</p>
                    <ExerciseItem sets={data.sets} />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExerciseHistoryBox;
