import { nanoid } from 'nanoid';
import React, { FC } from 'react';
import classes from './TabNav.module.css';

const MAX_CATEGORIES = 6;
interface Props {
  tabHeadings?: string[];
  setSelectedTab: (tab: string) => void;
  selectedTab: string;
  setCategoriesModalOpen?: (val: boolean) => void;
}

const TabNav: FC<Props> = ({ tabHeadings = [], setSelectedTab, selectedTab, setCategoriesModalOpen }) => {
  const tabs: any = tabHeadings.map((tab: string) => {
    return (
      <div
        key={nanoid()}
        onClick={() => setSelectedTab(tab)}
        className={selectedTab === tab ? [classes.Tab, classes.Selected].join(' ') : classes.Tab}
      >
        {tab}
      </div>
    );
  });

  return (
    <div className={classes.TabsContainer}>
      <div className={classes.LeftTabContainer}>
        {tabs}
        {setCategoriesModalOpen && (tabHeadings?.length || 0) < MAX_CATEGORIES && (
          <div onClick={() => setCategoriesModalOpen(true)} className={classes.AddCategoryButton}>
            <button type="button">+ Add Category</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TabNav;
