import React, { FC, useEffect, useState, ChangeEvent, useMemo, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { UseFormRegister, useForm } from 'react-hook-form';
import { ColumnDef } from '@tanstack/react-table';
import { database } from 'utils/firebase';

import { RootState } from 'store';
import { Button, Spinner, Input, Dialog, AlertDialog, Table } from 'components/UI';
import { AddProgram } from 'components/forms';
import { Clients, Programs as ProgramsInterface } from 'interfaces/db';
import ClientAssignCard from 'components/ClientAssignCard';
import {
  fetchPrograms,
  removeProgram,
  initialiseProgram,
  initialiseProgramStart,
  initialiseProgramSuccess,
  initialiseProgramFail,
} from 'store/actions/programs';
import { CALENDAR, PROGRAMS } from 'utils/routes';
import withAuth from 'utils/withAuth';
import { getInitials } from 'utils/helpers';
import Layout from '../Layout';
import classes from './Programs.module.css';
import { createProgram, fetchProgramById } from 'utils/api';
import AssignToClients from 'components/AssignToClients';
import { SearchValues } from 'interfaces/programs';
import EmptyState from 'components/EmptyState';
import { programTrackingService } from 'utils/tracking/programService';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { ReactComponent as SearchMagnifyingGlass } from 'assets/svgs/search-filled.svg';
import { ReactComponent as RightChevron } from 'assets/svgs/chevron_right.svg';
import SelectCheckbox from 'components/UI/Table/SelectCheckbox';
import { ReactComponent as AssignIcon } from 'assets/svgs/assign-filled.svg';
import { ReactComponent as DuplicateIcon } from 'assets/svgs/copy-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';

const mapStateToProps = ({ clients, programs, auth }: RootState) => {
  const { userId, coachProfile, token, loading } = auth;
  const { programs: programsList, loading: programsLoading, removeProgramLoading } = programs;

  return {
    token,
    userId,
    authLoading: loading,
    clients,
    programs: programsList,
    programsLoading,
    removeProgramLoading,
    coachProfile,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchPrograms: (userId: string) => dispatch(fetchPrograms(userId)),
    onRemoveProgram: (
      programKeys: string | string[],
      token: string,
      setShowRemoveProgram: React.Dispatch<React.SetStateAction<boolean>>,
    ) => dispatch(removeProgram(programKeys, token, setShowRemoveProgram)),
    onInitialiseProgram: (program: ProgramsInterface, token: string) => dispatch(initialiseProgram(program, token)),
    initialiseProgramSuccess: (program: ProgramsInterface, programKey: string) =>
      dispatch(initialiseProgramSuccess(program, programKey)),
    initialiseProgramStart: () => dispatch(initialiseProgramStart()),
    initialiseProgramFail: () => dispatch(initialiseProgramFail()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps;

const Programs: FC<Props> = ({
  token,
  userId,
  clients,
  history,
  programs,
  authLoading,
  onFetchPrograms,
  onRemoveProgram,
  onInitialiseProgram,
  initialiseProgramStart,
  initialiseProgramSuccess,
  initialiseProgramFail,
  programsLoading,
  removeProgramLoading,
}) => {
  const { addToast } = useToasts();
  const { register } = useForm<SearchValues>();
  const tableRef = useRef<any>();
  const [selectedProgramKeys, setSelectedProgramKeys] = useState('');
  const [programTitleModal, setProgramTitleModal] = useState<boolean>(false);
  const [showRemoveProgram, setShowRemoveProgram] = useState<boolean>(false);
  const [showAssignProgram, _setShowAssignProgramModal] = useState<boolean>(false);
  const [clientsToAssign, setClientsToAssign] = useState<string[]>([]);
  const [clientsFiltered, setClientsFiltered] = useState<[string, Clients][]>([]);
  const [clientSearchValue, setClientSearchValue] = useState<string | null>(null);
  const [programsFiltered, setProgramsFiltered] = useState<[string, ProgramsInterface][]>([]);
  const [programSearchValue, setProgramSearchValue] = useState<string | null>(null);
  const [assignProgramLoading, setAssignProgramLoading] = useState<boolean>(false);
  const [programsLength, setProgramsLength] = useState<number>(0);
  const [createProgramLoading, setCreateProgramLoading] = useState<boolean>(false);
  const [fetched, setFecthed] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    if (userId) {
      setFecthed(false);
      onFetchPrograms(userId);
      setFecthed(true);
    }

    return () => {
      setFecthed(false);
    };
  }, [onFetchPrograms, userId]);

  const setShowAssignProgramModal = (value: boolean) => {
    _setShowAssignProgramModal(value);
    setClientSearchValue(null);
  };

  const getSelectedProgramKeys = () => {
    const programRowsSelected = tableRef.current?.getSelectedRowModel()?.flatRows;
    const programKeys = programRowsSelected?.map((row) => row.original.programKey);
    return programKeys;
  };

  useEffect(() => {
    setProgramsLength(Object.entries(programs || {}).length);
  }, [programs]);

  const duplicateProgramHandler = async (keys: string | string[]) => {
    if (programs && userId && token) {
      const programKeys = Array.isArray(keys) ? keys : [keys];

      for (const programKey of programKeys) {
        if (programs[programKey]) {
          initialiseProgramStart();
          const response = await fetchProgramById(programKey);
          const program: ProgramsInterface = response.data.data?.program;

          if (program) {
            const name = `${program.name} (copy)`;

            await onInitialiseProgram(
              {
                name: name,
                image: '',
                weeks: program.weeks,
                userId: userId,
                coachId: userId,
              },
              token,
            );
          }
        }
      }

      setRowSelection({});
    }
  };

  const setAssignedClient = (key: string) => {
    if (clientsToAssign.includes(key)) {
      const newClientsToAssign = clientsToAssign.filter((el) => el !== key);
      setClientsToAssign(newClientsToAssign);
      return;
    }

    setClientsToAssign([...clientsToAssign, key]);
  };

  const updateClientHasProgram = (clientKey: string): Promise<any> => {
    const clientRef = database.ref(`clients/${clientKey}`);

    return clientRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        clientRef.update({
          hasPrograms: true,
        });
      }
    });
  };

  const assignProgram = async () => {
    if (programs) {
      const programKeys = Array.isArray(selectedProgramKeys) ? selectedProgramKeys : [selectedProgramKeys];
      setAssignProgramLoading(true);

      try {
        // promises array
        const promises: Promise<any>[] = [];

        for (const key of programKeys) {
          if (programs[key]) {
            // program to be assigned
            const response = await fetchProgramById(key);
            const program: ProgramsInterface = response.data.data?.program;

            // map over clients and set programs
            clientsToAssign.map((clientKey) => {
              const updatedProgram = {
                ...program,
                clientKey: clientKey,
                assignedAt: new Date(),
              };
              const query = database.ref(`newAssignedPrograms/${uuidv4()}`).set(updatedProgram);

              const updateClient = updateClientHasProgram(clientKey);
              // push promise into promises array
              promises.push(query, updateClient);

              return undefined;
            });

            programTrackingService.trackProgramEvent('assign_program', {
              program_id: key,
              location: 'program_templates',
              clients: clientsToAssign,
              program_length: program.weeks?.length || 0,
            });
          }
        }

        // resolve promises
        await Promise.all(promises);
        setShowAssignProgramModal(false);
        setAssignProgramLoading(false);
        setClientsToAssign([]);
        setRowSelection({});

        // show toast
        addToast('Program Assigned', { appearance: 'success' });
      } catch (err) {
        addToast('Program Could Not Be Assigned', {
          appearance: 'error',
        });
        setAssignProgramLoading(false);
        console.log(err);
      }
    }
  };

  const handleSubmit = async (title: string) => {
    try {
      setCreateProgramLoading(true);
      if (userId && token) {
        initialiseProgramStart();
        const newProgram = {
          name: title,
          image: '',
          weeks: [],
          userId,
          coachId: userId,
        };

        const response = await createProgram(token, newProgram);
        setProgramTitleModal(false);
        const updatedProgram = response.data?.data as ProgramsInterface;
        if (updatedProgram.programId) {
          programTrackingService.trackProgramEvent('create_program', {
            program_id: updatedProgram.programId,
            location: 'program_templates',
            object: 'button',
            action: 'click',
            program_title: updatedProgram.name,
            button: {
              text: 'CREATE PROGRAM',
              action: 'create_program_template',
              description: 'Create program button on add program modal',
            },
          });
          initialiseProgramSuccess(updatedProgram, updatedProgram.programId);
          history.push({ pathname: `${CALENDAR.URL}/${updatedProgram.programId}` });
        }
      }
    } catch {
      setProgramTitleModal(false);
      initialiseProgramFail();
    } finally {
      setCreateProgramLoading(false);
    }
  };

  const showRemoveProgramModal = () => {
    setShowRemoveProgram(true);
  };

  const removeProgramHandler = async () => {
    if (token) {
      const programKeys = getSelectedProgramKeys();
      await onRemoveProgram(programKeys, token, setShowRemoveProgram);
      setRowSelection({});
    }
  };

  const handleDuplication = () => {
    const programKeys = getSelectedProgramKeys();
    return duplicateProgramHandler(programKeys);
  };

  // const handleCardClick = () => {
  //   window.localStorage.setItem('programIndex', programIndex + '');
  //   history.push({
  //     pathname: `${CALENDAR.URL}/${programKey}`,
  //   });
  // };

  const tableColumns = useMemo<ColumnDef<Record<string, unknown>>[]>(
    () => [
      SelectCheckbox(),
      {
        header: 'Program Name',
        cell: (row) => row.renderValue(),
        accessorKey: 'name',
      },
      {
        header: 'Duration',
        cell: (row) => row.renderValue(),
        accessorKey: 'duration',
      },
      {
        header: '',
        accessorKey: 'actions',
        cell: ({ row }) => {
          return (
            <Button
              type="button"
              size="xsmall"
              onClick={() => {
                window.localStorage.setItem('programIndex', row.index + '');
                history.push({
                  pathname: `${CALENDAR.URL}/${row.original.programKey}`,
                });
              }}
              iconCenter={<RightChevron />}
            />
          );
        },
      },
    ],
    [],
  );

  const showAssignProgramModal = () => {
    const programKeys = getSelectedProgramKeys();
    setSelectedProgramKeys(programKeys);
    setShowAssignProgramModal(true);
  };

  const getProgramsList = () => {
    if (!programs) return null;

    let programsNew = Object.entries(programs);
    if (programSearchValue) {
      programsNew = programsFiltered;
    }

    const programsData = programsNew.map((item) => ({
      name: item[1].name,
      duration: (item[1].weeks?.length || 0) + ' Weeks',
      programKey: item[0],
    }));

    return (
      <Table
        ref={tableRef}
        columns={tableColumns}
        data={programsData}
        loading={programsLoading}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        bulkActions={
          <div className={classes.BulkActions}>
            <div>
              <Button type="button" intent="secondary" onClick={showAssignProgramModal} iconLeft={<AssignIcon />}>
                Assign
              </Button>
            </div>
            <div>
              <Button type="button" intent="secondary" onClick={handleDuplication} iconLeft={<DuplicateIcon />}>
                Duplicate
              </Button>
            </div>
            <div>
              <Button type="button" intent="secondary" onClick={showRemoveProgramModal} iconLeft={<DeleteIcon />}>
                Delete
              </Button>
            </div>
          </div>
        }
        onRowClick={(row) => {
          window.localStorage.setItem('programIndex', row.index + '');
          history.push({
            pathname: `${CALENDAR.URL}/${row.original.programKey}`,
          });
        }}
      />
    );
  };

  let clientList;

  if (clients.clients !== null) {
    let clientsNew = Object.entries(clients.clients);
    if (clientSearchValue) {
      clientsNew = clientsFiltered;
    }

    clientList = clients.loading ? (
      <Spinner />
    ) : (
      clientsNew.map(([clientKey, client]) => {
        const clientName = client.profile.fullname || '';

        return (
          <ClientAssignCard
            key={clientKey}
            name={clientName}
            initials={getInitials(clientName)}
            assigned={clientsToAssign.includes(clientKey)}
            setAssignedClient={() => setAssignedClient(clientKey)}
          />
        );
      })
    );
  }

  const programsList = programsLoading ? <Spinner /> : getProgramsList();

  const onSearchClients = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setClientSearchValue(value);

    const clientsFiltered = Object.entries(clients.clients || {})?.filter(([_, client]) => {
      return client?.profile?.fullname?.toLowerCase()?.includes(value);
    });

    setClientsFiltered(clientsFiltered);
  };

  const onSearchPrograms = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setProgramSearchValue(value);

    const programsFiltered = Object.entries(programs || {})?.filter(([_, program]) => {
      return program?.name?.toLowerCase()?.includes(value);
    });

    setProgramsFiltered(programsFiltered);
  };

  const addProgram = (
    <Dialog
      title="Create program"
      trigger={
        <div>
          <Button type="button" onClick={() => setProgramTitleModal(true)} iconLeft={<PlusIcon />}>
            Add New Program
          </Button>
        </div>
      }
      open={programTitleModal}
      onOpenChange={setProgramTitleModal}
    >
      <AddProgram
        handleSubmit={handleSubmit}
        closeModal={() => setProgramTitleModal(false)}
        loading={createProgramLoading}
      />
    </Dialog>
  );

  return (
    <Layout loading={authLoading} heading={PROGRAMS.TITLE}>
      {!programsLoading && programsLength > 0 && fetched && (
        <div className={classes.ToolBar}>
          <div className={classes.SearchPrograms}>
            <form>
              <Input
                name="programSearch"
                type="text"
                onChange={onSearchPrograms}
                register={register}
                placeholder="Search programs..."
                iconLeft={<SearchMagnifyingGlass />}
              />
            </form>
          </div>

          {addProgram}
        </div>
      )}

      {programsLength === 0 && fetched && !programsLoading ? (
        <div className={classes.NoPrograms}>
          <EmptyState title="Build your first program template" description="You don't have any programs yet.">
            {addProgram}
          </EmptyState>
        </div>
      ) : (
        <div className={classes.ProgramsContainer}>{programsList}</div>
      )}

      <AlertDialog
        title="Delete program"
        description="Do you want to delete this program? This cannot be undone."
        open={showRemoveProgram}
        onOpenChange={setShowRemoveProgram}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setShowAssignProgramModal(false)} aria-label="Close">
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={removeProgramHandler} loading={removeProgramLoading}>
            Delete
          </Button>
        }
      />

      <Dialog title="Assign a program" open={showAssignProgram} onOpenChange={setShowAssignProgramModal}>
        <AssignToClients
          clientList={clientList}
          onSearchClients={onSearchClients}
          handleAssign={assignProgram}
          setShowAssignModal={setShowAssignProgramModal}
          loading={assignProgramLoading}
          register={register as UseFormRegister<SearchValues>}
        />
      </Dialog>
    </Layout>
  );
};

export default connector(withAuth(Programs));
