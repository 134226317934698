import React, { FC } from 'react';

import Day from './Day/Day';
import { SetKeys, Day as DayType } from 'interfaces/db';
import classes from './Week.module.css';
import { Button } from 'components';
import { ReactComponent as DuplicateIcon } from 'assets/svgs/copy-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';

interface Props {
  days: DayType[];
  week: number;
  saveProgram: (workoutId?: string) => void;
  addWorkout: (weekIndex: number, dayIndex: number) => Promise<void>;
  copyWorkoutHandler: (weekIndex: number, dayIndex: number) => void;
  goToWorkoutBuilder: (weekIndex: number, dayIndex: number, workoutId?: string) => Promise<void>;
  pasteWorkoutHandler: (weekIndex: number, dayIndex: number) => void;
  deleteWeekHandler: (index: number) => void;
  programName: string;
  removeRestDayHandler: (weekIndex: number, dayIndex: number) => void;
  setRestDayHandler: (weekIndex: number, dayIndex: number) => void;
  duplicateWeekHandler: (Days: DayType[]) => void;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number, dayIndex: number) => void;
  addWorkoutNotes: (workoutNotes: string, dayIndex: number, weekIndex: number) => void;
  addSetHandler: (group: number, index: number, dayIndex: number) => void;
  removeSetHandler: (group: number, index: number, dayIndex: number) => void;
  setsChangeHandler: (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    index: number,
    dayIndex: number,
  ) => void;
  autoFillSetsHandler: (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    index: number,
    dayIndex: number,
  ) => void;
  programKey: string;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  weightColor: string;
  volumeColor: string;
  weightTextColor: string;
  volumeTextColor: string;
  programmedTextColor: string;
  achievedTextColor: string;
}

const Week: FC<Props> = ({
  days,
  week,
  programKey,
  addWorkout,
  saveProgram,
  programName,
  addSetHandler,
  addExerciseNotes,
  addWorkoutNotes,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  deleteWeekHandler,
  setRestDayHandler,
  goToWorkoutBuilder,
  copyWorkoutHandler,
  pasteWorkoutHandler,
  duplicateWeekHandler,
  removeRestDayHandler,
  setShowAutoFill,
  showAutoFill,
  weightColor,
  volumeColor,
  programmedTextColor,
  achievedTextColor,
  weightTextColor,
  volumeTextColor,
}) => {
  return (
    <>
      <div className={classes.WeekHeadingDiv}>
        <h4 className={classes.WeekHeadingStyle}>{`Week ${week}`}</h4>

        <div className={classes.WeekActionContainer}>
          <div className={classes.DuplicateDiv}>
            <Button
              type="button"
              intent="secondary"
              onClick={() => duplicateWeekHandler(days)}
              iconLeft={<DuplicateIcon />}
            >
              DUPLICATE
            </Button>
          </div>

          <div className={classes.DeleteDiv}>
            <Button
              type="button"
              intent="secondary"
              onClick={() => deleteWeekHandler(week - 1)}
              iconLeft={<DeleteIcon />}
            >
              DELETE
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.Week}>
        {days?.map((day, index) => (
          <Day
            key={index}
            day={day}
            weekNumber={week - 1}
            dayNumber={index}
            saveProgram={saveProgram}
            programName={programName}
            removeRestDay={removeRestDayHandler}
            addWorkout={() => addWorkout(week - 1, index)}
            copyWorkoutHandler={() => copyWorkoutHandler(week - 1, index)}
            pasteWorkoutHandler={() => pasteWorkoutHandler(week - 1, index)}
            goToWorkoutBuilder={() => goToWorkoutBuilder(week - 1, index, day.workoutId)}
            setRestDay={setRestDayHandler}
            addExerciseNotes={(exerciseNotes, group, single) => addExerciseNotes(exerciseNotes, group, single, index)}
            addWorkoutNotes={addWorkoutNotes}
            addSetHandler={(group, single) => addSetHandler(group, single, index)}
            removeSetHandler={(group, single) => removeSetHandler(group, single, index)}
            setsChangeHandler={(key: SetKeys, val: string, setIndex: number, group: number, exerciseIndex: number) =>
              setsChangeHandler(key, val, setIndex, group, exerciseIndex, index)
            }
            autoFillSetsHandler={(key: SetKeys, val: string, setIndex: number, group: number, exerciseIndex: number) =>
              autoFillSetsHandler(key, val, setIndex, group, exerciseIndex, index)
            }
            programKey={programKey}
            setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
            showAutoFill={showAutoFill}
            weightColor={weightColor}
            volumeColor={volumeColor}
            programmedTextColor={programmedTextColor}
            achievedTextColor={achievedTextColor}
            weightTextColor={weightTextColor}
            volumeTextColor={volumeTextColor}
          />
        ))}
      </div>
    </>
  );
};

export default Week;
