import React, { FC, ChangeEvent } from 'react';
import clsx from 'clsx';

import { SetKeys, Exercise, Day, SetObject } from 'interfaces/db';
import { setKeysMap } from 'utils/helpers';
import classes from './QuickEditTable.module.css';
import OutsideAlerter from 'components/OutsideAlerter/OutsideAlerter';
import Separator from './Separator';
import { Button } from 'components';
import { ReactComponent as TickIcon } from 'assets/svgs/tick-outline.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-outline.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { ReactComponent as MinusIcon } from 'assets/svgs/remove-outline.svg';

interface Props {
  exercise: Exercise;
  addSetHandler: () => void;
  removeSetHandler: () => void;
  setsChangeHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousDay?: Day;
  day?: Day;
  achievedTextColor: string;
  programmedTextColor: string;
}

const QuickEditTable: FC<Props> = ({
  exercise,
  addSetHandler,
  removeSetHandler,
  setsChangeHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  previousDay,
  achievedTextColor,
  programmedTextColor,
}) => {
  const { sets = [] } = exercise;
  const numSets = sets.length;

  const { completed, ...otherSets } = sets[0] || {};
  const keys = Object.keys(otherSets);
  const finished = previousDay?.workout?.finished || false;

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13 && showAutoFill === e.currentTarget.id) {
      const button = document.getElementById(e.currentTarget.id + 'button');
      return button?.click();
    }
  };

  return (
    <div className={classes.Container}>
      <ul className={classes.InnerWrapper}>
        <li className={finished ? classes.TableRowFeedback : classes.TableRow}>
          <div className={!finished ? classes.TableSetNumber : classes.TableSetNumberFinished}>Sets</div>
          {keys.map((el) => {
            const title = setKeysMap.find(({ value }) => value === el);

            return (
              <div key={el} className={classes.InputContainer}>
                <div>
                  <span>{title?.label}</span>
                </div>
              </div>
            );
          })}
          {finished && <div className={classes.FinishedSetIcons} />}
        </li>

        {sets.map((el, setIndex) => {
          const { completed, ...subSets } = el;
          const subSetsEntries = Object.entries(subSets);
          return (
            <li key={setIndex} className={!previousDay ? classes.TableRow : classes.TableRowFeedback}>
              <span className={!finished ? classes.TableSetNumber : classes.TableSetNumberFinished}>
                {setIndex + 1}
              </span>

              {subSetsEntries.map((el, i) => {
                const [key, value] = el;
                const isValString = typeof value === 'string';
                return (
                  <div className={classes.InputContainer} key={setIndex + (key as SetKeys) + exercise.type + i}>
                    <OutsideAlerter
                      show={showAutoFill === setIndex + (key as SetKeys) + exercise.type}
                      close={() => setShowAutoFill('')}
                    >
                      {!previousDay && (
                        <button
                          id={setIndex + (key as SetKeys) + exercise.type + 'button'}
                          className={
                            showAutoFill === setIndex + (key as SetKeys) + exercise.type
                              ? classes.AutofillButton
                              : classes.AutofillButtonHidden
                          }
                          onClick={() => {
                            setShowAutoFill('');
                            autoFillSetsHandler(
                              key as SetKeys,
                              (document.getElementById(setIndex + (key as SetKeys) + exercise.type) as HTMLInputElement)
                                .value + '' || '0',
                              setIndex,
                            );
                          }}
                        >
                          Autofill
                        </button>
                      )}
                      <div className={classes.Flex}>
                        <input
                          type="text"
                          id={
                            !previousDay
                              ? setIndex + (key as SetKeys) + exercise.type
                              : setIndex + (key as SetKeys) + exercise.type + 'feedback'
                          }
                          onKeyDown={(e) => handleKeyDown(e)}
                          onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                            setsChangeHandler(key as SetKeys, target.value, setIndex);
                            setShowAutoFill(setIndex + (key as SetKeys) + exercise.type);
                          }}
                          readOnly={finished}
                          value={isValString ? value : value?.programmed || (finished ? '-' : '')}
                          className={clsx(classes.TableInput, finished && classes.TableInputOriginal)}
                        />
                        {finished && (
                          <>
                            <Separator />
                            <input
                              id={setIndex + (key as SetKeys) + exercise.type + 'finished'}
                              style={{
                                color: (value as SetObject).achieved ? achievedTextColor : programmedTextColor,
                              }}
                              type="text"
                              readOnly
                              aria-readonly="true"
                              value={isValString ? value : value?.achieved || value?.programmed}
                              className={classes.TableInputAchieved}
                            />
                          </>
                        )}
                      </div>
                    </OutsideAlerter>
                  </div>
                );
              })}
              {finished &&
                (completed ? (
                  <div className={clsx(classes.FinishedSetIcons, classes.Tick)}>
                    <TickIcon />
                  </div>
                ) : (
                  <div className={clsx(classes.FinishedSetIcons, classes.Cross)}>
                    <CloseIcon />
                  </div>
                ))}
            </li>
          );
        })}
      </ul>

      {!previousDay && !finished && (
        <div className={classes.FlexContainer}>
          <div className={classes.AddButtonWrapper}>
            <Button
              type="button"
              intent="secondary"
              onClick={addSetHandler}
              iconLeft={
                <div className={classes.SetActionButton}>
                  <PlusIcon />
                </div>
              }
            >
              ADD SET
            </Button>
          </div>

          {numSets && (
            <div>
              <Button
                type="button"
                intent="secondary"
                onClick={removeSetHandler}
                iconLeft={
                  <div className={classes.SetActionButton}>
                    <MinusIcon />
                  </div>
                }
              >
                DELETE SET
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuickEditTable;
