import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { useYupValidationResolver } from 'hooks';
import classes from './CategoriesList.module.css';
import Input from 'components/UI/Input';
import ModalButtonGroup from 'components/ModalButtonGroup';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';

type CategoriesListProps = {
  categoriesEdit: { [key: string]: string };
  documents: { [key: string]: { category: string } } | null;
  userId: string;
  removeCategory: (key: string) => Promise<void>;
  isEligibleCoach: (id: string) => boolean;
  onSaveCategoriesHandler: (categories: { [key: string]: string }) => void;
  loading: boolean;
  loadingCoach: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
};

const schema = object({
  category: string().min(4, 'Min number of characters is 4.').required('Category is required.'),
});

const CategoriesList: FC<CategoriesListProps> = ({
  categoriesEdit,
  documents,
  userId,
  removeCategory,
  isEligibleCoach,
  onSaveCategoriesHandler,
  loading,
  loadingCoach,
  setModalOpen,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
  } = useForm<{ [key: string]: string }>({
    defaultValues: categoriesEdit,
    resolver: useYupValidationResolver(schema),
  });

  const removeCategoryHandler = (key: string) => {
    removeCategory(key).then(() => {
      unregister(key);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSaveCategoriesHandler)} className={classes.List}>
      {Object.keys(categoriesEdit || {}).map((catKey) => (
        <div key={catKey} className={classes.InputWrapper}>
          <div className={classes.Flex}>
            <Input
              type="text"
              register={register}
              name={catKey}
              placeholder="Enter a category name..."
              error={errors[catKey]}
              readOnly={catKey?.toLowerCase() === 'subscription app' && isEligibleCoach(userId)}
            />
          </div>
          {Object.values(documents || {}).find((doc) => doc?.category?.toLowerCase() === catKey?.toLowerCase()) ||
          (catKey?.toLowerCase() === 'subscription app' && isEligibleCoach(userId)) ? (
            <div className={classes.DeleteButton}></div>
          ) : (
            <button type="button" onClick={() => removeCategoryHandler(catKey)} className={classes.DeleteButton}>
              <DeleteIcon className={classes.DeleteIcon} />
            </button>
          )}
        </div>
      ))}
      <ModalButtonGroup
        mainButtonType="submit"
        mainButtonText="Save changes"
        secondaryButtonText="Cancel"
        onSecondaryClick={() => setModalOpen(false)}
        loading={loading || loadingCoach}
      />
    </form>
  );
};

export default CategoriesList;
