import { MixpanelService, EventProperties, AnyProperties } from './mixpanelService';

export type PaymentEventProperties = EventProperties & {
  location: 'payments' | 'client_checkout';
};

class PaymentTrackingService extends MixpanelService {
  public trackPaymentEvent(eventName: string, properties?: PaymentEventProperties): void {
    super.trackEvent(eventName, properties);
  }

  public setPropertiesOnce = (properties: AnyProperties): void => {
    super.setPropertiesOnce(properties);
  };

  public setProperties = (properties: AnyProperties): void => {
    super.setProperties(properties);
  };
}

export const paymentTrackingService = new PaymentTrackingService();
