import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string, mixed } from 'yup';

import Input from 'components/UI/Input';
import classes from './UploadDocument.module.css';
import Select from 'components/UI/Select';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import ProgressBar from 'components/UI/ProgressBar';
import ModalButtonGroup from 'components/ModalButtonGroup';
import FileUploadInput from 'components/FileUploadInput';

interface FormValues {
  title: string;
  file: any;
  category: string;
}

const validationSchema = object().shape({
  title: string().required('Title is required'),
  file: mixed().required('PDF is required'),
  category: string().required('Category is required'),
});

const Document = ({ handleSubmit, progress, categories, selectedTab, setModalOpen, loading }) => {
  const initialValues = { title: '', file: undefined, category: selectedTab };

  const [openSelect, setOpenSelect] = useState<string | null>(null);

  const handleSelectOpenChange = (name: string, isOpen: boolean) => {
    if (isOpen) {
      setOpenSelect(name);
    } else if (openSelect === name) {
      setOpenSelect(null);
    }
  };

  const {
    control,
    register,
    handleSubmit: handleFormSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: useYupValidationResolver(validationSchema),
    defaultValues: initialValues,
  });

  const categoryOptions = categories?.map((category) => {
    return { value: category, label: category };
  });

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)} className={classes.formWrapper}>
      <Input
        name="title"
        placeholder="Document title"
        label="Document title"
        register={register}
        error={errors.title}
      />
      <div className={classes.SelectWrapper}>
        <Select
          name="category"
          label="Category"
          placeholder="Select document category"
          control={control}
          onOpenChange={(isOpen) => handleSelectOpenChange('category', isOpen)}
          open={openSelect === 'category'}
          optionArray={categoryOptions}
          required
          defaultValue={selectedTab || categories[0]}
        />
      </div>

      <ProgressBar progress={progress} />

      <FileUploadInput
        setError={setError}
        clearErrors={clearErrors}
        defaultFile={initialValues?.file}
        fileName="file"
        onFileUpload={(file) => {
          setValue('file', file);
        }}
        maxFileSize={50000}
        acceptedFormats={['application/pdf']}
      />

      <ModalButtonGroup
        onSecondaryClick={() => setModalOpen(false)}
        mainButtonType="submit"
        mainButtonText="Upload Document"
        secondaryButtonText="Cancel"
        loading={loading}
        mainButtonDisabled={loading || isSubmitting}
      />
    </form>
  );
};

export default Document;
