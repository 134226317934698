import rfdc from 'rfdc';

import {
  Macronutrients,
  Micronutrients,
  NutrientObject,
  NutritionDay,
  NutritionPlan,
  NutritionWeek,
} from 'interfaces/nutrition';
import { generateWeekId } from './generateWeekId';
import { insertElementAt } from 'utils/helpers';

export type NutrientValue = NutrientObject | undefined;

export const resetNutrientObject = (nutrient: NutrientValue): NutrientValue => {
  if (nutrient && typeof nutrient === 'object') {
    return { ...nutrient, achieved: null };
  }
  return nutrient;
};

export const resetNutrients = <T extends Macronutrients | Micronutrients | undefined>(nutrients: T) => {
  if (!nutrients) return undefined;

  let resetNutrientsObj;
  Object.keys(nutrients).forEach((key) => {
    const nutrient = nutrients[key];

    if (nutrient !== null) {
      if (!resetNutrientsObj) {
        resetNutrientsObj = {};
      }
      if (key === 'completed') {
        resetNutrientsObj[key] = false;
        return;
      }
      resetNutrientsObj[key] = resetNutrientObject(nutrient);
    }
  });

  return resetNutrientsObj;
};

export const duplicateWeek = (originalWeekIndex: number, plan: NutritionPlan, weeksRef): NutritionPlan | null => {
  if (!plan?.weeks || !weeksRef.current) {
    console.warn('No weeks in plan to duplicate');
    return null;
  }

  const clone = rfdc();
  const weeksRefWeeks: NutritionWeek[] = weeksRef.current
    .map((week) => week?.getValues())
    .filter((week) => week !== null && week !== undefined);

  const weekValues = weeksRefWeeks[originalWeekIndex];
  const duplicatedWeek: NutritionWeek = clone(weekValues);

  duplicatedWeek.id = generateWeekId(plan.planId);
  duplicatedWeek.isNewWeek = true;

  if (duplicatedWeek.days && duplicatedWeek.days.length > 0) {
    duplicatedWeek.days = duplicatedWeek.days.map((day: NutritionDay) => {
      const resetMacros = day.macros ? resetNutrients(day.macros) : undefined;
      const resetMicros = day.micros ? resetNutrients(day.micros) : undefined;

      const duplicatedDay = {
        ...day,
        finished: false,
        dateFinished: undefined,
        calories: resetNutrientObject(day.calories as NutrientObject),
        macros: resetMacros,
        micros: resetMicros,
      };

      return duplicatedDay;
    });
  }

  const updatedPlan = {
    ...plan,
    weeks: [...clone(weeksRefWeeks), duplicatedWeek],
    weekOrder: insertElementAt(plan.weekOrder, originalWeekIndex + 1, duplicatedWeek.id),
  };

  return updatedPlan;
};
