import React, { FC, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { RootState } from 'store';
import { getInitials } from 'utils/helpers';
import { DASHBOARD } from 'utils/routes';
import withAuth from 'utils/withAuth';
import Spinner from 'components/UI/Spinner';
import { fetchClients } from 'store/actions/clients';
import Layout from '../Layout';
import classes from './Dashboard.module.css';
import SimpleClientCard from 'components/SimpleClientCard';
import { nanoid } from 'nanoid';
import EmptyState from 'components/EmptyState';
import { Button } from 'components';

const mapStateToProps = ({ clients, auth }: RootState) => {
  return {
    token: auth.token,
    userId: auth.userId,
    clients: clients.clients,
    loading: clients.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchClients: (userId: string) => dispatch(fetchClients(userId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps;

const Dashboard: FC<Props> = ({ token, userId, loading, clients, onFetchClients }) => {
  const history = useHistory();

  useEffect(() => {
    // navigate straight to clients
    history.push('/clients');

    if (token && userId) {
      onFetchClients(userId);
    }
  }, [token, userId, onFetchClients]);
  window.localStorage.removeItem('selectedProgramKey');

  const clientsList = Object.keys(clients || {})
    .filter((clientKey: string) => {
      const user = clients?.[clientKey];
      const { hasPrograms = false } = user || {};

      return !hasPrograms;
    })
    .map((clientKey: string) => {
      const user = clients?.[clientKey];
      const { profile } = user || {};
      const { fullname = '' } = profile || {};

      const initials = getInitials(fullname);

      return <SimpleClientCard key={nanoid()} initials={initials} fullname={fullname} />;
    });

  return (
    <Layout loading={loading} heading={DASHBOARD.TITLE}>
      <div className={classes.Wrapper}>
        {loading ? (
          <Spinner />
        ) : !clientsList.length ? (
          <div className={classes.NoClientsNeedTraining}>
            <EmptyState title="Needs training" description="None of your clients need training right now.">
              <Button to="/programs">View Programs</Button>
            </EmptyState>
          </div>
        ) : (
          <>
            <h3 className={classes.Title}>Needs Training</h3>
            <hr className={classes.Divider} />
            <div className={classes.ListWrapper}>{clientsList}</div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default connector(withAuth(Dashboard));
