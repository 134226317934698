import React, { FC, ReactNode } from 'react';
import { Root, Trigger, Portal, Overlay, Content, Title, Description } from '@radix-ui/react-alert-dialog';
import classes from './AlertDialog.module.css';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-outline.svg';

interface Props {
  title: string;
  description?: string;
  onOpenChange: (open: boolean) => void;
  trigger?: ReactNode;
  children?: ReactNode;
  open: boolean;
  actionButton: ReactNode[] | ReactNode;
  cancelButton?: ReactNode[] | ReactNode;
}

const AlertDialog: FC<Props> = ({
  children,
  title,
  description,
  trigger,
  open,
  onOpenChange,
  actionButton,
  cancelButton,
}) => {
  return (
    <Root open={open} onOpenChange={onOpenChange}>
      {trigger && <Trigger />}
      <Portal>
        <Overlay className={classes.DialogOverlay} />
        <Content className={classes.DialogContent}>
          <div className={classes.DialogHeader}>
            {title && <Title className={classes.DialogTitle}>{title}</Title>}
            {description && <Description className={classes.DialogDescription}>{description}</Description>}
          </div>
          {children && children}

          <button className={classes.IconButton} aria-label="Close" onClick={() => onOpenChange(false)}>
            <CloseIcon className={classes.CloseIcon} />
          </button>

          <div className={classes.Buttons}>
            {cancelButton && <div>{cancelButton}</div>}
            <div>{actionButton}</div>
          </div>
        </Content>
      </Portal>
    </Root>
  );
};

export default AlertDialog;
