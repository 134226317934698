import React, { forwardRef } from 'react';

import Day from './Day/Day';
import { SetKeys, Day as DayType, Week as WeekType } from 'interfaces/db';
import classes from './Week.module.css';
import { ReactComponent as DuplicateIcon } from 'assets/svgs/copy-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';
import Button from 'components/UI/Button';

interface Props {
  week: number;
  days: DayType[];
  saveProgram: () => void;
  addWorkout: (weekIndex: number, dayIndex: number) => Promise<void>;
  setRestDayHandler: (weekIndex: number, dayIndex: number) => void;
  copyWorkoutHandler: (weekIndex: number, dayIndex: number) => void;
  goToWorkoutBuilder: (weekIndex: number, dayIndex: number) => Promise<void>;
  pasteWorkoutHandler: (weekIndex: number, dayIndex: number) => void;
  removeRestDayHandler: (weekIndex: number, dayIndex: number) => void;
  deleteWeekHandler: () => void;
  duplicateWeekHandler: () => void;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number, dayIndex: number) => void;
  addWorkoutNotes: (workoutNotes: string, dayIndex: number, weekIndex: number) => void;
  addSetHandler: (group: number, index: number, dayIndex: number) => void;
  removeSetHandler: (group: number, index: number, dayIndex: number) => void;
  setsChangeHandler: (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    index: number,
    dayIndex: number,
  ) => void;
  autoFillSetsHandler: (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    index: number,
    dayIndex: number,
  ) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousWeek: WeekType | undefined;
  clientKey: string;
  selectedProgramKey: string;
}

const Week = forwardRef<HTMLDivElement | null, Props>(
  (
    {
      week,
      days,
      addWorkout,
      saveProgram,
      addSetHandler,
      addExerciseNotes,
      addWorkoutNotes,
      removeSetHandler,
      deleteWeekHandler,
      setRestDayHandler,
      setsChangeHandler,
      copyWorkoutHandler,
      goToWorkoutBuilder,
      pasteWorkoutHandler,
      duplicateWeekHandler,
      removeRestDayHandler,
      autoFillSetsHandler,
      setShowAutoFill,
      showAutoFill,
      previousWeek,
      clientKey,
      selectedProgramKey,
    },
    ref,
  ) => {
    return (
      <>
        <div ref={ref} className={classes.WeekHeadingDiv}>
          <h4 className={classes.WeekHeadingStyle}>{`Week ${week}`}</h4>

          <div className={classes.WeekActionContainer}>
            <div className={classes.DuplicateDiv}>
              <Button
                type="button"
                intent="secondary"
                onClick={() => duplicateWeekHandler()}
                iconLeft={<DuplicateIcon />}
              >
                DUPLICATE
              </Button>
            </div>

            <div className={classes.DeleteDiv}>
              <Button type="button" intent="secondary" onClick={() => deleteWeekHandler()} iconLeft={<DeleteIcon />}>
                DELETE
              </Button>
            </div>
          </div>
        </div>

        <div className={classes.Week}>
          {days.map((day, index) => (
            <Day
              day={day}
              previousDay={previousWeek?.Days[index] || undefined}
              key={index}
              dayNumber={index}
              weekNumber={week - 1}
              saveProgram={saveProgram}
              setRestDay={setRestDayHandler}
              addExerciseNotes={(exerciseNotes, group, single) => addExerciseNotes(exerciseNotes, group, single, index)}
              addWorkoutNotes={addWorkoutNotes}
              addSetHandler={(group, single) => addSetHandler(group, single, index)}
              removeSetHandler={(group, single) => removeSetHandler(group, single, index)}
              setsChangeHandler={(key: SetKeys, val: string, setIndex: number, group: number, exerciseIndex: number) =>
                setsChangeHandler(key, val, setIndex, group, exerciseIndex, index)
              }
              removeRestDay={removeRestDayHandler}
              addWorkout={() => addWorkout(week - 1, index)}
              copyWorkoutHandler={() => copyWorkoutHandler(week - 1, index)}
              pasteWorkoutHandler={() => pasteWorkoutHandler(week - 1, index)}
              goToWorkoutBuilder={() => goToWorkoutBuilder(week - 1, index)}
              autoFillSetsHandler={(
                key: SetKeys,
                val: string,
                setIndex: number,
                group: number,
                exerciseIndex: number,
              ) => autoFillSetsHandler(key, val, setIndex, group, exerciseIndex, index)}
              setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
              showAutoFill={showAutoFill}
              clientKey={clientKey}
              selectedProgramKey={selectedProgramKey}
            />
          ))}
        </div>
      </>
    );
  },
);

export default Week;
