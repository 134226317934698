import React, { FC } from 'react';
import Button from 'components/UI/Button';
import classes from './ModalButtonGroup.module.css';
import { ButtonProps } from 'interfaces/ui';

interface ButtonGroupProps {
  loading?: boolean;
  onSecondaryClick?: (params?: unknown) => void;
  onMainButtonClick?: (params?: unknown) => void;
  mainButtonText?: string;
  showCancelButton?: boolean;
  secondaryButtonText?: string;
  mainButtonIntent?: ButtonProps['intent'];
  secondaryButtonIntent?: ButtonProps['intent'];
  mainButtonType?: ButtonProps['type'];
  mainButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
}

const ModalButtonGroup: FC<ButtonGroupProps> = ({
  onSecondaryClick,
  onMainButtonClick,
  mainButtonText = 'Ok',
  showCancelButton = true,
  secondaryButtonText = 'Cancel',
  mainButtonIntent = 'primary',
  secondaryButtonIntent = 'secondary',
  mainButtonType = 'button',
  loading = false,
  mainButtonDisabled = false,
  secondaryButtonDisabled = false,
}) => {
  return (
    <div className={classes.Buttons}>
      {showCancelButton && (
        <Button
          type="button"
          intent={secondaryButtonIntent}
          disabled={loading || secondaryButtonDisabled}
          onClick={onSecondaryClick}
        >
          {secondaryButtonText}
        </Button>
      )}
      <Button
        type={mainButtonType}
        intent={mainButtonIntent}
        loading={loading}
        disabled={mainButtonDisabled}
        onClick={onMainButtonClick}
      >
        {mainButtonText}
      </Button>
    </div>
  );
};

export default ModalButtonGroup;
