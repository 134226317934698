import { NutrientObject, NutritionDay, NutritionPlan, NutritionWeek } from 'interfaces/nutrition';
import { resetNutrientObject, resetNutrients } from './duplicateWeek';
import rfdc from 'rfdc';

export const processCopiedDay = (
  plan: NutritionPlan,
  day: NutritionDay | null,
  newWeekIndex: number,
  newDayIndex: number,
  weeksRef,
): NutritionPlan | null => {
  const clone = rfdc();
  if (!day || !plan.weeks || newWeekIndex >= plan.weeks.length || !plan.weeks[newWeekIndex]?.days) {
    return null;
  }
  const weeksRefWeeks: NutritionWeek[] = weeksRef.current
    .map((week) => week?.getValues())
    .filter((week) => week !== null && week !== undefined);
  const newPlan = clone({ ...plan, weeks: weeksRefWeeks });

  if (!newPlan.weeks || newDayIndex >= newPlan.weeks[newWeekIndex].days!.length) {
    return null;
  }

  const newDay = clone(day);
  const resetMacros = newDay.macros ? resetNutrients(newDay.macros) : undefined;
  const resetMicros = newDay.micros ? resetNutrients(newDay.micros) : undefined;

  const duplicatedDay = {
    ...newDay,
    dayIndex: newDayIndex,
    finished: false,
    dateFinished: undefined,
    calories: resetNutrientObject(newDay.calories as NutrientObject),
    macros: resetMacros,
    micros: resetMicros,
  };

  newPlan.weeks[newWeekIndex].days![newDayIndex] = duplicatedDay;

  return newPlan;
};
