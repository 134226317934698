import React, { useState, FunctionComponent, ChangeEvent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { RootState } from 'store';

import Table from '../ExerciseTable';
import { ExerciseLibForm, Autocomplete } from 'components';
import ExerciseHistoryBox from '../ExerciseHistoryBox';
import { getThumbnailURL, formValues } from 'utils/helpers';
import { Set, SetKeys, Exercise } from 'interfaces/db';
import { saveExercise, fetchExercises } from 'store/actions/exerciseLibrary';
import classes from './ExerciseBlock.module.css';
import { IndexedExercise } from 'interfaces/utils';
import { Dialog, Button, TextArea } from 'components/UI';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/svgs/analytics-filled.svg';
import { ReactComponent as ChevronUp } from 'assets/svgs/chevron-up-outline.svg';
import { ReactComponent as ChevronDown } from 'assets/svgs/chevron-down-outline.svg';

type Suggestions = Exercise & {
  author: 'coach' | 'admin';
};

type CoachExerciseNotes = {
  coachExerciseNotes: string;
};

interface Props {
  fullname: string;
  finished: boolean;
  group: number;
  index: number;
  exercise: Exercise;
  clientKey?: string;
  programKey?: string;
  addSetsHandler: (sets: Set[], group: number, index: number) => void;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number) => void;
  addExerciseType: (exerciseType: string, group: number, index: number) => void;
  openAnalytics: (indexedExercise: IndexedExercise) => void;
  closeAnalytics: (indexedExercise: IndexedExercise) => void;
  analyticsExerciseList?: IndexedExercise[];
  bottom: boolean;
  supersetLength: number;
  swapSets: (groupIndexOne: number, groupIndexTwo: number) => void;
  swapInSupersets: (groupIndex: number, indexOne: number, indexTwo: number) => void;
  addSetHandler: () => void;
  removeSetHandler: () => void;
  setsChangeHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  autoFillSetsHandler: (setType: SetKeys, value: string, setIndex: number) => void;
  setTypeChangeHandler: (setType: SetKeys, setIndex: number) => void;
  suggestions: Suggestions[];
  suggestionsSource: Suggestions[];
  setSuggestions: (val: Suggestions[]) => void;
  setExerciseFromLib: (exercise: Suggestions) => void;
  removeTypeChangeHandler: (setKey: SetKeys) => void;
  addTypeChangehandler: (setType: SetKeys) => void;
  addSuperSet: (index: number) => void;
  handleExerciseDelete: (group: number, index: number) => void;
}

const mapStateToProps = ({ auth, clients, settings }: RootState) => {
  const { token, coachProfile, userId } = auth;

  return {
    token,
    coachKey: Object.keys(coachProfile || {})[0],
    clients: clients.clients,
    userId,
    theme: settings.theme,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchExercise: (token: string, coachKey: string) => dispatch(fetchExercises(token, coachKey)),

    onSaveExercise: (
      token: string,
      coachKey: string,
      exercise: Exercise,
      location: 'exercise_library' | 'workout_builder',
    ) => dispatch(saveExercise(token, coachKey, exercise, location)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = Partial<ConnectedProps<typeof connector>>;

const ExerciseBlock: FunctionComponent<Props & PropsFromRedux> = ({
  group,
  index,
  token,
  bottom,
  exercise,
  coachKey,
  finished,
  swapSets,
  fullname,
  clientKey,
  clients,
  userId,
  programKey,
  openAnalytics,
  closeAnalytics,
  analyticsExerciseList,
  addSetHandler,
  supersetLength,
  onSaveExercise,
  onFetchExercise,
  addExerciseNotes,
  swapInSupersets,
  addExerciseType,
  removeSetHandler,
  suggestionsSource,
  setsChangeHandler,
  autoFillSetsHandler,
  setExerciseFromLib,
  setTypeChangeHandler,
  addTypeChangehandler,
  removeTypeChangeHandler,
  addSuperSet,
  handleExerciseDelete,
  theme,
}) => {
  const { register, setValue } = useForm<CoachExerciseNotes>();
  const [initialValues, setInitialValues] = useState(formValues);
  const [exerciseModal, showExerciseModal] = useState(false);
  const [showAutoFill, _setShowAutoFill] = useState<string>('');
  const [thumbnailURL, setThumbnailURL] = useState<string>('');

  const setShowAutoFill = (setIdentifier: string) => {
    _setShowAutoFill(setIdentifier);
  };

  const handleSubmit = (val: Exercise) => {
    if (token && coachKey && onSaveExercise && onFetchExercise) {
      onSaveExercise(token, coachKey, val, 'workout_builder');
      showExerciseModal(false);
    }
  };

  const handleAddToLibrary = () => {
    const { type = '' } = exercise;

    setInitialValues({ ...formValues, type });
    showExerciseModal(true);
  };

  const exerciseTypeOnChangeHandler = (value: string) => {
    addExerciseType(value, group, index - 1);
  };

  const exerciseNotesOnChangeHandler = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = target;
    addExerciseNotes(value, group, index - 1);
  };

  const isAnalyticsVisible = (index: number, group: number) => {
    return analyticsExerciseList?.some((indexedExercise) => {
      return indexedExercise.index === index && indexedExercise.group === group;
    });
  };

  const exerciseHistoryButton = (exercise: Exercise, index: number, group: number) => {
    return (
      <button className={classes.AnalyticsButton} onClick={() => openAnalytics({ exercise, index, group })}>
        <AnalyticsIcon />
        <p>Open Exercise History</p>
      </button>
    );
  };

  const coachNotes = exercise.exerciseNotes?.coach;
  const athleteNotes = exercise.exerciseNotes?.athlete;

  useEffect(() => {
    if (!exercise) {
      return;
    }
    const { exerciseNotes } = exercise;
    setValue('coachExerciseNotes', exerciseNotes?.coach || '');
  }, [exercise]);

  useEffect(() => {
    (async () => {
      if (!exercise?.videoURL) {
        return;
      }
      const thumbnail = await getThumbnailURL(exercise.videoURL);
      setThumbnailURL(thumbnail);
    })();
  }, [exercise?.videoURL]);

  const block = exercise && (
    <div className={classes.Row}>
      <div className={classes.ExerciseBlock}>
        <div className={classes.BlockWrapper}>
          <div className={finished ? classes.ExerciseWrapperReview : classes.ExerciseWrapper}>
            <div className={classes.Exercise}>
              <Autocomplete
                footer={
                  <Button iconLeft={<PlusIcon />} intent="secondary" onClick={handleAddToLibrary}>
                    Add to exercise library
                  </Button>
                }
                fullname={fullname}
                exerciseType={exercise.type}
                suggestions={suggestionsSource}
                setExerciseFromLib={setExerciseFromLib}
                exerciseTypeOnChangeHandler={exerciseTypeOnChangeHandler}
              />
            </div>

            {exercise?.videoURL && thumbnailURL && (
              <a target="_blank" href={exercise.videoURL} rel="noreferrer noopener" className={classes.VideoURL}>
                <img alt="video thumbnail" className={classes.VideoThumb} src={thumbnailURL} />
              </a>
            )}

            <div className={clsx(classes.ExerciseNotes, finished && classes.Column)}>
              <div className={classes.FullWidth}>
                {finished && <p className={classes.NotesLabel}>Coach Notes</p>}
                <TextArea
                  register={register}
                  onChange={exerciseNotesOnChangeHandler}
                  name="coachExerciseNotes"
                  placeholder={finished ? 'No coach notes.' : 'Exercise Notes'}
                  defaultValue={coachNotes || ''}
                  readOnly={finished}
                />
              </div>
              {finished && (
                <div className={classes.FullWidth}>
                  {finished && <p className={classes.NotesLabel}>Client Feedback</p>}
                  <TextArea
                    register={register}
                    onChange={exerciseNotesOnChangeHandler}
                    name="clientExerciseNotes"
                    placeholder={athleteNotes ? '' : 'No client feedback.'}
                    defaultValue={athleteNotes || ''}
                    readOnly={true}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={classes.SetWrapper}>
            <div className={classes.SetTable}>
              <Table
                finished={finished}
                exercise={exercise}
                addSetHandler={addSetHandler}
                removeSetHandler={removeSetHandler}
                setsChangeHandler={setsChangeHandler}
                autoFillSetsHandler={autoFillSetsHandler}
                setTypeChangeHandler={setTypeChangeHandler}
                addTypeChangehandler={addTypeChangehandler}
                removeTypeChangeHandler={removeTypeChangeHandler}
                setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
                showAutoFill={showAutoFill}
                achievedTextColor={theme['--color-accent-primary'] || '#7782F8'}
                programmedTextColor={theme['--color-text-primary'] || '#181818'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const moveUp = () => {
    if (!group && !(index - 1)) {
      return;
    }
    if (supersetLength) {
      if (index - 1 === 0) {
        swapSets(group, group - 1);
        return;
      } else {
        swapInSupersets(group, index - 1, index - 2);
        return;
      }
    }
    swapSets(group, group - 1);
  };

  const moveDown = () => {
    if (bottom) {
      return;
    }

    if (supersetLength) {
      if (supersetLength === index - 1) {
        swapSets(group, group + 1);
        return;
      } else {
        swapInSupersets(group, index - 1, index);
        return;
      }
    }
    swapSets(group, group + 1);
  };

  return (
    <>
      <div className={classes.ContainerStyle}>
        <div className={classes.Row}>
          <div className={classes.ExerciseBlock}>{block}</div>

          {finished || isAnalyticsVisible(index, group) ? (
            isAnalyticsVisible(index, group) ? null : (
              exerciseHistoryButton(exercise, index, group)
            )
          ) : (
            <div className={classes.ExerciseMoveContainer}>
              <button
                onClick={moveUp}
                className={!group && !(index - 1) ? classes.MoveButtonDisabled : classes.MoveButton}
              >
                <ChevronUp width="2.25rem" />
                <p>{!supersetLength || index !== 1 ? 'Move Exercise Up' : 'Move Superset Up'}</p>
              </button>
              <button onClick={moveDown} className={bottom ? classes.MoveButtonDisabled : classes.MoveButton}>
                <ChevronDown width="2.25rem" />
                <p>{!supersetLength || index - 1 !== supersetLength ? 'Move Exercise Down' : 'Move Superset Down'}</p>
              </button>
              {!programKey && exerciseHistoryButton(exercise, index, group)}
            </div>
          )}
        </div>
        {!finished && (
          <div className={classes.AddSuperSet}>
            <div>
              <Button type="button" onClick={addSuperSet}>
                MAKE SUPERSET
              </Button>
            </div>
            <div>
              <Button type="button" intent="secondary" onClick={handleExerciseDelete} iconLeft={<DeleteIcon />}>
                DELETE
              </Button>
            </div>
          </div>
        )}
      </div>

      {isAnalyticsVisible(index, group) && !programKey && (
        <div className={classes.ExerciseHistoryWrapper}>
          <ExerciseHistoryBox
            closeAnalytics={closeAnalytics}
            indexedExercise={{ exercise, index, group }}
            userId={clientKey ? clients?.[clientKey].clientUserId : userId}
            weightColor={theme['--color-accent-primary'] || '#7782F8'}
            volumeColor={theme['--color-background-secondary'] || '#dbdbdb'}
            weightTextColor={theme['--color-text-on-accent'] || '#ffffff'}
            volumeTextColor={theme['--color-text-primary'] || '#181818'}
          />
        </div>
      )}

      <Dialog title="Create exercise" open={exerciseModal} onOpenChange={showExerciseModal}>
        <ExerciseLibForm handleSubmit={handleSubmit} initialValues={initialValues} setModalOpen={showExerciseModal} />
      </Dialog>
    </>
  );
};
export default connector(ExerciseBlock);
