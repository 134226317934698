import { MixpanelService, EventProperties } from './mixpanelService';

export type ChatEventProperties = EventProperties & {
  location: 'chat';
  contains_media?: boolean;
  channel_type?: 'direct' | 'group';
  is_community_chat_enabled?: boolean;
  is_direct_chat_enabled?: boolean;
};

class ChatTrackingService extends MixpanelService {
  public trackChatEvent(eventName: string, properties?: ChatEventProperties): void {
    super.trackEvent(eventName, properties);
  }
}

export const chatTrackingService = new ChatTrackingService();
