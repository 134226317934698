import { NutritionWeek } from 'interfaces/nutrition';
import { SelectOptionProps } from 'interfaces/ui';

export const generateListOfWeeks = (weeks: NutritionWeek[]): SelectOptionProps[] => {
  const listOfWeeks = weeks.map((week, index) => {
    return {
      value: week.id,
      label: `Week ${index + 1}`,
    };
  });

  return listOfWeeks;
};
