import React, { FC } from 'react';
import * as Progress from '@radix-ui/react-progress';
import classes from './ProgressBar.module.css';

interface Props {
  progress: number;
}

const ProgressBar: FC<Props> = ({ progress }) => {
  return (
    <Progress.Root className={classes.ProgressRoot} value={progress}>
      <Progress.Indicator
        className={classes.ProgressIndicator}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root>
  );
};

export default ProgressBar;
