import React, { FC, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Exercise from 'components/Exercise';
import QuickEditModal from 'components/WorkoutQuickEdit/QuickEditModal';
import { SetKeys, Day as DayType } from 'interfaces/db';
import classes from './Day.module.css';
import { RootState } from 'store';
import KebabMenu from 'components/KebabMenu';
import Button from 'components/UI/Button';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-filled.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { ReactComponent as RestIcon } from 'assets/svgs/rest-outline.svg';
import { ReactComponent as WorkoutFinishedIcon } from 'assets/svgs/day-complete.svg';
import { ReactComponent as CopyIcon } from 'assets/svgs/copy-filled.svg';
import { ReactComponent as PasteIcon } from 'assets/svgs/paste-filled.svg';
import DropdownMenu from 'components/UI/DropdownMenu';
import { useDropdown } from 'hooks';
import { ProgramEventProperties, programTrackingService } from 'utils/tracking/programService';

interface Props {
  day: DayType;
  dayNumber: number;
  weekNumber: number;
  saveProgram: () => void;
  setRestDay: (weekIndex: number, dayIndex: number) => void;
  removeRestDay: (weekIndex: number, dayIndex: number) => void;
  addWorkout: () => Promise<void>;
  copyWorkoutHandler: () => void;
  pasteWorkoutHandler: () => void;
  goToWorkoutBuilder: () => Promise<void>;
  addExerciseNotes: (exerciseNotes: string, group: number, index: number) => void;
  addWorkoutNotes: (workoutNotes: string, dayIndex: number, weekIndex: number) => void;
  addSetHandler: (group: number, index: number) => void;
  removeSetHandler: (group: number, index: number) => void;
  setsChangeHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  autoFillSetsHandler: (key: SetKeys, val: string, setIndex: number, group: number, index: number) => void;
  setShowAutoFill: (setIdentifier: string) => void;
  showAutoFill: string;
  previousDay?: DayType | undefined;
  clientKey: string;
  selectedProgramKey: string;
}

const mapStateToProps = ({ clients, settings }: RootState) => {
  return {
    clients: clients.clients,
    theme: settings.theme,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const Day: FC<Props & PropsFromRedux> = ({
  day,
  dayNumber,
  setRestDay,
  weekNumber,
  addWorkout,
  saveProgram,
  addSetHandler,
  removeRestDay,
  addExerciseNotes,
  addWorkoutNotes,
  removeSetHandler,
  setsChangeHandler,
  copyWorkoutHandler,
  goToWorkoutBuilder,
  pasteWorkoutHandler,
  autoFillSetsHandler,
  setShowAutoFill,
  showAutoFill,
  previousDay,
  clientKey,
  clients,
  theme,
  selectedProgramKey,
}) => {
  const { isOpen, closeDropdown, toggleDropdown } = useDropdown();
  const [isQuickEdit, setIsQuickEdit] = useState(false);

  const removeRestDayHandler = () => {
    removeRestDay(weekNumber, dayNumber);
  };

  const setRestDayHandler = () => {
    closeDropdown();
    setRestDay(weekNumber, dayNumber);
  };

  let exercises;

  if (day.rest === true) {
    exercises = (
      <div className={classes.RestDay}>
        <h6 className={classes.RestDayHeading}>REST DAY</h6>
        <Button type="button" onClick={removeRestDayHandler} intent="secondary">
          REMOVE REST DAY
        </Button>
      </div>
    );
  } else {
    exercises =
      day.workout &&
      day.workout.exercises &&
      Object.keys(day.workout.exercises).map((_, index) => {
        if (day.workout.exercises?.[index]) {
          return (
            <Exercise
              key={index}
              exerciseBlock={String.fromCharCode(65 + index)}
              exercise={day.workout.exercises[index]}
            />
          );
        }

        return null;
      }); // group might need to be index
  }
  const finished = day.workout && day.workout.finished;

  const menuItems = [
    {
      title: 'Copy',
      onClick: copyWorkoutHandler,
      iconLeft: <CopyIcon />,
    },
    {
      title: 'Paste',
      onClick: pasteWorkoutHandler,
      iconLeft: <PasteIcon />,
    },
    {
      title: 'Add rest day',
      onClick: setRestDayHandler,
      iconLeft: <RestIcon />,
    },
  ];

  const goToWorkoutBuilderHandler = (finished: boolean) => {
    const baseEvent: ProgramEventProperties = {
      program_id: selectedProgramKey,
      location: 'client_training',
      object: 'button',
      action: 'click',
      interaction_type: 'navigate_to_workout_builder',
    };
    if (!finished) {
      programTrackingService.trackProgramEvent('edit_workout', {
        ...baseEvent,
        button: {
          text: 'EDIT WORKOUT',
          action: 'navigate_to_workout_builder',
          description: 'Edit workout button on day in client training',
        },
      });
    } else {
      programTrackingService.trackProgramEvent('view_workout_feedback', {
        ...baseEvent,
        button: {
          text: 'VIEW WORKOUT',
          action: 'navigate_to_workout_builder',
          description: 'View workout button on day in client training for completed workout',
        },
      });
    }

    goToWorkoutBuilder();
  };

  const openQuickEdit = () => {
    programTrackingService.trackProgramEvent('edit_workout', {
      location: 'client_training',
      object: 'button',
      action: 'click',
      interaction_type: 'open_quick_edit_modal',
      button: {
        text: 'QUICK EDIT',
        action: 'open_quick_edit_modal',
        description: 'Quick edit button on day in client training',
      },
    });
    setIsQuickEdit(true);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.Day}>
          {finished ? (
            <div>
              <h6 className={classes.DayHeading}>Day {dayNumber + 1}</h6>
              <span className={classes.WorkoutName}>
                {day.rest ? 'Rest Day' : day.workout && day.workout.workoutTitle}
              </span>
              <div className={classes.WorkoutFinished}>
                <WorkoutFinishedIcon />
                <span className={classes.WorkoutCompleteText}>WORKOUT COMPLETE</span>
              </div>

              <Button type="button" onClick={() => goToWorkoutBuilderHandler(true)}>
                View Workout
              </Button>
            </div>
          ) : (
            <div>
              <div className={classes.Header}>
                <div>
                  <h6 className={classes.DayHeading}>Day {dayNumber + 1}</h6>
                  <span className={classes.WorkoutName}>
                    {day.rest ? 'Rest Day' : day.workout && day.workout.workoutTitle}
                  </span>
                </div>
                <DropdownMenu
                  menuItems={menuItems}
                  open={isOpen}
                  onOpenChange={toggleDropdown}
                  width={'12.5rem'}
                  trigger={<KebabMenu onClick={toggleDropdown} />}
                  side="right"
                  align="start"
                />
              </div>

              <div className={classes.DayContent}>
                <div className={classes.Workouts}>{exercises}</div>

                {!finished && !day.rest && (!day.workout.exercises || !day.workout.exercises.length) ? (
                  <>
                    <div className={classes.AddButtonWrapper} onClick={addWorkout}>
                      <Button type="button" size="small" iconCenter={<PlusIcon />} />
                      <div className={classes.AddText}>Add Workout</div>
                    </div>

                    <div className={classes.AddButtonWrapper} onClick={setRestDayHandler}>
                      <Button type="button" size="small" iconCenter={<RestIcon />} />
                      <div className={classes.AddText}>Make rest day</div>
                    </div>
                  </>
                ) : day.rest || finished ? null : (
                  <div className={classes.EditButtonsContainer}>
                    <Button type="button" onClick={() => goToWorkoutBuilderHandler(false)} iconLeft={<EditIcon />}>
                      Edit Workout
                    </Button>
                    <Button type="button" intent="secondary" onClick={openQuickEdit} iconLeft={<EditIcon />}>
                      Quick Edit
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <QuickEditModal
        showModal={isQuickEdit}
        day={day}
        dayNumber={dayNumber}
        weekNumber={weekNumber}
        saveProgram={saveProgram}
        addSetHandler={addSetHandler}
        setIsQuickEdit={setIsQuickEdit}
        removeSetHandler={removeSetHandler}
        addExerciseNotes={addExerciseNotes}
        addWorkoutNotes={addWorkoutNotes}
        setsChangeHandler={setsChangeHandler}
        autoFillSetsHandler={autoFillSetsHandler}
        setShowAutoFill={setShowAutoFill}
        showAutoFill={showAutoFill}
        previousDay={previousDay}
        clientId={clients?.[clientKey].clientUserId}
        weightColor={theme['--color-accent-primary'] || '#7782F8'}
        volumeColor={theme['--color-background-secondary'] || '#dbdbdb'}
        weightTextColor={theme['--color-text-on-accent'] || '#ffffff'}
        volumeTextColor={theme['--color-text-primary'] || '#181818'}
        programmedTextColor={theme['--color-text-primary'] || '#181818'}
        achievedTextColor={theme['--color-accent-primary'] || '#7782F8'}
      />
    </>
  );
};

export default connector(Day);
