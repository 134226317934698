import React, { useEffect, useRef, HTMLProps } from 'react';
import classes from './IndeterminateCheckbox.module.css';
import clsx from 'clsx';

const IndeterminateCheckbox = ({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} className={clsx(classes.Checkbox, className)} {...rest} />;
};

export default IndeterminateCheckbox;
