import React, { FC, ReactNode } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import classes from './Dialog.module.css';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-outline.svg';
import clsx from 'clsx';

interface DialogProps {
  title: string | ReactNode;
  description?: string;
  onOpenChange: (open: boolean) => void;
  trigger?: ReactNode;
  hideCloseButton?: boolean;
  children: ReactNode;
  open: boolean;
}

export interface DialogHandle {
  openDialog: () => void;
  closeDialog: () => void;
}

const Dialog: FC<DialogProps> = ({
  children,
  title,
  description,
  trigger,
  hideCloseButton = false,
  open,
  onOpenChange,
}) => {
  return (
    <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
      {trigger && (
        <RadixDialog.Trigger asChild>
          <div>{trigger}</div>
        </RadixDialog.Trigger>
      )}
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={classes.DialogOverlay} />
        <RadixDialog.Content className={classes.DialogContent}>
          <div>
            {typeof title === 'string' ? (
              <RadixDialog.Title className={clsx(classes.DialogTitle, !description && classes.HeaderMarginBottom)}>
                {title}
              </RadixDialog.Title>
            ) : (
              title
            )}
            {description && (
              <RadixDialog.Description className={clsx(classes.DialogDescription, classes.HeaderMarginBottom)}>
                {description}
              </RadixDialog.Description>
            )}
          </div>
          {children}

          {!hideCloseButton && (
            <RadixDialog.Close asChild>
              <button className={classes.IconButton} aria-label="Close" onClick={() => onOpenChange(false)}>
                <CloseIcon className={classes.CloseIcon} />
              </button>
            </RadixDialog.Close>
          )}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export default Dialog;
