import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import isEqual from 'react-fast-compare';

import classes from './Week.module.css';
import { NutritionWeek, NutritionDay } from 'interfaces/nutrition';
import Day from '../Day';
import { Button } from 'components/UI';
import { ReactComponent as DuplicateIcon } from 'assets/svgs/copy-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';

interface Props {
  week: NutritionWeek;
  weekIndex: number;
  copyDay: (day: NutritionDay) => void;
  pasteDay: (weekIndex: number, dayIndex: number) => void;
  handleOpenGoalsModal: (weekId: string, dayIndex: number, day: NutritionDay) => void;
  onFormChange: (val: boolean) => void;
  handleDuplicateWeek: (weekIndex: number) => void;
  handleDeleteWeek: (weekId: string) => void;
}

export interface WeekMethods {
  getValues: () => NutritionWeek;
  domElement: React.RefObject<HTMLDivElement>;
}

const Week = forwardRef<WeekMethods, Props>(
  (
    { week, weekIndex, copyDay, pasteDay, handleOpenGoalsModal, onFormChange, handleDuplicateWeek, handleDeleteWeek },
    ref,
  ) => {
    const methods = useForm<NutritionWeek>({ defaultValues: week });
    const [initialValues, setInitialValues] = useState(week);

    const domElement = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      getValues: methods.getValues,
      domElement,
    }));

    useEffect(() => {
      methods.reset(week);
      setInitialValues(week);
    }, [week, methods.reset]);

    useEffect(() => {
      const subscription = methods.watch((values) => {
        const isFormDirty = !isEqual(values?.days, initialValues?.days);
        onFormChange(isFormDirty);
      });
      return () => subscription.unsubscribe();
    }, [methods.watch, initialValues, onFormChange]);

    const days = week.days || [];

    return (
      <>
        <div className={classes.WeekHeadingDiv} ref={domElement}>
          <h4 className={classes.WeekHeadingStyle}>{`Week ${weekIndex + 1}`}</h4>
          <div className={classes.WeekActionContainer}>
            <div className={classes.DuplicateDiv}>
              <Button
                type="button"
                intent="secondary"
                onClick={() => handleDuplicateWeek(weekIndex)}
                iconLeft={<DuplicateIcon />}
              >
                DUPLICATE
              </Button>
            </div>

            <div className={classes.DeleteDiv}>
              <Button
                type="button"
                intent="secondary"
                onClick={() => handleDeleteWeek(week.id)}
                iconLeft={<DeleteIcon />}
              >
                DELETE
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.Week}>
          <FormProvider {...methods}>
            {days.map((day, index) => (
              <Day
                key={index}
                day={day}
                weekIndex={weekIndex}
                weekId={week.id}
                copyDay={copyDay}
                pasteDay={pasteDay}
                handleOpenGoalsModal={handleOpenGoalsModal}
              />
            ))}
          </FormProvider>
        </div>
      </>
    );
  },
);

Week.displayName = 'Week';

export default Week;
