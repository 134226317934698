import React, { FC, useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import { firestore } from 'utils/firebase';

import { getInitials } from 'utils/helpers';
import { CoachProfile } from 'interfaces/db';
import { useLocalStorage } from 'utils/hooks';
// import { LS_PRICING_INFO } from 'utils/constants';
import { CoachCodeForm } from 'components/forms';
// import { LocalStoragePricing } from 'interfaces/utils';
import { fetchCoachProfile, logout, saveCoachCode, saveCoachProfile } from 'store/actions/auth';
import { ACCOUNT, LOGOUT, PROGRAMS } from 'utils/routes';
import SideDrawer from '../SideDrawer';
import NotificationCentre from 'components/NotificationCentre';
import classes from './Header.module.css';
import DropdownMenu from '../DropdownMenu';
import Dialog from '../Dialog';
import NameBadge from 'components/NameBadge';
import { useDropdown, useClientCountAndProfile } from 'hooks';
import { ReactComponent as NotificationBellSvg } from 'assets/svgs/notification-bell-filled.svg';
import { ReactComponent as ChevronDown } from 'assets/svgs/chevron-down.svg';
// import { ReactComponent as CrediCardIcon } from 'assets/svgs/credit-card-filled.svg';
import { ReactComponent as ProfileIcon } from 'assets/svgs/profile-filled.svg';
import { ReactComponent as LogoutIcon } from 'assets/svgs/logout-filled.svg';
import { ReactComponent as BackIcon } from 'assets/svgs/chevron-back-outline.svg';
import { ReactComponent as LogoImage } from 'assets/svgs/header-logo.svg';
import { isClientProfileBasePath } from 'utils/navigation';

const partialProfile: Pick<
  CoachProfile,
  'isPaidSubscriptionActive' | 'planId' | 'clientCount' | 'code' | 'fullname' | 'profile_url'
> = {
  isPaidSubscriptionActive: false,
  planId: 1,
  clientCount: 0,
  code: 'test',
  fullname: '',
  profile_url: '',
};

const mapStateToProps = (state: RootState) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    isRegistered: state.auth.isRegistered,
    coachProfile: state.auth.coachProfile,
    dbPlans: state.plans.plans,
    clients: state.clients.clients,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: () => dispatch(logout()),
    onSaveCoachCode: (userId: string, coachKey: CoachProfile & { key: string }, code: string) =>
      dispatch(saveCoachCode(userId, coachKey, code)),
    onSaveCoachProfile: (coachProfile: CoachProfile, coachProfileKey: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey)),
    onFetchCoachProfile: (userId: string, isRegistered: boolean) => dispatch(fetchCoachProfile(userId, isRegistered)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  loading: boolean;
  heading: string;
  viewType: 'standard' | 'client';
};

const Header: FC<Props> = ({
  token,
  userId,
  loading,
  heading,
  dbPlans,
  onLogout,
  coachProfile,
  isRegistered,
  onSaveCoachCode,
  onSaveCoachProfile,
  onFetchCoachProfile,
  clients,
  viewType,
}) => {
  const { toggleDropdown, isOpen } = useDropdown();
  const history = useHistory();
  const { location } = history;
  // const [selectedPricing] = useLocalStorage<LocalStoragePricing | null>(LS_PRICING_INFO, null);
  const [coachKey, setCoachKey] = useState<string>('');
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useLocalStorage<number>('unreadNotificationsCount', 0);

  const notificationBellRef = useRef(null);

  const { profileObj, setProfileObj } = useClientCountAndProfile({
    clients,
    token,
    userId,
    dbPlans,
    onFetchCoachProfile,
    onSaveCoachProfile,
  });

  useEffect(() => {
    const key = Object.keys(coachProfile || {})[0];
    setCoachKey(key);
    const profile: any = coachProfile?.[key] ?? partialProfile;
    setProfileObj(profile);
  }, [coachProfile]);

  // TODO would rather use the newer firebease sdk to get the count, but need to upgrade the sdk first

  useEffect(() => {
    if (!userId) return;

    const unsubscribe = firestore
      .collection('users')
      .doc(userId)
      .collection('notifications')
      .where('isRead', '==', false)
      .onSnapshot((snapshot) => {
        setUnreadCount(snapshot.size); // This now sets both the state and the localStorage.
      });

    return () => {
      unsubscribe();
    };
  }, []);

  const logout = () => {
    onLogout();
  };

  const setCoachCode = (code: string) => {
    if (token && userId && coachProfile) {
      onSaveCoachCode(userId, { ...coachProfile[coachKey], key: coachKey }, code);
    }

    // if (selectedPricing) {
    //   history.push(BILLING.URL);
    // }
  };

  const canGoBack = history.length && location.pathname.split('/').length > 2;

  const handleBackClick = () => {
    if (location.pathname.includes('/programs/') && !location.pathname.includes('workout-builder')) {
      history.replace(PROGRAMS.URL);
    } else {
      history.goBack();
    }
  };

  const handleBackClickClientView = () => {
    if (isClientProfileBasePath(location.pathname)) {
      history.replace('/clients');
    } else {
      history.goBack();
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen((isDrawerOpen) => !isDrawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const { code: coachCode = '', fullname } = profileObj || partialProfile;
  const initials = getInitials(fullname + '');

  const menuItems = [
    {
      title: ACCOUNT.TITLE,
      url: ACCOUNT.URL,
      iconLeft: <ProfileIcon />,
    },
    // {
    //   title: BILLING.TITLE,
    //   url: BILLING.URL,
    //   iconLeft: <CrediCardIcon />,
    // },
    {
      title: LOGOUT.TITLE,
      onClick: logout,
      iconLeft: <LogoutIcon />,
    },
  ];

  const renderLeftSection = () => {
    return viewType === 'client' ? (
      <div className={classes.ClientProfileLeftSection}>
        <div className={classes.Logo}>
          <a href={'/clients'}>
            <LogoImage />
          </a>
        </div>
        <div className={classes.BackToClients} onClick={handleBackClickClientView}>
          <button type="button" className={classes.BackBtn}>
            <BackIcon width={24} height={24} />
          </button>
          <span>{isClientProfileBasePath(location.pathname) ? 'BACK TO CLIENTS' : heading}</span>
        </div>
      </div>
    ) : (
      <div className={classes.PageTitle}>
        {loading ? (
          <h2>Loading...</h2>
        ) : (
          <>
            {canGoBack && (
              <button type="button" className={classes.BackBtn} onClick={handleBackClick}>
                <BackIcon width={24} height={24} />
              </button>
            )}

            <h2>{heading}</h2>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={classes.Header}>
        {renderLeftSection()}
        <div className={classes.FlexWrapper}>
          <div ref={notificationBellRef} className={classes.NotificationBell} onClick={toggleDrawer}>
            <NotificationBellSvg />
            {unreadCount > 0 && <span className={classes.UnreadCount}>{unreadCount}</span>}
          </div>

          <DropdownMenu
            menuItems={menuItems}
            trigger={
              <div className={classes.FlexWrapper}>
                <div className={classes.ProfileWrapper}>
                  <div className={classes.Account}>
                    <NameBadge size="small" initials={initials} />
                  </div>

                  <div className={classes.ChevronDropDown}>
                    <ChevronDown />
                  </div>
                </div>
              </div>
            }
            width="10.5rem"
            open={isOpen}
            onOpenChange={toggleDropdown}
            side="bottom"
            align="end"
          />
        </div>
      </div>

      <Dialog
        title="Please enter your coach code"
        description="This code will be used by your clients to join your crew!"
        open={isRegistered || !coachCode}
        onOpenChange={() => null}
        hideCloseButton
      >
        <CoachCodeForm handleSubmit={setCoachCode} />
      </Dialog>

      <SideDrawer isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} exemptedRef={notificationBellRef} side="right">
        <NotificationCentre coachId={userId} />
      </SideDrawer>

      {/* {!isSubscriptionActive && (
        <div className={classes.SubNotification}>You have exceeded the limits of your current plan.</div>
      )} */}
    </>
  );
};

export default connector(Header);
