import { MixpanelService, EventProperties } from './mixpanelService';

export type DocumentEventProperties = EventProperties & {
  location: 'documents' | 'client_documents';
  document_title?: string;
  document_id?: string;
  document_category?: string;
  category_title?: string;
};

class DocumentTrackingService extends MixpanelService {
  public trackDocumentEvent(eventName: string, properties?: DocumentEventProperties): void {
    super.trackEvent(eventName, properties);
  }
}

export const documentTrackingService = new DocumentTrackingService();
