import React, { FC, useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { Buffer } from 'buffer';

import classes from './PostPayment.module.css';
import { verifyCheckoutSession } from 'api/clientBillingApi';
import { PostPaymentForm } from 'components';
import { BASE_URL, encodeData } from 'utils/helpers';
import GooglePlayBadge from 'assets/images/Google-play.svg';
import AppleStoreBadge from 'assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import AppGalleryBadge from 'assets/images/app-gallery.svg';
import Spinner from 'components/UI/Spinner';
import Button from 'components/UI/Button';
import AlertDialog from 'components/UI/AlertDialog';
import Footer from 'components/Footer';
import Logo from 'components/Logo';
import { VerifyResponse } from 'interfaces/api';
import { paymentTrackingService } from 'utils/tracking/paymentService';

type FormValues = {
  password: string;
};

interface IPostPayment {
  email: string;
  planCode: string;
  fullName: string;
  coachUserId: string;
  session_id?: string;
  paymentType: string;
  coachName: string;
  numPrograms: number;
  planDescription: string;
  planImage: string;
  price: number;
  funnelIdentifier: string;
  coachAccountId?: string;
  currency?: string;
  isClientBilling?: boolean;
}

interface Props {
  postPaymentInfo: IPostPayment | undefined;
  paymentStatus: 'SUCCESS' | 'FAILED' | 'INVALID';
}

const Timer: FC<Props> = (postPaymentInfo, paymentStatus) => {
  const data = encodeData(
    JSON.stringify({
      paymentType: 'sub',
      ...postPaymentInfo.postPaymentInfo,
    }),
  );
  const history = useHistory();
  const [timer, setTimer] = useState(paymentStatus === 'FAILED' ? 12 : 6);

  useEffect(() => {
    const x = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => {
      clearInterval(x);
    };
  }, []);

  const redirectBasedOnPaymentStatus = () => {
    if (paymentStatus === 'FAILED') {
      history.push('/checkout', data);
    } else {
      window.location.href = 'https://www.coachd.ai/';
    }
  };

  useEffect(() => {
    if (timer === 0) {
      redirectBasedOnPaymentStatus();
    }
  }, [timer]);

  return (
    <div className={classes.CountdownContainer}>
      <p className={classes.CountDown}>
        You will be redirected in: <span>{timer}</span> seconds
      </p>
      <Button type="button" disabled={false} onClick={() => redirectBasedOnPaymentStatus()}>
        BACK TO {paymentStatus === 'FAILED' ? 'CHECKOUT' : 'HOMEPAGE'}
      </Button>
    </div>
  );
};

const PostPayment: FC = () => {
  const [registerSuccessful, setRegisterSuccessful] = useState(false);
  const [loading, setLoading] = useState({
    verifying: false,
    createPassword: false,
  });
  const [paymentStatus, setPaymentStatus] = useState<'SUCCESS' | 'FAILED' | 'INVALID'>('FAILED'); // ? FAILED OR SUCCESS
  const [postPaymentInfo, _setPostPaymentInfo] = useState<IPostPayment>();
  const [isNewClient, setIsNewClient] = useState<boolean>(false);
  const postPaymentInfoRef = useRef(postPaymentInfo);
  const [isModalOpen, setModalState] = useState<boolean>(false);

  const setPostPaymentInfo = (info: any) => {
    postPaymentInfoRef.current = info;
    _setPostPaymentInfo(info);
  };

  const setIsLoading = (key: string, value: boolean) => {
    setLoading((oldLoading) => ({ ...oldLoading, [key]: value }));
  };

  useEffect(() => {
    setIsLoading('verifying', true);
    try {
      const parsed = queryString.parse(search);

      if (!parsed.session_id || !parsed.data) {
        // user shouldn't be on the post payment page hence redirecting user to homepage
        setIsLoading('verifying', false);
        setPaymentStatus('INVALID');
        return;
      }

      // TODO rather get data from verify session call response
      const objectString = parsed.data + '';
      const encodedDataBuffer = Buffer.from(objectString, 'base64');
      const decodedData = encodedDataBuffer.toString('utf8');
      const postPaymentInfo: IPostPayment = JSON.parse(decodedData);
      setPostPaymentInfo({ ...postPaymentInfo, session_id: parsed.session_id + '' });
      setIsNewClient(parsed?.isNewClient === 'true');

      // TODO update axios call to use stripe verify session
      const verifyTransaction = async () => {
        if (!postPaymentInfoRef.current?.session_id || !postPaymentInfoRef.current?.coachAccountId) {
          setIsLoading('verifying', false);
          setPaymentStatus('INVALID');
          return;
        }
        verifyCheckoutSession(postPaymentInfoRef.current?.session_id, postPaymentInfoRef.current?.coachAccountId)
          .then((response: AxiosResponse) => {
            const data: VerifyResponse = response.data?.data || {};
            if (data.status === 'complete' && data.paymentStatus === 'paid') {
              paymentTrackingService.trackPaymentEvent('checkout', {
                location: 'client_checkout',
                status: 'success',
                coach_identifier: postPaymentInfo?.funnelIdentifier,
                coach_user_id: postPaymentInfo?.coachUserId,
              });
              setIsLoading('verifying', false);
              setPaymentStatus('SUCCESS');
            } else {
              paymentTrackingService.trackPaymentEvent('checkout', {
                location: 'client_checkout',
                status: 'failed',
                description: data?.status + ': ' + data?.paymentStatus,
                coach_identifier: postPaymentInfo?.funnelIdentifier,
                coach_user_id: postPaymentInfo?.coachUserId,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading('verifying', false);
            paymentTrackingService.trackPaymentEvent('checkout', {
              location: 'client_checkout',
              status: 'failed',
              error: error,
              coach_identifier: postPaymentInfo?.funnelIdentifier,
              coach_user_id: postPaymentInfo?.coachUserId,
            });
          });
      };
      if (parsed?.session_id && parsed?.data) {
        verifyTransaction();
      }
    } catch (e) {
      console.log(e);
      setIsLoading('verifying', false);
      setPaymentStatus('INVALID');
      paymentTrackingService.trackPaymentEvent('checkout', {
        location: 'client_checkout',
        status: 'failed',
        error: e,
        coach_identifier: postPaymentInfo?.funnelIdentifier,
        coach_user_id: postPaymentInfo?.coachUserId,
      });
    }
  }, []);

  const handleSubmit = async ({ password }: FormValues) => {
    setIsLoading('createPassword', true);
    await axios({
      method: 'POST',
      url: `${BASE_URL}/v1-https-updateUser`,
      data: {
        password,
        email: postPaymentInfo?.email,
      },
    })
      .then(() => {
        setIsLoading('createPassword', false);
        setRegisterSuccessful(true);

        paymentTrackingService.trackPaymentEvent('post_checkout', {
          location: 'client_checkout',
          status: 'success',
          description: 'Client creates password',
          button: {
            text: 'CREATE PASSWORD',
            action: 'create_client_password',
            description: "Payment was successful and client's password was created",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading('createPassword', false);
        setModalState(true);
      });
  };
  const { search } = useLocation();

  const loader = (
    <div className={classes.Loading}>
      <Spinner />
    </div>
  );

  return (
    <>
      <div className={classes.Header}>
        <div className={classes.Logo}>
          <Logo link="https://www.coachd.ai/" checkout />
        </div>

        <div className={classes.AuthButtons}></div>
      </div>
      <div className={classes.Page}>
        {loading.verifying ? (
          loader
        ) : paymentStatus === 'SUCCESS' ? (
          !registerSuccessful && isNewClient ? (
            <>
              <h1 className={classes.PageTitle}>Thank you!</h1>
              <div className={classes.FormContainer}>
                <div className={classes.Flex}>
                  <div className={classes.Liststyle}>3</div>
                  <h4>Create your password</h4>
                </div>

                <p>
                  Thank you for choosing to subscribe to {postPaymentInfo?.coachName}. Please create a password for your
                  account below.
                </p>
                <PostPaymentForm handleSubmit={handleSubmit} loading={loading?.createPassword} />
              </div>
            </>
          ) : (
            <>
              <h1 className={classes.PageTitle}>Start training!</h1>
              <div className={classes.FormContainer}>
                <p className={classes.PerkOfMemberText}>
                  One of the perks of being a subscribed member is that you get full, unlimited access to our Coachd
                  mobile app which houses all of the programs by your coach &#40;{postPaymentInfo?.coachName}&#41;. Be
                  sure to download it here!
                </p>

                <div className={classes.playStoreBadges}>
                  <a
                    href="https://play.google.com/store/apps/details?id=ai.coachd.app"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={GooglePlayBadge} alt="Google play store badge" />
                  </a>
                  <a href="https://apps.apple.com/us/app/coachd/id6462992243" target="_blank" rel="noopener noreferrer">
                    <img src={AppleStoreBadge} alt="Apple-store download badge" />
                  </a>
                  <a
                    href="https://appgallery.huawei.com/app/C109038497?sharePrepath=ag"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={AppGalleryBadge} alt="App Gallery download badge" />
                  </a>
                </div>
              </div>
            </>
          )
        ) : (
          <>
            <h1 className={classes.PageTitle}>{paymentStatus === 'FAILED' ? 'PAYMENT FAILED' : 'INVALID REQUEST'}</h1>
            <Timer postPaymentInfo={postPaymentInfo} paymentStatus={paymentStatus} />
          </>
        )}
      </div>
      <Footer />
      <AlertDialog
        title="Something went wrong"
        description="Something went wrong, please wait a bit and try again. If the issue persists, try resetting your password on
        the mobile app or contact support@coachd.ai"
        open={isModalOpen}
        onOpenChange={setModalState}
        actionButton={
          <Button type="button" intent="danger" onClick={() => setModalState(false)}>
            Ok
          </Button>
        }
      />
    </>
  );
};

export default PostPayment;
