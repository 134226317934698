/**
 * Checkout Page
 * This page is used to display the checkout form and handle the payment process
 * It used for both client billing
 * Handles new and existing users
 * Handles trial and non-trial subscriptions
 * Handles reactivating subscriptions
 */
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Buffer } from 'buffer';
import { useToasts } from 'react-toast-notifications';
import tick from 'assets/icons/tick.svg';
import classes from './Checkout.module.css';
import { CheckoutClientBilling } from 'components/forms';
import { encodeData, getCurrencySymbol } from 'utils/helpers';
import Logo from 'components/Logo';
import Footer from 'components/Footer';

import { firestore } from 'utils/firebase';
import { createCheckoutSession } from 'api/clientBillingApi';
import { PaymentInfo } from 'interfaces/checkout';
import { paymentTrackingService } from 'utils/tracking/paymentService';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

const ClientBillingPaymentPage: FC = () => {
  const { addToast } = useToasts();
  const { state, search } = useLocation();
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();
  const [loading, setLoading] = useState<boolean>(false);

  const redirectToHomePage = () => {
    window.location.href = 'https://app.coachd.ai/';
  };

  const decodeData = (dataString: string) => {
    const encodedDataBuffer = Buffer.from(dataString, 'base64');
    const decodedData = encodedDataBuffer.toString('utf8');
    const paymentInfo: PaymentInfo = JSON.parse(decodedData);
    return paymentInfo;
  };

  useEffect(() => {
    try {
      if (state) {
        const objectString = state + '';
        const paymentInfo: PaymentInfo = decodeData(objectString);

        setPaymentInfo(paymentInfo);
      } else if (search) {
        const params = new URLSearchParams(search);
        const data = params.get('data') + '';
        const paymentInfo: PaymentInfo = decodeData(data);

        setPaymentInfo(paymentInfo);
      } else {
        redirectToHomePage();
      }
    } catch (error) {
      console.log(error);
      redirectToHomePage();
    }
  }, [state, search]);

  useEffect(() => {
    if (paymentInfo && !paymentInfo?.planCode) {
      redirectToHomePage();
    }
  }, [paymentInfo]);

  const handleSubmit = async ({ email, firstName, lastName }: FormValues) => {
    paymentTrackingService.trackPaymentEvent('checkout', {
      location: 'client_checkout',
      action: 'submit',
      interaction_type: 'navigate_to_stripe_checkout',
      button: {
        text: 'CHECKOUT',
        action: 'redirect_to_stripe_checkout',
      },
      coach_identifier: paymentInfo?.funnelIdentifier,
      coach_user_id: paymentInfo?.coachUserId,
    });
    try {
      setLoading(true);
      const data = encodeData(
        JSON.stringify({
          email: email,
          planCode: paymentInfo?.planCode,
          fullName: `${firstName} ${lastName}`,
          coachUserId: paymentInfo?.coachUserId,
          ...paymentInfo,
        }),
      );

      const coachRef = firestore.collection('coaches').doc(paymentInfo?.coachUserId);

      if (paymentInfo?.isClientBilling) {
        const clientBillingPlansRef = coachRef.collection('client-billing-plans');
        const clientBillingPlanPricesDoc = clientBillingPlansRef.doc(paymentInfo?.planCode).collection('prices');
        const priceDocSnapshot = await clientBillingPlanPricesDoc.limit(1).get();
        const priceDoc = priceDocSnapshot.docs[0];

        const result = await createCheckoutSession({
          email,
          firstName,
          lastName,
          coachUserId: paymentInfo?.coachUserId,
          planCode: paymentInfo?.planCode,
          priceId: priceDoc.id,
          isReactivateSubscription: paymentInfo?.isReactivateSubscription,
          identifier: paymentInfo?.funnelIdentifier,
          coachAccountId: paymentInfo?.coachAccountId,
          encodedData: data,
        });

        if (
          result.data?.data?.sessionUrl === undefined &&
          result.data.status_code === 400 &&
          result.data.message === 'Cannot create checkout session due to active subscription/s.'
        ) {
          addToast('You already have an active subscription.', {
            appearance: 'error',
          });
          paymentTrackingService.trackPaymentEvent('checkout', {
            location: 'client_checkout',
            status: 'failed',
            error: 'Active subscription',
            coach_identifier: paymentInfo?.funnelIdentifier,
            coach_user_id: paymentInfo?.coachUserId,
          });
          setLoading(false);
          return;
        }

        setLoading(false);
        window.location.href = result?.data?.data?.sessionUrl;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error === 'auth/user-exists') {
        addToast('Account already exists.', {
          appearance: 'error',
        });
      } else {
        paymentTrackingService.trackPaymentEvent('checkout', {
          location: 'client_checkout',
          coach_identifier: paymentInfo?.funnelIdentifier,
          coach_user_id: paymentInfo?.coachUserId,
          status: 'failed',
          error: error,
        });
      }
      addToast('Something went wrong.', {
        appearance: 'error',
      });
    }
  };

  const links = [
    {
      href: 'https://docs.google.com/document/d/16Rqu8_QSnGp_t1vUJaTndO8dHltGwAmyZKiSBj_xogY',
      text: 'privacy policy',
    },
    {
      href: 'https://docs.google.com/document/d/1CoVHks0TpuPR4-ltbiPuSgIyPyJf28K2P0kJdd3q4Do',
      text: 'terms of service',
    },
    {
      href: '',
      text: 'billing terms',
    },
  ];

  const anchorProps = {
    rel: 'noopener noreferrer',
    target: '_blank',
  };

  const TermsLink = ({ href, text }) => (
    <a href={href} {...anchorProps}>
      {text}
    </a>
  );

  const currencySymbol = getCurrencySymbol(paymentInfo?.currency);

  const pricing = paymentInfo?.isTrialEnabled
    ? `FIRST MONTH IS ${currencySymbol}1`
    : currencySymbol + paymentInfo?.price + '/month';

  return (
    <>
      <div className={classes.Page}>
        <div className={classes.Header}>
          <div className={classes.Logo}>
            <Logo link="https://www.coachd.ai" checkout />
          </div>

          <div className={classes.AuthButtons}></div>
        </div>
        <h1 className={classes.Heading}>Subscribe now!</h1>
        <ul>
          <li>
            <div className={clsx(classes.Liststyle, classes.ListstyleComplete)}>
              <img src={tick} alt="tick" />
            </div>
            Coach selected
          </li>
          <div className={classes.CardContainer}>
            {!paymentInfo?.isClientBilling && (
              <div className={classes.ImageContainer}>
                <img src={paymentInfo?.planImageDesktop} alt="coach logo" />
              </div>
            )}

            <div className={clsx(classes.CardContent, !paymentInfo?.isClientBilling && classes.CardContentPadding)}>
              <h4 className={classes.Pricing}>{pricing}</h4>
              {paymentInfo?.isTrialEnabled && (
                <h5>{`${currencySymbol + paymentInfo?.renewalPrice} (${
                  paymentInfo?.currency
                }) every month after. Cancel anytime.`}</h5>
              )}
              {paymentInfo?.isTrialEnabled && (
                <h6>
                  {`After completion of 1 month of your subscription, your renewal will be automatically charged at ${
                    currencySymbol + paymentInfo?.renewalPrice
                  }, with the flexibility to cancel at anytime.`}
                </h6>
              )}

              {!paymentInfo?.isTrialEnabled && (
                <h4 className={classes.Title}>
                  {paymentInfo?.coachName}{' '}
                  {paymentInfo?.numPrograms && (
                    <span>
                      &#40;{paymentInfo?.numPrograms} program{paymentInfo?.numPrograms > 1 && 's'}
                      &#41;
                    </span>
                  )}
                </h4>
              )}
              {!paymentInfo?.isTrialEnabled && paymentInfo?.numPrograms && (
                <span className={classes.NumPrograms}>
                  &#40;{paymentInfo?.numPrograms} program{paymentInfo?.numPrograms > 1 && 's'}
                  &#41;
                </span>
              )}
              {!paymentInfo?.isTrialEnabled && <p className={classes.Description}>{paymentInfo?.planDescription}</p>}
            </div>
          </div>
          <li>
            <div className={classes.Liststyle}>2</div>
            YOUR ACCOUNT
          </li>
          <CheckoutClientBilling handleSubmitForm={handleSubmit} loading={loading} />
          <span className={classes.Disclaimer}>
            By continuing you accept our{' '}
            {links.map((link, index) => (
              <React.Fragment key={index}>
                <TermsLink {...link} />
                {index !== links.length - 1 && ', '}
              </React.Fragment>
            ))}
            .
          </span>
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default ClientBillingPaymentPage;
