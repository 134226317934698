import React, { FC, useState, useEffect, ChangeEvent, useRef, createRef } from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';
import { useToasts } from 'react-toast-notifications';
import { database } from 'utils/firebase';
import { connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import rfdc from 'rfdc';

import { RootState } from 'store';
import { createDefaultSet, getNestedValue } from 'utils/helpers';
import { CLIENTS, CLIENTS_WORKOUT_BUILDER, PROGRAMS } from 'utils/routes';
import { DEFAULT_SET_PARAMS } from 'utils/constants';
import withAuth from 'utils/withAuth';
import { SetParam } from 'interfaces/utils';
import { Set, SetKeys, Exercise, Day, Programs, Week as IWeek } from 'interfaces/db';
import { SelectOptionProps } from 'interfaces/ui';
import classes from './ClientTraining.module.css';
import Layout from '../Layout';
import Week from './Week/Week';
import ProgramToolbar from 'components/ProgramToolbar';
import { Button, Select, AlertDialog, Spinner, Dialog } from 'components/UI';
import { useOpenSelect } from 'hooks';
import ForgotToSaveDialog from 'components/ReusableAlertDialogs/ForgotToSaveDialog';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-filled.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { programTrackingService } from 'utils/tracking/programService';
import EmptyState from 'components/EmptyState';
import { AddProgram } from 'components';
import { createProgram } from 'utils/api';

interface ProgramsType {
  [key: string]: Programs;
}

type ProgramSelect = {
  programSelect: string;
};

const mapStateToProps = ({ clients, auth }: RootState) => {
  return {
    clients: clients.clients,
    token: auth.token,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  RouteComponentProps<{
    clientKey: string;
  }>;

const ClientTraining: FC<Props> = ({ match, history, clients, token }) => {
  // get client key from url
  const { params } = match;
  const { clientKey = '' } = params;
  const { addToast } = useToasts();
  const { openSelect, handleSelectOpenChange } = useOpenSelect();
  const { control } = useForm<ProgramSelect>();
  const clone = rfdc();

  const elRefs = useRef([]);
  const [showAutoFill, _setShowAutoFill] = useState<string>('');
  const setShowAutoFill = (setIdentifier: string) => {
    _setShowAutoFill(setIdentifier);
  };
  const [program, setProgram] = useState<Programs | null>(null);
  const [programs, setPrograms] = useState<ProgramsType>({});
  const [selectedProgramKey, setSelectedProgramKey] = useState<string>('');
  const [trackProgramKey, setTrackProgramKey] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [navModal, showNavModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const [shouldNavOut, setShouldNavOut] = useState(false);
  const [activeWeek, setActiveWeek] = useState<number>(0);
  const [copiedWorkout, setCopiedWorkout] = useState<Day | null>(null);
  const [initialProgram, setInitialProgram] = useState<Programs | null>(null);
  const [showSaveProgram, setShowSaveProgram] = useState(false);
  const [showRemoveProgram, setShowRemoveProgram] = useState(false);
  const [showSaveAsTemplate, setShowSaveAsTemplate] = useState(false);
  const [defaultSetParams, setDefaultSetParams] = useState<SetParam[]>(DEFAULT_SET_PARAMS);
  const [clientName] = useState<string>(clients?.[clientKey]?.profile?.fullname ?? '');
  const [programTitleModal, setProgramTitleModal] = useState(false);
  const [creatingProgram, setCreatingProgram] = useState(false);
  const [createProgramLoading, setCreateProgramLoading] = useState(false);

  if (!elRefs.current.length) {
    elRefs.current = Array.from({ length: 7 }).map((_, i) => elRefs.current[i] || createRef());
  }

  const checkIfChanges = (program1: Programs, program2: Programs) => {
    if (!program1 || !program2) return;

    const program1ObjString = JSON.stringify(program1);
    const program2ObjString = JSON.stringify(program2);

    if (program2ObjString !== program1ObjString) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  // fetch program from database
  const getData = async () => {
    // showing loading spinner pending db call
    setLoading(true);
    try {
      // make the db query
      const snapshot = await database
        .ref(`newAssignedPrograms`)
        .orderByChild('clientKey')
        .equalTo(clientKey)
        .once('value');
      // check if query is valid
      if (snapshot.exists()) {
        // get client program
        let programs = snapshot.val();
        programs = Object.fromEntries(
          Object.entries(programs || {})?.filter(([_, val]) => {
            const value: any = val;
            const status = value?.status;
            return status === undefined && status !== 'completed';
          }),
        );

        if (Object.keys(programs || {}).length === 0) {
          return;
        }

        const program: any = Object.entries(programs)[0];

        const previouslySelectedProgramKey = window.localStorage.getItem('selectedProgramKey');

        const validKey = previouslySelectedProgramKey
          ? Object.keys(programs).includes(previouslySelectedProgramKey)
          : false;

        setSelectedProgramKey(validKey ? previouslySelectedProgramKey : program?.[0]);

        const { weeks = [], ...values } =
          validKey && previouslySelectedProgramKey ? programs[previouslySelectedProgramKey] : program?.[1];

        // set client program
        setPrograms(programs);
        const selectedProgram: Programs = { weeks, ...values };
        setProgram(selectedProgram);

        setDefaultSetParams(selectedProgram?.settings?.defaultSetParams || DEFAULT_SET_PARAMS);

        setInitialProgram({ weeks, ...values });
      }
    } catch (err) {
      // log error for now, we'll handle errors later
      console.log(err);
    } finally {
      // hide loading spinner
      setLoading(false);
    }
  };

  useEffect(() => {
    if (program && !initialProgram) {
      setInitialProgram(program);
    }

    if (!program || !initialProgram) return;

    checkIfChanges(program, initialProgram);
  }, [program, initialProgram]);

  useEffect(() => {
    if (shouldNavOut && nextLocation) {
      history.push(nextLocation);
    }
  }, [shouldNavOut, history, nextLocation]);

  useEffect(() => {
    getData();
  }, [clientKey]);

  useEffect(() => {
    const weekStatus = program?.weeks?.map((el) => {
      return el.Days.reduce((acc, cur) => {
        if (acc) {
          return acc;
        }

        return cur.workout.finished;
      }, false);
    });

    const currentWeekIndex = weekStatus?.lastIndexOf(true);

    if (!currentWeekIndex || currentWeekIndex === -1) {
      return;
    }

    setActiveWeek(currentWeekIndex);
  }, [program]);

  useEffect(() => {
    const activeNode: any = elRefs.current[activeWeek];

    if (activeNode?.current) {
      activeNode.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'end',
      });
    }
  }, [activeWeek]);

  const saveProgram = async () => {
    if (!program) {
      return;
    }
    // save program
    await database.ref(`newAssignedPrograms/${selectedProgramKey}`).update(program);

    setProgram(program);
    const programsCopy = Object.assign({}, programs);
    programsCopy[selectedProgramKey] = program;
    setPrograms(programsCopy);
    // hide save program modal
    setShowSaveProgram(false);
    setChanged(false);
    setInitialProgram(null);

    // show toast
    addToast('Program Saved', { appearance: 'success' });
  };

  const initialiseProgram = async (title: string) => {
    // create program
    setCreatingProgram(true);
    const assignedProgramsRef = database.ref(`newAssignedPrograms`);
    const newAssignedProgramRef = await assignedProgramsRef.push();
    const newProgram = {
      name: title,
      image: '',
      weeks: [],
      userId: clients?.[clientKey]?.userId || '',
      clientKey: clientKey,
      createdAt: new Date(),
    };
    await newAssignedProgramRef
      .set(newProgram)
      .then((data) => {
        programTrackingService.trackProgramEvent('create_program', {
          program_id: newAssignedProgramRef?.key,
          location: 'clients',
          object: 'button',
          action: 'click',
          program_title: title,
          button: {
            text: 'CREATE PROGRAM',
            action: 'create_program_for_client',
            description: 'Create program button on add program modal',
          },
        });
        initialiseProgramCallback(newProgram, data, newAssignedProgramRef?.key);
      })
      .catch((err) => {
        initialiseProgramCallback(null, err, null);
      });
  };

  const initialiseProgramCallback = async (
    newProgram: Programs | null,
    err: Error | null,
    selectedProgramKey: string | null,
  ) => {
    if (!err && selectedProgramKey && newProgram) {
      const clientRef = database.ref(`clients/${clientKey}`);

      await clientRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
          clientRef.update({
            hasPrograms: true,
          });
        }
      });
      window.localStorage.setItem('selectedProgramKey', selectedProgramKey);
      setSelectedProgramKey(selectedProgramKey);
      setProgram(newProgram);
      const programsCopy = Object.assign({}, programs);
      programsCopy[selectedProgramKey] = newProgram;
      setPrograms(programsCopy);
      setShowSaveProgram(false);
      setChanged(false);
      setInitialProgram(null);
      setCreatingProgram(false);
      setProgramTitleModal(false);

      // show toast
      addToast('Program Created', { appearance: 'success' });
    } else {
      setCreatingProgram(false);
      setProgramTitleModal(false);
    }
  };

  const updateClientHasProgram = (clientKey: string): Promise<any> => {
    const clientRef = database.ref(`clients/${clientKey}`);

    return clientRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        clientRef.update({
          hasPrograms: false,
        });
      }
    });
  };

  const removeProgram = async () => {
    // delete program
    await database.ref(`newAssignedPrograms/${selectedProgramKey}`).remove();

    const remaingPrograms = Object.fromEntries(
      Object.entries(programs || {})?.filter(([key, _]) => key !== selectedProgramKey),
    );

    // close program removal modal
    setShowRemoveProgram(false);

    // navigate back to clients page
    if (Object.keys(remaingPrograms)?.length === 0) {
      await updateClientHasProgram(clientKey);
      history.push(CLIENTS.URL);
    } else {
      const key = Object.keys(remaingPrograms)[0];
      const program = remaingPrograms?.[key];
      setSelectedProgramKey(key);
      setProgram(program);
      setInitialProgram(program);
      setPrograms(remaingPrograms);
    }
  };

  const completeProgram = async () => {
    const assignedProgramRef = database.ref(`newAssignedPrograms/${selectedProgramKey}`);

    await assignedProgramRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        assignedProgramRef.update({
          status: 'complete',
          completedDate: new Date(),
        });
      }
    });

    const numPrograms = Object.keys(programs || {})?.length;
    if (numPrograms === 1) {
      await updateClientHasProgram(clientKey);
      history.push(CLIENTS.URL);
    }

    await getData();
    setCompleteModal(false);
  };

  const saveProgramAsTemplate = async () => {
    if (!program || !token) {
      console.error('Program or token is missing');
      return;
    }

    setCreateProgramLoading(true);

    const duplicatedWeeks = program.weeks?.map((week: IWeek) => {
      const formattedDays = formatDaysForDuplication(week.Days);
      const formattedWeek = { Days: formattedDays };
      return formattedWeek;
    });

    const newProgram = { ...program, onMarketplace: false, weeks: duplicatedWeeks };

    try {
      await createProgram(token, newProgram);
      addToast('Program Saved as Template', { appearance: 'success' });
    } catch (error) {
      console.error('Error saving program:', error);
    }

    setCreateProgramLoading(false);
    setShowSaveAsTemplate(false);
  };

  const addWeekHandler = () => {
    if (!program) {
      return;
    }
    // create exercise objects for all days of the week.
    const newWeeks = {
      Days: Array.from({ length: 7 }).map(() => ({
        rest: false,
        workout: { finished: false },
      })),
    };

    // copy program and add new week
    const updatedProgram = {
      ...program,
      weeks: program.weeks ? [...program.weeks, newWeeks] : [newWeeks],
    };

    // update program
    setProgram(updatedProgram);
  };

  const formatDaysForDuplication = (Days: Day[]): Day[] => {
    // remove client workout inputs from copied workout

    const formattedDays = Days.map((day) => {
      if (day.rest) {
        return { ...day };
      }

      let exercises: Exercise[][] = [];

      if (day.workout.exercises) {
        exercises = day.workout.exercises.map((exerciseGroup) => {
          return !exerciseGroup
            ? []
            : exerciseGroup.map((exercise) => {
                return {
                  ...exercise,
                  reference: null,
                  exerciseNotes: {
                    athlete: '',
                    coach: exercise.exerciseNotes?.coach || '',
                  },
                  sets: !exercise.sets
                    ? []
                    : exercise.sets.map((set) => {
                        const { completed, ...otherSets } = set;

                        const setKeys = Object.keys(otherSets) as Array<keyof typeof otherSets>;

                        return setKeys.reduce(
                          (acc, cur) => {
                            const currentType = otherSets[cur];
                            const value = typeof currentType === 'string' ? currentType : currentType?.programmed;

                            return {
                              ...acc,
                              [cur]: {
                                achieved: '',
                                programmed: value || '',
                              },
                            };
                          },
                          { completed: false },
                        );
                      }),
                };
              });
        });
      }

      if (!exercises.length) {
        return {
          ...day,
          workout: {
            ...day.workout,
            finished: false,
            dateFinished: '',
            workoutNotes: {
              athlete: '',
              coach: day.workout.workoutNotes?.coach || '',
            },
          },
        };
      }

      return {
        ...day,
        workout: {
          ...day.workout,
          exercises,
          finished: false,
          dateFinished: '',
          workoutNotes: {
            athlete: '',
            coach: day.workout.workoutNotes?.coach || '',
          },
        },
      };
    });

    return formattedDays;
  };

  const duplicateWeekHandler = (Days: Day[]) => {
    if (!program) {
      return;
    }

    const formattedDays = formatDaysForDuplication(Days);

    // duplicate week
    const newWeek = { Days: formattedDays };

    // copy program and add new week
    const updatedProgram = {
      ...program,
      weeks: program.weeks ? [...program.weeks, newWeek] : [newWeek],
    };

    // update program
    setProgram(updatedProgram);
    programTrackingService.trackProgramEvent('duplicate_week', {
      program_id: selectedProgramKey,
      location: 'client_training',
    });
  };

  const deleteWeekHandler = (index: number) => {
    if (!program) {
      return;
    }
    // filter out selected week
    const weeks = program.weeks?.filter((_, i) => i !== index);

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const addWorkoutNotes = (workoutNotes: string, dayIndex: number, weekIndex: number) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map(
        (day, index) => {
          // get selected day
          if (index !== dayIndex) {
            return day;
          }
          return {
            ...day,
            workout: {
              ...day.workout,
              workoutNotes: {
                athlete: day.workout?.workoutNotes?.athlete || '',
                coach: workoutNotes || '',
              },
            },
          };
        },

        // return day if not selected day
      );

      // update days
      return { Days: days };

      // return week if not selected week
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const addExerciseNotes = (
    exerciseNotes: string,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }
        const newExercises = day.workout.exercises?.map((exercise, i) => {
          if (i !== group) {
            return exercise;
          }
          return exercise.map((exerciseGroup, i) => {
            if (i !== exerciseIndex) {
              return exerciseGroup;
            }
            return {
              ...exerciseGroup,
              exerciseNotes: {
                athlete: exerciseGroup.exerciseNotes?.athlete || '',
                coach: exerciseNotes,
              },
            };
          });
        });

        return {
          ...day,
          workout: { ...day.workout, exercises: newExercises },
        };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const addSetHandler = (group: number, exerciseIndex: number, dayIndex: number, weekIndex: number) => {
    if (!program) {
      return;
    }
    const newSet: Set = createDefaultSet(defaultSetParams);

    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }
        const newExercises = day.workout.exercises?.map((exercise, i) => {
          if (i !== group) {
            return exercise;
          }
          return exercise.map((exerciseGroup, i) => {
            if (i !== exerciseIndex) {
              return exerciseGroup;
            }
            const numberofSets = exerciseGroup.sets ? exerciseGroup.sets.length : 0;

            // if first set, initialize with empty values
            if (!numberofSets) {
              return {
                ...exerciseGroup,
                sets: [newSet],
              };
            } else {
              // initalize with last set values
              const { sets = [] } = exerciseGroup;
              const lastSet = {
                ...sets[numberofSets - 1],
                completed: false,
              };
              return {
                ...exerciseGroup,
                sets: [...sets, lastSet],
              };
            }
          });
        });

        return {
          ...day,
          workout: { ...day.workout, exercises: newExercises },
        };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const removeSetHandler = (group: number, exerciseIndex: number, dayIndex: number, weekIndex: number) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map(
      (week, i) => {
        // get selected week
        if (i !== weekIndex) {
          return week;
        }
        // map over days
        const days = week.Days.map((day, index) => {
          // get selected day
          if (index !== dayIndex) {
            return day;
          }
          const newExercises = day.workout.exercises?.map((exercise, i) => {
            if (i !== group) {
              return exercise;
            }
            return exercise.map((exerciseGroup, i) => {
              if (i !== exerciseIndex) {
                return exerciseGroup;
              }
              const { sets: newSets = [] } = exerciseGroup;
              newSets.pop();

              return {
                ...exerciseGroup,
                sets: newSets,
              };
            });
          });

          return {
            ...day,
            workout: { ...day.workout, exercises: newExercises },
          };
        });

        // update days
        return { Days: days };
      },

      // return week if not selected week
    );

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const autoFillSetsHandler = (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (!program) {
      return;
    }

    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }

      // map over days
      const days: any = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }

        const newExercises = day.workout.exercises?.map((exercise, i) => {
          if (i !== group) {
            return exercise;
          }

          return exercise.map((exerciseGroup, i) => {
            if (i !== exerciseIndex) {
              return exerciseGroup;
            }

            const { sets = [] } = exerciseGroup;
            return {
              ...exerciseGroup,
              sets: sets.map((set, i) => {
                if (i < setIndex) {
                  return set;
                }

                if (typeof set[key] === 'string') {
                  return {
                    ...set,
                    [key]: {
                      index: i + 1,
                      programmed: val,
                      achieved: '',
                    },
                  };
                }

                return {
                  ...set,
                  [key]: {
                    programmed: val,
                    achieved: '',
                    index: (set?.[key] as any)?.index || i + 1,
                  },
                };
              }),
            };
          });
        });

        return {
          ...day,
          workout: { ...day.workout, exercises: newExercises },
        };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const setsChangeHandler = (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // return week if not selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // return day if not selected day
        if (index !== dayIndex) {
          return day;
        }
        const newExercises = day.workout.exercises?.map((exercise, i) => {
          if (i !== group) {
            return exercise;
          }
          return exercise.map((exerciseGroup, i) => {
            if (i !== exerciseIndex) {
              return exerciseGroup;
            }
            const { sets = [] } = exerciseGroup;
            return {
              ...exerciseGroup,
              sets: sets.map((set, i) => {
                if (i !== setIndex) {
                  return set;
                }
                if (typeof set[key] === 'string') {
                  return {
                    ...set,
                    [key]: {
                      index: i + 1,
                      programmed: val,
                      achieved: '',
                    },
                  };
                }

                return {
                  ...set,
                  [key]: {
                    programmed: val,
                    achieved: '',
                    index: (set?.[key] as any)?.index || i + 1,
                  },
                };
              }),
            };
          });
        });

        return {
          ...day,
          workout: { ...day.workout, exercises: newExercises },
        };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const setRestDayHandler = (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }

    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }

      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }

        // update rest day
        return { ...day, rest: true };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const removeRestDayHandler = (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }
        // update rest day
        return { ...day, rest: false };
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // update program
    setProgram(updatedProgram);
  };

  const copyWorkoutHandler = (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }

    const copied = program.weeks?.[weekIndex]?.Days?.[dayIndex];
    setCopiedWorkout(copied || null);
  };

  const clearAchievedValues = (exercises?: Exercise[][]) => {
    if (!exercises) {
      return;
    }
    exercises?.forEach((exerciseGroup) => {
      exerciseGroup?.forEach((exercise) => {
        exercise.sets?.forEach((set) => {
          set.completed = false;
          Object.keys(set)?.forEach((key) => {
            if (set[key] && typeof set[key] === 'object' && 'achieved' in set[key]) {
              set[key].achieved = '';
            }
          });
        });
      });
    });
  };

  const pasteWorkoutHandler = (weekIndex: number, dayIndex: number) => {
    if (program && copiedWorkout) {
      // Deep clone copiedWorkout to avoid mutating the original object
      const workoutCopy = clone(copiedWorkout);

      // Clear Achieved values in the copied workout
      clearAchievedValues(workoutCopy.workout.exercises);

      // map over weeks
      const weeks = program.weeks?.map((week, i) => {
        // get selected week
        if (i !== weekIndex) {
          return week;
        }
        // map over days
        const days = week.Days.map((day, index) => {
          // get selected day
          if (index !== dayIndex) {
            return day;
          }
          // update rest day
          return workoutCopy;
        });

        // update days
        return { Days: days };
      });

      // copy program and update weeks
      const updatedProgram = { ...program, weeks };

      // update program
      setProgram(updatedProgram);
    }
  };

  const programNameOnChangeHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!program) {
      return;
    }
    const { value } = target;

    // copy program and add new week
    const updatedProgram = {
      ...program,
      name: value,
    };

    // update program
    setProgram(updatedProgram);
  };

  const addWorkout = async (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }
    // map over weeks
    const weeks = program.weeks?.map((week, i) => {
      // get selected week
      if (i !== weekIndex) {
        return week;
      }
      // map over days
      const days = week.Days.map((day, index) => {
        // get selected day
        if (index !== dayIndex) {
          return day;
        }
        const workoutTitle = getNestedValue(['workout', 'workoutTitle'], day) || '';

        const newWorkout = {
          rest: false,
          workout: {
            workoutTitle,
            finished: false,
          },
        };

        // add workout
        return newWorkout;
      });

      // update days
      return { Days: days };
    });

    // copy program and update weeks
    const updatedProgram = { ...program, weeks };

    // save program
    await database.ref(`newAssignedPrograms/${selectedProgramKey}`).update(updatedProgram);

    setChanged(false);
    setInitialProgram(null);

    programTrackingService.trackProgramEvent('add_workout', {
      program_id: selectedProgramKey,
      location: 'client_training',
      object: 'button',
      action: 'click',
      interaction_type: 'navigate_to_workout_builder',
      button: {
        text: 'ADD WORKOUT',
        action: 'add_workout',
        description: 'Add workout button on day in client training',
      },
    });

    //  navigate
    history.push({
      state: { programName: program.name, defaultSetParams },
      pathname: `${CLIENTS_WORKOUT_BUILDER.URL}/${clientKey}/workout-builder/${selectedProgramKey}/${weekIndex}/${dayIndex}`,
    });
  };

  const goToWorkoutBuilder = async (weekIndex: number, dayIndex: number) => {
    if (!program) {
      return;
    }
    if (changed) {
      // save program
      await database.ref(`newAssignedPrograms/${selectedProgramKey}`).update(program);

      setChanged(false);
      setInitialProgram(null);
    }

    window.localStorage.setItem('selectedProgramKey', selectedProgramKey);
    //  navigate
    history.push({
      state: { programName: program.name, defaultSetParams },
      pathname: `${CLIENTS_WORKOUT_BUILDER.URL}/${clientKey}/workout-builder/${selectedProgramKey}/${weekIndex}/${dayIndex}`,
    });
  };

  const handleNavPrompt = (nextLoc: Location<unknown>) => {
    if (!shouldNavOut) {
      setNextLocation(nextLoc as unknown as string);
      showNavModal(true);

      return false;
    }

    return true;
  };

  const discardChanges = () => {
    setProgram(programs?.[trackProgramKey]);
    setSelectedProgramKey(trackProgramKey);
    setInitialProgram(programs?.[trackProgramKey]);
    setChanged(false);
    showNavModal(false);
    setShouldNavOut(true);
  };

  const onChangeProgramHandler = (value: any) => {
    if (changed) {
      setTrackProgramKey(value);
      showNavModal(true);
    } else {
      setProgram(programs?.[value] || null);
      setInitialProgram(programs?.[value] || null);
      setSelectedProgramKey(value);
    }
  };

  const updateProgramDefaultSetParams = (updatedParams: SetParam[]) => {
    if (!program) {
      return;
    }
    const updatedProgram = {
      ...program,
      settings: {
        ...program.settings,
        defaultSetParams: updatedParams,
      },
    };
    setProgram(updatedProgram);
  };

  const addDefaultSetParam = (param: SetParam) => {
    const updatedParams = [...defaultSetParams, param];
    setDefaultSetParams(updatedParams);
    updateProgramDefaultSetParams(updatedParams);
  };

  const updateDefaultSetParam = (newParam: SetParam, originalParam?: SetParam) => {
    if (!originalParam) {
      return;
    }
    const paramIndex = defaultSetParams.indexOf(originalParam);
    const updatedParams = [...defaultSetParams];
    updatedParams[paramIndex] = newParam;
    setDefaultSetParams(updatedParams);
    updateProgramDefaultSetParams(updatedParams);
  };

  const removeDefaultSetParam = (originalParam?: SetParam) => {
    if (!originalParam) {
      return;
    }
    const paramIndex = defaultSetParams.indexOf(originalParam);
    const updatedParams = [...defaultSetParams];
    if (paramIndex > -1) {
      updatedParams.splice(paramIndex, 1);
    }
    setDefaultSetParams(updatedParams);
    updateProgramDefaultSetParams(updatedParams);
  };

  if (loading) {
    return (
      <Layout loading={loading} heading="Loading...">
        <div>
          <Spinner />
        </div>
      </Layout>
    );
  }

  if (!program) {
    return (
      <Layout loading={loading} heading="BACK TO TRAINING">
        <div className={classes.EmptyContainer}>
          <EmptyState
            title="No program set"
            description="You don’t have any program set up yet for this client."
            primaryButton={{
              text: 'ADD NEW PROGRAM',
              onClick: () => setProgramTitleModal(true),
            }}
            secondaryButton={{
              text: 'ASSIGN PROGRAM',
              onClick: () => {
                history.replace(PROGRAMS.URL);
              },
            }}
          ></EmptyState>
        </div>

        <Dialog title="Create program" open={programTitleModal} onOpenChange={setProgramTitleModal}>
          <AddProgram
            handleSubmit={initialiseProgram}
            closeModal={() => setProgramTitleModal(false)}
            loading={creatingProgram}
          />
        </Dialog>
      </Layout>
    );
  }

  const weeks = program?.weeks;

  const viewWeeksList = weeks?.map((week, numWeek) => (
    <Week
      key={numWeek}
      days={week.Days}
      week={numWeek + 1}
      previousWeek={numWeek > 0 ? (weeks ? weeks[numWeek - 1] : week) : undefined}
      addWorkout={addWorkout}
      saveProgram={saveProgram}
      ref={elRefs.current[numWeek]}
      setRestDayHandler={setRestDayHandler}
      copyWorkoutHandler={copyWorkoutHandler}
      goToWorkoutBuilder={goToWorkoutBuilder}
      pasteWorkoutHandler={pasteWorkoutHandler}
      removeRestDayHandler={removeRestDayHandler}
      deleteWeekHandler={() => deleteWeekHandler(numWeek)}
      duplicateWeekHandler={() => duplicateWeekHandler(week.Days)}
      addExerciseNotes={(exerciseNotes, group, index, dayIndex) =>
        addExerciseNotes(exerciseNotes, group, index, dayIndex, numWeek)
      }
      addWorkoutNotes={addWorkoutNotes}
      addSetHandler={(group, index, dayIndex) => addSetHandler(group, index, dayIndex, numWeek)}
      removeSetHandler={(group, index, dayIndex) => removeSetHandler(group, index, dayIndex, numWeek)}
      setsChangeHandler={(key, val, setIndex, group, exerciseIndex, dayIndex) =>
        setsChangeHandler(key, val, setIndex, group, exerciseIndex, dayIndex, numWeek)
      }
      autoFillSetsHandler={(key, val, setIndex, group, exerciseIndex, dayIndex) =>
        autoFillSetsHandler(key, val, setIndex, group, exerciseIndex, dayIndex, numWeek)
      }
      setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
      showAutoFill={showAutoFill}
      clientKey={clientKey}
      selectedProgramKey={selectedProgramKey}
    />
  ));

  let textInput: any = null;

  const nameFocusHandler = () => {
    textInput.focus();
  };

  const MAX_LENGTH = 30;
  const nameLength = program.name?.length || MAX_LENGTH - 5;

  const programName = (
    <div className={classes.TitleWrapper}>
      <input
        name="programName"
        type="text"
        maxLength={MAX_LENGTH}
        placeholder="Name The Program"
        onChange={programNameOnChangeHandler}
        ref={(elem) => (textInput = elem)}
        className={classes.ProgramNameInput}
        value={program.name}
        style={{
          width: `${nameLength * 30}px`,
        }}
      />
      <Button type="button" size="xsmall" onClick={nameFocusHandler} iconCenter={<EditIcon />} />
    </div>
  );

  const getProgramSelectOptions = () => {
    return Object.entries(programs || {})?.map(([key, value]) => {
      const { name = '' } = value;
      const capitalisedName = name[0].toUpperCase() + name.substring(1);
      return { label: capitalisedName, value: key };
    });
  };

  return (
    <Layout heading="" loading={loading}>
      <Prompt when={changed} message={handleNavPrompt} />

      {programName}
      <div className={classes.ProgramSelectWrapper}>
        {Object.keys(programs).length > 1 ? (
          <div className={classes.MinWidth}>
            <Select
              name="programSelect"
              placeholder="Select Program"
              control={control}
              onOpenChange={(isOpen) => handleSelectOpenChange('programSelect', isOpen)}
              open={openSelect === 'programSelect'}
              optionArray={getProgramSelectOptions() as SelectOptionProps[]}
              required
              onChange={onChangeProgramHandler}
              defaultValue={selectedProgramKey}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className={classes.ClientNameContainer}>
        <p>{clientName || ''}&#39;s Training</p>
      </div>
      <div className={classes.CalendarContent}>
        <div className={classes.Calendar}>
          <ProgramToolbar
            defaultSetParams={defaultSetParams}
            updateDefaultSetParam={updateDefaultSetParam}
            removeDefaultSetParam={removeDefaultSetParam}
            addDefaultSetParam={addDefaultSetParam}
            changed={changed}
            setShowSaveProgram={setShowSaveProgram}
            setShowRemoveProgram={setShowRemoveProgram}
            setCompleteModal={setCompleteModal}
            setShowSaveAsTemplate={setShowSaveAsTemplate}
          />

          <div>{viewWeeksList}</div>

          <div className={classes.AddWeekButtonWrapper}>
            <Button type="button" onClick={addWeekHandler} iconLeft={<PlusIcon />}>
              Add Week
            </Button>
          </div>
        </div>
      </div>

      <AlertDialog
        title="Save program"
        description="Do you want to save your program? This will save any changes that were made."
        open={showSaveProgram}
        onOpenChange={setShowSaveProgram}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setShowSaveProgram(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" onClick={saveProgram}>
            Save
          </Button>
        }
      />

      <AlertDialog
        title="Remove program"
        description="Do you want to remove this program from the client? This cannot be undone."
        open={showRemoveProgram}
        onOpenChange={setShowRemoveProgram}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setShowRemoveProgram(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={removeProgram}>
            Remove
          </Button>
        }
      />

      <AlertDialog
        title="Mark as complete"
        description="Marking this program as complete will archive it, indicating your client has finished all required workouts. You will no longer have access to it."
        open={completeModal}
        onOpenChange={setCompleteModal}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setCompleteModal(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" onClick={completeProgram}>
            Complete program
          </Button>
        }
      />

      <AlertDialog
        title="Save as program template"
        description="Saving this program as a template will save it on the programs tab and allow you to assign it to other clients."
        open={showSaveAsTemplate}
        onOpenChange={setShowSaveAsTemplate}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setShowSaveAsTemplate(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" onClick={saveProgramAsTemplate} loading={createProgramLoading}>
            Save Program
          </Button>
        }
      />

      <ForgotToSaveDialog openModal={navModal} setModalShow={showNavModal} discardChanges={discardChanges} />
    </Layout>
  );
};

export default connector(withAuth(ClientTraining));
