import React, { FC, useState, useCallback, useEffect, memo } from 'react';
import clsx from 'clsx';
import { FileRejection, useDropzone } from 'react-dropzone';
import { UseFormSetError, UseFormClearErrors } from 'react-hook-form';

import classes from './FileUploadInput.module.css';

interface FileUploadProps {
  fileName: any;
  setError: UseFormSetError<any>;
  onFileUpload: (file: File) => void;
  clearErrors: UseFormClearErrors<any>;
  defaultFile?: string | null;
  maxFileSize?: number;
  acceptedFormats?: string[];
  aspectRatio?: boolean;
  minHeights?: boolean;
}

const FileUploadInput: FC<FileUploadProps> = memo(
  ({
    fileName,
    setError,
    onFileUpload,
    clearErrors,
    defaultFile,
    maxFileSize = 512,
    acceptedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    aspectRatio = false,
    minHeights = false,
  }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    useEffect(() => {
      if (defaultFile) {
        setPreviewUrl(defaultFile);
      }
    }, [defaultFile]);

    const onDrop = useCallback(
      (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        clearErrors(fileName);
        if (rejectedFiles.length <= 0) {
          clearErrors(fileName);
          const file = acceptedFiles[0];
          setSelectedFile(file);

          if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (event) => {
              const img = new Image();
              img.onload = () => {
                clearErrors(fileName);
                if (minHeights && (img.width < 128 || img.height < 128)) {
                  setSelectedFile(null);
                  setPreviewUrl(null);
                  setError(fileName, {
                    type: 'manual',
                    message: 'Must be at least 128 x 128 pixels in size.',
                  });
                } else if (aspectRatio && img.width !== img.height) {
                  setSelectedFile(null);
                  setPreviewUrl(null);
                  setError(fileName, {
                    type: 'manual',
                    message: 'The image must have a 1:1 aspect ratio.',
                  });
                } else {
                  const base64File = event.target?.result as string;
                  onFileUpload(file);
                  setPreviewUrl(base64File);
                }
              };
              img.src = event.target?.result as string;
            };

            reader.readAsDataURL(file);
          } else if (file && file.type === 'application/pdf') {
            onFileUpload(file);
            setPreviewUrl(null);
          } else {
            setPreviewUrl(null);
          }
        }
      },
      [fileName, onFileUpload, clearErrors, setError],
    );

    const pdfUploadInput = acceptedFormats.includes('application/pdf');
    const dragDropMessage = pdfUploadInput ? 'a PDF' : 'an Image';

    const validator = (file: File) => {
      if (!acceptedFormats.includes(file.type)) {
        setError(fileName, {
          type: 'manual',
          message: `Formats accepted: JPG, JPEG${pdfUploadInput ? ',' : ' or'} PNG${
            pdfUploadInput ? ', or PDF files' : ''
          }.`,
        });
        return null;
      }

      if (file.size > maxFileSize * 1024 * 1024) {
        setError(fileName, {
          type: 'manual',
          message: `File size exceeds ${maxFileSize / 1000} MB limit.`,
        });
        return null;
      }

      return null;
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: acceptedFormats.join(', '),
      maxSize: maxFileSize * 1024,
      validator,
    });

    return (
      <div {...getRootProps()} className={clsx(classes.Dropzone, isDragActive && classes.Active)}>
        <input {...getInputProps()} accept={acceptedFormats.join(', ')} />
        {selectedFile || defaultFile ? (
          !pdfUploadInput ? (
            previewUrl && (
              <img
                src={previewUrl}
                alt={`Preview of ${selectedFile?.name || "the file you've selected"}`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '120px',
                }}
              />
            )
          ) : (
            <p>{selectedFile?.name || defaultFile}</p>
          )
        ) : isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>
            Drag & drop {dragDropMessage} file or click to select.
            {!pdfUploadInput && aspectRatio && minHeights && (
              <>
                <br />
                Min dimensions: 128 x 128 pixels
                <br />
                Aspect ratio: 1:1
              </>
            )}
            <br />
            Max file size: {maxFileSize / 1000} MB
          </p>
        )}
      </div>
    );
  },
);

export default FileUploadInput;
