import React, { FC } from 'react';
import classes from './NotFound.module.css';
import { CLIENTS } from 'utils/routes';
import { Button } from 'components';
import EmptyState from 'components/EmptyState';

const NotFound: FC = () => {
  return (
    <div className={classes.Container}>
      <EmptyState title="404: Not found" description="The page you are looking for does not exist">
        <Button to={CLIENTS.URL}>Go To Clients</Button>
      </EmptyState>
    </div>
  );
};

export default NotFound;
