import React, { FC, useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { ColumnDef } from '@tanstack/react-table';

import { ReactComponent as SearchMagnifyingGlass } from 'assets/svgs/search-filled.svg';
import { RootState } from 'store';
import { CLIENTS, CLIENTS_TRAINING, PROGRAMS } from 'utils/routes';
import { removeClients, fetchClients, updateClientStatus } from 'store/actions/clients';
import { database } from 'utils/firebase';
import withAuth from 'utils/withAuth';
import Layout from '../Layout';
import classes from './Clients.module.css';
import { ACTIVE_CLIENTS, CLIENT_STATUS_TABS } from 'utils/constants';
import TabNavTotals from 'components/UI/TabNav/TabNavTotals';
import { Table, Spinner, Input, AlertDialog, Button } from 'components/UI';
import EmptyState from 'components/EmptyState';
import { ReactComponent as RightChevron } from 'assets/svgs/chevron_right.svg';
import SelectCheckbox from 'components/UI/Table/SelectCheckbox';
import { ReactComponent as PauseIcon } from 'assets/svgs/pause-filled.svg';
import { ReactComponent as PlayIcon } from 'assets/svgs/play-filled.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';

const mapStateToProps = ({ clients, auth }: RootState) => {
  return {
    token: auth.token,
    clients: clients.clients,
    loading: clients.loading,
    userId: auth.userId,
    coachProfile: auth.coachProfile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onDeleteClient: (clientKeys: string[]) => dispatch(removeClients(clientKeys)),
    onFetchClients: (userId: string) => dispatch(fetchClients(userId)),
    onUpdateClientStatus: (clientKey: string, status: number) => dispatch(updateClientStatus(clientKey, status)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps;

type SearchValue = {
  clientsSearch: string;
};

const EmptyClientsList: FC = () => {
  return (
    <div className={classes.Flex}>
      <EmptyState
        title="First client loading..."
        description={
          <div>
            <p className={classes.HowToAddText}>How to add a client:</p>
            <ol className={classes.Centered}>
              <li>
                <p>Client downloads the app on the App Store</p>
              </li>
              <li>
                <p>Client registers using your unique coach code</p>
              </li>
              <li>
                <p>Once client has registered, refresh your page and they will appear here!</p>
              </li>
              <li>
                <p>Assign them their first program</p>
              </li>
            </ol>
          </div>
        }
      />
    </div>
  );
};

const Clients: FC<Props> = ({
  token,
  userId,
  clients,
  loading,
  history,
  onDeleteClient,
  onFetchClients,
  onUpdateClientStatus,
}) => {
  const tableRef = useRef<any>(null);
  const [removeModal, showRemoveModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [clientsFiltered, setClientsFiltered] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [pauseClientAccessModal, setPauseClientAccessModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ACTIVE_CLIENTS);
  const [modalLoading, setModalLoading] = useState(false);
  const [statusTabs, setStatusTabs] = useState<Array<{ title: string; items?: any }>>(
    CLIENT_STATUS_TABS.map((tab) => ({ title: tab })),
  );
  const [rowSelection, setRowSelection] = useState({});
  const [selectedClientsStatus, setSelectedClientsStatus] = useState(1);

  const { register } = useForm<SearchValue>();

  useEffect(() => {
    if (token && userId) {
      onFetchClients(userId);
      window.localStorage.removeItem('selectedProgramKey');
    }
  }, [token, userId, onFetchClients]);

  const getSelectedClientKeys = () => {
    const clientRowsSelected = tableRef.current?.getSelectedRowModel()?.flatRows;
    const clientKeys = clientRowsSelected?.map((row) => row.original.clientKey);
    return clientKeys;
  };

  const preRemoveClient = () => {
    const selectedClientKeys = getSelectedClientKeys();
    setSelectedUsers(selectedClientKeys);
    showRemoveModal(true);
  };

  const handleRemoveClient = () => {
    if (token) {
      onDeleteClient(selectedUsers);
      showRemoveModal(false);
      setRowSelection({});
    }
  };

  const showPauseClientAccessModal = () => {
    const selectedClientKeys = getSelectedClientKeys();
    setSelectedUsers(selectedClientKeys);
    setPauseClientAccessModal(true);
  };

  const setClientsStatus = async (selectedClientKeys: string[]) => {
    setModalLoading(true);

    // Prepare the updates object for multi-path update
    const updates = {};
    selectedClientKeys.forEach((clientKey) => {
      const currentStatus = clients?.[clientKey]?.status;
      const newStatus = currentStatus === 1 || !currentStatus ? 2 : 1;
      updates[`clients/${clientKey}/status`] = newStatus;
    });

    // Perform a multi-path update
    await database
      .ref()
      .update(updates)
      .then(() => {
        selectedClientKeys.forEach((clientKey) => {
          const newStatus = updates[`clients/${clientKey}/status`];
          onUpdateClientStatus(clientKey, newStatus);
        });
        setModalLoading(false);
        setPauseClientAccessModal(false);
        setRowSelection({});
      })
      .catch((e) => {
        console.log('Error in updating clients: ', e);
        setModalLoading(false);
      });
  };

  let clientsPerStatus = clients;
  if (clients) {
    if (selectedTab !== 'All') {
      clientsPerStatus = Object.fromEntries(
        Object.entries(clients || {})?.filter(([, value]) => CLIENT_STATUS_TABS[value?.status || 1] === selectedTab),
      );
    }
  }

  useEffect(() => {
    if (clients) {
      const TABS_OBJECT = CLIENT_STATUS_TABS.map((tab) => {
        if (tab === 'All') {
          return { title: tab, items: Object.keys(clients).length };
        }
        // TODO could use this result to predetermine the clients that need to be shown in each tab instead of having to rerun it above (line 211)
        const result = Object.fromEntries(
          Object.entries(clients || {})?.filter(([, value]) => CLIENT_STATUS_TABS[value?.status || 1] === tab),
        );
        return { title: tab, items: Object.keys(result || {}).length };
      });
      setStatusTabs(TABS_OBJECT);
    }
  }, [clients]);

  const checkStatusesOfSelectedUsers = () => {
    const selectedClientKeys = getSelectedClientKeys();
    if (!selectedClientKeys || selectedClientKeys.length === 0) return 3; // Handle case with no selected users
    if (selectedClientKeys.length === 1) return clients?.[selectedClientKeys[0]]?.status || 1; // Handle case with one selected user
    const firstStatus = clients?.[selectedClientKeys[0]]?.status || 1;
    let allStatusOne = firstStatus === 1;
    let allStatusTwo = firstStatus === 2;

    for (let i = 1; i < selectedClientKeys.length; i++) {
      const currentUserStatus = clients?.[selectedClientKeys[i]]?.status || 1;
      if (currentUserStatus !== firstStatus) {
        if (currentUserStatus === 1) allStatusTwo = false;
        if (currentUserStatus === 2) allStatusOne = false;
      }

      if (!allStatusOne && !allStatusTwo) {
        return 3; // A mix of statuses
      }
    }

    return allStatusOne ? 1 : 2; // All have status 1 or all have status 2
  };

  useEffect(() => {
    if (!rowSelection) return;
    if (Object.keys(rowSelection)?.length === 0) return setSelectedClientsStatus(3);
    const status = checkStatusesOfSelectedUsers();
    setSelectedClientsStatus(status);
  }, [rowSelection, checkStatusesOfSelectedUsers]);

  const tableColumns = useMemo<ColumnDef<Record<string, unknown>>[]>(
    () => [
      SelectCheckbox(),
      {
        header: 'Name',
        cell: (row) => row.renderValue(),
        accessorKey: 'name',
      },
      {
        header: 'Email Address',
        cell: (row) => row.renderValue(),
        accessorKey: 'email',
      },
      {
        header: 'Program',
        accessorKey: 'program',
        cell: ({ row }) => {
          return row.original.hasPrograms ? (
            <p className={classes.ClientActive}>Assigned</p>
          ) : (
            <NavLink to={PROGRAMS.URL}>
              <button
                type="button"
                className={classes.PausedAccessAssignButton}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Assign program
              </button>
            </NavLink>
          );
        },
      },
      {
        header: '',
        accessorKey: 'actions',
        cell: ({ row }) => {
          return (
            <Button
              type="button"
              size="xsmall"
              onClick={() => {
                window.localStorage.setItem('clientKey', row.original.clientKey as string);
                history.push({
                  pathname: `${CLIENTS_TRAINING.URL}/${row.original.clientKey}`,
                });
              }}
              iconCenter={<RightChevron />}
            />
          );
        },
      },
    ],
    [],
  );

  const ClientsList = () => {
    if (!clients) {
      return null;
    }

    let clientsNew = Object.entries(clientsPerStatus || {});
    if (searchValue) {
      clientsNew = clientsFiltered;
    }

    if (!clientsNew || clientsNew.length === 0) {
      if (selectedTab === 'All') {
        return <EmptyClientsList />;
      }
      return (
        <div className={classes.Flex}>
          <EmptyState
            title={`No ${selectedTab.toLowerCase()} clients`}
            description="No users fall under this status."
          />
        </div>
      );
    }

    const users = clientsNew.map(([clientKey, clientData]) => ({
      name: clientData.profile.fullname,
      email: clientData.profile.email,
      clientKey: clientKey,
      hasPrograms: clientData.hasPrograms,
    }));

    const lengthOfRowsSelected = Object.keys(rowSelection || {}).length;

    return (
      <Table
        ref={tableRef}
        data={users}
        columns={tableColumns}
        loading={false}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        bulkActions={
          <div className={classes.BulkActions}>
            {selectedClientsStatus !== 3 && (
              <div>
                <Button
                  type="button"
                  intent="secondary"
                  onClick={showPauseClientAccessModal}
                  iconLeft={selectedClientsStatus === 1 || !selectedClientsStatus ? <PauseIcon /> : <PlayIcon />}
                >
                  {selectedClientsStatus === 1 || !selectedClientsStatus
                    ? 'Pause '
                    : selectedClientsStatus === 2 && 'Unpause '}
                  Access
                </Button>
              </div>
            )}
            <div>
              <Button type="button" intent="secondary" onClick={preRemoveClient} iconLeft={<DeleteIcon />}>
                Delete Client{lengthOfRowsSelected > 1 && 's'}
              </Button>
            </div>
          </div>
        }
        onRowClick={({ original }) => {
          window.localStorage.setItem('clientKey', original.clientKey);
          history.push({
            pathname: `${CLIENTS_TRAINING.URL}/${original.clientKey}`,
          });
        }}
      />
    );
  };

  const hasClients = !!Object.keys(clients || {}).length;
  const clientsPerStatusLength = Object.keys(clientsPerStatus || {}).length;

  if (loading) {
    return (
      <Layout loading={loading} heading={CLIENTS.TITLE}>
        <div className={classes.Clients}>
          <Spinner />
        </div>
      </Layout>
    );
  }

  const onSearchClients = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setSearchValue(value);

    const clientsFiltered = Object.entries(clientsPerStatus || {})?.filter(([, client]) => {
      return client?.profile?.fullname?.toLowerCase()?.includes(value);
    });

    setClientsFiltered(clientsFiltered);
  };

  const onSetSelectedTab = (tab: string) => {
    setSelectedTab(tab);
    setSearchValue(null);
  };

  const getClientName = (clientKey: string) => {
    return clients?.[clientKey]?.profile?.fullname;
  };
  const formatUserNames = (userKeys) => {
    return userKeys
      .map((key) => getClientName(key))
      .join(', ')
      .replace(/, ([^,]*)$/, ' and $1');
  };
  const clientStatus = clients?.[selectedUsers[0]]?.status;

  return (
    <Layout loading={loading} heading={CLIENTS.TITLE}>
      <div className={classes.Container}>
        <TabNavTotals setSelectedTab={onSetSelectedTab} selectedTab={selectedTab} tabHeadingsObject={statusTabs} />

        <div className={clsx(clientsPerStatusLength === 0 && classes.EmptyClients)}>
          {clientsPerStatusLength > 0 && (
            <div className={classes.SearchClients}>
              <form>
                <Input
                  name="clientsSearch"
                  type="text"
                  placeholder="Search clients..."
                  register={register}
                  onChange={onSearchClients}
                  iconLeft={<SearchMagnifyingGlass />}
                />
              </form>
            </div>
          )}
          {hasClients ? <div className={classes.Clients}>{ClientsList()}</div> : <EmptyClientsList />}
        </div>
      </div>

      <AlertDialog
        title="Remove client"
        description={`Please confirm you want to remove ${
          selectedUsers.length > 1 ? formatUserNames(selectedUsers) : getClientName(selectedUsers[0])
        }. This cannot be undone.`}
        open={removeModal}
        onOpenChange={showRemoveModal}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => showRemoveModal(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={handleRemoveClient}>
            Remove
          </Button>
        }
      />

      <AlertDialog
        title={`${clientStatus === 1 || !clientStatus ? 'Pause' : 'Unpause'} client access`}
        description={`Please confirm you want to ${
          clientStatus === 1 || !clientStatus ? 'pause' : 'unpause'
        } access for ${selectedUsers.length > 1 ? formatUserNames(selectedUsers) : getClientName(selectedUsers[0])}`}
        open={pauseClientAccessModal}
        onOpenChange={setPauseClientAccessModal}
        cancelButton={
          <Button
            type="button"
            intent="secondary"
            onClick={() => setPauseClientAccessModal(false)}
            disabled={modalLoading}
          >
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={() => setClientsStatus(selectedUsers)} loading={modalLoading}>
            {clientStatus === 1 || !clientStatus ? 'Pause' : 'Unpause'} Access
          </Button>
        }
      />
    </Layout>
  );
};

export default connector(withAuth(Clients));
