import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import classes from './Home.module.css';
import { Plans } from 'interfaces/db';
import withoutAuth from 'utils/withoutAuth';
import { useLocalStorage } from 'utils/hooks';
import { REGISTER, LOGIN } from 'utils/routes';
import coaches from 'assets/images/coaches.png';
import { LS_PRICING_INFO } from 'utils/constants';
import { LocalStoragePricing } from 'interfaces/utils';
import builderScreenshot from 'assets/images/builder_screenshot.png';
import workoutScreenshot from 'assets/images/workouts_screenshot.png';
import workoutMDScreenshot from 'assets/images/workout_md_screenshot.png';

const pricingArr: Plans[] = [
  {
    id: 1,
    maxClients: 5,
    name: 'Free',
    price: [
      { amount: 0, currency: 'ZAR' },
      { amount: 0, currency: 'USD' },
    ],
    annualPrice: [
      { amount: 0, currency: 'ZAR' },
      { amount: 0, currency: 'USD' },
    ],
  },
  {
    id: 2,
    maxClients: 15,
    name: 'Basic',
    price: [
      { amount: 250, currency: 'ZAR' },
      { amount: 20, currency: 'USD' },
    ],
    annualPrice: [
      { amount: 225, currency: 'ZAR' },
      { amount: 18, currency: 'USD' },
    ],
  },
  {
    id: 3,
    maxClients: 30,
    name: 'Pro',
    price: [
      { amount: 500, currency: 'ZAR' },
      { amount: 30, currency: 'USD' },
    ],
    annualPrice: [
      { amount: 450, currency: 'ZAR' },
      { amount: 27, currency: 'USD' },
    ],
  },
  {
    id: 4,
    maxClients: 50,
    name: 'Elite',
    price: [
      { amount: 800, currency: 'ZAR' },
      { amount: 50, currency: 'USD' },
    ],
    annualPrice: [
      { amount: 720, currency: 'ZAR' },
      { amount: 45, currency: 'USD' },
    ],
  },
];

const NewLanding: FC<RouteComponentProps> = ({ history }) => {
  const [, setPricing] = useLocalStorage<LocalStoragePricing | null>(LS_PRICING_INFO, null);

  const [isYearly, setIsYearly] = useState<boolean>(false);

  const handlePlanSelection = (planId: number) => {
    const isFreePlan = planId === 1;
    const duration = isYearly ? 'year' : 'month';

    if (isFreePlan) {
      setPricing(null);
      history.push({
        pathname: REGISTER.URL,
      });
      return;
    }

    setPricing({ planId, duration });
    history.push({
      pathname: REGISTER.URL,
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.introWrapper}>
        <div className={classes.introWrapperInner}>
          <div className={classes.ceContainer}>
            <div className={classes.intro}>
              <div className={classes.header}>
                <div className={classes.logoWrapper}>
                  <svg
                    viewBox="0 0 148.289 31"
                    className={classes.logo}
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <linearGradient id="a" x1="-0.068" x2="1" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#da0c1a"></stop>
                        <stop offset="1" stopColor="#f8810a"></stop>
                      </linearGradient>
                      <linearGradient id="b" x1="0" x2="0.926" xlinkHref="#a"></linearGradient>
                    </defs>
                    <g data-name="Menu Logo">
                      <rect width="31" height="31" fill="url(#a)" data-name="Rectangle 31" rx="10.252"></rect>
                      <path
                        fill="#191c23"
                        d="M22.096 7.94c-.028-.057-.059-.107-.09-.161l-.073-.111a2.284 2.284 0 00-2.02-.807h-5.722c-1.944 0-3.069 1.052-3.435 3.247L9.137 20.329a3.663 3.663 0 00.173 2.2.763.763 0 00.083.157 2.24 2.24 0 002.124.941h5.722c1.923 0 3.044-1.052 3.39-3.247l.28-1.974.19-1.205h-.023l.049-.333h-4.059l-.346 2.6c-.066.337-.152.432-.453.432h-1.2l1.439-9.264h1.235c.3 0 .367.1.322.428l-.253 1.794-.059.379h.007l-.062.432h4.061l.495-3.511a3.685 3.685 0 00-.156-2.218zm-7.58 10.19h-1.172a.215.215 0 01-.2-.259l.884-5.549a.207.207 0 01.2-.182h1.215z"
                        data-name="Path 12"
                      ></path>
                      <path
                        fill="url(#b)"
                        d="M635.637 254.8h9.178l-.484 2.788h-5.964l-.426 2.459h4.759l-.484 2.749h-4.763l-.484 2.769h5.944l-.484 2.788h-9.178zm10.9 0h3.214l-1.878 10.727h5.46l-.484 2.827h-8.694zm10.088 0h3.214l-2.382 13.554h-3.214l1.012-5.757zm7.493 2.808h-3.427l.484-2.808h10.088l-.484 2.808h-3.446l-1.9 10.746h-3.214zm8.462-2.808h9.178l-.484 2.788h-5.964l-.426 2.459h4.763l-.484 2.749H674.4l-.484 2.769h5.945l-.484 2.788H670.2z"
                        transform="translate(-533.471 -246.231)"
                      ></path>
                      <g fill="#f5f5f5">
                        <path
                          d="M40.223 19.177l1.433-8.21a2.844 2.844 0 013.1-2.672h4.8c1.762 0 2.44.852 2.13 2.672l-.426 2.362h-3.214l.348-1.878c.039-.29-.039-.368-.31-.368h-2.885c-.252 0-.368.078-.407.368l-1.278 7.242c-.058.271.019.368.29.368h2.866c.29 0 .387-.1.445-.368l.329-1.9h3.214l-.426 2.382a2.84 2.84 0 01-3.077 2.675h-4.8c-1.764 0-2.461-.872-2.132-2.673z"
                          data-name="Path 6"
                        ></path>
                        <path
                          d="M51.705 19.177l1.433-8.21a2.827 2.827 0 013.079-2.672h5.131c1.781 0 2.479.852 2.149 2.672l-1.433 8.21a2.846 2.846 0 01-3.1 2.672h-5.129c-1.762.001-2.459-.871-2.13-2.672zm6.816-.116c.252 0 .348-.1.407-.368l1.278-7.242c.039-.29-.019-.368-.291-.368h-3.233c-.252 0-.368.078-.407.368l-1.278 7.242c-.058.271.019.368.29.368z"
                          data-name="Path 7"
                        ></path>
                        <path
                          d="M71.011 18.984h-4.183l-1.239 2.866h-3.427l6.409-13.554h4.4l1.661 13.554h-3.369zm-.232-2.73l-.445-5.305-2.3 5.305z"
                          data-name="Path 8"
                        ></path>
                        <path
                          d="M75.696 19.177l1.433-8.21a2.844 2.844 0 013.1-2.672h4.8c1.762 0 2.44.852 2.13 2.672l-.426 2.362h-3.214l.348-1.878c.039-.29-.039-.368-.31-.368h-2.882c-.252 0-.368.078-.407.368l-1.278 7.242c-.058.271.019.368.29.368h2.866c.29 0 .387-.1.445-.368l.329-1.9h3.214l-.426 2.382a2.84 2.84 0 01-3.08 2.675h-4.8c-1.764 0-2.461-.872-2.132-2.673z"
                          data-name="Path 9"
                        ></path>
                        <path
                          d="M89.309 8.295h3.214l-.91 5.209h4.2l.91-5.209h3.214l-2.38 13.555h-3.214l.968-5.519h-4.2l-.97 5.519h-3.214z"
                          data-name="Path 10"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>

                <div className={classes.menu}>
                  <ul>
                    <li>
                      <a href="#features">Features</a>
                    </li>

                    <li>
                      <a href="#pricing">Pricing</a>
                    </li>

                    <li>
                      <NavLink to={{ pathname: REGISTER.URL }} className={classes.signUpBtn}>
                        Sign up
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to={{ pathname: LOGIN.URL }} className={classes.loginBtn}>
                        Login
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={classes.bannerWrapper}>
                <div className={classes.bannerLHS}>
                  <div className={classes.bannerText}>
                    IT&apos;S TIME TO TAKE <br />
                    <span className={classes.highlightedBannerText}>YOUR COACHING</span> TO <br /> THE NEXT LEVEL.
                  </div>

                  <p className={classes.description}>
                    Stop wasting time on admin and start providing a professional and personalized coaching experience
                    that scales your business.
                  </p>

                  <div className={classes.coachesWrapper}>
                    <img src={coaches} alt="coaches" className={classes.coaches} />
                  </div>

                  <div className={classes.callToAction}>
                    <a href="#pricing" className={classes.ctaLink}>
                      get started
                    </a>

                    <p className={classes.ctaDescription}>
                      Use Coachd for <strong>free up to 5 clients</strong>. No credit card required.
                    </p>
                  </div>

                  <div className={classes.buttonWrapper}>
                    <button type="button" className={classes.desktopBtn}>
                      GO TO OUR DESKTOP SITE TO GET STARTED
                    </button>

                    <div className={classes.bannerLink}>
                      OR <a href="#features">FIND OUT MORE</a>
                    </div>
                  </div>
                </div>
                <div className={classes.bannerRHS}>
                  <img src={coaches} alt="coaches" className={classes.coaches} />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.gradient} />
        </div>
      </div>

      <div id="features" className={classes.ceContainer}>
        <div className={clsx(classes.midSection, classes.workoutSection)}>
          <div className={classes.midSectionTitle}>BUILD WORKOUTS AND ASSIGN THEM TO CLIENTS</div>

          <p className={classes.description}>
            Our powerful, easy to use workout builder allows you to name, organise and assign workouts to multiple
            clients.
          </p>

          <div className={classes.workoutGroup}>
            <img src={builderScreenshot} alt="builder screenshot" className={classes.builderScreenshot} />

            <div className={classes.listWrapper}>
              <ol>
                <li>Name your workout</li>
                <li>Select or create an exercise</li>
                <li>Assign reps, sets, weight, time.</li>
              </ol>

              <p className={classes.MDdescription}>
                Our powerful, easy to use workout builder allows you to name, organise and assign workouts to multiple
                clients.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.ceContainer}>
        <div className={clsx(classes.midSection, classes.clientsSection)}>
          <div className={classes.midSectionTitle}>
            TRACK YOUR CLIENT&apos;S PROGRESS WITH INSTANT FEEDBACK
            <p className={classes.MDdescription}>
              Client’s will be able to give you detailed feedback on their workouts such as their achieved sets, reps,
              weight, RPE and more!
            </p>
          </div>

          <div className={classes.screenshotWrapper}>
            <img src={workoutScreenshot} alt="workout screenshot" className={classes.workoutScreenshot} />

            <img src={workoutMDScreenshot} alt="workout screenshot" className={classes.workoutMDScreenshot} />
          </div>

          <p className={classes.description}>
            Client’s will be able to give you detailed feedback on their workouts such as their achieved sets, reps,
            weight, RPE and more!
          </p>
        </div>
      </div>

      <div id="pricing" className={classes.ceContainer}>
        <div className={classes.midSection}>
          <div className={classes.midSectionTitle}>PRICING & PLANS</div>

          <div className={classes.toggleWrapper}>
            <div className={classes.toggleInner}>
              <div
                role="button"
                onClick={() => setIsYearly(true)}
                className={clsx(classes.toggleBtn, isYearly && classes.activeToggleBtn)}
              >
                <span>yearly</span>
              </div>
              <div
                role="button"
                onClick={() => setIsYearly(false)}
                className={clsx(classes.toggleBtn, !isYearly && classes.activeToggleBtn)}
              >
                <span>monthly</span>
              </div>
            </div>
          </div>

          <div className={classes.priceCardWrapper}>
            {pricingArr.map(({ id, name, price, annualPrice, maxClients }) => {
              const isFree = id === 1;
              const isBasic = id === 2;

              const zarAmount = price[0].amount;
              const zarAnnualAmount = annualPrice[0].amount;

              return (
                <div key={id} className={classes.priceCard}>
                  <div className={classes.priceInner}>
                    <div className={classes.planTitle}>{isFree ? 'STARTER' : name} PLAN</div>

                    <div className={classes.planContentWrapper}>
                      <div className={classes.price}>
                        {isFree ? (
                          'FREE'
                        ) : (
                          <>
                            R{isYearly ? zarAnnualAmount : zarAmount}
                            <span>/pm</span>
                          </>
                        )}
                      </div>

                      <div className={classes.clientCount}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="9.174" height="9.91">
                          <path
                            data-name="Path 10591"
                            d="M1.369 5.493L3.376 8.91l4.429-7.541"
                            fill="none"
                            stroke="#58cb2c"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                          />
                        </svg>
                        Up to {maxClients} clients
                      </div>

                      <button type="button" className={classes.priceBtn} onClick={() => handlePlanSelection(id)}>
                        {isFree ? 'START FOR FREE' : isBasic ? 'GET STARTED' : `GET ${name}`}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={classes.priceFooter}>
            {/* <p className={classes.enterpriseLink}>
              Need More? <a href="#">Enterprise Plan</a>
            </p> */}

            <p className={classes.discountText}>*All Plans 10% off when purchasing a yearly subscription!</p>
          </div>
        </div>
      </div>

      <footer>
        <div className={classes.ceContainer}>
          <div className={classes.footer}>
            <div className={classes.footerLHS}>
              <svg
                viewBox="0 0 148.289 31"
                className={classes.logo}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <linearGradient id="a" x1="-0.068" x2="1" y2="1" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#da0c1a"></stop>
                    <stop offset="1" stopColor="#f8810a"></stop>
                  </linearGradient>
                  <linearGradient id="b" x1="0" x2="0.926" xlinkHref="#a"></linearGradient>
                </defs>
                <g data-name="Menu Logo">
                  <rect width="31" height="31" fill="url(#a)" data-name="Rectangle 31" rx="10.252"></rect>
                  <path
                    fill="#191c23"
                    d="M22.096 7.94c-.028-.057-.059-.107-.09-.161l-.073-.111a2.284 2.284 0 00-2.02-.807h-5.722c-1.944 0-3.069 1.052-3.435 3.247L9.137 20.329a3.663 3.663 0 00.173 2.2.763.763 0 00.083.157 2.24 2.24 0 002.124.941h5.722c1.923 0 3.044-1.052 3.39-3.247l.28-1.974.19-1.205h-.023l.049-.333h-4.059l-.346 2.6c-.066.337-.152.432-.453.432h-1.2l1.439-9.264h1.235c.3 0 .367.1.322.428l-.253 1.794-.059.379h.007l-.062.432h4.061l.495-3.511a3.685 3.685 0 00-.156-2.218zm-7.58 10.19h-1.172a.215.215 0 01-.2-.259l.884-5.549a.207.207 0 01.2-.182h1.215z"
                    data-name="Path 12"
                  ></path>
                  <path
                    fill="url(#b)"
                    d="M635.637 254.8h9.178l-.484 2.788h-5.964l-.426 2.459h4.759l-.484 2.749h-4.763l-.484 2.769h5.944l-.484 2.788h-9.178zm10.9 0h3.214l-1.878 10.727h5.46l-.484 2.827h-8.694zm10.088 0h3.214l-2.382 13.554h-3.214l1.012-5.757zm7.493 2.808h-3.427l.484-2.808h10.088l-.484 2.808h-3.446l-1.9 10.746h-3.214zm8.462-2.808h9.178l-.484 2.788h-5.964l-.426 2.459h4.763l-.484 2.749H674.4l-.484 2.769h5.945l-.484 2.788H670.2z"
                    transform="translate(-533.471 -246.231)"
                  ></path>
                  <g fill="#f5f5f5">
                    <path
                      d="M40.223 19.177l1.433-8.21a2.844 2.844 0 013.1-2.672h4.8c1.762 0 2.44.852 2.13 2.672l-.426 2.362h-3.214l.348-1.878c.039-.29-.039-.368-.31-.368h-2.885c-.252 0-.368.078-.407.368l-1.278 7.242c-.058.271.019.368.29.368h2.866c.29 0 .387-.1.445-.368l.329-1.9h3.214l-.426 2.382a2.84 2.84 0 01-3.077 2.675h-4.8c-1.764 0-2.461-.872-2.132-2.673z"
                      data-name="Path 6"
                    ></path>
                    <path
                      d="M51.705 19.177l1.433-8.21a2.827 2.827 0 013.079-2.672h5.131c1.781 0 2.479.852 2.149 2.672l-1.433 8.21a2.846 2.846 0 01-3.1 2.672h-5.129c-1.762.001-2.459-.871-2.13-2.672zm6.816-.116c.252 0 .348-.1.407-.368l1.278-7.242c.039-.29-.019-.368-.291-.368h-3.233c-.252 0-.368.078-.407.368l-1.278 7.242c-.058.271.019.368.29.368z"
                      data-name="Path 7"
                    ></path>
                    <path
                      d="M71.011 18.984h-4.183l-1.239 2.866h-3.427l6.409-13.554h4.4l1.661 13.554h-3.369zm-.232-2.73l-.445-5.305-2.3 5.305z"
                      data-name="Path 8"
                    ></path>
                    <path
                      d="M75.696 19.177l1.433-8.21a2.844 2.844 0 013.1-2.672h4.8c1.762 0 2.44.852 2.13 2.672l-.426 2.362h-3.214l.348-1.878c.039-.29-.039-.368-.31-.368h-2.882c-.252 0-.368.078-.407.368l-1.278 7.242c-.058.271.019.368.29.368h2.866c.29 0 .387-.1.445-.368l.329-1.9h3.214l-.426 2.382a2.84 2.84 0 01-3.08 2.675h-4.8c-1.764 0-2.461-.872-2.132-2.673z"
                      data-name="Path 9"
                    ></path>
                    <path
                      d="M89.309 8.295h3.214l-.91 5.209h4.2l.91-5.209h3.214l-2.38 13.555h-3.214l.968-5.519h-4.2l-.97 5.519h-3.214z"
                      data-name="Path 10"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>

            <div className={classes.footerRHS}>
              <div className={classes.footerSection}>
                <div className={classes.footerTitle}>COMPANY</div>

                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://docs.google.com/document/d/1CoVHks0TpuPR4-ltbiPuSgIyPyJf28K2P0kJdd3q4Do"
                    >
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://docs.google.com/document/d/16Rqu8_QSnGp_t1vUJaTndO8dHltGwAmyZKiSBj_xogY"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className={classes.footerSection}>
                <div className={classes.footerTitle}>SUPPORT</div>

                <p>
                  Need help? Mail <a href="mailto:support@coachd.ai">support@coachd.ai</a> for All your support queries
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default withoutAuth(NewLanding);
