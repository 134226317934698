import React, { FC } from 'react';
import {
  Select as SelectPrimitive,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/UI/Select/SelectPrimitives';
import { SelectProps } from 'interfaces/ui';
import { Controller } from 'react-hook-form';
import classes from './Select.module.css';

const Select: FC<SelectProps> = ({
  name,
  required = false,
  placeholder,
  control,
  optionArray,
  onOpenChange,
  open,
  label,
  error,
  onChange,
  defaultValue,
  value,
}) => {
  const errorMessage = typeof error === 'string' ? error : error?.message || 'This field is required';
  return (
    <div className={classes.SelectContainer}>
      {label && (
        <label htmlFor={name} className={classes.Label}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={defaultValue}
        render={({ field }) => {
          const attributes = {
            onValueChange: (value) => {
              field.onChange(value);
              onChange && onChange(value);
            },
            onOpenChange,
            open,
            name,
            ...(value ? { value } : {}),
            ...(defaultValue ? { defaultValue } : {}),
          };

          return (
            <SelectPrimitive {...attributes}>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
              <SelectContent>
                {optionArray.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectPrimitive>
          );
        }}
      />
      {error && <span className={classes.ErrorMessage}>{errorMessage}</span>}
    </div>
  );
};

export default Select;
