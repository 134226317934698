/**
 * Checkout Page
 * This page is used to display the checkout form and handle the payment process
 * It used for both client billing and funnel customer subscriptions
 * Handles new and existing users
 * Handles trial and non-trial subscriptions
 * Handles reactivating subscriptions
 */
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import tick from 'assets/icons/tick.svg';
import classes from './Checkout.module.css';
import { CheckoutForm } from 'components';
import { BASE_URL, encodeData, getCurrencySymbol } from 'utils/helpers';
import Modal from 'components/UI/Modal/Modal';
import Button from 'components/UI/Button';
import Logo from 'assets/images/logo.svg';
import Footer from 'components/Footer';
import mixpanel from 'mixpanel-browser';
import { firestore } from 'utils/firebase';
import { createCheckoutSession } from 'api/clientBillingApi';
import { PaymentInfo } from 'interfaces/checkout';
import clsx from 'clsx';

type FormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

const PaymentPage: FC = () => {
  const { state, search } = useLocation();
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();
  const [isModalOpen, setModalState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState('');

  const redirectToHomePage = () => {
    window.location.href = 'https://www.coachelite.co/';
  };

  const decodeData = (dataString: string) => {
    const encodedDataBuffer = Buffer.from(dataString, 'base64');
    const decodedData = encodedDataBuffer.toString('utf8');
    const paymentInfo: PaymentInfo = JSON.parse(decodedData);
    return paymentInfo;
  };

  useEffect(() => {
    try {
      if (state) {
        const objectString = state + '';
        const paymentInfo: PaymentInfo = decodeData(objectString);

        setPaymentInfo(paymentInfo);
      } else if (search) {
        const params = new URLSearchParams(search);
        const data = params.get('data') + '';
        const paymentInfo: PaymentInfo = decodeData(data);

        setPaymentInfo(paymentInfo);
      } else {
        redirectToHomePage();
      }
    } catch (error) {
      redirectToHomePage();
    }
  }, [state, search]);

  useEffect(() => {
    if (paymentInfo && !paymentInfo?.planCode) {
      redirectToHomePage();
    }
  }, [paymentInfo]);

  const handleSubmit = async ({ email, firstName, lastName }: FormValues) => {
    mixpanel.track('Checkout Button Click', {
      CoachIdentifier: paymentInfo?.funnelIdentifier,
    });
    try {
      setLoading(true);
      const data = encodeData(
        JSON.stringify({
          email: email,
          planCode: paymentInfo?.planCode,
          fullName: `${firstName} ${lastName}`,
          coachUserId: paymentInfo?.coachUserId,
          ...paymentInfo,
        }),
      );

      const coachRef = firestore.collection('coaches').doc(paymentInfo?.coachUserId);

      if (paymentInfo?.isClientBilling) {
        // Handle client billing
        const clientBillingPlansRef = coachRef.collection('client-billing-plans');
        const clientBillingPlanPricesDoc = clientBillingPlansRef.doc(paymentInfo?.planCode).collection('prices');
        const priceDocSnapshot = await clientBillingPlanPricesDoc.limit(1).get();
        const priceDoc = priceDocSnapshot.docs[0];

        const result = await createCheckoutSession({
          email,
          firstName,
          lastName,
          coachUserId: paymentInfo?.coachUserId,
          planCode: paymentInfo?.planCode,
          priceId: priceDoc.id,
          isReactivateSubscription: paymentInfo?.isReactivateSubscription,
          identifier: paymentInfo?.funnelIdentifier,
          coachAccountId: paymentInfo?.coachAccountId,
          encodedData: data,
        });

        if (
          result.data?.data?.sessionUrl === undefined &&
          result.data.status_code === 400 &&
          result.data.message === 'Cannot create checkout session due to active subscription/s.'
        ) {
          setModalState(true);
          setMessage('You already have an active subscription.');
          setLoading(false);
          return;
        }

        setLoading(false);
        window.location.href = result?.data?.data?.sessionUrl;
      } else {
        // Handle funnel plans
        const funnelPricesRef = coachRef.collection('funnel-plans').doc(paymentInfo?.planCode).collection('prices');
        const priceDocSnapshot = await funnelPricesRef.limit(1).get();
        const priceDoc = priceDocSnapshot.docs[0];

        const response = await axios.post(`${BASE_URL}/v1-https-funnels-createCheckoutSession`, {
          email,
          firstName,
          lastName,
          coachUserId: paymentInfo?.coachUserId,
          planCode: paymentInfo?.planCode,
          priceId: priceDoc.id,
          identifier: paymentInfo?.funnelIdentifier,
          isReactivateSubscription: paymentInfo?.isReactivateSubscription,
          data,
        });

        setLoading(false);
        window.location.href = response?.data?.data?.sessionUrl;
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      let message = 'Something went wrong.';

      if (error === 'auth/user-exists') {
        setModalState(true); // warn user they are already a client and can't subscribe
        message = 'Account already exists.';
      } else {
        mixpanel.track('Checkout Error', {
          CoachIdentifier: paymentInfo?.funnelIdentifier,
        });
      }

      setMessage(message);
    }
  };

  const currencySymbol = getCurrencySymbol(paymentInfo?.currency);

  const pricing = paymentInfo?.isTrialEnabled
    ? `FIRST MONTH IS ${currencySymbol}1`
    : currencySymbol + paymentInfo?.price + '/month';

  return (
    <>
      <div className={classes.Page}>
        <div className={classes.Header}>
          <div className={classes.Logo}>
            <a href={`https://app.coachd.ai/${!paymentInfo?.isClientBilling ? paymentInfo?.funnelIdentifier : ''}`}>
              <img src={Logo} alt="logo" />
            </a>
          </div>

          <div className={classes.AuthButtons}></div>
        </div>
        <h1>SUBSCRIBE NOW!</h1>
        <ul>
          <li>
            <div className={clsx(classes.Liststyle, classes.ListstyleComplete)}>
              <img src={tick} alt="tick" />
            </div>
            {!paymentInfo?.isClientBilling ? 'SUBSCRIPTION SELECTED' : 'COACH SELECTED'}
          </li>
          <div
            className={clsx(
              classes.CardContainer,
              !paymentInfo?.isClientBilling && [classes.FunnelCardContainer, classes.CardContainerHeight],
            )}
          >
            {!paymentInfo?.isClientBilling && (
              <div className={classes.ImageContainer}>
                <img src={paymentInfo?.planImageDesktop} alt="coach logo" />
              </div>
            )}

            <div className={clsx(classes.CardContent, !paymentInfo?.isClientBilling && classes.CardContentPadding)}>
              <h3>{pricing}</h3>
              {paymentInfo?.isTrialEnabled && (
                <h5>{`${currencySymbol + paymentInfo?.renewalPrice} (${
                  paymentInfo?.currency
                }) every month after. Cancel anytime.`}</h5>
              )}
              {paymentInfo?.isTrialEnabled && (
                <h6>
                  {`After completion of 1 month of your subscription, your renewal will be automatically charged at ${
                    currencySymbol + paymentInfo?.renewalPrice
                  }, with the flexibility to cancel at anytime.`}
                </h6>
              )}

              {!paymentInfo?.isTrialEnabled && (
                <h4 className={classes.Title}>
                  {paymentInfo?.coachName}{' '}
                  {paymentInfo?.numPrograms && (
                    <span>
                      &#40;{paymentInfo?.numPrograms} program{paymentInfo?.numPrograms > 1 && 's'}
                      &#41;
                    </span>
                  )}
                </h4>
              )}
              {!paymentInfo?.isTrialEnabled && paymentInfo?.numPrograms && (
                <span className={classes.NumPrograms}>
                  &#40;{paymentInfo?.numPrograms} program{paymentInfo?.numPrograms > 1 && 's'}
                  &#41;
                </span>
              )}
              {!paymentInfo?.isTrialEnabled && <p className={classes.Description}>{paymentInfo?.planDescription}</p>}
            </div>
          </div>
          <li>
            <div className={classes.Liststyle}>2</div>
            YOUR ACCOUNT
          </li>
          <CheckoutForm handleSubmit={handleSubmit} loading={loading} />
          <span className={classes.Disclaimer}>
            By continuing you accept our{' '}
            <a
              href="https://docs.google.com/document/d/16Rqu8_QSnGp_t1vUJaTndO8dHltGwAmyZKiSBj_xogY"
              rel="noopener noreferrer"
              target="_blank"
            >
              privacy policy
            </a>
            ,{' '}
            <a
              href="https://docs.google.com/document/d/1CoVHks0TpuPR4-ltbiPuSgIyPyJf28K2P0kJdd3q4Do"
              rel="noopener noreferrer"
              target="_blank"
            >
              terms of service
            </a>{' '}
            and{' '}
            <a href="" target="_blank" rel="noopener noreferrer">
              billing terms
            </a>
            .
          </span>
        </ul>
      </div>
      <Footer />

      <Modal openModal={() => setModalState(false)} isModalOpen={isModalOpen}>
        <div className={classes.modalContainer}>
          <div className={classes.ModalClose}>
            <img
              onClick={() => setModalState(false)}
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGlkPSJDYXBhXzEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDM4Ni42NjcgMzg2LjY2NyIgaGVpZ2h0PSI1MTIiIHZpZXdCb3g9IjAgMCAzODYuNjY3IDM4Ni42NjciIHdpZHRoPSI1MTIiIGNsYXNzPSJob3ZlcmVkLXBhdGhzIj48Zz48cGF0aCBkPSJtMzg2LjY2NyA0NS41NjQtNDUuNTY0LTQ1LjU2NC0xNDcuNzcgMTQ3Ljc2OS0xNDcuNzY5LTE0Ny43NjktNDUuNTY0IDQ1LjU2NCAxNDcuNzY5IDE0Ny43NjktMTQ3Ljc2OSAxNDcuNzcgNDUuNTY0IDQ1LjU2NCAxNDcuNzY5LTE0Ny43NjkgMTQ3Ljc2OSAxNDcuNzY5IDQ1LjU2NC00NS41NjQtMTQ3Ljc2OC0xNDcuNzd6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iaG92ZXJlZC1wYXRoIGFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPjwvZz4gPC9zdmc+"
              alt="close"
            />
          </div>
          <p className={classes.ModalLargeText}>{message || 'Something went wrong.'}</p>
          <div className={classes.ModalButtons}>
            <Button onClick={() => setModalState(false)}>Ok</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentPage;
