import { MixpanelService, EventProperties } from './mixpanelService';

type UserRole = 'client' | 'coach' | 'admin' | 'subscriber' | 'mentor';
export type UserProfileProperties = {
  $email?: string;
  $last_login?: string;
  $name?: string;
  role?: UserRole;
};

export type UserProfileEventProperties = EventProperties & {
  location?: 'login' | 'signup' | 'profile' | 'profile_update' | 'logout' | 'with_auth';
  action?: 'token_update' | 'logout';
};

export class UserProfileTrackingService extends MixpanelService {
  private isIdentified: boolean = false;

  public identifyUser(userId: string): void {
    if (!this.isIdentified) {
      super.identify(userId);
      this.isIdentified = true;
    }
  }

  public setUserProperties(properties: UserProfileProperties): void {
    super.setProperties(properties);
  }

  public setUserPropertiesOnce(properties: UserProfileProperties): void {
    super.setPropertiesOnce(properties);
  }

  public trackUserEvent(eventName: string, properties?: UserProfileEventProperties): void {
    super.trackEvent(eventName, properties);
  }

  public reset(): void {
    super.reset();
    this.isIdentified = false;
  }

  public setUserRole(role: UserRole): void {
    this.setUserPropertiesOnce({ role });
    super.setGroup('role', role);
  }
}

export const userProfileTrackingService = new UserProfileTrackingService();
