/**
 * This funnel is used for both funnel subscription to coaches as well as client billing subscriptions to coaching.
 */
import React, { FC, useEffect, useState } from 'react';
import Hero from './Hero';
import Programs from './Programs';
import Testimonials from './Testimonials';
import CoachDetails from './CoachDetails';
import SideNav from 'components/Navigation/FunnelSideNav';
import Feature from './Features';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import FAQ from './FAQ';
import CTA from './CTA';
import classes from './SubscriptionFunnel.module.css';
import { firestore } from 'utils/firebase';
import { FunnelIdentifier, FunnelPlan, FunnelProgram } from 'interfaces/db';
import Footer from 'components/Footer';
import Spinner from 'components/UI/Spinner';
import { NotFound } from 'containers';
import { encodeData, getCurrencySymbol } from 'utils/helpers';
import mixpanel from 'mixpanel-browser';
import { PaymentInfo } from 'interfaces/checkout';

const SubscriptionFunnel: FC = () => {
  const history = useHistory();
  const { search } = useLocation();

  const [identifier, setIdentifier] = useState<FunnelIdentifier>();

  const [plan, setPlan] = useState<FunnelPlan | undefined>();
  const [programs, setPrograms] = useState<FunnelProgram[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [gotError, setGotError] = useState<boolean>(false);
  const [reactivateSub, setReactivateSub] = useState(false);
  const [price, setPrice] = useState(1);
  const { funnelId } = useParams<{
    funnelId: string;
  }>();

  useEffect(() => {
    const parsed = queryString.parse(search);
    try {
      if (parsed.action) {
        const objectString = parsed.action + '';
        const encodedDataBuffer = Buffer.from(objectString, 'base64');
        const decodedData = encodedDataBuffer.toString('utf8');
        const actionObject = JSON.parse(decodedData);
        setReactivateSub(actionObject?.reactivate || false);
      }
    } catch (err) {}
  }, [search]);

  const loader = (
    <div className={classes.Loading}>
      <Spinner />
      Loading...
    </div>
  );

  const handleError = (err: any) => {
    setGotError(true);
    setLoading(false);
    throw err;
  };

  useEffect(() => {
    setLoading(true);
    firestore
      .collection('funnel-identifiers')
      .doc(funnelId.toLowerCase())
      .get()
      .then((query) => {
        const data = query.data();
        if (!data) {
          setLoading(false);
        } else {
          setIdentifier(data as FunnelIdentifier);
          mixpanel.track('Page Visit', {
            CoachIdentifier: funnelId,
          });
        }
      })
      .catch((err) => {
        handleError(err);
      });
  }, []);

  useEffect(() => {
    if (identifier) {
      const { planCode, coachUserId } = identifier;

      firestore
        .doc(`coaches/${coachUserId}/funnel-plans/${planCode}`)
        .get()
        .then((q) => {
          const data = q.data();
          if (!data) setLoading(false);
          else setPlan(data as FunnelPlan);
        })
        .catch((err) => {
          handleError(err);
        });

      firestore
        .collection(`coaches/${coachUserId}/funnel-plans/${planCode}/programs`)
        .get()
        .then((q) => {
          const docs = q.docs;
          const temp: FunnelProgram[] = [];
          docs.forEach((program) => {
            const data = program.data();
            if (data) temp.push(data as FunnelProgram);
          });
          setPrograms(temp);
          setLoading(false);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [identifier]);

  useEffect(() => {
    if (plan) {
      setPrice(
        plan.planOverview?.isTrialEnabled ? (reactivateSub ? plan.planOverview.price : 1) : plan.planOverview.price,
      );
    }
  }, [plan]);

  if (loading) return loader;
  if (gotError || !plan || !programs || !identifier) return <NotFound />;

  const handleSubscribeClick = () => {
    mixpanel.track('Subscribe Button Click', {
      CoachIdentifier: funnelId,
    });
    const paymentInfo: PaymentInfo = {
      paymentType: 'sub',
      coachName: plan.coachOverview.coachName || '',
      numPrograms: programs.length || 0,
      planDescription: plan.planOverview.description || '',
      planImageDesktop: plan.otherImages.heroImageDesktop?.downloadURL || '',
      planImageMobile: plan.otherImages.heroImageMobile?.downloadURL || '',
      isTrialEnabled: (reactivateSub ? false : plan.planOverview?.isTrialEnabled) || false,
      planCode: identifier?.planCode || '',
      funnelIdentifier: funnelId || '',
      coachUserId: identifier?.coachUserId || '',
      price: price || 1,
      renewalPrice: plan.planOverview.price || 1,
      displayPrice: plan.planOverview.displayPrice || 0,
      currency: plan.planOverview.currency || 'ZAR',
      isReactivateSubscription: reactivateSub,
      isClientBilling: false,
    };
    const data = encodeData(JSON.stringify(paymentInfo));
    history.push(`/${funnelId}/checkout`, data);
  };

  const currencySymbol = getCurrencySymbol(plan.planOverview.currency);

  return (
    <div className={classes.FunnelMain}>
      <Hero
        isTrialEnabled={(reactivateSub ? false : plan.planOverview?.isTrialEnabled) || false}
        coachName={plan.coachOverview.coachName}
        planDescription={plan.planOverview.description}
        price={price}
        programsCount={programs.length}
        heroImageDesktop={plan.otherImages.heroImageDesktop}
        heroImageMobile={plan.otherImages.heroImageMobile}
        handleSubscribeClick={handleSubscribeClick}
        currencySymbol={currencySymbol}
        organisation={plan.planOverview.organisation}
      />
      <div className={classes.MainGrid}>
        <SideNav
          isTrialEnabled={(reactivateSub ? false : plan.planOverview?.isTrialEnabled) || false}
          price={price}
          handleSubscribeClick={handleSubscribeClick}
          currencySymbol={currencySymbol}
        />
        <div className={classes.SectionsWrapper}>
          <div>
            <Programs coachName={plan.coachOverview.coachName} programs={programs} />
          </div>
          <Feature
            isTrialEnabled={(reactivateSub ? false : plan.planOverview?.isTrialEnabled) || false}
            organisation={plan.planOverview.organisation}
          />
          <Testimonials testimonials={plan.testimonials} organisation={plan.planOverview.organisation} />
          <CoachDetails coachImg={plan.otherImages.getToKnowImage} coachDetails={plan.coachOverview} />
          <FAQ
            organisation={plan.planOverview.organisation}
            price={plan.planOverview.price}
            currency={plan.planOverview.currency}
            currencySymbol={currencySymbol}
            isTrialEnabled={plan.planOverview.isTrialEnabled}
            reactivateSub={reactivateSub || false}
          />
        </div>
      </div>
      <CTA
        isTrialEnabled={(reactivateSub ? false : plan.planOverview?.isTrialEnabled) || false}
        handleSubscribeClick={handleSubscribeClick}
        currencySymbol={currencySymbol}
      />
      <Footer />
    </div>
  );
};

export default SubscriptionFunnel;
