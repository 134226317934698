import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import { Location } from 'history';
import { useToasts } from 'react-toast-notifications';

import Layout from 'containers/Layout';
import Week, { WeekMethods } from './components/Week';
import {
  duplicateWeek,
  deleteWeek,
  addWeek,
  useDeletedWeeks,
  savePlan,
  removePlan,
  saveGoalsAndPlan,
  processCopiedDay,
  completePlan,
  convertToNutritionPlan,
  generateListOfWeeks,
  mapDayToForm,
  getSortedWeeks,
  findCurrentWeek,
} from './helpers';
import { createNutritionPlan, fetchNutritionPlan } from 'store/actions/nutrition';
import { RootState } from 'store';
import { CLIENTS_NUTRITION } from 'utils/routes';
import classes from './Nutrition.module.css';
import EmptyState from 'components/EmptyState';
import Toolbar from './components/Toolbar';
import ForgotToSaveDialog from 'components/ReusableAlertDialogs/ForgotToSaveDialog';
import { Button } from 'components';
import { NutritionPlan, NutritionDay, NutritionGoals, CreateNutritionPlanDTO } from 'interfaces/nutrition';
import { AlertDialog, Dialog, Spinner } from 'components/UI';
import SetGoals from './components/SetGoals';
import { SelectOptionProps } from 'interfaces/ui';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { nutritionTrackingService } from 'utils/tracking/nutritionService';
import WithAuth from 'utils/withAuth';

type NutritionProps = RouteComponentProps<{
  clientKey: string;
}>;

const Nutrition: FC<NutritionProps> = ({ match, history }) => {
  const {
    params: { clientKey },
  } = match;
  const { addToast } = useToasts();

  // REDUX STATE
  const dispatch = useDispatch();
  const { clients } = useSelector((state: RootState) => state.clients);
  const { token, userId } = useSelector((state: RootState) => state.auth);
  const { plan: planRedux, loading } = useSelector((state: RootState) => state.nutrition);

  const [plan, setPlan] = useState<NutritionPlan>(planRedux || ({} as NutritionPlan)); // holds the state of the plan
  const [listOfWeeks, setListOfWeeks] = useState<SelectOptionProps[]>([]);
  const [draftPlan, setDraftPlan] = useState<NutritionPlan>(plan);
  const weeksRef = useRef<(WeekMethods | null)[]>([]); // holds the refs for each week which holds functions to read the state of the form content inside each week
  const { deletedWeeks, addDeletedWeek, clearDeletedWeeks } = useDeletedWeeks();

  const [changed, setChanged] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const [shouldNavOut, setShouldNavOut] = useState(false);
  const [defaultValues, setDefaultValues] = useState<NutritionGoals>();
  const [selectedDayIndex, setSelectedDayIndex] = useState(0);
  const [copiedDay, setCopiedDay] = useState<NutritionDay | null>(null);

  // LOADING STATES
  const [fetched, setFetched] = useState<boolean>(false);
  const [saveGoalsLoading, setSaveGoalsLoading] = useState<boolean>(false);
  const [savePlanLoading, setSavePlanLoading] = useState<boolean>(false);
  const [removePlanLoading, setRemovePlanLoading] = useState<boolean>(false);
  const [createPlanLoading, setCreatePlanLoading] = useState<boolean>(false);
  const [completePlanLoading, setCompletePlanLoading] = useState<boolean>(false);

  // MODAL STATES
  const [isGoalsModalVsibile, setGoalModalVisible] = useState(false);
  const [isNavModalVisible, setNavModalVisible] = useState(false);
  const [isSaveModalVisible, setSaveModalVisible] = useState(false);
  const [isRemoveModalVisible, setRemoveModalVisible] = useState(false);
  const [isComplateModalVisible, setIsCompleteModalVisible] = useState(false);

  const client = clients?.[clientKey];

  const checkIfChanges = (reduxPlan: NutritionPlan | null, localPlan: NutritionPlan) => {
    if (!reduxPlan || !localPlan) return;

    if (!isEqual(reduxPlan, localPlan)) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  };

  useEffect(() => {
    if (!draftPlan || !planRedux) return;

    checkIfChanges(planRedux, draftPlan);
  }, [draftPlan, planRedux]);

  useEffect(() => {
    if (planRedux !== plan) {
      setPlan(planRedux || ({} as NutritionPlan));
    }
  }, [planRedux]);

  useEffect(() => {
    setFetched(false);
    const clientUserId = clients?.[clientKey]?.clientUserId;
    if (clientUserId && clientUserId !== '') {
      dispatch(fetchNutritionPlan(clientUserId, token || ''));
      setFetched(true);
    }

    return () => {
      setFetched(false);
    };
  }, []);

  useEffect(() => {
    setDraftPlan(plan);
  }, [plan]);

  const handleOpenGoalsModal = (weekId: string, dayIndex: number, day: NutritionDay) => {
    const defaultValues = mapDayToForm(day, weekId);
    setDefaultValues(defaultValues);
    setSelectedDayIndex(dayIndex);
    setGoalModalVisible(true);
  };

  const handleFormChange = (isFormDirty: boolean) => {
    setChanged(isFormDirty);
  };

  const sortedWeeks = useMemo(
    () => getSortedWeeks(draftPlan?.weeks || [], draftPlan?.weekOrder),
    [draftPlan?.weeks, draftPlan?.weekOrder],
  );

  const memoizedDeleteWeekHandler = useCallback(
    (id) => {
      deleteWeek(id, draftPlan, setDraftPlan, addDeletedWeek);
    },
    [plan, setDraftPlan, addDeletedWeek],
  );

  const handleDuplicateWeek = (index: number) => {
    const updatedPlan = duplicateWeek(index, draftPlan, weeksRef);
    if (!updatedPlan) return;
    setDraftPlan(updatedPlan);
  };

  const handleCopyDay = (day: NutritionDay) => {
    setCopiedDay(day);
  };

  const handlePasteDay = (weekIndex: number, dayIndex: number) => {
    const newPlan = processCopiedDay(draftPlan, copiedDay, weekIndex, dayIndex, weeksRef);
    if (!newPlan) return;
    setDraftPlan(newPlan);
  };

  const weeksJSX = sortedWeeks.map((week, index) => {
    return (
      <Week
        key={index}
        ref={(el) => (weeksRef.current[index] = el)}
        week={week}
        weekIndex={index}
        copyDay={handleCopyDay}
        pasteDay={handlePasteDay}
        handleOpenGoalsModal={handleOpenGoalsModal}
        onFormChange={handleFormChange}
        handleDuplicateWeek={handleDuplicateWeek}
        handleDeleteWeek={memoizedDeleteWeekHandler}
      />
    );
  });

  useEffect(() => {
    const list = generateListOfWeeks(sortedWeeks);
    setListOfWeeks(list);
  }, [sortedWeeks]);

  useEffect(() => {
    if (shouldNavOut && nextLocation) {
      history.push(nextLocation);
    }
  }, [shouldNavOut, history, nextLocation]);

  const handleSaveGoals = async (nutritionGoals: NutritionGoals) => {
    setSaveGoalsLoading(true);
    setChanged(false);

    const newPlan = convertToNutritionPlan(nutritionGoals, draftPlan, weeksRef);
    await saveGoalsAndPlan(plan?.weeks || [], deletedWeeks, newPlan, clearDeletedWeeks, dispatch, token);
    setPlan(newPlan);
    setSaveGoalsLoading(false);
    setGoalModalVisible(false);

    nutritionTrackingService.trackNutritionEvent('save_nutrition_goals', {
      location: 'nutrition',
      macro_type: nutritionGoals?.macrosType || 'percentage',
      protein_selected: nutritionGoals?.macros?.protein?.selected || false,
      carbs_selected: nutritionGoals?.macros?.carbs?.selected || false,
      fat_selected: nutritionGoals?.macros?.fat?.selected || false,
      notes_added: !!nutritionGoals?.notes,
      schedule: {
        start_week: nutritionGoals?.schedule?.startWeek || '1',
        number_of_weeks: nutritionGoals?.schedule?.numberOfWeeks || '1',
        days: nutritionGoals?.schedule?.days || [],
      },
    });
  };

  const handleCreatePlan = async () => {
    setCreatePlanLoading(true);
    const planDTO: CreateNutritionPlanDTO = {
      clientUserId: client?.clientUserId || '',
      coachUserId: userId || '',
      finished: false,
    };
    await dispatch(createNutritionPlan(planDTO, token || ''));

    setCreatePlanLoading(false);

    nutritionTrackingService.trackNutritionEvent('create_nutrition_plan', {
      location: 'nutrition',
      client_user_id: client?.clientUserId || '',
      coach_user_id: userId || '',
      created_at: new Date().toISOString(),
    });
  };

  const handleNavPrompt = (nextLoc: Location<unknown>) => {
    if (!shouldNavOut) {
      setNextLocation(nextLoc as unknown as string);
      setNavModalVisible(true);
      return false;
    }
    return true;
  };

  const discardChanges = () => {
    setNavModalVisible(false);
    setShouldNavOut(true);
  };

  const handleSavePlan = async () => {
    setSavePlanLoading(true);
    try {
      await savePlan(weeksRef, plan?.weeks || [], deletedWeeks, draftPlan, clearDeletedWeeks, dispatch, token);
      setChanged(false);
      setSaveModalVisible(false);
    } catch (error) {
      console.log(error);
      addToast(`An error has occurred saving your plan.`, { appearance: 'error' });
    } finally {
      setSavePlanLoading(false);
    }
  };

  const handleRemovePlan = async () => {
    setRemovePlanLoading(true);
    await removePlan(plan.planId, token, dispatch);
    setRemovePlanLoading(false);
    setRemoveModalVisible(false);
    nutritionTrackingService.trackNutritionEvent('remove_nutrition_plan', {
      location: 'nutrition',
      client_user_id: client?.clientUserId || '',
      coach_user_id: userId || '',
      deleted_at: new Date().toISOString(),
    });
  };

  const completPlan = async () => {
    setCompletePlanLoading(true);
    await completePlan(plan.planId, token, dispatch);
    setCompletePlanLoading(false);
    setIsCompleteModalVisible(false);

    nutritionTrackingService.trackNutritionEvent('complete_nutrition_plan', {
      location: 'nutrition',
      client_user_id: client?.clientUserId || '',
      coach_user_id: userId || '',
    });
  };

  const scrollToWeek = (index: number) => {
    const weekElement = weeksRef.current[index];
    if (weekElement && weekElement?.domElement.current) {
      weekElement?.domElement.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    const draftWeeks = draftPlan?.weeks;
    if (!draftWeeks) return;
    if (draftWeeks && draftWeeks.length === 0) return;
    const currentWeekIndex = findCurrentWeek(draftWeeks || []);
    scrollToWeek(currentWeekIndex);
  }, [draftPlan?.weeks]);

  if (loading) {
    return (
      <Layout loading={loading} heading={CLIENTS_NUTRITION.TITLE}>
        <div className={classes.SpinnerWrapper}>
          <Spinner />
        </div>
      </Layout>
    );
  }

  return (
    <Layout heading={client?.profile?.fullname || ''} loading={false}>
      <Prompt when={changed} message={handleNavPrompt} />
      {!loading && plan?.planId ? (
        <>
          <div className={classes.CalendarContent}>
            <Toolbar
              changed={changed}
              setShowSavePlan={setSaveModalVisible}
              setShowRemovePlan={setRemoveModalVisible}
              setShowCompletePlan={setIsCompleteModalVisible}
            />
            <div>{weeksJSX}</div>
            <div className={classes.AddWeekButtonWrapper}>
              <Button type="button" onClick={() => addWeek(draftPlan, setDraftPlan)} iconLeft={<PlusIcon />}>
                Add Week
              </Button>
            </div>
          </div>

          <Dialog
            title={<div className={classes.MinHeight}></div>}
            open={isGoalsModalVsibile}
            onOpenChange={setGoalModalVisible}
          >
            <SetGoals
              defaultValues={defaultValues}
              selectedDayIndex={selectedDayIndex}
              listOfWeeks={listOfWeeks}
              handleSaveGoals={handleSaveGoals}
              loading={saveGoalsLoading}
            />
          </Dialog>
        </>
      ) : (
        weeksJSX.length === 0 &&
        fetched && (
          <div className={classes.EmptyContainer}>
            <EmptyState title="No nutrition set" description="You don’t have any nutrition set up yet.">
              <Button
                type="button"
                onClick={handleCreatePlan}
                iconLeft={<PlusIcon />}
                loading={createPlanLoading}
                disabled={createPlanLoading}
              >
                ADD NUTRITION
              </Button>
            </EmptyState>
          </div>
        )
      )}

      <AlertDialog
        title="Save nutrition plan"
        description="Do you want to save your nutrition plan? This will save any changes that were made."
        open={isSaveModalVisible}
        onOpenChange={setSaveModalVisible}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setSaveModalVisible(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" onClick={handleSavePlan} loading={savePlanLoading}>
            Save
          </Button>
        }
      />

      <AlertDialog
        title="Remove nutrition plan"
        description="Do you want to remove this nutrition plan from the client? This cannot be undone."
        open={isRemoveModalVisible}
        onOpenChange={setRemoveModalVisible}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setRemoveModalVisible(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={handleRemovePlan} loading={removePlanLoading}>
            Remove
          </Button>
        }
      />

      <AlertDialog
        title="Mark as complete"
        description="Marking this nutrition plan as complete will archive it, indicating your client has finished all required days. You will no longer have access to it."
        open={isComplateModalVisible}
        onOpenChange={setIsCompleteModalVisible}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setIsCompleteModalVisible(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" onClick={completPlan} loading={completePlanLoading}>
            Complete program
          </Button>
        }
      />

      <ForgotToSaveDialog
        openModal={isNavModalVisible}
        setModalShow={setNavModalVisible}
        discardChanges={discardChanges}
      />
    </Layout>
  );
};

export default WithAuth(Nutrition);
