import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import ReactPlayer from 'react-player';
import { DocumentData } from '@firebase/firestore-types';

import classes from './Message.module.css';
import { getInitials } from 'utils/helpers';
import NameBadge from 'components/NameBadge';
import Dialog from 'components/UI/Dialog';
dayjs.extend(localizedFormat);
interface Props {
  message: DocumentData;
  currentUserId: string;
}

const Message: FC<Props> = ({ message = {}, currentUserId }) => {
  const { createdAt, text, displayName, media, userId } = message;

  const { url, metadata } = media || {};
  const [style, setStyle] = useState({ display: 'none' });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  if (!text && !media) return null;
  const messageClass = userId === currentUserId ? classes.Sent : classes.Received;
  const displayNameClass = userId === currentUserId ? classes.DisplayNameSent : classes.DisplayNameReceived;
  const messageContentClass = userId === currentUserId ? classes.MessageContentSent : classes.MessageContentReceived;
  const timeNameContainerClass =
    userId === currentUserId ? classes.TimeNameContainerSent : classes.TimeNameContainerReceived;
  const initials = getInitials(displayName);
  const time = createdAt?.seconds ? dayjs(createdAt.seconds * 1000).format('D/M/YY h:mma') : null;

  return (
    <>
      <div className={messageClass}>
        <div className={classes.Badge}>
          <NameBadge size="small" initials={initials} />
        </div>

        <div className={messageContentClass}>
          {displayName && (
            <div className={timeNameContainerClass}>
              <p className={displayNameClass}>{displayName}</p>
              <span className={classes.Time}>{time?.toString()}</span>
            </div>
          )}
          {media && (
            <div
              className={classes.Content}
              onMouseEnter={() => {
                setStyle({ display: 'flex' });
              }}
              onMouseLeave={() => {
                setStyle({ display: 'none' });
              }}
            >
              {url && metadata && metadata?.contentType?.includes('video') ? (
                <div className={classes.PlayerWrapper}>
                  <ReactPlayer
                    className={classes.ReactPlayer}
                    url={url}
                    controls={true}
                    light={true}
                    width="100%"
                    height="100%"
                  />
                </div>
              ) : (
                <>
                  <div style={style} className={classes.MediaOptions}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        fill="#c0c0c0"
                        className="bi bi-cloud-arrow-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z"
                        />
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                      </svg>
                    </a>
                  </div>
                  <img src={url} alt="message attachment" onClick={() => setModalOpen(true)} />
                </>
              )}
            </div>
          )}
          <p className={classes.MessageText}>{text}</p>
        </div>
      </div>

      <Dialog open={isModalOpen} onOpenChange={setModalOpen} title="Attachment">
        <>
          <div className={classes.DownloadWrapper}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#c0c0c0"
                className="bi bi-cloud-arrow-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
              </svg>
            </a>
          </div>
          <div className={classes.ImageContainerModal}>
            <img src={url} alt="message attachment" />
          </div>
        </>
      </Dialog>
    </>
  );
};

export default Message;
