import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { hotjar } from 'react-hotjar';
import { ToastProvider } from 'react-toast-notifications';
import mixpanel from 'mixpanel-browser';

import './index.css';
import App from './App';
import { persistedReducer } from './store';
import { CustomToast } from './components';
import * as serviceWorker from './serviceWorker';

const isProduction = process.env.NODE_ENV === 'production';
const projectToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '';
// const projecTokenCoachElite = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN_COACHELITE || '';
if (!process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
  console.error('Missing mixpanel project token');
}

mixpanel.init(projectToken, { debug: !isProduction, autotrack: false, track_pageview: false });
// mixpanel.init(
//   projecTokenCoachElite,
//   { debug: !isProduction, autotrack: false, track_pageview: false },
//   'projectCoachElite',
// );

if (!isProduction) {
  mixpanel.disable();
}

const sentrySDN = 'https://0f6c18deb49a4c6b828e80439b5eb8fa@o436803.ingest.sentry.io/5398476';
Sentry.init({
  dsn: sentrySDN,
  enabled: isProduction,
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer));

const persistor = persistStore(store);

const Entry = () => (
  <Sentry.ErrorBoundary fallback={'An error has occured'} showDialog>
    <ToastProvider autoDismiss placement="top-center" components={{ Toast: CustomToast }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ToastProvider>
  </Sentry.ErrorBoundary>
);

// hotjar
if (process.env.NODE_ENV === 'production') {
  const sideID = 1953717;
  hotjar.initialize(sideID, 10);
}

ReactDOM.render(<Entry />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
