import { NutritionPlan } from 'interfaces/nutrition';
import {
  FETCH_NUTRITION_PLAN_START,
  FETCH_NUTRITION_PLAN_SUCCESS,
  FETCH_NUTRITION_PLAN_FAIL,
  REMOVE_NUTRITION_PLAN_START,
  REMOVE_NUTRITION_PLAN_SUCCESS,
  REMOVE_NUTRITION_PLAN_FAIL,
  COMPLETE_NUTRITION_PLAN_START,
  COMPLETE_NUTRITION_PLAN_SUCCESS,
  COMPLETE_NUTRITION_PLAN_FAIL,
  CREATE_NUTRITION_PLAN_FAIL,
  CREATE_NUTRITION_PLAN_START,
  CREATE_NUTRITION_PLAN_SUCCESS,
  UPDATE_NUTRITION_PLAN_FAIL,
  UPDATE_NUTRITION_PLAN_START,
  UPDATE_NUTRITION_PLAN_SUCCESS,
} from '../actions/actionTypes';
import { updateObject } from '../utility';
import { NutritionState } from 'interfaces/state';

const initialState: NutritionState = {
  plan: null,
  loading: false,
  dayIndex: null,
  weekIndex: null,
  changed: false,
  error: null,
};

const fetchNutritionPlanStart = (state: NutritionState): NutritionState => {
  return updateObject(state, { loading: true });
};

const fetchNutritionPlanSuccess = (state: NutritionState, action: { plan: NutritionPlan }): NutritionState => {
  return updateObject(state, {
    plan: action.plan,
    loading: false,
    changed: false,
  });
};

const fetchNutritionPlanFail = (state: NutritionState, action: { error: Error | string | null }): NutritionState => {
  return updateObject(state, { loading: false, error: action.error || 'Error fetching nutrition plan', plan: null });
};

const createNutritionPlanSuccess = (state: NutritionState, action: { plan: NutritionPlan }): NutritionState => {
  return updateObject(state, {
    plan: action.plan,
  });
};

const updateNutritionPlanSuccess = (state: NutritionState, action: { plan: NutritionPlan }): NutritionState => {
  return updateObject(state, {
    plan: action.plan,
    changed: false,
  });
};

const removeNutritionPlanSuccess = (state: NutritionState): NutritionState => {
  return updateObject(state, {
    loading: false,
    plan: null,
  });
};

const completeNutritionPlanSuccess = (state: NutritionState): NutritionState => {
  return updateObject(state, {
    loading: false,
    plan: null,
  });
};

const reducer = (state = initialState, action): NutritionState => {
  switch (action.type) {
    case FETCH_NUTRITION_PLAN_START:
      return fetchNutritionPlanStart(state);
    case FETCH_NUTRITION_PLAN_SUCCESS:
      return fetchNutritionPlanSuccess(state, action);
    case FETCH_NUTRITION_PLAN_FAIL:
      return fetchNutritionPlanFail(state, action);
    case CREATE_NUTRITION_PLAN_START:
      return state;
    case CREATE_NUTRITION_PLAN_SUCCESS:
      return createNutritionPlanSuccess(state, action);
    case CREATE_NUTRITION_PLAN_FAIL:
      return state;
    case UPDATE_NUTRITION_PLAN_START:
      return state;
    case UPDATE_NUTRITION_PLAN_SUCCESS:
      return updateNutritionPlanSuccess(state, action);
    case UPDATE_NUTRITION_PLAN_FAIL:
      return state;
    case REMOVE_NUTRITION_PLAN_START:
      return state;
    case REMOVE_NUTRITION_PLAN_SUCCESS:
      return removeNutritionPlanSuccess(state);
    case REMOVE_NUTRITION_PLAN_FAIL:
      return state;
    case COMPLETE_NUTRITION_PLAN_START:
      return state;
    case COMPLETE_NUTRITION_PLAN_SUCCESS:
      return completeNutritionPlanSuccess(state);
    case COMPLETE_NUTRITION_PLAN_FAIL:
      return state;
    default:
      return state;
  }
};

export default reducer;
