import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as LogoImage } from 'assets/svgs/logo.svg';
import { CLIENTS } from 'utils/routes';
import classes from './Logo.module.css';

interface Props {
  height?: number;
  link?: string;
  dashboard?: boolean;
  auth?: boolean;
  checkout?: boolean;
}

const Logo: FC<Props> = ({ link = CLIENTS.URL, auth = false, checkout = false }) => {
  return (
    <div className={classes.Logo}>
      {auth || checkout ? (
        <a href={link}>
          <LogoImage />
        </a>
      ) : (
        <NavLink to={link}>
          <LogoImage />
        </NavLink>
      )}
    </div>
  );
};

export default Logo;
