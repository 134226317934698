import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import { Prompt, Redirect, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import {
  addWeek,
  putProgram,
  removeWeek,
  setRestDay,
  copyWorkout,
  pasteWorkout,
  removeRestDay,
  fetchPrograms,
  goBackWorkout,
  duplicateWeek,
  fetchProgram,
} from 'store/actions/programs';
import Week from './Week/Week';
import Layout from '../Layout';
import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import { createDefaultSet } from 'utils/helpers';
import { CalendarParams } from 'interfaces/routes';
import { PROGRAM_WORKOUT_BUILDER } from 'utils/routes';
import Spinner from 'components/UI/Spinner';
import { SetParam } from 'interfaces/utils';
import { Set, SetKeys, Week as WeekType, Day, Exercise, Programs } from 'interfaces/db';
import classes from './Calendar.module.css';
import { DEFAULT_SET_PARAMS } from 'utils/constants';
import ProgramToolbar from 'components/ProgramToolbar';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-filled.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { Button } from 'components';
import AlertDialog from 'components/UI/AlertDialog';
import ForgotToSaveDialog from 'components/ReusableAlertDialogs/ForgotToSaveDialog';
import { programTrackingService } from 'utils/tracking/programService';

const mapStateToProps = ({ auth, programs, settings }: RootState) => {
  return {
    token: auth.token,
    userId: auth.userId,
    loading: programs.loading,
    changed: programs.changed,
    programs: programs.programs,
    programIndex: programs.programIndex,
    loadingSaving: programs.loadingSaving,
    theme: settings.theme,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onAddWeek: (week: WeekType, key: string) => {
      dispatch(addWeek(week, key));
    },
    onDuplicateWeek: (weekIndex: number) => {
      dispatch(duplicateWeek(weekIndex));
    },
    onSaveProgram: async (program: Programs, programKey: string, token: string, workoutId?: string) => {
      await dispatch(putProgram(programKey, program, token, workoutId));
    },
    onGoBack: () => dispatch(goBackWorkout()),
    onRemoveWeek: (index: number) => dispatch(removeWeek(index)),
    onFetchPrograms: (userId: string) => dispatch(fetchPrograms(userId)),
    onFetchProgram: (programId: string) => dispatch(fetchProgram(programId)),
    onSetRestDay: (weekIndex: number, dayIndex: number, programIndex: number) =>
      dispatch(setRestDay(weekIndex, dayIndex, programIndex)),
    onRemoveRestDay: (weekIndex: number, dayIndex: number, programIndex: number) =>
      dispatch(removeRestDay(weekIndex, dayIndex, programIndex)),
    onCopyWorkout: (weekIndex: number, dayIndex: number, programIndex: number) =>
      dispatch(copyWorkout(weekIndex, dayIndex, programIndex)),
    onPasteWorkout: (weekIndex: number, dayIndex: number, programIndex: number) =>
      dispatch(pasteWorkout(weekIndex, dayIndex, programIndex)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps<CalendarParams>;

const Calendar: FC<Props> = ({
  token,
  match,
  loading,
  history,
  onGoBack,
  onSaveProgram,
  onFetchProgram,
  programs: rdxPrograms,
  loadingSaving,
  theme,
}) => {
  const { addToast } = useToasts();

  const { params } = match;
  const { programKey } = params;

  const [changed, setChanged] = useState(false);
  const [navModal, showNavModal] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const [programs, setPrograms] = useState(rdxPrograms);
  const [shouldNavOut, setShouldNavOut] = useState(false);
  const [copiedWorkout, setCopiedWorkout] = useState<Day | null>(null);
  const [initialProgram, setInitialProgram] = useState<Programs | null>(null);
  const [showSaveProgram, setShowSaveProgram] = useState(false);
  const [showAutoFill, _setShowAutoFill] = useState<string>('');
  const [defaultSetParams, setDefaultSetParams] = useState<SetParam[]>(
    programs?.[programKey || '']?.settings?.defaultSetParams || DEFAULT_SET_PARAMS,
  );
  const [initialRender, setInitialRender] = useState(true);

  const setShowAutoFill = (setIdentifier: string) => {
    _setShowAutoFill(setIdentifier);
  };

  useEffect(() => {
    if (!programKey) {
      return;
    }

    if (programs && !initialProgram && !loadingSaving) {
      if (programs?.[programKey].isWorkoutDataPopulated) {
        // if the workout data is populated - set initial program to the program in redux
        setInitialProgram(programs[programKey]);
      } else if (!initialRender) {
        // else - we only set the initial program when workout data has been fetched and populated in redux (2nd render)
        setInitialProgram(programs[programKey]);
      }
    }

    setInitialRender(false);

    if (!programs || !initialProgram) {
      return;
    }

    if (programKey && programs[programKey]) {
      setDefaultSetParams(programs[programKey].settings?.defaultSetParams || DEFAULT_SET_PARAMS);
    }

    const programObjString = JSON.stringify(programs[programKey]);
    const initalProgramObjString = JSON.stringify(initialProgram);

    if (initalProgramObjString !== programObjString) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [programs, initialProgram]);

  useEffect(() => {
    if (!programKey) {
      return;
    }
    if (!programs?.[programKey].isWorkoutDataPopulated) {
      onFetchProgram(programKey);
    }
  }, [onFetchProgram]);

  useEffect(() => {
    setPrograms(rdxPrograms);
  }, [rdxPrograms]);

  useEffect(() => {
    if (shouldNavOut && nextLocation) {
      onGoBack();
      history.push(nextLocation);
    }
  }, [shouldNavOut, history, nextLocation, onGoBack]);

  const programNameOnChangeHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    if (programs && programKey && programs[programKey]) {
      // copy program and add new week
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          name: value,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const saveProgram = async (workoutId?: string) => {
    if (token && programs && programKey && programs[programKey]) {
      const updatedProgram: Programs = programs[programKey];

      // hide save program modal
      setShowSaveProgram(false);
      setChanged(false);
      setInitialProgram(null);

      // TODO add saving loader in the save button...
      try {
        await onSaveProgram(updatedProgram, programKey, token, workoutId);
      } catch (err) {
        addToast('Error saving program', { appearance: 'error' });
        setChanged(true);
      }
    }
  };

  const addWeekHandler = (): void => {
    if (programs && programKey && programs[programKey]) {
      // destructure weeks
      const { weeks = [] } = programs[programKey];

      // Calculate the weekIndex for the new week
      const weekIndex = weeks.length;

      const newWeeks = {
        Days: Array.from({ length: 7 }).map((_, dayIndex) => ({
          rest: false,
          workout: { finished: false, weekIndex, dayIndex },
          weekIndex,
          dayIndex,
        })),
      };

      // copy program and add new week
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks: [...weeks, newWeeks],
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const duplicateWeekHandler = (Days: Day[]) => {
    if (programs && programKey && programs[programKey]) {
      // remove client workout inputs from copied workout
      const formattedDays = Days.map((day) => {
        if (day.rest) {
          return { ...day, workoutId: undefined };
        }

        const workout = {
          workoutId: undefined,
          finished: false,
          workoutNotes: {
            athlete: '',
            coach: day.workout.workoutNotes?.coach || '',
          },
          workoutTitle: day.workout.workoutTitle || '',
        };

        let exercises: Exercise[][] = [];

        if (day.workout.exercises) {
          exercises = day.workout.exercises?.map((exerciseGroup) => {
            return !exerciseGroup
              ? []
              : exerciseGroup.map((exercise) => {
                  return {
                    ...exercise,
                    exerciseNotes: {
                      athlete: '',
                      coach: exercise.exerciseNotes?.coach || '',
                    },
                    sets: !exercise.sets
                      ? []
                      : exercise.sets.map((set) => {
                          const { completed, ...otherSets } = set;

                          const setKeys = Object.keys(otherSets) as Array<keyof typeof otherSets>;

                          return setKeys.reduce(
                            (acc, cur) => {
                              const currentType = otherSets[cur];
                              const value = typeof currentType === 'string' ? currentType : currentType?.programmed;

                              return {
                                ...acc,
                                [cur]: {
                                  achieved: '',
                                  programmed: value || '',
                                },
                              };
                            },
                            { completed: false },
                          );
                        }),
                  };
                });
          });
        }

        if (!exercises?.length) {
          return {
            ...day,
            workoutId: undefined,
            isDuplicated: true,
            workout: {
              ...workout,
            },
          };
        }

        return {
          ...day,
          workoutId: undefined,
          isDuplicated: true,
          workout: {
            ...workout,
            exercises,
          },
        };
      });

      const weeksLength = programs?.[programKey]?.weeks?.length;

      // duplicate week
      const newWeek = { Days: formattedDays, weekIndex: weeksLength || null };

      // destructure weeks
      const { weeks = [] } = programs[programKey];

      const updatedProgram: Programs = {
        ...programs[programKey],
        weeks: [...weeks, newWeek],
      };

      // copy program and add new week
      const updatedPrograms = {
        ...programs,
        [programKey]: {
          ...updatedProgram,
        },
      };

      // update program
      setPrograms(updatedPrograms);
      programTrackingService.trackProgramEvent('duplicate_week', {
        program_id: programKey,
        location: 'program_template',
      });
    }
  };

  const deleteWeekHandler = (index: number) => {
    if (programs && programKey && programs[programKey]) {
      // filter out selected week
      const weeks = programs[programKey]?.weeks?.filter((_, i) => i !== index);

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const addWorkoutNotes = (workoutNotes: string, dayIndex: number, weekIndex: number) => {
    if (programs && programKey && programs[programKey]) {
      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              return {
                ...day,
                workout: {
                  ...day.workout,
                  workoutNotes: {
                    athlete: day.workout?.workoutNotes?.athlete || '',
                    coach: workoutNotes || '',
                  },
                },
              };
            }

            // return day if not selected day
            return day;
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const addExerciseNotes = (
    exerciseNotes: string,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (programs && programKey && programs[programKey]) {
      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              const newExercises = day.workout.exercises?.map((exercise, i) => {
                if (i === group) {
                  return exercise.map((exerciseGroup, i) => {
                    if (i === exerciseIndex) {
                      return {
                        ...exerciseGroup,
                        exerciseNotes: {
                          athlete: exerciseGroup.exerciseNotes?.athlete || '',
                          coach: exerciseNotes,
                        },
                      };
                    }
                    return exerciseGroup;
                  });
                }
                return exercise;
              });

              return {
                ...day,
                workout: { ...day.workout, exercises: newExercises },
              };
            }

            // return day if not selected day
            return day;
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const addSetHandler = (group: number, exerciseIndex: number, dayIndex: number, weekIndex: number) => {
    if (programs && programKey && programs[programKey]) {
      const newSet: Set = createDefaultSet(defaultSetParams);

      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              const newExercises = day.workout.exercises?.map((exercise, i) => {
                if (i === group) {
                  return exercise.map((exerciseGroup, i) => {
                    if (i === exerciseIndex) {
                      const numberofSets = exerciseGroup.sets ? exerciseGroup.sets.length : 0;

                      // if first set, initialize with empty values
                      if (!numberofSets) {
                        return {
                          ...exerciseGroup,
                          sets: [newSet],
                        };
                      } else {
                        // initalize with last set values
                        const { sets = [] } = exerciseGroup;
                        const lastSet = {
                          ...sets[numberofSets - 1],
                          completed: false,
                        };
                        return {
                          ...exerciseGroup,
                          sets: [...sets, lastSet],
                        };
                      }
                    }
                    return exerciseGroup;
                  });
                }
                return exercise;
              });

              return {
                ...day,
                workout: { ...day.workout, exercises: newExercises },
              };
            }

            // return day if not selected day
            return day;
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const removeSetHandler = (group: number, exerciseIndex: number, dayIndex: number, weekIndex: number) => {
    if (programs && programKey && programs[programKey]) {
      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              const newExercises = day.workout.exercises?.map((exercise, i) => {
                if (i === group) {
                  return exercise.map((exerciseGroup, i) => {
                    if (i === exerciseIndex) {
                      const { sets: newSets = [] } = exerciseGroup;
                      newSets.pop();

                      return {
                        ...exerciseGroup,
                        sets: newSets,
                      };
                    }
                    return exerciseGroup;
                  });
                }
                return exercise;
              });

              return {
                ...day,
                workout: { ...day.workout, exercises: newExercises },
              };
            }

            // return day if not selected day
            return day;
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const autoFillSetsHandler = (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (programs && programKey && programs[programKey]) {
      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              const newExercises = day.workout.exercises?.map((exercise, i) => {
                if (i === group) {
                  return exercise.map((exerciseGroup, i) => {
                    if (i === exerciseIndex) {
                      const { sets = [] } = exerciseGroup;
                      return {
                        ...exerciseGroup,
                        sets: sets.map((set, i) => {
                          if (i >= setIndex) {
                            if (typeof set[key] === 'string') {
                              return {
                                ...set,
                                [key]: {
                                  index: i + 1,
                                  programmed: val,
                                  achieved: '',
                                },
                              };
                            }

                            return {
                              ...set,
                              [key]: {
                                programmed: val,
                                achieved: '',
                                index: (set?.[key] as any)?.index || i + 1,
                              },
                            };
                          }

                          return set;
                        }),
                      };
                    }
                    return exerciseGroup;
                  });
                }
                return exercise;
              });

              return {
                ...day,
                workout: { ...day.workout, exercises: newExercises },
              };
            }

            // return day if not selected day
            return day;
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const setsChangeHandler = (
    key: SetKeys,
    val: string,
    setIndex: number,
    group: number,
    exerciseIndex: number,
    dayIndex: number,
    weekIndex: number,
  ) => {
    if (programs && programKey && programs[programKey]) {
      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              const newExercises = day.workout.exercises?.map((exercise, i) => {
                if (i === group) {
                  return exercise.map((exerciseGroup, i) => {
                    if (i === exerciseIndex) {
                      const { sets = [] } = exerciseGroup;
                      return {
                        ...exerciseGroup,
                        sets: sets.map((set, i) => {
                          if (i === setIndex) {
                            if (typeof set[key] === 'string') {
                              return {
                                ...set,
                                [key]: {
                                  index: i + 1,
                                  programmed: val,
                                  achieved: '',
                                },
                              };
                            }

                            return {
                              ...set,
                              [key]: {
                                programmed: val,
                                achieved: '',
                                index: (set?.[key] as any)?.index || i + 1,
                              },
                            };
                          }

                          return set;
                        }),
                      };
                    }
                    return exerciseGroup;
                  });
                }
                return exercise;
              });

              return {
                ...day,
                workout: { ...day.workout, exercises: newExercises },
              };
            }

            // return day if not selected day
            return day;
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const setRestDayHandler = (weekIndex: number, dayIndex: number) => {
    if (programs && programKey && programs[programKey]) {
      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              // update rest day
              return { ...day, rest: true };
            }

            // return day if not selected day
            return day;
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const removeRestDayHandler = (weekIndex: number, dayIndex: number) => {
    if (programs && programKey && programs[programKey]) {
      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              // update rest day
              return { ...day, rest: false };
            }

            // return day if not selected day
            return day;
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const copyWorkoutHandler = (weekIndex: number, dayIndex: number) => {
    if (programs && programKey && programs[programKey]) {
      const copied = programs[programKey]?.weeks?.[weekIndex].Days[dayIndex];
      setCopiedWorkout(copied || null);
    }
  };

  const pasteWorkoutHandler = (weekIndex: number, dayIndex: number) => {
    if (programs && programKey && programs[programKey] && copiedWorkout) {
      // map over weeks
      const weeks = programs[programKey]?.weeks?.map((week, i) => {
        // get selected week
        if (i === weekIndex) {
          // map over days
          const days = week.Days.map((day, index) => {
            // get selected day
            if (index === dayIndex) {
              // Preserve existing workoutId if it exists, otherwise set to undefined
              const workoutId = day.workoutId ?? undefined;
              // update rest day
              return {
                ...copiedWorkout,
                isDuplicated: true,
                workoutId,
                workout: { ...copiedWorkout.workout, workoutId },
              };
            }

            // return day if not selected day
            return { ...day };
          });

          // update days
          return { Days: days };
        }

        // return week if not selected week
        return week;
      });

      // copy program and update weeks
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          weeks,
        },
      };

      // update program
      setPrograms(updatedProgram);
    }
  };

  const addWorkout = async (weekIndex: number, dayIndex: number) => {
    if (programs && programKey && programs[programKey]) {
      programTrackingService.trackProgramEvent('add_workout', {
        program_id: programKey,
        location: 'program_template',
        object: 'button',
        action: 'click',
        interaction_type: 'navigate_to_workout_builder',
        button: {
          text: 'ADD WORKOUT',
          action: 'add_workout',
          description: 'Add workout button on day in program template',
        },
      });

      if (changed) {
        onSaveProgram(programs[programKey], programKey, token || '');
      }

      setChanged(false);
      setInitialProgram(null);
      setShouldNavOut(true);

      //  navigate
      history.push({
        state: { programName: programs[programKey].name, defaultSetParams },
        pathname: `${PROGRAM_WORKOUT_BUILDER.URL}/${programKey}/${weekIndex}/${dayIndex}`,
      });
    }
  };

  const goToWorkoutBuilder = async (weekIndex: number, dayIndex: number, workoutId?: string) => {
    if (programs && programKey && programs[programKey]) {
      if (changed) {
        // save program

        onSaveProgram(programs[programKey], programKey, token || '');

        setChanged(false);
        setInitialProgram(null);
      }

      setShouldNavOut(true);

      programTrackingService.trackProgramEvent('edit_workout', {
        program_id: programKey,
        location: 'program_template',
        object: 'button',
        action: 'click',
        interaction_type: 'navigate_to_workout_builder',
        button: {
          text: 'EDIT WORKOUT',
          action: 'navigate_to_workout_builder',
          description: 'Edit workout button on day in program template',
        },
      });

      //  navigate
      history.push({
        state: { programName: programs[programKey].name, defaultSetParams, workoutId },
        pathname: `${PROGRAM_WORKOUT_BUILDER.URL}/${programKey}/${weekIndex}/${dayIndex}`,
      });
    }
  };

  const handleNavPrompt = (nextLoc: Location<unknown>) => {
    if (!shouldNavOut) {
      setNextLocation(nextLoc as unknown as string);
      showNavModal(true);

      return false;
    }

    return true;
  };

  const updateProgramDefaultSetParams = (updatedParams: SetParam[]) => {
    if (programs && programKey && programs[programKey]) {
      const updatedProgram = {
        ...programs,
        [programKey]: {
          ...programs[programKey],
          settings: {
            ...programs[programKey].settings,
            defaultSetParams: updatedParams,
          },
        },
      };
      // update program
      setPrograms(updatedProgram);
    }
  };

  const addDefaultSetParam = (param: SetParam) => {
    const updatedParams = [...defaultSetParams, param];
    setDefaultSetParams(updatedParams);
    updateProgramDefaultSetParams(updatedParams);
  };

  const updateDefaultSetParam = (newParam: SetParam, originalParam?: SetParam) => {
    if (originalParam) {
      const paramIndex = defaultSetParams.indexOf(originalParam);
      const updatedParams = [...defaultSetParams];
      updatedParams[paramIndex] = newParam;
      setDefaultSetParams(updatedParams);
      updateProgramDefaultSetParams(updatedParams);
    }
  };

  const removeDefaultSetParam = (originalParam?: SetParam) => {
    if (originalParam) {
      const paramIndex = defaultSetParams.indexOf(originalParam);
      const updatedParams = [...defaultSetParams];
      if (paramIndex > -1) {
        updatedParams.splice(paramIndex, 1);
      }

      setDefaultSetParams(updatedParams);
      updateProgramDefaultSetParams(updatedParams);
    }
  };

  const discardChanges = () => {
    setShouldNavOut(true);
  };

  if (loading) {
    return (
      <Layout loading={loading} heading="Loading...">
        <div>
          <Spinner />
        </div>
      </Layout>
    );
  }

  if (!programs || !programKey || !programs[programKey]) {
    return <Redirect to="/404" />;
  }

  let textInput: any = null;

  const nameFocusHandler = () => {
    textInput.focus();
  };

  const MAX_LENGTH = 30;
  const nameLength = programs?.[programKey].name?.length || MAX_LENGTH - 5;

  return (
    <Layout loading={loading} heading={''}>
      <Prompt when={changed} message={handleNavPrompt} />
      <div className={classes.TitleWrapper}>
        <input
          name="programName"
          type="text"
          maxLength={MAX_LENGTH}
          placeholder="Name The Program"
          onChange={programNameOnChangeHandler}
          ref={(elem) => (textInput = elem)}
          className={classes.ProgramNameInput}
          defaultValue={programs[programKey].name}
          style={{
            width: `${nameLength * 30}px`,
          }}
        />
        <Button type="button" size="xsmall" onClick={nameFocusHandler} iconCenter={<EditIcon />} />
      </div>

      <div className={classes.CalendarContent}>
        <div className={classes.Calendar}>
          <ProgramToolbar
            defaultSetParams={defaultSetParams}
            updateDefaultSetParam={updateDefaultSetParam}
            removeDefaultSetParam={removeDefaultSetParam}
            addDefaultSetParam={addDefaultSetParam}
            changed={changed}
            setShowSaveProgram={setShowSaveProgram}
          />
          <div>
            {programs[programKey]?.weeks?.map((week, numWeek) => (
              <Week
                key={numWeek}
                days={week.Days}
                week={numWeek + 1}
                addWorkout={addWorkout}
                saveProgram={saveProgram}
                copyWorkoutHandler={copyWorkoutHandler}
                goToWorkoutBuilder={goToWorkoutBuilder}
                pasteWorkoutHandler={pasteWorkoutHandler}
                deleteWeekHandler={deleteWeekHandler}
                programName={programs[programKey].name || ''}
                removeRestDayHandler={removeRestDayHandler}
                setRestDayHandler={setRestDayHandler}
                duplicateWeekHandler={duplicateWeekHandler}
                addExerciseNotes={(exerciseNotes, group, index, dayIndex) =>
                  addExerciseNotes(exerciseNotes, group, index, dayIndex, numWeek)
                }
                addWorkoutNotes={addWorkoutNotes}
                addSetHandler={(group, index, dayIndex) => addSetHandler(group, index, dayIndex, numWeek)}
                removeSetHandler={(group, index, dayIndex) => removeSetHandler(group, index, dayIndex, numWeek)}
                setsChangeHandler={(key, val, setIndex, group, exerciseIndex, dayIndex) =>
                  setsChangeHandler(key, val, setIndex, group, exerciseIndex, dayIndex, numWeek)
                }
                autoFillSetsHandler={(key, val, setIndex, group, exerciseIndex, dayIndex) =>
                  autoFillSetsHandler(key, val, setIndex, group, exerciseIndex, dayIndex, numWeek)
                }
                programKey={programKey}
                setShowAutoFill={(setIdentifier: string) => setShowAutoFill(setIdentifier)}
                showAutoFill={showAutoFill}
                weightColor={theme['--color-accent-primary'] || '#7782F8'}
                volumeColor={theme['--color-background-secondary'] || '#dbdbdb'}
                weightTextColor={theme['--color-text-on-accent'] || '#ffffff'}
                volumeTextColor={theme['--color-text-primary'] || '#181818'}
                programmedTextColor={theme['--color-text-primary'] || '#181818'}
                achievedTextColor={theme['--color-accent-primary'] || '#7782F8'}
              />
            ))}
          </div>
          <div className={classes.AddWeekButtonWrapper}>
            <Button type="button" onClick={addWeekHandler} iconLeft={<PlusIcon />}>
              Add Week
            </Button>
          </div>
        </div>
      </div>

      <AlertDialog
        title="Save program"
        description="Do you want to save your program? This will save any changes that were made."
        open={showSaveProgram}
        onOpenChange={setShowSaveProgram}
        cancelButton={
          <Button intent="secondary" onClick={() => setShowSaveProgram(false)}>
            Cancel
          </Button>
        }
        actionButton={<Button onClick={saveProgram}>Save</Button>}
      />

      <ForgotToSaveDialog openModal={navModal} setModalShow={showNavModal} discardChanges={discardChanges} />
    </Layout>
  );
};

export default connector(withAuth(Calendar));
