import { Clients, Documents, Profile } from 'interfaces/db';
import { FirebaseObject } from 'interfaces/utils';

export const assignDocuments = async (
  token: string | null,
  clients: FirebaseObject<Clients> | null,
  clientsToAssign: string[],
  setLoading: (value: React.SetStateAction<boolean>) => void,
  onSaveClientProfile: (clientProfile: Profile, clientKey: string) => any,
  onUpdateDocuments: (token: string, documents: { [key: string]: Documents }) => any,
  documentTrackingService,
  pathname: string,
  documentKeys: string[],
  documents: FirebaseObject<Documents> | null,
) => {
  if (!token || !clients) return;

  setLoading(true);

  const documentsToUpdate: { [key: string]: Documents } = {};

  for (const clientKey of clientsToAssign) {
    const profile = clients?.[clientKey]?.profile;
    if (!profile) continue;

    // Collect documents to add to this client
    const documentsToAdd = documentKeys.filter((documentKey) => {
      const document = documents?.[documentKey];
      if (!document || profile.documents?.includes(documentKey)) return false;

      // Add client to document's client list
      if (!documentsToUpdate[documentKey]) {
        documentsToUpdate[documentKey] = {
          ...document,
          clientList: document?.clientList ? [...document.clientList] : [],
        };
      }
      if (!documentsToUpdate[documentKey]?.clientList?.includes(clientKey)) {
        documentsToUpdate[documentKey]?.clientList?.push(clientKey);
      }

      return true;
    });

    if (documentsToAdd.length > 0) {
      const newClientProfile = {
        ...profile,
        documents: [...(profile.documents || []), ...documentsToAdd],
      };

      await onSaveClientProfile(newClientProfile, clientKey);
    }
  }

  // Update documents with new client lists
  await onUpdateDocuments(token, documentsToUpdate);

  // Track document events
  Object.entries(documentsToUpdate).forEach(([documentKey, document]) => {
    const updatedDocument: Documents = document as Documents;
    documentTrackingService.trackDocumentEvent('assign_document', {
      location: pathname.includes('clients') ? 'client_documents' : 'documents',
      document_id: documentKey,
      document_title: updatedDocument.title,
      document_category: updatedDocument.category,
      clients: updatedDocument.clientList,
    });
  });

  setLoading(false);
};
