import React, { FC, useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Prompt, Redirect, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';
import { useSelector, useDispatch, connect, ConnectedProps } from 'react-redux';
import { useForm } from 'react-hook-form';
import isEqual from 'react-fast-compare';
import { database } from 'utils/firebase';
import rfdc from 'rfdc';

import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import { Set, SetKeys, Day, Exercise, instanceOfSetObject, Workout, Programs } from 'interfaces/db';
import { Spinner, TextArea, AlertDialog, Input, Button } from 'components/UI';
import { WorkoutBuilderParams } from 'interfaces/routes';
import { fetchExercises } from 'store/actions/exerciseLibrary';
import ExerciseBlock from 'components/ExerciseBlock';
import ExerciseGroup from 'components/ExerciseGroup';
import Layout from '../Layout';
import classes from './WorkoutBuilder.module.css';
import { IndexedExercise, SetParam } from 'interfaces/utils';
import { DEFAULT_SET_PARAMS } from 'utils/constants';
import { createDefaultSet } from 'utils/helpers';
import { putProgramStart, putProgramSuccess, saveWorkout } from 'store/actions/programs';
import ForgotToSaveDialog from 'components/ReusableAlertDialogs/ForgotToSaveDialog';
import { ReactComponent as SaveIcon } from 'assets/svgs/save-filled.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-filled.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { programTrackingService } from 'utils/tracking/programService';

type Suggestions = Exercise & {
  author: 'coach' | 'admin';
};

type LocationStateProps = {
  programName: string;
  defaultSetParams: SetParam[];
  workoutId: string;
};

type WorkoutBuilderFormData = {
  coachWorkoutNotes: string;
  clientWorkoutNotes: string;
  workoutTitle: string;
};

const mapStateToProps = ({ auth, programs }: RootState) => {
  return {
    token: auth.token,
    userId: auth.userId,
    loading: programs.loading,
    programs: programs.programs,
    loadingSaving: programs.loadingSaving,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    putProgramStart: () => dispatch(putProgramStart()),
    putProgramSuccess: (programId: string, programCopy: Programs) =>
      dispatch(putProgramSuccess(programId, programCopy)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps<WorkoutBuilderParams, any, LocationStateProps>;

const WorkoutBuilder: FC<Props> = ({ match, history, programs, loadingSaving, userId }) => {
  const clone = rfdc();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { register, setValue, getValues } = useForm<WorkoutBuilderFormData>({
    defaultValues: {
      coachWorkoutNotes: '',
      clientWorkoutNotes: '',
      workoutTitle: '',
    },
  });

  const { coachProfile, token } = useSelector((state: RootState) => state.auth);
  const exerciseLibraryState = useSelector((state: RootState) => state.exerciseLibrary);

  const safeCoachProfile = coachProfile || {};
  const coachKey = Object.keys(coachProfile || {})[0];

  const { fullname = '' } = safeCoachProfile[coachKey] || {};
  const { exercises: libExercises, finishedPull } = exerciseLibraryState;
  const { params } = match;

  // get params from url
  const workoutSource = params.programKey
    ? `programs/${params.programKey}/weeks/${params.weekIndex}/Days/${params.dayIndex}`
    : params.assignedProgramKey
    ? `newAssignedPrograms/${params.assignedProgramKey}/weeks/${params.weekIndex}/Days/${params.dayIndex}`
    : undefined;

  const [loading, setLoading] = useState<boolean>(true);
  const [savingWorkout, setSavingWorkout] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);
  const [navModal, showNavModal] = useState<boolean>(false);
  const [deleteModal, showDeleteModal] = useState<boolean>(false);

  const day: Day | null =
    programs?.[params.programKey || 0]?.weeks?.[+params.weekIndex]?.Days?.[+params.dayIndex] || null;
  const [program, setProgram] = useState<Day | null>(day);

  const [nextLocation, setNextLocation] = useState<string>('');
  const [workoutTitle, setWorkoutTitle] = useState<string>('');
  const [shouldNavOut, setShouldNavOut] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Suggestions[]>([]);
  const [isProgramSet, setIsProgramSet] = useState<boolean>(false);

  const [exerciseToDelete, setExerciseToDelete] = useState({
    group: 0,
    index: 0,
  });
  const [suggestionsSource, setSuggestionsSource] = useState<Suggestions[]>([]);
  const [initialProgram, setInitialProgram] = useState<Day | null>(null);
  const [showSaveProgram, setShowSaveProgram] = useState<boolean>(false);
  const [editWorkoutTitle, setEditWorkoutTitle] = useState<boolean>(false);

  const [analyticsExerciseList, setAnalyticsExerciseList] = useState<IndexedExercise[]>([]);

  const openAnalytics = (indexedExercise: IndexedExercise) => {
    programTrackingService.trackProgramEvent('open_analytics', {
      location: 'workout_builder',
      parent_location: params.programKey ? 'program_template' : 'client_training',
    });
    const exerciseList = [...analyticsExerciseList];
    exerciseList.push(indexedExercise);
    setAnalyticsExerciseList(exerciseList);
  };

  const closeAnalytics = (indexedExercise: IndexedExercise) => {
    const exerciseList = [...analyticsExerciseList];
    const newList = exerciseList.filter((ex) => {
      return !(ex.group === indexedExercise.group && ex.index === indexedExercise.index);
    });
    setAnalyticsExerciseList(newList);
  };

  const checkChanged = useCallback(() => {
    if (program && !initialProgram && !loadingSaving) {
      const clonedProgram = clone(program);
      setInitialProgram(clonedProgram);
    }

    if (!program || !initialProgram) return;
    if (!isEqual(program, initialProgram)) {
      setChanged(true);
    } else {
      setChanged(false);
    }
  }, [program, initialProgram]);

  useEffect(() => {
    checkChanged();
  }, [checkChanged]);

  useEffect(() => {
    if (token && coachKey) {
      dispatch(fetchExercises(token, coachKey));
    }
  }, [coachKey, dispatch, token]);

  useEffect(() => {
    const runAsync = async () => {
      let coachExercises: Suggestions[] = [];

      if (libExercises) {
        const keys = Object.keys(libExercises);
        const newCoachExercises: Suggestions[] = keys.map((key) => {
          return { ...libExercises[key], author: 'coach' };
        });

        coachExercises = [...coachExercises, ...newCoachExercises];
      }

      // get our exercises
      const snapshot = await database.ref('exerciseLibrary/admin').once('value');

      if (snapshot.exists()) {
        const data = snapshot.val();
        const adminKeys = Object.keys(data);

        const adminExercises: Suggestions[] = adminKeys.map((key) => {
          return { ...data[key], author: 'admin' };
        });

        setSuggestionsSource([...adminExercises, ...coachExercises]);
      } else {
        setSuggestionsSource(coachExercises);
      }
    };

    if (finishedPull) runAsync();
  }, [libExercises, finishedPull]);

  useEffect(() => {
    if (shouldNavOut && nextLocation) {
      history.push(nextLocation);
    }
  }, [shouldNavOut, history, nextLocation]);

  useEffect(() => {
    if (params.programKey) {
      const day: Day | null =
        programs?.[params.programKey]?.weeks?.[+params.weekIndex]?.Days?.[+params.dayIndex] || null;
      setProgram(day);
      setValue('workoutTitle', day?.workout?.workoutTitle || '');
      setValue('coachWorkoutNotes', day?.workout?.workoutNotes?.coach || '');
      setValue('clientWorkoutNotes', day?.workout?.workoutNotes?.athlete || '');
      if (!loadingSaving) {
        setIsProgramSet(true);
      }
    }
  }, [programs]);

  useEffect(() => {
    // showing loading spinner pending db call
    setLoading(true);

    // fetch program from databse
    const getData = async () => {
      try {
        if (!params.assignedProgramKey) return;

        if (workoutSource) {
          const snapshot = await database.ref(workoutSource).once('value');
          const { workout = { finished: false } }: Day = snapshot.val();
          const { workoutTitle = '' } = workout;
          setWorkoutTitle(workoutTitle);
          setValue('workoutTitle', workoutTitle);
          setValue('coachWorkoutNotes', workout.workoutNotes?.coach || '');
          setValue('clientWorkoutNotes', workout.workoutNotes?.athlete || '');
          setProgram({ workout, rest: false });
          setIsProgramSet(true);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [workoutSource]);

  const addExerciseBlockHandler = () => {
    const newExercise = {
      type: '',
      sets: [],
      exerciseNotes: { coach: '', athlete: '' },
    };
    const updatedProgram: Day = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: program?.workout?.exercises ? [...program.workout.exercises, [newExercise]] : [[newExercise]],
      },
    };
    setProgram(updatedProgram);
  };

  const addSuperSet = (index: number) => {
    const newSet = {
      type: '',
      sets: [],
      exerciseNotes: { coach: '', athlete: '' },
    };

    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === index) {
        return [...exercise, newSet];
      }
      return exercise;
    });
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const addTableSetsHandler = (sets: Set[], group: number, index: number) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            return { ...exerciseGroup, sets };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
    checkChanged();
  };

  const addExerciseNotes = (exerciseNotes: string, group: number, index: number) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            return {
              ...exerciseGroup,
              exerciseNotes: {
                athlete: exerciseGroup.exerciseNotes?.athlete || '',
                coach: exerciseNotes,
              },
            };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const addExerciseType = (exerciseType: string, group: number, index: number) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            return {
              ...exerciseGroup,
              type: exerciseType,
            };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };

    setProgram(updatedProgram);
    checkChanged();
  };

  const addWorkoutNotesHandler = (notes: string) => {
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        workoutNotes: {
          athlete: program?.workout.workoutNotes?.athlete || '',
          coach: notes,
        },
      },
    };
    setProgram(updatedProgram);
    checkChanged();
  };

  const removeExerciseBlockHandler = (group: number, index: number) => {
    const newExercises = [...(program?.workout?.exercises || [])];

    if (newExercises?.[group]) {
      newExercises[group].splice(index - 1, 1);
      if (newExercises[group].length === 0) {
        newExercises.splice(group, 1);
      }
    }

    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
    checkChanged();
  };

  const editWorkoutTitleHandler = () => {
    const save = editWorkoutTitle;
    setEditWorkoutTitle((editWorkoutTitle) => !editWorkoutTitle);
    const title = getValues('workoutTitle');
    if (save) {
      const updatedProgram = {
        rest: !!program?.rest,
        workout: {
          ...program?.workout,
          finished: !!program?.workout?.finished,
          workoutTitle: title || workoutTitle,
        },
      };
      setProgram(updatedProgram);
      checkChanged();
    }
  };

  const workoutTitleChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setWorkoutTitle(e.target.value);
  };

  const saveProgram = async () => {
    if (!program || !token || !userId) {
      return;
    }
    setSavingWorkout(true);
    try {
      if (params.programKey) {
        await dispatch(saveWorkout(token, params.programKey, params.weekIndex, params.dayIndex, userId, workout));
        addToast('Workout Saved', { appearance: 'success' });
      } else if (params.assignedProgramKey) {
        // Assigned program
        await database.ref(workoutSource).update(program);
        addToast('Workout Saved', { appearance: 'success' });
      }
      programTrackingService.trackProgramEvent('save_workout', {
        program_id: params.programKey || params.assignedProgramKey,
        location: 'workout_builder',
        parent_location: params.programKey ? 'program_template' : 'client_training',
      });
      setChanged(false);
      setInitialProgram(null);
    } catch (err) {
      addToast('Workout Save Failed', { appearance: 'error' });
    } finally {
      setSavingWorkout(false);
      setShowSaveProgram(false);
    }
  };

  const autoFillSetsHandler = (setType: SetKeys, value: string, group: number, index: number, setIndex: number) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            const { sets = [] } = exerciseGroup;
            return {
              ...exerciseGroup,
              sets: sets.map((set, i) => {
                if (i >= setIndex) {
                  if (typeof set[setType] === 'string') {
                    return {
                      ...set,
                      [setType]: {
                        achieved: '',
                        programmed: value,
                      },
                    };
                  }

                  return {
                    ...set,
                    [setType]: {
                      achieved: '',
                      programmed: value,
                    },
                  };
                }

                return set;
              }),
            };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const setsChangeHandler = (setType: SetKeys, value: string, group: number, index: number, setIndex: number) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            const { sets = [] } = exerciseGroup;
            return {
              ...exerciseGroup,
              sets: sets.map((set, i) => {
                if (i === setIndex) {
                  if (typeof set[setType] === 'string') {
                    return {
                      ...set,
                      [setType]: {
                        achieved: '',
                        programmed: value,
                      },
                    };
                  }

                  return {
                    ...set,
                    [setType]: {
                      achieved: '',
                      programmed: value,
                    },
                  };
                }

                return set;
              }),
            };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const setTypeChangeHandler = (setType: SetKeys, group: number, index: number, setIndex: number) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            const { sets: groupSets = [] } = exerciseGroup;
            const sets = groupSets.map((set: Set) => {
              // remove completed key
              const { completed, ...otherSets } = set;

              const entries = Object.entries(otherSets);

              const sortedSets = entries.sort((elA, elB) => {
                const [, valA] = elA;
                const [, valB] = elB;

                if (instanceOfSetObject(valA) && instanceOfSetObject(valB)) {
                  return (valA?.index || 0) - (valB?.index || 0);
                }
                return 0;
              });

              const newSets = sortedSets.reduce(
                (acc, curr, index) => {
                  const [key, value] = curr;

                  if (index === setIndex) {
                    return { ...acc, [setType]: value };
                  }

                  return { ...acc, [key]: value };
                },
                { completed },
              );

              return newSets;
            });

            return { ...exerciseGroup, sets };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });

    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const removeTypeChangeHandler = (group: number, index: number, setKey: SetKeys) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            const { sets: groupSets = [] } = exerciseGroup;
            const sets = groupSets.map((el) => {
              // remove completed key
              const { completed, ...otherSets } = el;

              const entries = Object.entries(otherSets);
              let indexCount = 0;

              const newSets = entries.reduce(
                (acc, curr) => {
                  const [key, value] = curr;

                  if (key === setKey) {
                    return { ...acc };
                  }

                  indexCount++;

                  if (instanceOfSetObject(value)) {
                    return { ...acc, [key]: { ...value, index: indexCount } };
                  }
                  return { ...acc, [key]: { value, index: indexCount } };
                },
                { completed },
              );

              return newSets;
            });

            return { ...exerciseGroup, sets };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });

    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const addTypeChangehandler = (setType: SetKeys, group: number, index: number) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            const { sets: groupSets = [] } = exerciseGroup;
            const sets = groupSets.map((el) => {
              const newSets = {
                ...el,
                [setType]: {
                  achieved: '',
                  programmed: '',
                  index: Object.keys(el).length,
                },
              };

              return newSets;
            });

            return { ...exerciseGroup, sets };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });

    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const removeSetHandler = (group: number, index: number) => {
    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            const { sets: newSets = [] } = exerciseGroup;
            newSets.pop();

            return {
              ...exerciseGroup,
              sets: newSets,
            };
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const addSetHandler = (group: number, index: number) => {
    const defaultSet = createDefaultSet(history?.location?.state?.defaultSetParams || DEFAULT_SET_PARAMS);

    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, i) => {
          if (i === index) {
            const numberofSets = exerciseGroup.sets ? exerciseGroup.sets.length : 0;

            // if first set, initialize with empty values
            if (!numberofSets) {
              return {
                ...exerciseGroup,
                sets: [defaultSet],
              };
            } else {
              // initalize with last set values
              const { sets = [] } = exerciseGroup;
              const lastSet = {
                ...sets[numberofSets - 1],
                completed: false,
              };
              return {
                ...exerciseGroup,
                sets: [...sets, lastSet],
              };
            }
          }
          return exerciseGroup;
        });
      }
      return exercise;
    });
    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };
    setProgram(updatedProgram);
  };

  const handleNavPrompt = (nextLoc: Location<unknown>) => {
    if (!shouldNavOut) {
      setNextLocation(nextLoc as unknown as string);
      showNavModal(true);

      return false;
    }

    return true;
  };

  const setExerciseFromLib = (suggestion: Suggestions, group: number, index: number) => {
    const defaultSet = createDefaultSet(history?.location?.state?.defaultSetParams || DEFAULT_SET_PARAMS);

    const newExercises = program?.workout?.exercises?.map((exercise, i) => {
      if (i === group) {
        return exercise.map((exerciseGroup, j) => {
          if (j === index) {
            if (suggestion.author === 'coach') {
              return suggestion;
            }
            return { ...suggestion, sets: [defaultSet] };
          }

          return exerciseGroup;
        });
      }

      return exercise;
    });

    const updatedProgram = {
      rest: !!program?.rest,
      workout: {
        ...program?.workout,
        finished: !!program?.workout?.finished,
        exercises: newExercises,
      },
    };

    setProgram(updatedProgram);
  };

  const discardChanges = () => {
    setShouldNavOut(true);
  };

  const handleExerciseDel = () => {
    const { group, index } = exerciseToDelete;
    removeExerciseBlockHandler(group, index);
    showDeleteModal(false);
  };

  const swapSets = (groupIndexOne: number, groupIndexTwo: number) => {
    const source = document.getElementById(`${groupIndexOne}`);
    const destination = document.getElementById(`${groupIndexTwo}`);

    if (!source || !destination || !program || !program.workout) {
      return;
    }

    const { workout } = program;
    const { exercises = [] } = workout;

    const sourceOffset = source.offsetTop;
    const destinationOffset = destination.offsetTop;

    const sourceHeight = source.offsetHeight;
    const destinationHeight = destination.offsetHeight;

    if (sourceOffset < destinationOffset) {
      source.style.transform = `translateY(100%)`;
      destination.style.transform = `translateY(-100%)`;
    } else {
      source.style.transform = `translateY(-100%)`;
      destination.style.transform = `translateY(100%)`;
    }

    source.style.transition = 'transform 0.6s cubic-bezier(0.85, 0, 0.15, 1)';
    destination.style.transition = 'transform 0.6s cubic-bezier(0.85, 0, 0.15, 1)';

    if (sourceOffset < destinationOffset) {
      source.style.transform = `translateY(${destinationHeight}px)`;
      destination.style.transform = `translateY(-${sourceHeight}px)`;
    } else {
      source.style.transform = `translateY(-${destinationHeight}px)`;
      destination.style.transform = `translateY(${sourceHeight}px)`;
    }

    source.addEventListener(
      'transitionend',
      () => {
        source.style.transition = '';
        destination.style.transition = '';
        source.style.transform = `translateY(0)`;
        destination.style.transform = `translateY(0)`;

        const swappedExerciseOne = exercises[groupIndexOne];
        const swappedExerciseTwo = exercises[groupIndexTwo];

        const newExercises = exercises.map((groupEl, i) => {
          if (i === groupIndexOne) {
            return swappedExerciseTwo;
          }

          if (i === groupIndexTwo) {
            return swappedExerciseOne;
          }

          return groupEl;
        });

        const updatedProgram = {
          ...program,
          workout: {
            ...program.workout,
            exercises: newExercises,
          },
        };
        setProgram(updatedProgram);
      },
      {
        once: true,
      },
    );
  };

  const swapInSupersets = (groupIndex: number, indexOne: number, indexTwo: number) => {
    const source = document.getElementById(`${groupIndex}${indexOne}`);
    const destination = document.getElementById(`${groupIndex}${indexTwo}`);

    if (!source || !destination || !program || !program.workout) {
      return;
    }

    const { workout } = program;
    const { exercises = [] } = workout;

    const sourceOffset = source.offsetTop;
    const destinationOffset = destination.offsetTop;

    const sourceHeight = source.offsetHeight;
    const destinationHeight = destination.offsetHeight;

    source.style.transition = 'transform 0.6s cubic-bezier(0.85, 0, 0.15, 1)';
    destination.style.transition = 'transform 0.6s cubic-bezier(0.85, 0, 0.15, 1)';

    if (sourceOffset < destinationOffset) {
      source.style.transform = `translateY(${destinationHeight}px)`;
      destination.style.transform = `translateY(-${sourceHeight}px)`;
    } else {
      source.style.transform = `translateY(-${destinationHeight}px)`;
      destination.style.transform = `translateY(${sourceHeight}px)`;
    }

    source.addEventListener(
      'transitionend',
      () => {
        source.style.transition = '';
        destination.style.transition = '';
        source.style.transform = `translateY(0)`;
        destination.style.transform = `translateY(0)`;

        const newExercises = exercises.map((groupEl, i) => {
          if (i === groupIndex) {
            const swappedExerciseOne = groupEl[indexOne];
            const swappedExerciseTwo = groupEl[indexTwo];

            return groupEl.map((el, j) => {
              if (j === indexOne) {
                return swappedExerciseTwo;
              }

              if (j === indexTwo) {
                return swappedExerciseOne;
              }

              return el;
            });
          }

          return groupEl;
        });

        const updatedProgram = {
          ...program,
          workout: {
            ...program.workout,
            exercises: newExercises,
          },
        };
        setProgram(updatedProgram);
      },
      { once: true },
    );
  };

  const handleExerciseDelete = (group: number, index: number) => {
    setExerciseToDelete({ group, index });
    showDeleteModal(true);
  };

  if (loading || loadingSaving || !isProgramSet) {
    const loadingText = loadingSaving ? 'Saving Program...' : 'Loading Workout...';
    return (
      <Layout loading={loading} heading={loadingText}>
        <div>
          <Spinner />
        </div>
      </Layout>
    );
  }

  if (!params.programKey && !params.assignedProgramKey) {
    return <Redirect to="/404" />;
  }

  let workout: Workout;
  if (program?.workout) {
    workout = program.workout;
  } else {
    workout = { finished: false };
  }

  const { exercises = [], finished } = workout;

  const weekText = `Week ${+params.weekIndex + 1}`;
  const dayText = `Day ${+params.dayIndex + 1}`;

  return (
    <Layout loading={loading} heading={`${weekText}, ${dayText}`}>
      <Prompt when={changed} message={handleNavPrompt} />
      <div>
        <div className={classes.Toolbar}>
          <div className={classes.TitleInputContainer}>
            <h3 className={classes.WorkoutTitle}>{workout.workoutTitle || 'Untitled workout'}</h3>
            {editWorkoutTitle && (
              <div className={classes.Input}>
                <Input
                  register={register}
                  name="workoutTitle"
                  placeholder="Untitled workout"
                  defaultValue={workoutTitle}
                  onChange={workoutTitleChangeHandler}
                />
              </div>
            )}
            {!finished &&
              (editWorkoutTitle ? (
                <div>
                  <Button type="button" intent="secondary" onClick={editWorkoutTitleHandler}>
                    SAVE
                  </Button>
                </div>
              ) : (
                <div className={classes.MarginLeft}>
                  <Button type="button" size="xsmall" onClick={editWorkoutTitleHandler} iconCenter={<EditIcon />} />
                </div>
              ))}
          </div>

          {!finished && (
            <div className={classes.ActionButtons}>
              <Button
                type="button"
                intent="success"
                onClick={() => setShowSaveProgram(true)}
                disabled={!changed}
                iconLeft={<SaveIcon />}
              >
                SAVE
              </Button>
            </div>
          )}
        </div>

        <div className={classes.HeaderContainer}>
          <div className={finished ? classes.SessionNotesReview : classes.SessionNotes}>
            {finished ? (
              <>
                <div className={classes.SessionNotesReviewColumn}>
                  <p>Your Workout Notes:</p>
                  <TextArea
                    register={register}
                    name="coachWorkoutNotes"
                    placeholder="You assigned no notes for this workout."
                    defaultValue={workout?.workoutNotes?.coach || ''}
                    readOnly={true}
                  />
                </div>
                <div className={classes.SessionNotesReviewColumn}>
                  <p>Client&apos;s Workout Feedback:</p>
                  <TextArea
                    register={register}
                    name="clientWorkoutNotes"
                    placeholder="Client did not add notes to this workout."
                    defaultValue={workout?.workoutNotes?.athlete || ''}
                    readOnly={true}
                  />
                </div>
              </>
            ) : (
              <TextArea
                register={register}
                name="coachWorkoutNotes"
                placeholder="Add session notes..."
                defaultValue={workout?.workoutNotes?.coach}
                onChange={(e) => addWorkoutNotesHandler(e.target.value)}
              />
            )}
          </div>
        </div>

        <div className={finished ? classes.WorkoutReview : classes.Workout}>
          <div className={classes.ExerciseContainer}>
            {exercises.map((group, i) => {
              const groupLength = group?.length || 0;
              return (
                <div id={`${i}`} key={i}>
                  {group.map((exercise, j) => {
                    return (
                      <div id={`${i}${j}`} key={`${i}${j}`} className={classes.Exercise}>
                        <div className={classes.GroupIndicatorContainer}>
                          <ExerciseGroup group={i} index={j + 1} />
                          {groupLength > 1 && j !== groupLength - 1 && (
                            <div className={classes.SuperSetLineWrapper}>
                              <div className={classes.SuperSetLine}></div>
                            </div>
                          )}
                        </div>

                        <ExerciseBlock
                          group={i}
                          index={j + 1}
                          finished={finished}
                          exercise={exercise}
                          fullname={fullname}
                          suggestions={suggestions}
                          clientKey={params.clientKey}
                          programKey={params.programKey}
                          setSuggestions={setSuggestions}
                          addExerciseType={addExerciseType}
                          addExerciseNotes={addExerciseNotes}
                          suggestionsSource={suggestionsSource}
                          addSetsHandler={addTableSetsHandler}
                          openAnalytics={openAnalytics}
                          closeAnalytics={() => closeAnalytics({ exercise, group: i, index: j + 1 })}
                          analyticsExerciseList={analyticsExerciseList}
                          bottom={exercises.length - 1 === i && group.length - 1 === j}
                          supersetLength={group.length - 1}
                          swapSets={swapSets}
                          swapInSupersets={swapInSupersets}
                          addSetHandler={() => addSetHandler(i, j)}
                          removeSetHandler={() => removeSetHandler(i, j)}
                          autoFillSetsHandler={(setType, value, setIndex) =>
                            autoFillSetsHandler(setType, value, i, j, setIndex)
                          }
                          setsChangeHandler={(setType, value, setIndex) =>
                            setsChangeHandler(setType, value, i, j, setIndex)
                          }
                          removeTypeChangeHandler={(setKey) => removeTypeChangeHandler(i, j, setKey)}
                          addTypeChangehandler={(setType: SetKeys) => addTypeChangehandler(setType, i, j)}
                          setExerciseFromLib={(suggestion: Suggestions) => setExerciseFromLib(suggestion, i, j)}
                          setTypeChangeHandler={(setType, setIndex) => setTypeChangeHandler(setType, i, j, setIndex)}
                          addSuperSet={() => addSuperSet(i)}
                          handleExerciseDelete={() => handleExerciseDelete(i, j + 1)}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {!finished && (
            <div className={classes.AddExerciseButtonContainer}>
              <Button type="button" onClick={addExerciseBlockHandler} iconLeft={<PlusIcon />}>
                ADD EXERCISE
              </Button>
            </div>
          )}
        </div>
      </div>

      <AlertDialog
        title="Save workout"
        description="Do you want to save your workout? This will save any changes that were made."
        open={showSaveProgram}
        onOpenChange={setShowSaveProgram}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setShowSaveProgram(false)} disabled={savingWorkout}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" onClick={saveProgram} loading={savingWorkout}>
            Save
          </Button>
        }
      />

      <ForgotToSaveDialog openModal={navModal} setModalShow={showNavModal} discardChanges={discardChanges} />

      <AlertDialog
        title="Delete exercise"
        description="Do you want to delete this exercise block? This cannot be undone."
        open={deleteModal}
        onOpenChange={showDeleteModal}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => showDeleteModal(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={handleExerciseDel}>
            Delete
          </Button>
        }
      />
    </Layout>
  );
};

export default connector(withAuth(WorkoutBuilder));
