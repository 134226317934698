import axios from 'axios';
import { ApiBasicResponse } from 'interfaces/api';
import { PlanPayload } from 'interfaces/clientBilling';
import { functions } from 'utils/firebase';
import firebase from 'firebase/app';
import { BASE_URL } from 'utils/helpers';

export const getConnectAccount = () => {
  return functions.httpsCallable('v1-https-clientBilling-getConnectAccount')();
};

export const createExpressAccount = () => {
  return functions.httpsCallable('v1-https-clientBilling-createConnectAccount')();
};

export const getAccountLinkUrl = (accountId: string) => {
  return functions.httpsCallable('v1-https-clientBilling-getAccountLinkUrl')({ accountId });
};

export const createPlan = ({
  planName,
  planDescription,
  price,
  currency,
  paymentCycle,
  intervalCount,
  accountId,
  identifier,
  coachName,
}: PlanPayload) => {
  return functions.httpsCallable('v1-https-clientBilling-createPlan')({
    planName,
    planDescription,
    price,
    currency,
    paymentCycle,
    intervalCount,
    accountId,
    identifier,
    coachName,
  });
};

export const getActiveProducts = (accountId: string) => {
  return functions.httpsCallable('v1-https-clientBilling-getActiveProducts')({ accountId });
};

export const createCheckoutSession = ({
  email,
  firstName,
  lastName,
  coachUserId,
  planCode,
  priceId,
  isReactivateSubscription,
  identifier,
  coachAccountId,
  encodedData,
}): Promise<firebase.functions.HttpsCallableResult | ApiBasicResponse> => {
  return functions.httpsCallable('v1-https-clientBilling-createCheckoutSession')({
    email,
    firstName,
    lastName,
    coachUserId,
    planCode,
    priceId,
    isReactivateSubscription,
    identifier,
    coachAccountId,
    encodedData,
  });
};

export const getStripeFileId = (file: File, purpose: string): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('purpose', purpose);

  return axios({
    url: 'https://files.stripe.com/v1/files',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRIPE_FILE_UPLOAD_API_KEY}`,
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
};

export const createLoginLink = (accountId: string) => {
  return functions.httpsCallable('v1-https-clientBilling-createLoginLink')({ accountId });
};

export const verifyCheckoutSession = (sessionId: string, coachAccountId: string) => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/v1-https-clientBilling-verifySession`,
    data: {
      sessionId,
      accountId: coachAccountId,
    },
  });
};
