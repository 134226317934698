import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CLIENTS } from './routes';
import { auth } from './firebase';

type Status = 'LOADING' | 'SIGNED_IN';

const WithoutAuth = (Component: FC<any>): FC<any> => {
  const AuthFreeComponent = (props: any) => {
    const [status, setStatus] = useState<Status>('LOADING');
    const history = useHistory();

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          history.push(CLIENTS.URL);
        } else {
          setStatus('SIGNED_IN');
        }
      });

      // Clean up the subscription on unmount
      return () => unsubscribe();
    }, [history]);

    if (status === 'LOADING') {
      return null;
    } else {
      return <Component {...props} />;
    }
  };

  return AuthFreeComponent;
};

export default WithoutAuth;
