import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { object, string } from 'yup';

import Button from 'components/UI/Button';
import { RESET_PASSWORD } from 'utils/routes';
import { FormProps } from 'interfaces/utils';
import classes from './Login.module.css';
import { ReactComponent as EmailIcon } from 'assets/svgs/mail-filled.svg';
import { ReactComponent as PasswordIcon } from 'assets/svgs/lock-closed-filled.svg';
import Input from 'components/UI/Input';
import useYupValidationResolver from 'hooks/useYupValidationResolver';

const validationSchema = object().shape({
  email: string().email().required('Email address is required'),
  password: string().required('Password is required'),
});

type FormValues = {
  email: string;
  password: string;
};

const Login: FC<FormProps<FormValues> & { loading: boolean; theme: any }> = ({ loading, theme, handleSubmit }) => {
  const {
    register,
    handleSubmit: hookFormSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: useYupValidationResolver(validationSchema),
  });

  const onSubmit = async (values: FormValues) => {
    await handleSubmit(values);
  };

  return (
    <form className={classes.FormContainer} onSubmit={hookFormSubmit(onSubmit)}>
      <div className={classes.InputWrapper}>
        <Input
          name="email"
          error={errors.email}
          register={register}
          placeholder="Email"
          label="Email address"
          iconLeft={<EmailIcon fill={theme['--color-icon-stroke']} />}
        />
      </div>

      <Input
        type="password"
        error={errors.password}
        name="password"
        register={register}
        placeholder="Password"
        label="Password"
        iconLeft={<PasswordIcon fill={theme['--color-icon-stroke']} />}
      />
      <div className={classes.ResetPasswordWrapper}>
        <NavLink to={{ pathname: RESET_PASSWORD.URL }} className={classes.ResetPassword}>
          <span>FORGOT YOUR PASSWORD?</span>
        </NavLink>
      </div>

      <div className={classes.ButtonWrapper}>
        <Button type="submit" loading={loading || isSubmitting} disabled={loading || isSubmitting}>
          SIGN IN
        </Button>
      </div>
    </form>
  );
};

export default Login;
