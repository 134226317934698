import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import clsx from 'clsx';

import { Currencies, Plan } from 'interfaces/clientBilling';
import Select from 'components/UI/Select';
import Input from 'components/UI/Input';
import TextArea from 'components/UI/TextArea';
import { PAYMENT_CYCLE, CURRENCY } from 'utils/constants';
import classes from './CreateClientBillingPlan.module.css';
import Button from 'components/UI/Button';

interface CreateClientBillingPlanProps {
  onSubmit: (plan: Plan) => void;
  loading?: boolean;
}

const CreateClientBillingPlan: FC<CreateClientBillingPlanProps> = ({ onSubmit, loading }) => {
  const [openSelect, setOpenSelect] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Plan>();

  const handleFormSubmit: SubmitHandler<Plan> = (data: Plan) => {
    onSubmit(data);
  };

  const handleSelectOpenChange = (name: string, isOpen: boolean) => {
    if (isOpen) {
      setOpenSelect(name);
    } else if (openSelect === name) {
      setOpenSelect(null);
    }
  };

  return (
    <div className={classes.Form}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className={classes.FormElement} id="planNameFormElement">
          <Input
            name="planName"
            label="Plan name"
            type="text"
            placeholder="Enter your plan name"
            register={register}
            error={errors.planName}
          />
        </div>

        <div className={classes.Row}>
          <div className={clsx(classes.FormElement, classes.CurrencyFormElement)}>
            <Select
              name="currency"
              placeholder="Currency"
              control={control}
              onOpenChange={(isOpen) => handleSelectOpenChange('currency', isOpen)}
              open={openSelect === 'currency'}
              optionArray={CURRENCY}
              label="Currency"
              error={errors.currency}
              required
              defaultValue={Currencies.USD}
            />
          </div>
          <div className={clsx(classes.FormElement, classes.PriceFormElement)}>
            <Input
              name="price"
              label="Plan price"
              type="number"
              placeholder="Enter your plan price"
              register={register}
              error={errors.price}
            />
          </div>
        </div>

        <div className={classes.Row}>
          <div className={clsx(classes.FormElement, classes.IntervalCountFormElement)}>
            <Input
              name="intervalCount"
              label="Interval count"
              type="number"
              placeholder="Enter your interval count"
              register={register}
              error={errors.intervalCount}
            />
          </div>
          <div className={clsx(classes.FormElement, classes.PaymentCycleFormElement)}>
            <Select
              name="paymentCycle"
              placeholder="Payment cycle"
              control={control}
              onOpenChange={(isOpen) => handleSelectOpenChange('paymentCycle', isOpen)}
              open={openSelect === 'paymentCycle'}
              optionArray={PAYMENT_CYCLE}
              label="Payment cycle"
              error={errors.paymentCycle}
              required
              defaultValue="month"
            />
          </div>
        </div>

        <div className={classes.FormElement}>
          <TextArea
            name="planDescription"
            label="Plan description"
            placeholder="Enter your description"
            register={register}
            error={errors.planDescription}
            required
          />
        </div>

        <div className={classes.ButtonContainer}>
          <div>
            <Button type="submit" loading={loading}>
              Create
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateClientBillingPlan;
