import React, { FC, useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { UploadTaskSnapshot, UploadTask } from '@firebase/storage-types';
import { useForm } from 'react-hook-form';

import { ClientDocumentParams } from 'interfaces/routes';
import { RootState } from 'store';
import withAuth from 'utils/withAuth';
import { storage } from 'utils/firebase';
import { Button, UploadDocument } from 'components';
import Input from 'components/UI/Input';
import TabNav from 'components/UI/TabNav/TabNav';
import { CLIENTS_TRAINING, DOCUMENTS } from 'utils/routes';
import { CoachProfile, Profile, Documents as DocumentsType, Documents } from 'interfaces/db';
import { getToday, isEmptyOrWhitespace } from 'utils/helpers';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-filled.svg';
import { saveClientProfile } from 'store/actions/clients';
import { saveCoachProfile } from 'store/actions/auth';
import Layout from '../../Layout';
import classes from './ClientDocuments.module.css';
import { addDocument, updateDocuments, deleteDocuments, fetchDocument } from 'store/actions/documents';
import Dialog from 'components/UI/Dialog';
import { ReactComponent as SearchMagnifyingGlass } from 'assets/svgs/search-filled.svg';
import EmptyState from 'components/EmptyState';
import { FirebaseObject } from 'interfaces/utils';
import { ColumnDef } from '@tanstack/react-table';
import { DropdownMenu, Table, AlertDialog, Spinner } from 'components/UI';
import KebabMenu from 'components/KebabMenu';

import { ReactComponent as DeleteIcon } from 'assets/svgs/bin-filled.svg';
import { useDropdownUnique } from 'hooks';
import { deleteDocumentsForClient } from '../helpers';
import SelectCheckbox from 'components/UI/Table/SelectCheckbox';

type SearchValue = {
  documentsSearch: string;
};

const mapStateToProps = ({ auth, clients, documents }: RootState) => {
  const { userId, token, coachProfile } = auth;
  return {
    token,
    userId,
    loading: documents.loading,
    coachProfile,
    clients,
    documents: documents.documents,
    coachKey: Object.keys(coachProfile || {})[0],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveCoachProfile: (coachProfile: CoachProfile, coachProfileKey: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey)),
    onSaveClientProfile: (clientProfile: Profile, clientKey: string) =>
      dispatch(saveClientProfile(clientProfile, clientKey)),
    onDeleteDocuments: (
      url: string[],
      token: string,
      userId: string,
      documents: FirebaseObject<Documents> | null,
      documentIds: string[],
    ) => dispatch(deleteDocuments(url, token, userId, documents, documentIds)),
    onAddDocument: (token: string, document: DocumentsType, userId?: string) =>
      dispatch(addDocument(token, document, userId)),
    onUpdateDocuments: (token: string, documents: { [docKey: string]: Documents }) =>
      dispatch(updateDocuments(token, documents)),
    onFetchDocument: (token: string, documentKey: string) => dispatch(fetchDocument(token, documentKey)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & RouteComponentProps<ClientDocumentParams>;

const ClientDocuments: FC<Props> = ({
  token,
  userId,
  clients,
  documents,
  loading,
  coachKey,
  coachProfile,
  onFetchDocument,
  onSaveCoachProfile,
  onSaveClientProfile,
  onUpdateDocuments,
  onAddDocument,
  match,
}) => {
  const tableRef = useRef<any>();
  const { clientKey } = match.params;
  const client = clients.clients?.[clientKey || ''];
  const { register } = useForm<SearchValue>();
  const [progress, setProgress] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>(categories[0]);
  const [filteredDocuments, setFilteredDocuments] = useState<any>([]);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [selectedDocumentKey, setSelectedDocumentKey] = useState<string | null>(null);
  const { toggleDropdown, isOpen } = useDropdownUnique();
  const [searchDocumentsValue, setSearchDocumentsValue] = useState<string | null>(null);

  const [rowSelection, setRowSelection] = useState({});

  const documentsForCategory =
    client?.profile?.documents &&
    Object.fromEntries(
      Object.entries(documents || {})?.filter(
        ([key, value]) =>
          value?.category?.toLowerCase() === selectedTab?.toLowerCase() && client?.profile?.documents?.includes(key),
      ),
    );

  useEffect(() => {
    if (!client?.profile?.documents || !token || isEmptyOrWhitespace(token)) return;
    for (const docKey of client?.profile?.documents || []) {
      onFetchDocument(token || '', docKey);
    }
  }, [client?.profile?.documents, token, userId, onFetchDocument]);

  useEffect(() => {
    const allClientCategories = Object.keys(documents || {})
      ?.map((docKey) => {
        if (client?.profile?.documents?.includes(docKey)) {
          return documents?.[docKey]?.category || 'null';
        }
        return 'null';
      })
      .filter((val) => val !== 'null');
    // get all unique values from mapped array
    const clientCategories = Array.from(new Set(allClientCategories));
    setCategories(clientCategories);
    setSelectedTab(clientCategories[0]);
  }, [documents]);

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  const uploadError: (a: Error) => any = (error) => {
    console.log(error);
  };

  const uploadSuccess = ({
    uploadTask,
    title,
    fileName,
    fileSize,
    category,
  }: {
    uploadTask: UploadTask;
    title: string;
    fileName: string;
    fileSize: number;
    category: string;
  }) => {
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL: string) => {
      if (coachProfile && coachProfile[coachKey] && token && documents && userId) {
        const { totalDocumentsSize = 0, documentCount, recentDocuments = [] } = coachProfile[coachKey];

        const newCoachProfile = {
          ...coachProfile[coachKey],
          totalDocumentsSize: totalDocumentsSize + fileSize,
          documentCount: documentCount && documentCount + 1,
          recentDocuments: recentDocuments,
        };

        const newDocument = {
          documentId: uuidv4(),
          clientList: [],
          downloadURL: downloadURL,
          fileName: fileName,
          title: title,
          userId: userId,
          fileSize: fileSize,
          uploadedAt: getToday(),
          category: category,
        };

        onAddDocument(token, newDocument, userId);
        onSaveCoachProfile(newCoachProfile, coachKey);
        setModalOpen(false);
        setProgress(0);
      }
    });
  };

  const handleSubmit = async ({ title, file, category }: { title: string; file: FileList; category: string }) => {
    const { name, size } = file[0];
    const storageRef = storage.ref(`${userId}/${uuidv4()}`);
    const uploadTask: any = storageRef.put(file[0]);

    uploadTask.on('state_changed', uploadProgress, uploadError, () =>
      uploadSuccess({
        uploadTask,
        title,
        fileName: name,
        fileSize: size,
        category,
      }),
    );
  };

  const onSearchDocuments = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target?.value.toLowerCase();
    setSearchDocumentsValue(value);

    const documentsFilteredBySearch = Object.entries(documentsForCategory || {})?.filter(([_, document]) => {
      return document?.title?.toLowerCase()?.includes(value);
    });

    setFilteredDocuments(documentsFilteredBySearch);
  };

  const documentsList = () => {
    if (!documents) {
      return null;
    }

    let documentsNew = Object.entries(documentsForCategory || {});
    if (searchDocumentsValue) documentsNew = filteredDocuments;

    const documentData = documentsNew.map(([documentKey, documentData]) => ({
      title: documentData.title,
      fileName: documentData.fileName,
      uploadedAt: documentData.uploadedAt,
      documentKey: documentKey,
      downloadURL: documentData.downloadURL,
    }));

    return (
      <Table
        ref={tableRef}
        data={documentData}
        columns={tableColumns}
        loading={false}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        bulkActions={
          <div className={classes.BulkActions}>
            <div>
              <Button
                type="button"
                intent="secondary"
                onClick={() => {
                  setSelectedDocumentKey(null);
                  setDeleteDocumentModal(true);
                }}
                iconLeft={<DeleteIcon />}
              >
                Delete
              </Button>
            </div>
          </div>
        }
        onRowClick={(row) => {
          const downloadURL = row.original.downloadURL;
          if (downloadURL) {
            window.open(downloadURL, '_blank');
          }
        }}
      />
    );
  };

  const categoriesLength = categories?.length || 0;

  const handleOpenDropdown = (id: string) => {
    toggleDropdown(id);
  };

  const handleOpenDeleteModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, documentKey: string) => {
    e.stopPropagation();
    setDeleteDocumentModal(true);
    setSelectedDocumentKey(documentKey);
  };

  const getSelectedDocumentKeys = () => {
    const documentRowsSelected = tableRef.current?.getSelectedRowModel()?.flatRows;
    const programKeys = documentRowsSelected?.map((row) => row.original.documentKey);
    return programKeys;
  };

  const handleDeleteDocuments = async () => {
    if (!token || !userId) return;

    const selectedDocumentKeys = !selectedDocumentKey ? getSelectedDocumentKeys() : [selectedDocumentKey];

    await deleteDocumentsForClient({
      documentKeys: selectedDocumentKeys,
      clients: clients.clients || {},
      clientKey: clientKey || '',
      token,
      userId,
      documents,
      onSaveClientProfile,
      onUpdateDocuments,
    });

    setRowSelection({});
    setDeleteDocumentModal(false);
  };

  const menuItems = (documentKey: string) => [
    {
      title: 'Delete',
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleOpenDeleteModal(e, documentKey),
      iconLeft: <DeleteIcon />,
    },
  ];

  const tableColumns = useMemo<ColumnDef<Record<string, unknown>>[]>(
    () => [
      SelectCheckbox(),
      {
        header: 'Title',
        cell: ({ getValue }) => getValue(),
        accessorKey: 'title',
      },
      {
        header: 'Document Name',
        cell: (row) => row.renderValue(),
        accessorKey: 'fileName',
      },
      {
        header: 'Date Uploaded',
        cell: (row) => row.renderValue(),
        accessorKey: 'uploadedAt',
      },
      {
        header: '',
        accessorKey: 'actions',
        cell: ({ row }) => {
          return (
            <DropdownMenu
              menuItems={menuItems(row.original.documentKey as string)}
              open={isOpen(row.id)}
              onOpenChange={() => handleOpenDropdown(row.id)}
              trigger={
                <KebabMenu
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(row.id);
                  }}
                />
              }
              width="12.5rem"
            />
          );
        },
      },
    ],
    [isOpen],
  );

  if (loading) {
    return (
      <Layout loading={loading} heading={CLIENTS_TRAINING.TITLE}>
        <div className={classes.Clients}>
          <Spinner />
        </div>
      </Layout>
    );
  }

  return (
    <Layout loading={loading} heading={DOCUMENTS.TITLE}>
      <div className={classes.Container}>
        {categoriesLength > 0 ? (
          <>
            <TabNav setSelectedTab={setSelectedTab} selectedTab={selectedTab} tabHeadings={categories} />

            <div className={classes.TopSection}>
              <div className={classes.SearchDocuments}>
                <form>
                  <Input
                    name="documentsSearch"
                    type="text"
                    placeholder="Search documents..."
                    register={register}
                    onChange={onSearchDocuments}
                    iconLeft={<SearchMagnifyingGlass />}
                  />
                </form>
              </div>
            </div>

            <div className={classes.FilesWrapper}>{documentsList()}</div>
          </>
        ) : (
          <div className={classes.NoDocuments}>
            <EmptyState
              title="No assigned documents"
              description="Upload a document in your account in order to assign it."
            >
              <Button type="button" to="/documents">
                Go to documents
              </Button>
            </EmptyState>
          </div>
        )}
      </div>
      {/* Hide for now */}
      {false && (
        <Dialog
          title="Upload document"
          trigger={
            <div>
              <Button type="button" intent="primary" onClick={() => setModalOpen(true)} iconLeft={<PlusIcon />}>
                Upload Document
              </Button>
            </div>
          }
          open={isModalOpen}
          onOpenChange={setModalOpen}
        >
          <UploadDocument
            progress={progress}
            selectedTab={selectedTab}
            handleSubmit={handleSubmit}
            categories={categories}
            setModalOpen={setModalOpen}
            loading={false}
          />
        </Dialog>
      )}

      <AlertDialog
        title="Delete document"
        description="Are you sure you want to remove this document? This action cannot be undone."
        open={deleteDocumentModal}
        onOpenChange={setDeleteDocumentModal}
        cancelButton={
          <Button type="button" intent="secondary" onClick={() => setDeleteDocumentModal(false)}>
            Cancel
          </Button>
        }
        actionButton={
          <Button type="button" intent="danger" onClick={handleDeleteDocuments} loading={loading}>
            Delete
          </Button>
        }
      />
    </Layout>
  );
};

export default connector(withAuth(ClientDocuments));
