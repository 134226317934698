import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import Logo from 'components/Logo';
import NavItems from 'components/Navigation/NavItems';
import classes from './Sidebar.module.css';
import NameBadge from 'components/NameBadge';
import { getInitials } from 'utils/helpers';
import { getClientKeyFromPath } from 'utils/navigation';

interface Props {
  viewType: 'standard' | 'client';
}

const Sidebar: FC<Props> = ({ viewType }) => {
  const { isInSouthAfrica, userId } = useSelector((state: RootState) => state.auth);
  const { clients } = useSelector((state: RootState) => state.clients);

  const sidebarClass = viewType === 'client' ? `${classes.Sidebar} ${classes.ClientProfileSidebar}` : classes.Sidebar;

  const clientKey = viewType === 'client' ? getClientKeyFromPath(location.pathname) : '';

  const renderClientProfileNameSection = () => {
    if (viewType !== 'client') return null;

    const clientProfile = clients && clientKey ? clients[clientKey]?.profile : null;

    if (!clientProfile) return null;

    return (
      <div className={classes.ClientProfileNameSection}>
        <div className={classes.ImageDiv}>
          <NameBadge size={'small'} initials={getInitials(clientProfile.fullname || '')} />
        </div>
        <span className={classes.ClientProfileName}>{clientProfile.fullname}</span>
      </div>
    );
  };

  return (
    <div className={sidebarClass}>
      {viewType === 'standard' && <Logo dashboard />}
      {viewType === 'client' && renderClientProfileNameSection()}
      <nav>
        <NavItems isInSouthAfrica={isInSouthAfrica} userId={userId} viewType={viewType} clientKey={clientKey} />
      </nav>
    </div>
  );
};

export default Sidebar;
