import React, { FC, FunctionComponent, SVGProps } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavItem.module.css';
import clsx from 'clsx';

interface Props {
  SVGIcon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  link: string;
  viewType: 'standard' | 'client';
  styleType?: 'stroke' | 'fill';
}

const ITEM_CLASSES: Record<string, string> = {
  standard: classes.NavItem,
  client: clsx(classes.NavItem, classes.ClientProfileNavItem),
};

const LABEL_CLASSES: Record<string, string> = {
  standard: classes.LinkLabel,
  client: classes.ClientProfileLabel,
};

const ICON_CLASSES: Record<string, string> = {
  standard: classes.NavIcon,
  client: classes.ClientProfileIcon,
};

const NavItem: FC<Props> = ({ SVGIcon, link, children, viewType, styleType = 'fill' }) => {
  const itemClass = ITEM_CLASSES[viewType];
  const labelClass = LABEL_CLASSES[viewType];

  const svgClass = styleType === 'stroke' ? classes.SvgStroke : classes.SvgFill;
  const iconClass = clsx(ICON_CLASSES[viewType], svgClass);

  return (
    <li className={itemClass}>
      <NavLink to={link} activeClassName={classes.Active}>
        <div className={iconClass}>
          <SVGIcon className={iconClass} />
        </div>
        <span className={labelClass}>{children}</span>
      </NavLink>
    </li>
  );
};
export default NavItem;
