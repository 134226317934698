import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAIL,
  ADD_DOCUMENT_START,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAIL,
  UPDATE_DOCUMENT_START,
  UPDATE_DOCUMENT_SUCCESS,
  UPDATE_DOCUMENT_FAIL,
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_FAIL,
} from './actionTypes';
import { AppThunk } from '../index';
import axios from '../../axios-base';
import { FirebaseObject } from 'interfaces/utils';
import { DocumentsActionTypes } from 'interfaces/actions/documents';
import { Documents } from 'interfaces/db';
import { storage, firestore, database } from 'utils/firebase';
import { deleteDocumentFromClient } from './clients';

const fetchDocumentsStart = (): DocumentsActionTypes => {
  return {
    type: FETCH_DOCUMENTS_START,
  };
};

const fetchDocumentsSuccess = (documents: FirebaseObject<Documents>): DocumentsActionTypes => {
  return {
    type: FETCH_DOCUMENTS_SUCCESS,
    documents: documents,
  };
};

const fetchDocumentsFail = (): DocumentsActionTypes => {
  return {
    type: FETCH_DOCUMENTS_FAIL,
  };
};

export const fetchDocuments = (token: string, userId: string): AppThunk => {
  return (dispatch) => {
    dispatch(fetchDocumentsStart());
    const queryParams = `?auth=${token}&orderBy="userId"&equalTo="${userId}"`;

    axios
      .get('/documents.json' + queryParams)
      .then((response) => {
        dispatch(fetchDocumentsSuccess(response.data));
        return Promise.resolve();
      })
      .catch((e) => {
        dispatch(fetchDocumentsFail());
        return Promise.reject(e);
      });
  };
};

export const addDocumentStart = (): DocumentsActionTypes => {
  return {
    type: ADD_DOCUMENT_START,
  };
};

export const addDocumentSuccess = (document: Documents, documentId: string): DocumentsActionTypes => {
  return {
    type: ADD_DOCUMENT_SUCCESS,
    document: document,
    documentId: documentId,
  };
};

export const addDocumentFail = (): DocumentsActionTypes => {
  return {
    type: ADD_DOCUMENT_FAIL,
  };
};

export const addDocument = (token: string, document: Documents, userId?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(addDocumentStart());
    if (document && document?.category === 'Subscription App' && userId) {
      const coachesRef = firestore?.collection('coaches');
      const coachDocument = coachesRef?.doc(userId || 'test');
      const funnelPlansRef = coachDocument?.collection('funnel-plans');
      const doc = await funnelPlansRef.limit(1).get();

      await funnelPlansRef.doc(doc.docs[0]?.id).collection('documents').doc(document?.documentId).set(document);
    }
    axios
      .post('/documents.json?auth=' + token, document)
      .then((response) => {
        dispatch(addDocumentSuccess(document, response.data.name));
        return Promise.resolve(null);
      })
      .catch((e) => {
        dispatch(addDocumentFail());
        return Promise.reject(e);
      });
  };
};

export const updateDocumentsStart = (): DocumentsActionTypes => {
  return {
    type: UPDATE_DOCUMENT_START,
  };
};

export const updateDocumentsSuccess = (documentsMap: { [key: string]: Documents }): DocumentsActionTypes => {
  return {
    type: UPDATE_DOCUMENT_SUCCESS,
    documents: documentsMap,
  };
};

export const updateDocumentsFail = (): DocumentsActionTypes => {
  return {
    type: UPDATE_DOCUMENT_FAIL,
  };
};

export const updateDocuments = (token: string, documents: { [docKey: string]: Documents }): AppThunk => {
  return async (dispatch) => {
    if (!token || !documents) {
      return;
    }
    dispatch(updateDocumentsStart());

    const updatePromises = Object.entries(documents).map(([docKey, document]) =>
      database
        .ref(`/documents/${docKey}`)
        .update(document)
        .catch((e) => {
          console.log(e);
          dispatch(updateDocumentsFail());
        }),
    );

    try {
      await Promise.all(updatePromises);
      dispatch(updateDocumentsSuccess(documents));
      return Promise.resolve(null);
    } catch (e) {
      console.error('Error updating documents:', e);
      dispatch(updateDocumentsFail());
      return Promise.reject(e);
    }
  };
};

export const deleteDocuments = (
  urls: string[],
  token: string,
  userId: string,
  documents: FirebaseObject<Documents> | null,
  documentIds: string[],
): AppThunk => {
  return async (dispatch) => {
    if (!token || !userId || !documents || documentIds.length === 0) {
      return;
    }

    const storagePromises = urls.map((url) => storage.refFromURL(url).delete());
    const removeDocumentNodes = documentIds.map((docId) => axios.delete(`/documents/${docId}.json?auth=${token}`));

    await Promise.all([...storagePromises, ...removeDocumentNodes])
      .then(async () => {
        documentIds.forEach((docId) => {
          const documentToDelete = documents[docId];
          if (documentToDelete) {
            dispatch(deleteDocumentFromClient(documentToDelete, docId));
          }
        });
        await dispatch(fetchDocuments(token, userId));
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(new Error(err));
      });
  };
};

const fetchDocumentStart = (): DocumentsActionTypes => {
  return {
    type: FETCH_DOCUMENT_START,
  };
};

const fetchDocumentSuccess = (document: FirebaseObject<Documents>, documentKey: string): DocumentsActionTypes => {
  return {
    type: FETCH_DOCUMENT_SUCCESS,
    document: document,
    documentKey: documentKey,
  };
};

const fetchDocumentFail = (): DocumentsActionTypes => {
  return {
    type: FETCH_DOCUMENT_FAIL,
  };
};

export const fetchDocument = (token: string, documentKey: string): AppThunk => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchDocumentStart());
      axios
        .get(`/documents/${documentKey + ''}.json?auth=${token}`)
        .then((response) => {
          dispatch(fetchDocumentSuccess(response.data, documentKey));
          resolve(null);
        })
        .catch((e) => {
          dispatch(fetchDocumentFail());
          reject(e);
        });
    });
  };
};
