import React, { FC } from 'react';

import { getSetsLabel, getSetCountLabel } from 'utils/helpers';
import { Exercise as ExerciseI, SetKeys, SetObject } from 'interfaces/db';
import classes from './Exercise.module.css';
import clsx from 'clsx';

interface Props {
  exerciseBlock: string;
  exercise: ExerciseI[];
}

const Exercise: FC<Props> = ({ exercise, exerciseBlock }) => {
  const exerciseLength = exercise?.length || 0;
  return (
    <div className={classes.Exercise}>
      {exercise.map((exerciseType, index) => {
        const isSuperSet = exerciseLength && index !== exerciseLength - 1;
        const { sets = [] } = exerciseType;

        const { completed, ...types } = sets[0] || {};
        const typesArray = Object.keys(types || {});

        return (
          <div key={`exercise-${index}`}>
            <div className={clsx(classes.BlockAndTitle, !isSuperSet && classes.MarginBottom)}>
              <div className={classes.ExerciseBlock}>
                <span>{`${exerciseBlock}${index + 1}`}</span>
              </div>

              <div>
                <div className={classes.Title}>{exerciseType.type || 'No Exercise'}</div>

                <div className={classes.Summary}>
                  {getSetCountLabel(sets.length || 0)}
                  {typesArray.map((el) => {
                    const set = types[el as SetKeys];
                    if (!set) {
                      return null;
                    }

                    const programmedValue =
                      typeof set === 'string' && set === ''
                        ? '0'
                        : typeof set === 'string'
                        ? set
                        : (set as SetObject).programmed.length === 0
                        ? '0'
                        : (set as SetObject).programmed;

                    return getSetsLabel(el as SetKeys, programmedValue);
                  })}
                </div>
              </div>
            </div>

            {isSuperSet && (
              <div className={classes.SuperSetLineWrapper}>
                <div className={classes.SuperSetLine}></div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Exercise;
