import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { PROGRAMS } from 'utils/routes';
import classes from './SimpleClientCard.module.css';
import NameBadge from 'components/NameBadge';

interface SimpleClientCardProps {
  initials: string;
  fullname: string;
}

const SimpleClientCard: FC<SimpleClientCardProps> = ({ initials, fullname }) => {
  return (
    <div className={classes.ClientWrapper}>
      <NavLink
        to={{
          pathname: PROGRAMS.URL,
        }}
        exact
      >
        <div className={classes.ContentWrapper}>
          <NameBadge initials={initials} />

          <div className={classes.RightContainer}>
            <p className={classes.Name}>{fullname}</p>

            <span className={classes.Description}>Assign A Program</span>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default SimpleClientCard;
