import React, { FC, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import axios from 'axios';
import dayjs from 'dayjs';
import { firestore } from 'utils/firebase';

import classes from './Analytics.module.css';
import Layout from '../Layout';
import BasicSmallSpinner from 'components/UI/Spinners';
import AnalyticCard from 'components/AnalyticCard';
import ProgramPerformanceList from 'components/ProgramPerformanceList';
import withAuth from 'utils/withAuth';
import { RootState } from 'store';
import { ANALYTICS } from 'utils/routes';
import { BASE_URL, isEligibleCoach } from 'utils/helpers';
import { fetchClients } from 'store/actions/clients';
import { AnalyticsProgramData } from 'interfaces/api';
// import PerformanceList from '../../components/PerformanceList/PerformanceList';
// import PerformanceGraphComponent from '../../components/PerformanceGraphComponent/PerformanceGraphComponent';

const mapStateToProps = ({ clients, auth }: RootState) => {
  return {
    token: auth.token,
    userId: auth.userId,
    clients: clients.clients,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onFetchClients: (userId: string) => dispatch(fetchClients(userId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & RouteComponentProps;

type Series = {
  [key: string]: { all: string | number };
};

type Mixpanel = {
  series: Series;
  date_range: {
    from_date: Date | string;
    to_date: Date | string;
  };
};

interface IAnalytics {
  internal: {
    subscription_count: number;
    revenue: number;
    trial_subscribers_count: number;
  };
  mixpanel: Mixpanel;
}

const Analytics: FC<Props> = ({ token, userId, onFetchClients }) => {
  const history = useHistory();
  const [planCode, setPlanCode] = useState<string>('');

  const [funnelLoading, setFunnelLoading] = useState<boolean>(false);
  const [funnelAnalytics, setFunnelAnalytics] = useState<IAnalytics>();

  const [programLoading, setProgramLoading] = useState<boolean>(false);
  const [programAnalytics, setProgramAnalytics] = useState<AnalyticsProgramData[]>([]);

  const funnelPlansRef = firestore
    .collection('coaches')
    .doc(userId || 'userId')
    .collection('funnel-plans');

  useEffect(() => {
    const initAnalytics = async () => {
      setFunnelLoading(true);
      setProgramLoading(true);

      const fetchPlanCode = () => {
        return funnelPlansRef.limit(1).get();
      };

      const planCodeDoc = await fetchPlanCode();
      const planCode = planCodeDoc.docs[0]?.id;
      setPlanCode(planCode);
    };

    initAnalytics();
  }, []);

  useEffect(() => {
    if (!planCode) return;
    const getFunnelAnalytics = async () => {
      const fetchPaystackPlanCode = async () => {
        const marketplacePlansRef = firestore
          .collection('coaches')
          .doc(userId || '')
          .collection('marketplace-plans');

        const response = await marketplacePlansRef.get();
        const doc = response?.docs[0];
        return doc?.exists ? doc.id : null;
      };

      const fetchPriceId = (planCode: string) => {
        return funnelPlansRef.doc(planCode)?.collection('prices').limit(1).get();
      };

      const fetchFunnelAnalytics = async () => {
        const pricesDoc = await fetchPriceId(planCode);
        const paystackPlanCode = await fetchPaystackPlanCode();

        return axios.get(`${BASE_URL}/v1-https-funnels-getFunnelAnalytics`, {
          params: {
            userId: userId,
            planCode: planCode,
            priceId: pricesDoc.docs[0]?.id,
            paystackPlanCode: paystackPlanCode || undefined,
          },
        });
      };

      fetchFunnelAnalytics().then((response: any) => {
        const { subscription_count, mixpanel, revenue, trial_subscribers_count } = response.data?.data;
        setFunnelAnalytics({
          internal: {
            subscription_count: subscription_count,
            revenue: revenue,
            trial_subscribers_count: trial_subscribers_count,
          },
          mixpanel: mixpanel as Mixpanel,
        });
        setFunnelLoading(false);
      });
    };
    getFunnelAnalytics();
  }, [planCode]);

  useEffect(() => {
    if (!planCode) return;

    const getProgramAnalytics = async () => {
      const fetchProgramAnalytics = async () => {
        return axios.get(`${BASE_URL}/v1-https-funnels-getProgramAnalytics`, {
          params: {
            coachUserId: userId,
            planCode: planCode,
          },
        });
      };

      fetchProgramAnalytics()
        .then((response: any) => {
          setProgramAnalytics(response.data?.data as AnalyticsProgramData[]);
        })
        .finally(() => setProgramLoading(false));
    };
    getProgramAnalytics();
  }, [planCode]);

  useEffect(() => {
    if (token && userId) {
      if (!isEligibleCoach(userId, true)) history.push('/clients');
      else onFetchClients(userId);
    }
  }, [token, userId, onFetchClients]);

  const getMixpanelMappedData = (key: string) =>
    funnelLoading ? (
      <BasicSmallSpinner />
    ) : !funnelAnalytics ? (
      'No data'
    ) : (
      funnelAnalytics?.mixpanel?.series?.[key]?.all
    );

  const getDateRange = () => {
    if (funnelLoading) {
      return <BasicSmallSpinner />;
    } else if (!funnelAnalytics?.mixpanel?.date_range) {
      return 'No Data';
    }
    const fromDate = dayjs(funnelAnalytics.mixpanel?.date_range?.from_date).format('DD MMMM');
    const toDate = dayjs(funnelAnalytics.mixpanel?.date_range?.to_date).format('DD MMMM');
    return fromDate + ' - ' + toDate;
  };

  return (
    <Layout loading={false} heading={ANALYTICS.TITLE}>
      <div className={classes.Header}>
        <div className={classes.DateRange}>
          <span>{getDateRange()}</span>
        </div>
      </div>
      <div className={classes.Wrapper}>
        <div className={classes.LeftStatsContainer}>
          <div className={classes.WebAnalyticsTitle}>
            <h2>Performance analytics</h2>
          </div>
          <div className={classes.TopLevelStatsWrapper}>
            <AnalyticCard
              label="Active Subscribers"
              value={
                funnelLoading ? (
                  <BasicSmallSpinner />
                ) : !funnelAnalytics ? (
                  'No data'
                ) : (
                  funnelAnalytics?.internal?.subscription_count || 0
                )
              }
            />

            <AnalyticCard
              label="Trial Subscribers"
              value={
                funnelLoading ? (
                  <BasicSmallSpinner />
                ) : !funnelAnalytics ? (
                  'No data'
                ) : (
                  funnelAnalytics?.internal?.trial_subscribers_count || 0
                )
              }
            />

            <AnalyticCard
              label="Non-Trial Subscribers"
              value={
                funnelLoading ? (
                  <BasicSmallSpinner />
                ) : !funnelAnalytics ? (
                  'No data'
                ) : (
                  (funnelAnalytics?.internal?.subscription_count || 0) -
                  (funnelAnalytics?.internal?.trial_subscribers_count || 0)
                )
              }
            />
          </div>
          {/* <PerformanceGraphComponent graphTitle={"ACTIVE SUBSCRIBERS"}></PerformanceGraphComponent> */}
          <div className={classes.WebAnalyticsContainer}>
            <div className={classes.WebAnalyticsTitle}>
              <h2>Website analytics</h2>
            </div>
            <div className={classes.WebAnalyticsWrapper}>
              <AnalyticCard label="Page Visits" value={getMixpanelMappedData('A. Page Visit - Unique')} />
              <AnalyticCard
                label="Subscribe Button Click"
                value={getMixpanelMappedData('B. Subscribe Button Click - Unique')}
              />
              <AnalyticCard
                label="Checkout Button Click"
                value={getMixpanelMappedData('C. Checkout Button Click - Unique')}
              />
              {/* <AnalyticCard
                label="CHECKOUT SUCCESS"
                value={getMixpanelMappedData('D. Checkout Success - Unique')}
              /> */}
            </div>
          </div>
        </div>

        <div className={classes.RightStatsContainer}>
          <ProgramPerformanceList programAnalyticsData={programAnalytics} loading={programLoading} />
        </div>
      </div>
    </Layout>
  );
};

export default connector(withAuth(Analytics));
