import React, { ChangeEvent, FC } from 'react';
import { FieldError, Merge, UseFormRegister } from 'react-hook-form';
import classes from './Input.module.css';
import clsx from 'clsx';
import { SVGIconProps } from 'interfaces/ui';

interface InputProps {
  register?: UseFormRegister<any>;
  onChange?: ({ target }: ChangeEvent<HTMLInputElement>) => void;
  error?: Merge<FieldError, (FieldError | undefined)[]> | string | undefined;
  name: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'tel';
  placeholder: string;
  label?: string;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  iconLeft?: React.ReactElement<SVGIconProps> | null;
  iconRight?: React.ReactElement<SVGIconProps> | null;
  defaultValue?: string | number;
  valueAsNumber?: boolean;
  onFocus?: (name: string) => void;
  showError?: boolean;
  validationRules?: any;
  min?: number;
  max?: number;
}

const Input: FC<InputProps> = ({
  register,
  onChange,
  error,
  name,
  placeholder,
  label,
  className,
  type = 'text',
  defaultValue = '',
  readOnly = false,
  required = true,
  iconLeft = null,
  iconRight = null,
  valueAsNumber = false,
  onFocus,
  showError = true,
  validationRules,
  min,
  max,
}) => {
  const inputClasses = clsx(
    classes.Input,
    className,
    iconLeft && classes.WithIconLeft,
    iconRight && classes.WithIconRight,
    error && classes.ErrorInput,
  );

  let inputAttributes = {
    className: inputClasses,
    placeholder,
    type,
    readOnly,
  };

  if (register) {
    inputAttributes = {
      ...inputAttributes,
      ...register(name, { required, onChange, valueAsNumber, ...validationRules }),
    };
  }

  const errorMessage = typeof error === 'string' ? error : error?.message || 'This field is required';
  return (
    <fieldset className={classes.InputContainer}>
      {label && (
        <label htmlFor={name} className={classes.Label} defaultValue={defaultValue}>
          {label}
        </label>
      )}
      <div className={classes.RelativeWrapper}>
        {iconLeft && <span className={classes.LeftIcon}>{iconLeft}</span>}
        <input {...inputAttributes} onFocus={(event) => onFocus && onFocus(event.target.name)} min={min} max={max} />
        {iconRight && <span className={classes.RightIcon}>{iconRight}</span>}
      </div>
      {error && showError && <span className={classes.Error}>{errorMessage}</span>}
    </fieldset>
  );
};

export default Input;
