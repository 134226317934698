import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { object, string } from 'yup';

import { CoachProfile } from 'interfaces/db';
import { FormProps } from 'interfaces/utils';
import classes from './UpdateProfile.module.css';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';

type FormValues = Pick<CoachProfile, 'fullname' | 'birthday' | 'email'>;
type InitialValues = FormValues & Pick<CoachProfile, 'code' | 'email'>;

const UpdateProfile: FC<FormProps<FormValues> & { initialValues: InitialValues }> = ({
  handleSubmit,
  initialValues,
}) => {
  // const validationSchema = object().shape({
  //   fullname: string()
  //     .test('fullname-test', 'Fill first and last name', (value = '') => {
  //       const [firstName, lastName] = value ? value.trim().split(' ') : [];
  //       return !!(firstName && lastName);
  //     })
  //     .required('Full name is a required field'),
  //   birthday: string()
  //     .matches(
  //       /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/,
  //       {
  //         message: 'Must be in DD/MM/YYYY format',
  //         excludeEmptyString: false,
  //       },
  //     )
  //     .required(),
  //   email: string().email().required(),
  //   code: string().required(),
  // });

  const {
    register,
    handleSubmit: handleHookFormSubmit,
    formState: { errors },
  } = useForm<InitialValues>({
    defaultValues: initialValues,
    // resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: InitialValues) => {
    await handleSubmit({ fullname: data.fullname, birthday: data.birthday, email: data.email });
  };

  return (
    <form onSubmit={handleHookFormSubmit(onSubmit)}>
      <div>
        <div className={classes.Row}>
          <div className={classes.InputWrapper}>
            <Input
              type="text"
              name="fullname"
              placeholder="Enter full name"
              register={register}
              error={errors.fullname}
              label="Name"
            />
          </div>

          <div className={classes.InputWrapper}>
            <Input
              type="text"
              name="email"
              placeholder="Enter email address"
              register={register}
              error={errors.email}
              label="Email address"
            />
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.InputWrapper}>
            <Input
              type="text"
              name="code"
              placeholder="Enter coach code"
              register={register}
              error={errors.code}
              label="Coach code"
              readOnly
            />
          </div>
        </div>
      </div>
      <div className={classes.Button}>
        <Button type="submit">Save</Button>
      </div>
    </form>
  );
};

export default UpdateProfile;
