import { NutritionDay, NutritionPlan } from 'interfaces/nutrition';
import { generateWeekId } from './generateWeekId';
import { insertElementAt } from 'utils/helpers';

export const addWeek = (plan: NutritionPlan, setPlan) => {
  if (!plan) {
    return;
  }

  const { weeks = [] } = plan;

  const newWeekIndex = weeks.length;
  const newWeekId = generateWeekId(plan.planId);

  const newWeeks = {
    id: newWeekId,
    isNewWeek: true,
    days: Array.from({ length: 7 }).map(
      (_, dayIndex) =>
        ({
          finished: false,
          dayIndex,
          title: 'Nutrition Day Title',
        } as NutritionDay),
    ),
  };

  const updatedPlan = {
    ...plan,
    weeks: [...weeks, newWeeks],
    weekOrder: insertElementAt(plan.weekOrder, newWeekIndex, newWeekId),
  };

  setPlan(updatedPlan);
};
