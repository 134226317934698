import React, { FC } from 'react';
import classes from './NameBadge.module.css';
import clsx from 'clsx';

interface Props {
  initials: string;
  size?: 'xsmall' | 'small' | 'large';
}

const NameBadge: FC<Props> = ({ initials, size = 'large' }) => {
  const sizeClass = size === 'small' ? classes.Small : size === 'xsmall' ? classes.XSmall : classes.Large;
  return (
    <div className={clsx(classes.Container, sizeClass)}>
      <span>{initials}</span>
    </div>
  );
};

export default NameBadge;
