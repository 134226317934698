import { useState } from 'react';

const useOpenSelect = () => {
  const [openSelect, setOpenSelect] = useState<string | null>(null);
  const handleSelectOpenChange = (name: string, isOpen: boolean) => {
    if (isOpen && name !== 'remove_option') {
      setOpenSelect(name);
    } else if (openSelect === name) {
      setOpenSelect(null);
    }
  };

  return { openSelect, handleSelectOpenChange };
};

export default useOpenSelect;
