import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { functions } from 'utils/firebase';

import { emoji_strip } from 'utils/helpers';
import { FormProps } from 'interfaces/utils';
import classes from './CoachCode.module.css';
import Input from 'components/UI/Input';
import { useYupValidationResolver } from 'hooks';
import ModalButtonGroup from 'components/ModalButtonGroup';

type FormData = {
  code: string;
};

const validationSchema = object().shape({
  code: string().min(4, 'Code must be at least 4 characters.').required('Code is required.'),
});

const CoachCode: FC<FormProps<string>> = ({ handleSubmit }) => {
  const [loading, setLoading] = useState(false);
  const checkCode = functions.httpsCallable('v1-https-checkUniqueCoachCode');
  const {
    register,
    handleSubmit: handleFormSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: useYupValidationResolver(validationSchema),
  });

  const codeTransform = (code: string) => {
    return emoji_strip(code.toLowerCase().replace(/\s/g, ''));
  };

  const onSubmit = async (data: FormData) => {
    if (data.code) {
      try {
        setLoading(true);
        const result = await checkCode({ code: data.code });
        if (result.data) {
          handleSubmit(codeTransform(data.code));
        } else {
          setValue('code', ''); // Clear the input field
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        // Handle error
      }
    }
  };

  const onChange = (value: string) => {
    const manipulatedValue = codeTransform(value);
    setValue('code', manipulatedValue);
  };

  return (
    <form onSubmit={handleFormSubmit(onSubmit)} className={classes.Form}>
      <div className={classes.Content}>
        <Input
          type="text"
          name="code"
          label="Coach code"
          placeholder="Enter Your Coach Code"
          register={register}
          onChange={(event: any) => {
            onChange(event.target.value);
          }}
          error={errors.code}
        />

        <ModalButtonGroup
          mainButtonType="submit"
          mainButtonText="Save Code"
          showCancelButton={false}
          loading={loading}
        />
      </div>
    </form>
  );
};

export default CoachCode;
