import React, { FC, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import classes from './CompareProgressPhotos.module.css';
import ModalButtonGroup from 'components/ModalButtonGroup';
import Select from 'components/UI/Select';
import { ReactComponent as ImageIcon } from 'assets/svgs/image-filled.svg';

interface Props {
  leftSelectedEntry: any;
  selectedLeftImageCategory: string;
  leftDate: string;
  setLeftDate: (date: string) => void;
  setSelectedLeftImageCategory: (category: string) => void;
  rightSelectedEntry: any;
  selectedRightImageCategory: string;
  rightDate: string;
  setRightDate: (date: string) => void;
  setSelectedRightImageCategory: (category: string) => void;
  data: any;
  setIsModalVisible: (isVisible: boolean) => void;
}

const CompareProgressPhotos: FC<Props> = ({
  leftSelectedEntry,
  selectedLeftImageCategory,
  leftDate,
  setLeftDate,
  setSelectedLeftImageCategory,
  rightSelectedEntry,
  selectedRightImageCategory,
  rightDate,
  setRightDate,
  setSelectedRightImageCategory,
  data,
  setIsModalVisible,
}) => {
  const { control, setValue } = useForm<any>({
    defaultValues: {
      leftDateVal: dayjs(leftDate).format('DD MMMM YYYY'),
      rightDateVal: dayjs(rightDate).format('DD MMMM YYYY'),
      leftCategory: selectedLeftImageCategory,
      rightCategory: selectedRightImageCategory,
    },
  });
  const [openSelect, setOpenSelect] = useState<string | null>(null);
  const handleSelectOpenChange = (name: string, isOpen: boolean) => {
    if (isOpen) {
      setOpenSelect(name);
    } else if (openSelect === name) {
      setOpenSelect(null);
    }
  };

  const dateOptions = useCallback(() => {
    return Object.keys(data || {}).map((key) => {
      return {
        label: dayjs(data[key]?.date).format('DD MMMM YYYY'),
        value: dayjs(data[key]?.date).format('DD MMMM YYYY'),
      };
    });
  }, [data]);

  const rightCategoryOptions = useCallback(() => {
    return Object.keys(rightSelectedEntry?.photos || {}).map((key) => {
      const photoCategory = rightSelectedEntry?.photos[key];
      return {
        label: photoCategory?.categoryName,
        value: key,
      };
    });
  }, [rightSelectedEntry?.photos]);

  const leftCategoryOptions = useCallback(() => {
    return Object.keys(leftSelectedEntry?.photos || {}).map((key) => {
      const photoCategory = leftSelectedEntry?.photos[key];
      return {
        label: photoCategory?.categoryName,
        value: key,
      };
    });
  }, [leftSelectedEntry?.photos]);

  return (
    <>
      <div className={classes.CompareContainer}>
        <div className={classes.LeftProgressContainer}>
          <div
            className={
              leftSelectedEntry?.photos?.[selectedLeftImageCategory]?.image
                ? classes.ImageContainerModal
                : classes.NoImageContainerModal
            }
          >
            {leftSelectedEntry?.photos?.[selectedLeftImageCategory]?.image ? (
              <img alt="left progress photo" src={leftSelectedEntry?.photos?.[selectedLeftImageCategory]?.image} />
            ) : (
              <ImageIcon />
            )}
          </div>
          <div className={classes.InputsWrapper}>
            {leftDate && (
              <Select
                name="leftDate"
                label="Left date"
                placeholder="Select date for the left image"
                control={control}
                onOpenChange={(isOpen) => handleSelectOpenChange('leftDate', isOpen)}
                open={openSelect === 'leftDate'}
                optionArray={dateOptions()}
                required
                onChange={(value: any) => {
                  setLeftDate(value);
                  setValue('leftDate', value);
                }}
                defaultValue={dayjs(leftDate).format('DD MMMM YYYY')}
                value={dayjs(leftDate).format('DD MMMM YYYY')}
              />
            )}
            {leftSelectedEntry && (
              <Select
                name="leftCategory"
                label="Type / Image / Pose"
                placeholder="Select category for the left image"
                control={control}
                onOpenChange={(isOpen) => handleSelectOpenChange('leftCategory', isOpen)}
                open={openSelect === 'leftCategory'}
                optionArray={leftCategoryOptions()}
                required
                onChange={(value: any) => {
                  setSelectedLeftImageCategory(value);
                  setValue('leftCategory', value);
                }}
                defaultValue={selectedLeftImageCategory}
                value={selectedLeftImageCategory}
              />
            )}
          </div>
        </div>
        <div className={classes.RightProgressContainer}>
          <div
            className={
              rightSelectedEntry?.photos?.[selectedRightImageCategory]?.image
                ? classes.ImageContainerModal
                : classes.NoImageContainerModal
            }
          >
            {rightSelectedEntry?.photos?.[selectedRightImageCategory]?.image ? (
              <img alt="right progress photo" src={rightSelectedEntry?.photos?.[selectedRightImageCategory]?.image} />
            ) : (
              <ImageIcon />
            )}
          </div>
          <div className={classes.InputsWrapper}>
            {rightDate && (
              <Select
                name="rightDate"
                label="Right date"
                placeholder="Select date for the right image"
                control={control}
                onOpenChange={(isOpen) => handleSelectOpenChange('rightDate', isOpen)}
                open={openSelect === 'rightDate'}
                optionArray={dateOptions()}
                required
                onChange={(value: any) => {
                  setRightDate(value);
                  setValue('rightDate', value);
                }}
                defaultValue={dayjs(rightDate).format('DD MMMM YYYY')}
                value={dayjs(rightDate).format('DD MMMM YYYY')}
              />
            )}
            {rightSelectedEntry && (
              <Select
                name="rightCategory"
                label="Type / Image / Pose"
                placeholder="Select category for the right image"
                control={control}
                onOpenChange={(isOpen) => handleSelectOpenChange('rightCategory', isOpen)}
                open={openSelect === 'rightCategory'}
                optionArray={rightCategoryOptions()}
                required
                onChange={(value: any) => {
                  setSelectedRightImageCategory(value);
                  setValue('rightCategory', value);
                }}
                defaultValue={selectedRightImageCategory}
                value={selectedRightImageCategory}
              />
            )}
          </div>
        </div>
      </div>
      <ModalButtonGroup
        showCancelButton={false}
        mainButtonText="Close"
        onMainButtonClick={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default CompareProgressPhotos;
