import React, { FC } from 'react';
import classes from './ExerciseGroup.module.css';

interface Props {
  group: number;
  index: number;
}

const ExerciseGroup: FC<Props> = ({ group, index }) => {
  const char = String.fromCharCode(65 + group);

  return (
    <div className={classes.Container}>
      <span>{char + index}</span>
    </div>
  );
};

export default ExerciseGroup;
