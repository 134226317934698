import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import classes from './Checkbox.module.css';
import BaseCheckBox from '.';

interface Props {
  name: string;
  control: any;
  label: string;
  onCheckedChange?: (value: boolean) => void;
}

const Checkbox: FC<Props> = ({ name, control, label, onCheckedChange }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <BaseCheckBox
          label={<span className={classes.CheckboxLabel}>{label}</span>}
          onCheckedChange={(checked) => {
            onChange(checked);
            if (onCheckedChange) {
              onCheckedChange(checked);
            }
          }}
          checked={value}
          name={name}
        />
      )}
    />
  );
};

export default Checkbox;
