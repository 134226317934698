import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { productFruits } from 'product-fruits';

import {
  Login,
  Clients,
  Account,
  Programs,
  Calendar,
  Register,
  // Settings,
  NotFound,
  Documents,
  Dashboard,
  ClientProfile,
  ClientTraining,
  WorkoutBuilder,
  ForgotPassword,
  ExerciseLibrary,
  ClientDocument,
  Community,
  Checkout,
  SubscriptionFunnel,
  PostPayment,
  // ClientBillingPostPayment,
  ClientBillingCheckout,
  Progress,
  Analytics,
  Payments,
} from './containers';
import * as ROUTES from './utils/routes';
import './App.css';
import { RootState } from 'store';
import { fetchThemePreference } from 'store/actions/settings';
import Nutrition from 'containers/Nutrition';

const App: FC = () => {
  const { theme = 'light' } = useSelector((state: RootState) => state.settings);
  const { userId, coachProfile } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchThemePreference(userId));
  }, [dispatch]);

  useEffect(() => {
    // Convert the theme object into a CSS string
    const cssVariables = Object.entries(theme)
      .map(([key, value]) => `${key}:${value}`)
      .join(';');

    // Create a <style> element and inject it into the <head> of the document
    let styleEl = document.getElementById('themeStyles');
    if (!styleEl) {
      styleEl = document.createElement('style');
      styleEl.id = 'themeStyles';
      document.head.appendChild(styleEl);
    }

    // Inject CSS variables into the style element
    styleEl.innerText = `:root { ${cssVariables} }`;
  }, [theme]);

  const coachProfileData = Object.values(coachProfile || {})?.[0];
  const userInfo = coachProfileData
    ? { username: coachProfileData.email, email: coachProfileData.email }
    : { username: 'test@gmail.com', email: '' };

  useEffect(() => {
    productFruits.init(process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE || '', 'en', userInfo);
  }, []);

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={ROUTES.LOGIN.URL} />
      </Route>
      <Route path={`${ROUTES.CLIENTS_PROGRESS.URL}/:clientKey`} component={Progress} />
      {/* <Route path={ROUTES.POSTPAYMENT.URL} component={PostPayment} /> */}
      <Route path={`${ROUTES.CLIENT_BILLING.URL}${ROUTES.POSTPAYMENT.URL}`} component={PostPayment} exact />
      <Route path={ROUTES.CHECKOUT.URL} component={Checkout} />
      <Route path={ROUTES.LOGIN.URL} component={Login} exact />
      <Route path={ROUTES.ACCOUNT.URL} component={Account} />
      <Route path={ROUTES.PAYEMENTS.URL} component={Payments} />
      <Route path={ROUTES.LOGIN.URL} component={Login} exact />
      <Route path={ROUTES.DASHBOARD.URL} component={Dashboard} />
      <Route path={ROUTES.REGISTER.URL} component={Register} exact />
      {/* <Route path={ROUTES.BILLING.URL} component={Settings} /> */}
      <Route path={ROUTES.CLIENTS.URL} component={Clients} exact />
      <Route path={ROUTES.DOCUMENTS.URL} component={Documents} exact />
      <Route path={ROUTES.ANALYTICS.URL} component={Analytics} exact />
      <Route path={`${ROUTES.CLIENTS_DOCUMENTS.URL}/:clientKey`} component={ClientDocument} exact />
      <Route path={ROUTES.RESET_PASSWORD.URL} component={ForgotPassword} exact />
      <Route path={ROUTES.EXERCISE_LIBRARY.URL} component={ExerciseLibrary} exact />
      <Route
        path={`${ROUTES.CLIENTS_WORKOUT_BUILDER.URL}/:clientKey/workout-builder/:assignedProgramKey/:weekIndex/:dayIndex`}
        component={WorkoutBuilder}
        exact
      />
      <Route path={`${ROUTES.CLIENTS_TRAINING.URL}/:clientKey`} component={ClientTraining} />
      <Route
        component={WorkoutBuilder}
        path={`${ROUTES.COACH_WORKOUT_BUILDER.URL}/:assignedProgramKey/:weekIndex/:dayIndex`}
        exact
      />
      <Route path={`${ROUTES.CLIENT_PROFILE.URL}/:clientKey`} component={ClientProfile} />
      <Route path={ROUTES.PROGRAMS.URL} component={Programs} exact />
      <Route
        component={WorkoutBuilder}
        path={`${ROUTES.PROGRAM_WORKOUT_BUILDER.URL}/:programKey/:weekIndex/:dayIndex`}
        exact
      />
      <Route path={`${ROUTES.CALENDAR.URL}/:programKey`} component={Calendar} />
      <Route path={ROUTES.CHAT.URL} component={Community} />
      <Route
        path={`${ROUTES.CLIENT_BILLING.URL}/:funnelId${ROUTES.CHECKOUT.URL}`}
        component={ClientBillingCheckout}
        exact
      />
      <Route path={`${ROUTES.CLIENTS_NUTRITION.URL}/:clientKey`} component={Nutrition} exact />
      <Route path={`/:funnelId${ROUTES.CHECKOUT.URL}`} component={Checkout} exact />
      <Route path={`/:funnelId`} component={SubscriptionFunnel} exact />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(App);
