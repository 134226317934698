import {
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_SUCCESS,
  FETCH_PROGRAMS_FAIL,
  INITIALISE_PROGRAM_SUCCESS,
  INITIALISE_PROGRAM_FAIL,
  INITIALISE_PROGRAM_START,
  REMOVE_PROGRAM_SUCCESS,
  REMOVE_PROGRAM_FAIL,
  REMOVE_PROGRAM_START,
  ADD_WEEK,
  REMOVE_WEEK,
  PUT_PROGRAM_SUCCESS,
  PUT_PROGRAM_FAIL,
  PUT_PROGRAM_START,
  SET_REST_DAY,
  REMOVE_REST_DAY,
  COPY_WORKOUT,
  PASTE_WORKOUT,
  DUPLICATE_WEEK,
  GO_BACK_PROGRAM,
  FETCH_PROGRAM_FAIL,
  FETCH_PROGRAM_START,
  FETCH_PROGRAM_SUCCESS,
  SAVE_WORKOUT_SUCCESS,
  SAVE_WORKOUT_FAIL,
  SAVE_WORKOUT_START,
} from '../actions/actionTypes';
import { AppThunk } from '../index';
import { FirebaseObject } from 'interfaces/utils';
import { Week, Programs, Workout } from 'interfaces/db';
import { ProgramsActionTypes } from 'interfaces/actions/programs';
import { firestore } from 'utils/firebase';
import { AxiosResponse } from 'axios';
import {
  createProgram,
  createWorkout,
  fetchProgramById,
  removeProgramById,
  updateProgram,
  updateWorkout,
} from 'utils/api';
import { findWorkoutById } from 'utils/helpers';

const fetchProgramsStart = (): ProgramsActionTypes => {
  return {
    type: FETCH_PROGRAMS_START,
  };
};

const fetchProgramsSuccess = (programs: FirebaseObject<Programs>): ProgramsActionTypes => {
  return {
    type: FETCH_PROGRAMS_SUCCESS,
    programs,
  };
};

const fetchProgramFail = (): ProgramsActionTypes => {
  return {
    type: FETCH_PROGRAM_FAIL,
  };
};

const fetchProgramStart = (): ProgramsActionTypes => {
  return {
    type: FETCH_PROGRAM_START,
  };
};

const fetchProgramSuccess = (programId: string, program: Programs): ProgramsActionTypes => {
  return {
    type: FETCH_PROGRAM_SUCCESS,
    programId,
    program,
  };
};

const fetchProgramsFail = (): ProgramsActionTypes => {
  return {
    type: FETCH_PROGRAMS_FAIL,
  };
};

export const initialiseProgramSuccess = (program: Programs, programKey: string): ProgramsActionTypes => {
  return {
    type: INITIALISE_PROGRAM_SUCCESS,
    name: programKey,
    program,
  };
};

export const initialiseProgramFail = (): ProgramsActionTypes => {
  return {
    type: INITIALISE_PROGRAM_FAIL,
  };
};

export const initialiseProgramStart = (): ProgramsActionTypes => {
  return {
    type: INITIALISE_PROGRAM_START,
  };
};

const removeProgramSuccess = (programKeys: string | string[]): ProgramsActionTypes => {
  return {
    type: REMOVE_PROGRAM_SUCCESS,
    programKeys,
  };
};

const removeProgramFail = (): ProgramsActionTypes => {
  return {
    type: REMOVE_PROGRAM_FAIL,
  };
};

const removeProgramStart = (): ProgramsActionTypes => {
  return {
    type: REMOVE_PROGRAM_START,
  };
};

export const addWeek = (week: Week, key: string): ProgramsActionTypes => {
  return {
    week,
    key,
    type: ADD_WEEK,
  };
};

export const removeWeek = (weekIndex: number): ProgramsActionTypes => {
  return {
    type: REMOVE_WEEK,
    weekIndex,
  };
};

export const putProgramSuccess = (programKey: string, program: Programs): ProgramsActionTypes => {
  return {
    type: PUT_PROGRAM_SUCCESS,
    programKey,
    program,
  };
};

const putProgramFail = (): ProgramsActionTypes => {
  return {
    type: PUT_PROGRAM_FAIL,
  };
};

export const putProgramStart = (): ProgramsActionTypes => {
  return {
    type: PUT_PROGRAM_START,
  };
};

export const setRestDay = (weekIndex: number, dayIndex: number, programIndex: number): ProgramsActionTypes => {
  return {
    type: SET_REST_DAY,
    weekIndex,
    dayIndex,
    programIndex,
  };
};

export const removeRestDay = (weekIndex: number, dayIndex: number, programIndex: number): ProgramsActionTypes => {
  return {
    type: REMOVE_REST_DAY,
    weekIndex,
    dayIndex,
    programIndex,
  };
};

export const copyWorkout = (weekIndex: number, dayIndex: number, programIndex: number): ProgramsActionTypes => {
  return {
    type: COPY_WORKOUT,
    weekIndex,
    dayIndex,
    programIndex,
  };
};

export const pasteWorkout = (weekIndex: number, dayIndex: number, programIndex: number): ProgramsActionTypes => {
  return {
    type: PASTE_WORKOUT,
    weekIndex,
    dayIndex,
    programIndex,
  };
};

export const duplicateWeek = (weekIndex: number): ProgramsActionTypes => {
  return {
    type: DUPLICATE_WEEK,
    weekIndex,
  };
};

export const goBackWorkout = (): ProgramsActionTypes => {
  return {
    type: GO_BACK_PROGRAM,
  };
};

export const fetchPrograms = (userId: string): AppThunk => {
  return async (dispatch) => {
    dispatch(fetchProgramsStart());

    try {
      const programSnapshots = await firestore.collection('programs').where('coachId', '==', userId).get();

      const programs: { [programId: string]: Programs } = {};

      programSnapshots.forEach((doc) => {
        programs[doc.id] = doc.data() as Programs;
      });

      dispatch(fetchProgramsSuccess(programs));
    } catch (error) {
      dispatch(fetchProgramsFail());
    }
  };
};

export const fetchProgram = (programId: string): AppThunk => {
  return async (dispatch) => {
    dispatch(fetchProgramStart());
    try {
      const response = await fetchProgramById(programId);
      dispatch(fetchProgramSuccess(programId, response.data.data?.program as Programs));
    } catch (error) {
      console.log(error);
      dispatch(fetchProgramFail());
    }
  };
};

export const initialiseProgram = (program: Programs, token: string): AppThunk => {
  return async (dispatch) => {
    dispatch(initialiseProgramStart());

    await createProgram(token, program)
      .then((response: AxiosResponse) => {
        const newProgram = response.data.data as Programs;
        if (newProgram.programId) {
          dispatch(initialiseProgramSuccess(newProgram, newProgram.programId));
        } else {
          dispatch(initialiseProgramFail());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(initialiseProgramFail());
      });
  };
};

export const removeProgram = (
  programIds: string | string[],
  token: string,
  setShowRemoveProgram: React.Dispatch<React.SetStateAction<boolean>>,
): AppThunk => {
  return async (dispatch) => {
    dispatch(removeProgramStart());

    const ids = Array.isArray(programIds) ? programIds : [programIds];

    try {
      for (const programId of ids) {
        await removeProgramById(token, programId);
      }
      dispatch(removeProgramSuccess(ids));
      setShowRemoveProgram(false);
    } catch (error) {
      console.log(error);
      dispatch(removeProgramFail());
    }
  };
};

export const putProgram = (programId: string, program: Programs, token: string, workoutId?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(putProgramStart());
    const workout = workoutId ? findWorkoutById(program, workoutId) : null;

    let updatePromise;
    if (workoutId && workout) {
      updatePromise = updateWorkout(token, workoutId, workout);
    } else {
      const { isWorkoutDataPopulated, ...programData } = program;
      updatePromise = updateProgram(token, programId, programData);
    }

    return updatePromise
      .then((response: AxiosResponse) => {
        const updatedProgram = !workoutId ? (response.data.data as Programs) : program;
        dispatch(putProgramSuccess(programId, { ...updatedProgram, isWorkoutDataPopulated: true }));
        return Promise.resolve(updatedProgram);
      })
      .catch((error) => {
        console.log(error);
        dispatch(putProgramFail());
        return Promise.reject(error);
      });
  };
};

export const saveWorkoutSuccess = (
  programKey: string,
  updatedWorkout: Workout,
  weekIndex: number,
  dayIndex: number,
): ProgramsActionTypes => {
  return {
    type: SAVE_WORKOUT_SUCCESS,
    programKey,
    updatedWorkout,
    weekIndex,
    dayIndex,
  };
};

const saveWorkoutFail = (): ProgramsActionTypes => {
  return {
    type: SAVE_WORKOUT_FAIL,
  };
};

export const saveWorkoutStart = (): ProgramsActionTypes => {
  return {
    type: SAVE_WORKOUT_START,
  };
};

export const saveWorkout = (
  token: string,
  programKey: string,
  weekIndex: string,
  dayIndex: string,
  userId: string,
  workout: Workout,
): AppThunk => {
  return async (dispatch) => {
    dispatch(saveWorkoutStart());
    try {
      const workoutResponse = workout.workoutId
        ? await updateWorkout(token, workout.workoutId, workout)
        : await createWorkout(token, programKey, workout, +weekIndex, +dayIndex, userId);

      const updatedWorkout: Workout = workoutResponse.data.data as Workout;

      if (updatedWorkout) {
        dispatch(saveWorkoutSuccess(programKey, updatedWorkout, +weekIndex, +dayIndex));
        return Promise.resolve(updatedWorkout);
      } else {
        dispatch(saveWorkoutFail());
        return Promise.reject(new Error('Workout not updated'));
      }
    } catch (error) {
      console.log(error);
      dispatch(saveWorkoutFail());
      return Promise.reject(error);
    }
  };
};
