import axios from 'axios';
// import { firestore } from 'utils/firebase';
import { BASE_URL } from 'utils/helpers';

export const createSubscriptionPlan = async (planName: string, amount: number, coachUserId: string) => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/v1-https-createMarketplacePlan`,
    data: {
      planName: planName,
      amount: `${+amount * 100}`,
      coachUserId: coachUserId,
    },
  });
};

// todo need to create global interface for funnel settings
interface PlanOverview {
  identifier: string;
  price: number;
  // displayPrice: number;
  description: string;
  currency: string;
  organisation: boolean;
}

export const createFunnelSubscriptionPlan = async (planOverview: PlanOverview, coachUserId: string) => {
  return axios({
    method: 'POST',
    url: `${BASE_URL}/v1-https-funnels-createPlan`,
    data: {
      planOverview,
      coachUserId,
    },
  });
};
