import React from 'react';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import classes from './ToggleSettings.module.css';

const ToggleSettings = ({ enabledCommunity, toggleCommunity, enabledDirectMessages, toggleDirectMessages }) => {
  return (
    <>
      <div className={classes.ToggleContainer}>
        <ToggleSwitch checked={enabledCommunity} onChange={toggleCommunity} />
        <span className={classes.ToggleText}>Enable Community</span>
      </div>
      <div className={classes.ToggleContainer}>
        <ToggleSwitch checked={enabledDirectMessages} onChange={toggleDirectMessages} />
        <span className={classes.ToggleText}>Enable Direct Messages</span>
      </div>
    </>
  );
};

export default ToggleSettings;
