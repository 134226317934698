import { FunctionComponent, SVGProps } from 'react';
import {
  CLIENTS,
  PROGRAMS,
  DOCUMENTS,
  EXERCISE_LIBRARY,
  CHAT,
  PAYEMENTS,
  ANALYTICS,
  CLIENTS_TRAINING,
  CLIENTS_NUTRITION,
  CLIENTS_PROGRESS,
  CLIENTS_DOCUMENTS,
} from 'utils/routes';
// import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as ProgramsIcon } from 'assets/icons/programs.svg';
import { ReactComponent as ClientsIcon } from 'assets/icons/clients-icon-new.svg';
import { ReactComponent as DocumentsIcon } from 'assets/icons/documents.svg';
import { ReactComponent as ExerciseLibraryIcon } from 'assets/icons/exercise-library.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chatbubbles.svg';
import { ReactComponent as CardIcon } from 'assets/svgs/credit-card-solid.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg';
import { ReactComponent as TrainingIcon } from 'assets/icons/training-icon.svg';
import { ReactComponent as NutritionIcon } from 'assets/icons/nutrition-icon.svg';
import { ReactComponent as ProgressIcon } from 'assets/svgs/progress-outline.svg';
import { ReactComponent as ClientDocumentsIcon } from 'assets/icons/documents-icon.svg';
type NavItemType = {
  link: string;
  SVGIcon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  title: string;
  styleType?: 'stroke' | 'fill';
};

export const getStandardNavItems: NavItemType[] = [
  // {
  //   link: DASHBOARD.URL,
  //   SVGIcon: DashboardIcon,
  //   title: DASHBOARD.TITLE,
  // },
  {
    link: CLIENTS.URL,
    SVGIcon: ClientsIcon,
    title: CLIENTS.TITLE,
  },
  {
    link: PROGRAMS.URL,
    SVGIcon: ProgramsIcon,
    title: PROGRAMS.TITLE,
  },
  {
    link: DOCUMENTS.URL,
    SVGIcon: DocumentsIcon,
    title: DOCUMENTS.TITLE,
  },
  {
    link: EXERCISE_LIBRARY.URL,
    SVGIcon: ExerciseLibraryIcon,
    title: EXERCISE_LIBRARY.TITLE,
  },
  {
    link: CHAT.URL,
    SVGIcon: ChatIcon,
    title: CHAT.TITLE,
  },
  {
    link: ANALYTICS.URL,
    SVGIcon: AnalyticsIcon,
    title: ANALYTICS.TITLE,
  },
  {
    link: PAYEMENTS.URL,
    SVGIcon: CardIcon,
    title: PAYEMENTS.TITLE,
  },
];

export const getClientProfileNavItems = (clientKey: string): NavItemType[] => {
  return [
    {
      link: `${CLIENTS_TRAINING.URL}/${clientKey}`,
      SVGIcon: TrainingIcon,
      title: CLIENTS_TRAINING.TITLE,
    },
    {
      link: `${CLIENTS_NUTRITION.URL}/${clientKey}`,
      SVGIcon: NutritionIcon,
      title: CLIENTS_NUTRITION.TITLE,
      styleType: 'stroke',
    },
    {
      link: `${CLIENTS_PROGRESS.URL}/${clientKey}`,
      SVGIcon: ProgressIcon,
      title: CLIENTS_PROGRESS.TITLE,
    },
    {
      link: `${CLIENTS_DOCUMENTS.URL}/${clientKey}`,
      SVGIcon: ClientDocumentsIcon,
      title: CLIENTS_DOCUMENTS.TITLE,
      styleType: 'stroke',
    },
  ];
};

export const isClientProfileBasePath = (path: string): boolean => {
  // Regular expression to match the pattern 'clients/{category}/{clientKey}'
  // where {category} and {clientKey} can be any string without slashes
  const clientProfilePathRegex = /^\/clients\/[^/]+\/[^/]+$/;

  return clientProfilePathRegex.test(path);
};

export const getClientKeyFromPath = (path: string): string => {
  const pathSegments = path.split('/');

  if (pathSegments.length >= 4 && pathSegments[1] === 'clients') {
    return pathSegments[3];
  }

  return '';
};
