import { Clients, CoachProfile, Plans } from 'interfaces/db';
import { FirebaseObject } from 'interfaces/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { BILLING } from 'utils/routes';

type Props = {
  clients: FirebaseObject<Clients> | null;
  token: string | null;
  userId: string | null;
  dbPlans: FirebaseObject<Plans> | null;
  onFetchCoachProfile: (userId: string, isRegistered: boolean) => any;
  onSaveCoachProfile: (coachProfile: CoachProfile, coachProfileKey: string) => any;
};

/**
 * The function `useClientCountAndProfile` is a custom hook that fetches the coach's
 * profile, updates the client count if necessary, and checks if the subscription is active.
 * @param {Props}  - - `clients`: an object containing client data
 * - - `token`: the user's token
 * - - `userId`: the user's id
 * - - `dbPlans`: an object containing the plans
 * - - `onFetchCoachProfile`: a function that fetches the coach's profile
 * - - `onSaveCoachProfile`: a function that saves the coach's profile
 * @returns an object with three properties: "isSubscriptionActive", "profileObj", and "setProfileObj".
 */
const useClientCountAndProfile = ({
  clients,
  token,
  userId,
  dbPlans,
  onFetchCoachProfile,
  onSaveCoachProfile,
}: Props) => {
  // const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>(true);
  const [profileObj, setProfileObj] = useState<CoachProfile | null>(null);
  const history = useHistory();

  useEffect(() => {
    const setCodeAndClientCount = async () => {
      if (clients && token && userId) {
        const plansArr: Plans[] = Object.values(dbPlans || {}) || [];
        if (!plansArr.length) {
          return;
        }

        await onFetchCoachProfile(userId, false)
          .then(async (response: { [key: string]: CoachProfile }) => {
            const key = Object.keys(response)[0];
            const profile: CoachProfile = response?.[key];

            const currentClientCount: number = profile?.clientCount || 0;
            const fetchedClientCount: number = Object.keys(clients).length || 0;
            if (currentClientCount !== fetchedClientCount) {
              await onSaveCoachProfile(
                {
                  ...profile,
                  clientCount: fetchedClientCount,
                },
                key,
              );
            }

            setProfileObj(profile);

            // const { isPaidSubscriptionActive, planId = 1, clientCount } = profile;

            // let maxClients = 1;
            // const plan: Plans | undefined = plansArr.find((el: Plans) => el.id === planId);

            // if (plan) {
            //   maxClients = 2;
            // }

            // if ((!isPaidSubscriptionActive && planId !== 1) || clientCount! > maxClients) {
            //   // history.push(BILLING.URL);
            //   setIsSubscriptionActive(false);
            // } else {
            //   setIsSubscriptionActive(true);
            // }
          })
          .catch((err: any) => {
            console.log(err, 'error');
          });
      }
    };

    setCodeAndClientCount();
  }, [dbPlans, history, onFetchCoachProfile, onSaveCoachProfile, clients, token, userId]);

  return { profileObj, setProfileObj };
};

export default useClientCountAndProfile;
