import React, { FC, useState, useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import TabNav from 'components/UI/TabNav/TabNav';
import { useToasts } from 'react-toast-notifications';
import { firestore, storage, getUser } from 'utils/firebase';
import { UploadTask, UploadTaskSnapshot } from '@firebase/storage-types';
import { v4 as uuidv4 } from 'uuid';

import { UpdateProfileForm, FunnelSettingsForm, Button } from 'components';
import { saveCoachProfile } from 'store/actions/auth';
import Spinner from 'components/UI/Spinner';
import withAuth from 'utils/withAuth';
import { RootState } from 'store';
import Layout from '../Layout';
import { ACCOUNT } from 'utils/routes';
import { CoachProfile as ICoachProfile } from 'interfaces/db';
import classes from './Account.module.css';
import { createFunnelSubscriptionPlan } from './functions';
import { isEligibleCoach } from 'utils/helpers';
import AlertDialog from 'components/UI/AlertDialog';

const mapStateToProps = ({ auth }: RootState) => {
  return {
    token: auth.token,
    userId: auth.userId,
    coachProfile: auth.coachProfile,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSaveCoachProfile: (coachProfile: ICoachProfile, coachProfileKey: string) =>
      dispatch(saveCoachProfile(coachProfile, coachProfileKey)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FormValues = Pick<ICoachProfile, 'fullname' | 'birthday' | 'email'>;

interface FunnelSettings {
  coachOverview: {
    coachName: string;
    title: string;
    descriptionTitle: string;
    description: string;
  };
  planOverview: {
    identifier: string;
    price: number;
    description: string;
    currency: string;
    organisation: boolean;
    isTrialEnabled: boolean;
  };
  testimonials: any[];
  faqs: any[];
  otherImages: {
    heroImageDesktop: any;
    heroImageMobile: any;
    getToKnowImage: any;
  };
}

const CoachProfile: FC<PropsFromRedux> = ({ token, onSaveCoachProfile, coachProfile, userId }) => {
  const TABS = isEligibleCoach(userId) ? ['Edit Profile', 'Subscription Funnel Settings'] : ['Edit Profile'];
  const [loading, setLoading] = useState(false);
  const [saveProfileLoading, setSaveProfileLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showSaveProfile, setShowSaveProfile] = useState(false);
  const [newFormVals, setNewFormVals] = useState<FormValues>({
    fullname: '',
    birthday: '',
    email: '',
  });
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [funnelSettings, _setFunnelSettings] = useState<FunnelSettings>({
    coachOverview: {
      coachName: '',
      title: '',
      descriptionTitle: '',
      description: '',
    },
    planOverview: {
      identifier: '',
      price: 0,
      description: '',
      currency: 'ZAR',
      organisation: false,
      isTrialEnabled: false,
    },
    testimonials: [],
    faqs: [],
    otherImages: {
      heroImageDesktop: '',
      heroImageMobile: '',
      getToKnowImage: '',
    },
  });
  const [planCode, setPlanCode] = useState<string | null>('');

  const funnelSettingsRef = useRef(funnelSettings);

  const { addToast } = useToasts();

  const setFunnelSettings = (info: any) => {
    funnelSettingsRef.current = info;
    _setFunnelSettings(info);
  };

  // ! we will eventually also fetch the prices collections to show the different pricing options
  useEffect(() => {
    const fetchPlanDoc = () =>
      new Promise((resolve) => {
        setLoading(true);
        const coachesRef = firestore?.collection('coaches');
        const coachDocument = coachesRef?.doc(userId || 'test');
        const funnelPlansRef = coachDocument?.collection('funnel-plans');
        funnelPlansRef
          .limit(1)
          .get()
          .then((funnelPlansRef) => {
            const doc = funnelPlansRef.docs[0];
            if (!doc?.exists) {
              return resolve({});
            }
            setPlanCode(doc.id);
            return resolve(doc.data());
          });
      });
    fetchPlanDoc()
      .then((result) => {
        setLoading(false);
        const funnelSettings = result as FunnelSettings;
        setFunnelSettings(funnelSettings);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const showSaveProfileModal = ({ fullname, birthday, email }: FormValues) => {
    setNewFormVals({ fullname, birthday, email });
    setShowSaveProfile(true);
  };

  const handleSaveProfile = async () => {
    if (coachProfile && token) {
      setSaveProfileLoading(true);
      const key = Object.keys(coachProfile)[0];
      const newCoachProfile = {
        ...coachProfile[key],
        ...newFormVals,
      };

      try {
        if (getCoachValues().email !== newFormVals.email) {
          // Email updated
          try {
            await getUser()?.updateEmail(newFormVals.email);
          } catch (err: any) {
            addToast(`An error has occurred updating Account`, { appearance: 'error' });
            setSaveProfileLoading(false);
            setShowSaveProfile(false);
            return;
          }
        }
        await onSaveCoachProfile(newCoachProfile, key);
        addToast('Account updated successfully', { appearance: 'success' });
        setSaveProfileLoading(false);
        setShowSaveProfile(false);
      } catch (err: any) {
        addToast(`An error has occurred updating Account`, { appearance: 'error' });
        setSaveProfileLoading(false);
        setShowSaveProfile(false);
      }
    }
  };

  const uploadProgress: (a: UploadTaskSnapshot) => any = (snapshot) => {
    setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
  };

  console.log(progress);
  const uploadError: (a: Error) => any = (error) => {
    console.log(error);
  };

  const uploadSuccess = async ({
    uploadTask,
    fileName,
    fileSize,
    testimonialId,
    key,
    resolve,
  }: {
    uploadTask: UploadTask;
    fileName: string;
    fileSize: number;
    testimonialId?: string;
    key: number | string;
    resolve: any;
  }) => {
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
      if (userId) {
        let image = {
          downloadURL: downloadURL,
          fileName: fileName || 'No file name',
          userId: userId,
          fileSize: fileSize || 50,
        };
        if (testimonialId) {
          image = {
            ...image,
            testimonialId: testimonialId,
          } as any;

          testimonialObjectUpdate(testimonialId, key, image, resolve);
        } else {
          otherImageObjectUpdate(key, image, resolve);
        }
      }
    });
  };

  const otherImageObjectUpdate = async (key: number | string, image: any, resolve: any) => {
    const funnelSettingsCopy = Object.assign({}, funnelSettingsRef.current);
    funnelSettingsCopy.otherImages = {
      ...funnelSettingsCopy.otherImages,
      [key]: image,
    };
    await setFunnelSettings(funnelSettingsCopy);
    resolve(funnelSettingsRef.current);
  };

  const testimonialObjectUpdate = async (testimonialId: string, key: number | string, image: any, resolve: any) => {
    const funnelSettingsCopy = Object.assign({}, funnelSettingsRef.current);
    funnelSettingsCopy.testimonials = funnelSettingsCopy?.testimonials.map((testimonial) => {
      if (testimonial.id === testimonialId) {
        const imageType = key ? 'afterImage' : 'beforeImage';
        return { ...testimonial, [imageType]: image };
      }
      return testimonial;
    });
    await setFunnelSettings(funnelSettingsCopy);
    resolve(funnelSettingsRef.current);
  };

  const testimonialUploadTasks = async (image: File, key: number, testimonialId: string) =>
    new Promise((resolve) => {
      const { name, size } = image;
      const storageRef = storage.ref(`coaches/${userId}/marketplace/testimonials/${testimonialId}/${uuidv4()}`);
      const uploadTask: any = storageRef.put(image);

      uploadTask.on('state_changed', uploadProgress, uploadError, () =>
        uploadSuccess({
          uploadTask: uploadTask,
          fileName: name,
          fileSize: size,
          testimonialId: testimonialId,
          key: key,
          resolve,
        }),
      );
    });

  const handleUploadTestimonialImages = async (testimonials: any[], resolve: any) => {
    await Promise.all(
      testimonials.map(async (testimonial) => {
        return new Promise((resolve) => {
          const promises: Promise<any>[] = [];
          try {
            const beforeImage = testimonial.beforeImage;
            const afterImage = testimonial.afterImage;
            if (afterImage instanceof File && !(beforeImage instanceof File)) {
              promises.push(testimonialUploadTasks(afterImage, 1, testimonial.id));
            } else if (beforeImage instanceof File && !(afterImage instanceof File)) {
              promises.push(testimonialUploadTasks(beforeImage, 0, testimonial.id));
            } else if (!(beforeImage instanceof File) && !(afterImage instanceof File)) {
              resolve(null);
              return;
            } else {
              promises.push(testimonialUploadTasks(beforeImage, 0, testimonial.id));
              promises.push(testimonialUploadTasks(afterImage, 1, testimonial.id));
            }

            Promise.all(promises).then(() => {
              resolve(funnelSettingsRef.current);
            });
          } catch (e) {
            console.log(e);
          }
        });
      }),
    ).then(() => {
      resolve(funnelSettingsRef.current);
    });

    setProgress(0);
  };

  const handleUploadOtherImages = async (otherImages: any, resolve: any) => {
    await Promise.all(
      Object.keys(otherImages).map(async (key) => {
        return new Promise((resolve) => {
          try {
            const file = otherImages[key];
            if ((typeof file === 'string' && file.length === 0) || !(file instanceof File)) {
              resolve(null);
              return;
            }
            const { name, size } = file;
            const storageRef = storage.ref(`coaches/${userId}/marketplace/otherImages/${key}/${uuidv4()}`);

            const uploadTask: any = storageRef.put(file);
            uploadTask.on('state_changed', uploadProgress, uploadError, () =>
              uploadSuccess({
                uploadTask,
                fileName: name,
                fileSize: size,
                key: key,
                resolve,
              }),
            );
          } catch (e) {
            console.log(e);
          }
        }).then(() => {
          console.log('One image uploaded successfully');
        });
      }),
    ).then(() => {
      resolve(funnelSettingsRef.current);
    });

    setProgress(0);
  };

  const handleSaveFunnel = async (payload: FunnelSettings) => {
    setFunnelSettings(payload);
    const promiseTestimonial = new Promise((resolve) => handleUploadTestimonialImages(payload?.testimonials, resolve));
    const promiseOtherImages = new Promise((resolve) => handleUploadOtherImages(payload?.otherImages, resolve));

    Promise.all([promiseOtherImages, promiseTestimonial]).then(async () => {
      try {
        const planOverview = funnelSettingsRef.current?.planOverview;
        if (coachProfile && token && userId) {
          const identifiersRef = firestore?.collection('funnel-identifiers');
          const doc = await identifiersRef.doc(planOverview?.identifier)?.get();

          if (!doc?.exists) {
            // New subscription funnel
            try {
              const response: any = await createFunnelSubscriptionPlan(planOverview, userId);
              const planCode = response.data?.data?.productId;

              setPlanCode(planCode);

              // ? should we instead pass all setttings data through and update on the backend?
              const coachesRef = firestore?.collection('coaches');
              const coachDocument = coachesRef?.doc(userId || 'test');
              const funnelPlansRef = coachDocument?.collection('funnel-plans');
              funnelPlansRef
                .doc(planCode)
                .update(funnelSettingsRef.current)
                .then(() => {
                  console.log('Completed creating subscription funnel');
                  // need to set loading to false as well
                })
                .catch((e) => {
                  console.log(e);
                });
              // });
            } catch (error) {
              console.log(error);
            }
          } else {
            // Exisiting subscription funnel
            if (doc.data()?.coachUserId === userId && planCode) {
              const coachesRef = firestore?.collection('coaches');
              const coachDocument = coachesRef?.doc(userId || 'test');
              const funnelPlansRef = coachDocument?.collection('funnel-plans');
              funnelPlansRef
                .doc(planCode)
                .update({ ...funnelSettingsRef.current })
                .then(() => {
                  console.log('Completed updating subscription funnel');
                  // need to set loading to false
                })
                .catch((e) => {
                  console.log(e);
                });
            } else {
              console.log('Identifier already exists');
            }
          }
        }
      } catch (e) {
        console.log('Error Occurred While Updating Funnel Settings:' + e);
      }
    });
  };

  const loadingCoachProfile = !Object.keys(coachProfile || {}).length;

  if (loadingCoachProfile) {
    return (
      <Layout loading={loadingCoachProfile} heading="Your Profile">
        <Spinner />
      </Layout>
    );
  }

  const getCoachValues = () => {
    const { email, code, fullname, birthday } = Object.values(coachProfile || {})[0];

    return {
      code,
      email,
      birthday,
      fullname,
    };
  };

  const BodyContent = () => {
    switch (selectedTab) {
      case 'Edit Profile':
        return (
          <div className={classes.Card}>
            <UpdateProfileForm initialValues={getCoachValues()} handleSubmit={showSaveProfileModal} />
          </div>
        );
      case 'Subscription Funnel Settings':
        return (
          <div className={classes.Card}>
            <FunnelSettingsForm
              handleSubmit={handleSaveFunnel}
              funnelValues={funnelSettings}
              loading={loading}
              coachValues={Object.values(coachProfile || {})[0]}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Layout loading={loadingCoachProfile} heading={ACCOUNT.TITLE}>
      <div className={classes.Container}>
        <TabNav setSelectedTab={setSelectedTab} selectedTab={selectedTab} tabHeadings={TABS} />
        {BodyContent()}
        <AlertDialog
          title="Save account"
          description="Changing your email address will update your login details."
          open={showSaveProfile}
          onOpenChange={setShowSaveProfile}
          cancelButton={
            <Button type="button" intent="secondary" onClick={() => setShowSaveProfile(false)}>
              Cancel
            </Button>
          }
          actionButton={
            <Button type="button" intent="danger" onClick={handleSaveProfile} loading={saveProfileLoading}>
              Save
            </Button>
          }
        />
      </div>
    </Layout>
  );
};

export default connector(withAuth(CoachProfile));
